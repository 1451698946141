import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const VivedIcon = ({
  fill = colors.gray.dark,
  width = '24px',
  ...iconProps
}) => (
  <svg version="1.1" id="Layer_1" {...iconProps} x="0" y="0" viewBox="0 0 24 24" space="preserve" enableBackground="new 0 0 24 24" width={width}>
    <title>vived icon single tone</title>
    <g className="nc-icon-wrapper" fill={fill}><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm2.8 14.7H9.2L3.7 7.8h5.6l2.8 4.4 2.8-4.4h5.6l-5.7 8.9z" /></g>
  </svg>
);

VivedIcon.propTypes = Props;
export default VivedIcon;
