import React from "react";
import { Typography } from "@rmwc/typography"; //CSS import not needed here
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { ContentContainer } from "../../components/Layout/Layout";
import { useQuery } from "../../hooks/useQuery";

export const ApplicationError = () => {
  const searchParams = useQuery();
  const errorStatusCode = searchParams.get("status");
  const errorMessage = searchParams.get("message");

  const renderHeader = () => {
    return (
      <Typography tag="h1" use="heading6">
        Oops! Something went wrong.
      </Typography>
    );
  };
  const renderStatus = () => {
    if (errorStatusCode) {
      return (
        <Typography tag="p" use="body1" theme="error">
          Status: {errorStatusCode}
        </Typography>
      );
    } else return null;
  };
  const renderMessage = () => {
    if (errorMessage) {
      return (
        <Typography tag="p" use="body1">
          {errorMessage}
        </Typography>
      );
    } else return null;
  };

  const renderError = () => {
    if (errorStatusCode || errorMessage) {
      return (
        <>
          {renderHeader()}
          {renderStatus()}
          {renderMessage()}
        </>
      );
    } else
      return (
        <>
          {renderHeader()}
          <Typography tag="p" use="body1" theme="error">
            404: Page Not Found
          </Typography>
          <Typography tag="p" use="body1">
            The requested page couldn't be found.
          </Typography>
        </>
      );
  };

  return (
    <PageWrapper protectedContent={false}>
      <ContentContainer>{renderError()}</ContentContainer>
    </PageWrapper>
  );
};
