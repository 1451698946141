import { useEffect, useState } from "react";
import { useAllCollections } from "./Collections/useAllCollections";
import { useAllBundles } from "./Collections/useAllBundles";
import useGetAllApps from "./Apps/useGetAllApps";

export default function useAllAvailableResources(channelId) {

  const [error, setError] = useState(null);
  let { collections, error:collection_error } = useAllCollections();
  let { bundles, error:bundles_error } = useAllBundles();
  let { apps, error:apps_error } = useGetAllApps()
  const [allResources, setAllResources] = useState([]);
  const [weNeedResources, setWeNeedResources] = useState(true);
  const [numOfResources, setNumOfResources] = useState(0);

  bundles = Object.values(bundles) 
  collections = Object.values(collections)
  apps = Object.values(apps)

  //Organize and set data
  useEffect(() => {
    let _resources = [...bundles, ...collections, ...apps]
    const isDataReady = _resources.length > 0
    if(!error && isDataReady && weNeedResources) {
      
      let _allResources = _resources.filter(resource => {
        resource.name = resource?.title
        resource.key = resource?.id

        if(resource.type === "bundle") {
          resource.name = resource.description
        }

        return resource
      })

      setAllResources(_allResources)
      setWeNeedResources(false)
    }
  }, [collections, bundles, channelId, weNeedResources, error, apps]);

  // Track for new data
  useEffect(() => {
    let currentResources = [...bundles, ...collections, ...apps].length

    if(numOfResources !== currentResources) {
      setNumOfResources(currentResources)
      setWeNeedResources(true)
    }
  }, [apps, bundles, collections, numOfResources])

  // Error
  useEffect(() => {

    // if(collection_error) {
    //   setError(collection_error)
    // }

    if(bundles_error) {
      setError(bundles_error)
    }

    if(apps_error) {
      setError(apps_error)
    }


  }, [collection_error, bundles_error, apps_error])

  return { allResources, waitingForContent: weNeedResources, error };
}
