import * as TYPES from "./UserTypes";

const initialState = {
  id: null,
  name: null,
  given_name: null,
  family_name: null,
  email: null,
  phone: null,
  loginStatus: TYPES.LoginEnum.init,
  groups: [],
  avatar: null,
  isLtiUser: false,
  channels: {},
  pendingChannels: {},
  isGoogleUser: false
};

const UserReducers = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.SET_USER_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload.status,
      };

    case TYPES.LOGOUT_USER:
      return {
        ...initialState,
        userData: {},
        loginStatus: TYPES.LoginEnum.isNotLoggedIn,
      };

    case TYPES.USER_SET_LTI_INFO:  
      return {
        ...state,
        isLtiUser: true,
        lti: action.payload.lti,
      };

    case TYPES.USER_SET_LTI_SESSION:
      return {
        ...state,
        ltiSession: action.payload.session,
      };

    case TYPES.SET_USER_CHANNEL_LIST:
      return {
        ...state,
        channels: action.payload.userChannels,
      };

    case TYPES.SET_PENDING_CHANNEL_LIST:
      return {
        ...state,
        pendingChannels: action.payload.pendingChannels,
      };

    case TYPES.REMOVE_PENDING_CHANNEL:
      return RemovePendingChannel(state, action.payload.channelId);

    case TYPES.ADD_USER_CHANNEL:
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.payload.channelId]: action.payload.role,
        },
      };
      
    case TYPES.REMOVE_USER_CHANNEL:
      return RemoveUserChannel(state, action.payload.channelId);

    default:
      return state;
  }
};

export default UserReducers;

function RemovePendingChannel(state, channelId) {
  const pendingChannels = { ...state.pendingChannels };
  delete pendingChannels[channelId];

  return {
    ...state,
    pendingChannels,
  };
}

function RemoveUserChannel(state, channelId) {
  const channels = { ...state.channels };
  delete channels[channelId];

  return {
    ...state,
    channels,
  };
}
