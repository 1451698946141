import {
  setUserChannelList,
  setPendingChannelList,
  addUserChannel,
  removePendingChannel,
  setUserData
} from "./UserActions";
import { call, put, select } from "redux-saga/effects";
import Actions from "../actions";

export const getUserChannels = (requestModule) =>
  function* getUserChannelsSaga(action) {
    try {
      const req = yield call(requestModule, {
        path: `userChannels`,
      });

      const userChannels = {};
      const pendingChannels = {};
      let channels = [];

      req.data.forEach((channelData) => {

        if (channelData.status && channelData.status === "pending") {
          pendingChannels[channelData.PK] = channelData.role;
        } else {
          userChannels[channelData.PK] = channelData.role;
        }

        if(channelData.subscriptions) {
          channelData.subscriptions.forEach(sub => {
            if(!userChannels[sub.SK]) {
              userChannels[sub.SK] = "subscriber"
            }
          })

          //we did what we need with subscriptions
          //we dont want to use this data to display subscriptions
          //we get proper subscription data through getChannel
          delete channelData.subscriptions
        }

        const channel = {
          id: channelData.PK,
          name: channelData.channel_name,
          image_url: channelData.image,
          banner_url: channelData.banner
        }

        if(channelData.public)
        {
          channel.public = channelData.public;
        }

        if(channelData.access_pattern)
        {
          channel.access_pattern = channelData.access_pattern;
        }

        if(channelData.embed_permission)
        {
          channel.embed_permission = channelData.embed_permission;
        }

        if(channelData.subscriptions) {
          channel.subscriptions = channelData.subscriptions;
        }

        channels.push(channel);
      });

      yield put(Actions.Channels.addChannels(channels));

      yield put(setUserChannelList(userChannels));
      yield put(setPendingChannelList(pendingChannels));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, err });
      } else {
        console.error(err);
      }
    }

    // After everything is done, do this
    yield call(requestModule, {
      path: `usercheck`,
    });
  };

export const checkIfMainUser = (requestModule) =>
  function* checkIfMainUserSaga(action) {
    try {
      let req = yield call(requestModule, {
        path: `preSignUp`,
        query: {
          email: action.payload.email,
          requestState: action.payload.requestState
        }
      });

      if (action.callback) {
        let isMainUser = req.data === "PASS" ? true : false

        yield call(action.callback, { success: true , isMainUser });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, err });
      } else {
        console.error(err);
      }
    }
  };

export const fetchAcceptChannelInvite = (requestModule) =>
  function* fetchAcceptChannelInviteSaga(action) {
    try {
      const { channelId } = action.payload;

      const state = yield select();
      let role = "none";

      if (!state.User.pendingChannels.hasOwnProperty(channelId)) {
        throw Error("Could not find invite in store");
      } else {
        role = state.User.pendingChannels[channelId];
      }

      yield call(requestModule, {
        path: `channels/${channelId}/acceptinvite`,
      });

      yield put(removePendingChannel(channelId));
      yield put(addUserChannel(channelId, role));
      
      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, err });
      } else {
        console.error(err);
      }
    }
  };

export const fetchDeclineChannelInvite = (requestModule) =>
  function* fetchDeclineChannelInviteSaga(action) {
    try {
      const { channelId, role } = action.payload;

      yield call(requestModule, {
        method:"DELETE",
        path: `channels/${channelId}/denyinvite`
      });

      if(role === "owner") {

        let state = yield select(),
        user = state.User

        user.channels[channelId] = user.pendingChannels[channelId]
        delete user.pendingChannels[channelId]

        yield put(setUserData(user))
      } else {
        yield put(removePendingChannel(channelId));
      }

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, err });
      } else {
        console.error(err);
      }
    }
  };