import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

import { TopAppBarFixedAdjust } from "@rmwc/top-app-bar";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";

import style from "./PageWrapper.module.scss";
import PageBanner from "./PageBanner";

import AppBar from "../TopAppBar/AppBar";
import {
  RootContainer,
  VerticalScrollContainer,
  MainContainer,
} from "../Layout/Layout";

import { LoginEnum } from "../../Redux/Users/UserTypes";
import { useUserState } from "../../hooks/useUserState";
import { useIsLTIUser } from "../../hooks/User/useIsLTIUser";

const PageWrapper = ({
  children,
  appBarTitle,
  fixedAppBar,
  protectedContent,
}) => {

  const [loginStatus, setLoginStatus] = useState("init");

  const history = useHistory();
  const userMeta = useUserState();
  const isLTIUser = useIsLTIUser();


  //Effect hook for is logged in
  useEffect(() => {
    if(loginStatus !== userMeta.loginStatus) {
      setLoginStatus(userMeta.loginStatus);
    }
  }, [userMeta.loginStatus, loginStatus]);

  //Effect hook for protected page redirects
  useEffect(() => {
    if (isLTIUser !== null) {
      if (
        protectedContent &&
        userMeta.loginStatus === "isNotLoggedIn" &&
        !isLTIUser
      ) {
        //Booted to the landing page with a redirect
        history.push({
          pathname: "/",
          search: `?redirect=${
            history.location.pathname + history.location.search
          }`,
        });
      }
    }
  }, [protectedContent, history, userMeta.loginStatus, isLTIUser]);

  const renderBanner = () => {
    return (
      <PageBanner
        message="You"
        // message="Tree line text string example with two actions. One to two lines is preferable. Three lines should be considered the maximum string length on desktop in order to keep messages short and actionable."
        actionPrimary={{
          label: "Affirmative action",
          onClick: () => {}
        }}
        actionDismissive={{
          label: "Dismissive action",
          onClick: () => {}
        }}
        icon="mail"
        open={false}
      />
    );
  };

  const renderContent = () => {
    const showSpinner =
      protectedContent && loginStatus !== "isLoggedIn" && !isLTIUser
        ? true
        : false;

    return (
      <VerticalScrollContainer>
        <MainContainer>
          <TopAppBarFixedAdjust />
          {renderBanner()}
          {showSpinner ? <PageLoadingIndicator /> : children}
        </MainContainer>
      </VerticalScrollContainer>
    );
  };

  return (
    <RootContainer>
      <AppBar
        fixed={fixedAppBar}
        title={appBarTitle}
        userMeta={userMeta}
        history={history}
        isSignedIn={userMeta.loginStatus === LoginEnum.isLoggedIn}
        className={style.topAppBarZ7}
      />
      {renderContent()}
    </RootContainer>
  );
};

PageWrapper.defaultProps = {
  fixedAppBar: true,
  protectedContent: true,
};

PageWrapper.propTypes = {
  /**
   * The title to be placed in the App Bar. If a title is included in the body
   * of the page then the same title should not be used here. Generally you
   * won't need to set this. Optional. Default Value: `VLP`.
   */
  appBarTitle: PropTypes.string,
  /**
   * The place to put the content to be displayed on the page. Optional
   */
  children: PropTypes.node,
  /**
   * When true, sets the App Bar position to fixed so it is always
   * displayed on the page. When false the App Bar will scroll away
   * with the rest of the page when scrolling.
   * Optional. Default Value: `false`.
   */
  fixedAppBar: PropTypes.bool,
  /**
   * When true, the page will send any users who are not signed in back
   * to the landing page with a redirect query back to the page once
   * the user sucessfully signs in.
   */
  protectedContent: PropTypes.bool,
};

export default PageWrapper;
