import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const FolderIcon = ({
  fill = colors.gray.dark,
  width = '28px',
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...iconProps}>
    <title>ic_folder_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
    </g>
  </svg>
);

FolderIcon.propTypes = Props;
export default FolderIcon;
