import * as TYPES from "./types"

export function uploadAnyLargeFile(folder, file, filename, callback) {
  return {
    type: TYPES.UNIVERSAL_UPLOAD_ANY_LARGE_FILE,
    payload: {
      folder, 
      file,
      filename
    },
    callback
  }
}