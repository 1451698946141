import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export function useAllBundles() {
    const [isFetching, setIsFetching] = useState(null);
    const [error, setError] = useState(null);

    const bundlesList = useSelector((state) => state.Collections.bundles);
    const [bundles, setBundles] = useState(bundlesList); // prevents getting stuck will null bundles list after they have all been retrieved
    const [bundlesCount, setBundlesCount] = useState(-1);
    const dispatch = useDispatch();
    
    useEffect(() => {

        if (!isFetching && !error && (bundlesList.length !== bundlesCount)) {

            // Need to fetch more collections 
            setIsFetching(true);
            dispatch(
                Actions.Collections.getAllBundles((resp) => {
                    if (!resp.success) {
                        // Error
                        setError(resp.msg);
                    }
                    // Finished call to get collections
                    setIsFetching(false);
                    setBundles(bundlesList);  
                    setBundlesCount(bundlesList.length)
                })
            );

        }
    }, [dispatch, bundlesList, bundlesCount, isFetching, error]);

    return {bundles, isFetching, error};
}
