import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import GoogleButton from "react-google-button";
// import { Auth } from "aws-amplify";
// import { v4 as uuidv4 } from "uuid";

import { Elevation } from "@rmwc/elevation";
import '@rmwc/elevation/styles';
import { Typography } from "@rmwc/typography"; // Using SASS in main, so no css needed
import { Theme } from "@rmwc/theme"; // Sass imported in main, no css needed
import { LinearProgress } from "@rmwc/linear-progress";
import '@rmwc/linear-progress/styles';


import style from "./Auth.module.scss";

import Image from "../Image";
import globalStyle from "../../styles/global.module.scss";

export const AuthPageContainer = ({ children, elevation, linearProgress }) => {
  return (
    <div className={style.container}>
      <Elevation z={elevation} wrap>
        <div className={style.card}>
          <LinearProgress 
            buffer={linearProgress.buffer}
            closed={linearProgress.closed}
            progress={linearProgress.progress}
            reversed={linearProgress.reversed}
          />
          <div className={style.cardContent} >
            {children}
          </div>
        </div>
      </Elevation>
    </div>
  );
};

AuthPageContainer.defaultProps = {
  elevation: 0,
  linearProgress: {
    closed: true
  }
}
AuthPageContainer.propTypes = {
  children: PropTypes.node,
  elevation: PropTypes.number,
  linearProgress: PropTypes.shape({
    buffer: PropTypes.number,
    closed: PropTypes.bool,
    progress: PropTypes.number,
    reversed: PropTypes.bool
  })
}

export const AuthPageHeading = ({ title, align }) => {
  const classes = align === "center" ? style.headingCenter : style.headingLeft;
  return (
    <div className={classes}>
      <Typography use="headline6" tag="h1">
        {title}
      </Typography>
    </div>
  );
};

AuthPageHeading.defaultProps = {
  align: "left"
}
AuthPageHeading.propTypes = {
  align: PropTypes.oneOf(["left", "center"]),
  title: PropTypes.string.isRequired
}

export const AuthLogoBanner = ({ align }) => {
  const classes = align === "left" ? style.logoLeft : style.logoCenter;
  return (
    <div className={classes}>
      <Image src="logo-banner-nobg" />
    </div>
  );
};

AuthLogoBanner.defaultProps = {
  align: "left"
};
AuthLogoBanner.propTypes = {
  align: PropTypes.oneOf(["left", "center"])
};

export const AuthErrorMessage = ({ isShown, message }) => {
  if (isShown) {
    return (
      <Theme wrap use={["error"]}>
        <Typography use="body1" tag="p">
          {message}
        </Typography>
      </Theme>
    );
  } else return null;
};
AuthErrorMessage.defaultProps = {
  isShown: false,
  message: ""
};
AuthErrorMessage.propTypes = {
  isShown: PropTypes.bool,
  message: PropTypes.string
};

export const AuthForgotPassword = ({email}) => {
  return (
    <Link to="/reset-password" className={globalStyle.linkNoStyle}>
      <Theme wrap use={["primary"]}>
        <Typography use="body1" tag="span">
          Forgot password?
        </Typography>
      </Theme>
    </Link>
  );
};

export const AuthActionButtons = ({ primaryAction, secondaryAction }) => {
  return (
    <div className={style.actionButtons}>
      <div className={style.actionButtonSecondary}>{secondaryAction}</div>
      <div className={style.actionButtonSecondary}>{primaryAction}</div>
    </div>
  );
};

AuthActionButtons.propTypes = {
  primaryAction: PropTypes.element.isRequired,
  secondaryAction: PropTypes.element
}

export const AuthGoogleSso = ({ label, theme, onClick}) => {
  const signInLabel = "Sign in with Google";
  const signUpLabel = "Sign up with Google";

  return (
    <div className={style.sso}>
      <Typography use="body1" tag="span">
        or
      </Typography>
      <GoogleButton
        type={theme}
        className={style.googleSignIn}
        onClick={onClick}
        label={label === "sign up" ? signUpLabel : signInLabel}
      />
    </div>
  );
}
AuthGoogleSso.defaultProps = {
  theme: "light"
}
AuthGoogleSso.propTypes = {
  label: PropTypes.oneOf([ "sign in", "sign up" ]).isRequired,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.oneOf([ "light", "dark" ])
}
