import React from "react";

import { Dialog, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import AddChannelSubscriptionForm from "./AddChannelSubscriptionForm";

const AddChannelSubscriptionDialog = ({
  open,
  onClose,
  onClosed,
  setProcessing,
  processing,
  renderToPortal,
  channelId,
}) => {
  return (
    <Dialog
      open={open}
      onClose={(evt) => onClose(evt)}
      onClosed={(evt) => onClosed(evt)}
      renderToPortal={renderToPortal}
      preventOutsideDismiss={processing}
    >
      <DialogTitle>Add new subscription</DialogTitle>
      <AddChannelSubscriptionForm
        setProcessing={setProcessing}
        processing={processing}
        channelId={channelId}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default AddChannelSubscriptionDialog;
