import React, { useState, useRef } from "react";
import {  useHistory } from "react-router-dom";
import { apiClient } from "../../utils/apiClient";
import { currentUser } from "../../utils/AmplifyAuthController";
import { useForm } from "react-hook-form";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { Select } from "@rmwc/select";
import "@rmwc/select/styles";
import style from "./RequestChannel.module.scss";
import PageWrapper from "../../components/PageWrapper/PageWrapper"; 
import {
  AuthPageContainer,
  AuthPageHeading,
  AuthLogoBanner,
  AuthErrorMessage,
  AuthActionButtons  
} from "../../components/Auth/Auth";
import { InputErrorMessage } from "../../components/Auth/AuthInputFields";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const RequestChannel = () => {  
  const [Error, setError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [country, setCountry] = useState("US");
  const [category, setCategory] = useState("K12");

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    defaultValues: { country: "US", phone: "+1" },
  });

  const firstNameRef = useRef();
  const thisHistory = useHistory();

  const countryList = countries.getNames("en");
  const categoryList = ["K12", "Higher Education", "Enterprise"];  
  
  const renderEmail = () => {
    const inputLabel = "Email";
    const inputName = "email";
    const inputType = "email";
    const inputMaxLength = 255;    
    const inputPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validationErrMsg = {
      required: "An email address is required.",
      maxLength: "The email address must be 254 characters or less.",
      pattern: "Email requires a valid email address.",
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern,
          },
        })}
        invalid={errors[inputName]}
        // onChange={() => triggerValidation(inputName)}        
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderFirstName = () => {
    const inputLabel = "First name";
    const inputName = "firstName";
    const inputType = "text";
    const inputMaxLength = 35;
    const validationErrMsg = {
      required: "First name is required.",
      maxLength: `First name must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined
        // className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
          firstNameRef.current = e;
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };  

  const renderLastName = () => {
    const inputLabel = "Last name";
    const inputName = "lastName";
    const inputType = "text";
    const inputMaxLength = 40;
    const validationErrMsg = {
      required: "Last name is required.",
      maxLength: "Last name must be 40 characters or less.",
    };

    return (
      <TextField
        // general style props
        outlined
        // className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderCompany = () => {
    const inputLabel = "Company";
    const inputName = "company";
    const inputType = "text";
    const inputMaxLength = 40;
    const validationErrMsg = {      
      maxLength: `Company must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined        
        className={style.inputFullWidth}
        // value, configuration, validation props
        //required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            //required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
          firstNameRef.current = e;
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };  

  const renderPhoneNumber = () => {
    const inputLabel = "Phone number";
    const inputName = "phone";
    const inputType = "text";
    const inputMaxLength = 20;
    const validationErrMsg = {      
      maxLength: `Phone number must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined        
        className={style.inputFullWidth}
        // value, configuration, validation props
        //required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            //required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
          firstNameRef.current = e;
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };  

  const renderCountry = () => {
    const inputLabel = "Country";
    const inputName = "country";

    return (
      <Select
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        value={country}
        onChange={(e) => {
          setCountry(e.currentTarget.value);
        }}
        label={inputLabel}
        name={inputName}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
        options={countryList}
      />
    );
  };  

  const renderRevenue= () => {
    const inputLabel = "Last years revenue";
    const inputName = "revenue";
    const inputType = "text";
    const inputMaxLength = 20;
    const validationErrMsg = {      
      maxLength: `Revenue must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined        
        className={style.inputFullWidth}
        // value, configuration, validation props
        //required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            //required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
          firstNameRef.current = e;
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };  

  const renderTerritory= () => {
    const inputLabel = "Territory you are interested in";
    const inputName = "territory";
    const inputType = "text";
    const inputMaxLength = 40;
    const validationErrMsg = {      
      maxLength: `Territory must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined        
        className={style.inputFullWidth}
        // value, configuration, validation props
        //required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            //required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
          firstNameRef.current = e;
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };      

  const renderOtherProducts= () => {
    const inputLabel = "Other products you offer";
    const inputName = "products";
    const inputType = "text";
    const inputMaxLength = 50;
    const validationErrMsg = {      
      maxLength: `Products must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined        
        className={style.inputFullWidth}
        // value, configuration, validation props
        //required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            //required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
          firstNameRef.current = e;
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };      

  const renderCategory = () => {
    const inputLabel = "Category";
    const inputName = "category";

    return (
      <Select
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        value={category}
        onChange={(e) => {
          setCategory(e.currentTarget.value);
        }}
        label={inputLabel}
        name={inputName}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
        options={categoryList}
      />
    );
  };  

  const renderForm = () => {    
    return (
      <>        
        <div className={style.name}>
          <div className={style.firstName}>{renderFirstName()}</div>
          <div className={style.lastName}>{renderLastName()}</div>
        </div>
        <div>{renderCompany()}</div>
        <div>{renderEmail()}</div>       
        <div>{renderPhoneNumber()}</div> 
        <div>{renderCountry()}</div>      
        <div>{renderRevenue()}</div>  
        <div>{renderTerritory()}</div>  
        <div>{renderOtherProducts()}</div>
        <div>{renderCategory()}</div>
      </>
    );    
  };


  const onSubmit = (data, e) => {    
    submitRegistration(data)    
  };

  const submitRegistration = async(data) => {      
    setIsSubmitting(true);

    let body = {
      to: "tom@vivedlearning.com",
      email: data.email,
      name: data.firstName + " " + data.lastName,
      company: data.company,
      phone: data.phone,
      country: countryList[country],
      revenue: data.revenue,
      territory: data.territory,
      products: data.products,
      category: category
    }          
    
    try{
      const user = await currentUser();
      if (!user) throw Error("Could not get user");

      await apiClient({      
        userToken: user.signInUserSession.idToken.jwtToken,
        path: `channels/request`,
        data: body,
        method: "POST",
      });

      // success
      setIsSubmitting(false);

      snackbarQueue.notify({
        title: <b>Success!</b>,
        body: `Request has been sent.`,
      });

      // wait a second
      await new Promise(resolve => setTimeout(resolve, 2000));

      // redirect to dashboard
      thisHistory.push("/dashboard");
  }
  catch (e) {
    // fail
    setIsSubmitting(false);
    setError(true);
    setErrorMessage(e);
  }
    
  };    

  const renderContent = () =>{
    return(
      <AuthPageContainer
        elevation={0}
        linearProgress={{ closed: isSubmitting ? false : true }}
      >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthLogoBanner align="left" />
        <AuthPageHeading title="Request to create your own channel" />
        <AuthErrorMessage
          isShown={Error}
          message={ErrorMessage}
        />
        <div>          
          <div>{renderForm()}</div>
          <AuthActionButtons
            primaryAction={
              <Button
                raised
                label="Submit"
                type="submit"
              />
            }            
          />
        </div>
      </form>      
    </AuthPageContainer>
    );
  }

  return (
    <PageWrapper>
      {renderContent()}
    </PageWrapper>
  );
};

export default RequestChannel;
