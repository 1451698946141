import React from "react";

import { Theme } from "@rmwc/theme"; // Using SASS in main instead of css here
import { Typography } from "@rmwc/typography"; // Using Sass in main. CSS not needed here.

import style from "./ThreeKeyPoints.module.scss";

export const ThreeKeyPoints = ({ className, children }) => {
  const containerClasses = [style.container, className ? className : null].join(" ");

  return <div className={containerClasses}>{children}</div>;
};

export const KeyPoint = ({ graphic, heading, description }) => {
  return (
    <Theme use={["surface"]} wrap>
      <div className={style.point}>
        <div className={style.graphic}>{graphic}</div>
        <Theme use={["primary"]} wrap>
          <Typography use="subtitle1" tag="div">
            {heading}
          </Typography>
        </Theme>
        <Typography use="body2" tag="div">
          {description}
        </Typography>
      </div>
    </Theme>
  );
};
