import { useState, useEffect } from "react";
import { useUserIsVivedEmployee } from "./useUserIsVivedEmployee";

/**
 * @description This hook determins if the current user has the permission to create a new channel. Initializes as false and updates to true if permissions are found.
 * @returns { boolean } canCreateChannel
 */
export const useUserCanCreateChannel = () => {
  const isEmployee = useUserIsVivedEmployee();
  const [canCreateChannel, setCanCreateChannel] = useState(false);

  useEffect(() => {
    if (isEmployee) {
      setCanCreateChannel(true);
    } else {
      setCanCreateChannel(false);
    }
  }, [isEmployee]);

  return canCreateChannel;
};
