import { Theme } from "@rmwc/theme";
import { Typography } from "@rmwc/typography";
import React from "react";

import { SectionHeadlineRow } from "../../components/Layout/Headings";
import { ContentContainer } from "../../components/Layout/Layout";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { BasicInformation } from "../../components/UserProfile/BasicProfile/BasicInformation";
// import { Connections } from "../../components/UserProfile/Connections/Connections";
import { ProfileAvatar } from "../../components/UserProfile/ProfileAvatar/ProfileAvatar";

import { useUserState } from "../../hooks/useUserState";

export const AccountEdit = () => {
  const pageTitle = "Edit account";
  const {
    email,
    givenName: firstName,
    familyName: lastName,
    fullName,
    avatar,
    isGoogleUser,
  } = useUserState();

  const googleFederatedUserMessage =
    "Your VIVED account was created using Single Sign On (SS0) with Google. This means your profile information is managed through your Google account. To update your user profile, you need to update it directly in your Google account and changes will automatically be reflected here.";

  const renderFederatedUserNotice = () => {
    if (isGoogleUser) {
      return (
        <Theme use={["primaryBg", "onPrimary"]} wrap>
          <Typography tag="p" use="body2" style={{ padding: "16px" }}>
            {googleFederatedUserMessage}
          </Typography>
        </Theme>
      );
    }
  };
  const editingProhibited = isGoogleUser;

  return (
    <PageWrapper>
      <ContentContainer>
        <SectionHeadlineRow
          title={pageTitle}
          typographyStyle="headline6"
          tag="h1"
        />
        {renderFederatedUserNotice()}
        <BasicInformation
          defaultValues={{
            email,
            firstName,
            lastName,
          }}
          editingProhibited={editingProhibited}
        />
        <ProfileAvatar
          name={fullName}
          image={avatar}
          editingProhibited={editingProhibited}
        />
        {/* <Connections isGoogleUser={isGoogleUser}/> */}
      </ContentContainer>
    </PageWrapper>
  );
};
