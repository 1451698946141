import PropTypes from "prop-types";
import React from 'react';

import { Typography } from "@rmwc/typography"; // Using Sass in main. CSS not needed here.
import { Theme } from "@rmwc/theme"; // Using SASS in main instead of css here


import style from "./Hero.module.scss";

import { usePlayerState } from "../../../hooks/usePlayerState";

function getImagesForSrcSet(image) {
  let imageSrcs = [];
  if (typeof image === "object"){
    imageSrcs = image.map((i) => {
      return `${i.src} ${i.size}\n`
    })
    return imageSrcs;
  }
}

export const Hero = ({ imageDesktop, imageMobile, imageAlt, headline, subHeadline, primaryAction, secondaryAction}) => {
  const device = usePlayerState();

  const imageSrcSet = {};
  imageSrcSet.mobile = getImagesForSrcSet(imageMobile);
  imageSrcSet.desktop = getImagesForSrcSet(imageDesktop);

  const renderSecondaryAction = () => <div className={style.action}>{secondaryAction}</div>;

  const renderMobile = () => {
    return (
      <div className={style.container}>
        <img srcSet={imageSrcSet.mobile ? imageSrcSet.mobile : imageMobile} alt={imageAlt} className={style.image}/>
        <div className={style.content}>
          <Theme wrap use={["textPrimaryOnDark"]}>
            <div className={style.copy}>
              
              <Typography use="headline5" tag="div" className={style.headline}>{headline} </Typography>
              <Typography use="body1" tag="div">{subHeadline}</Typography>
              
            </div>
            </Theme>
            <div className={style.actions}>
              {secondaryAction && renderSecondaryAction()}
              <div className={style.action}>{primaryAction}</div>
            </div>
        </div>
      </div>
    )
  }

  const renderDesktop = () => {
    return (
      <div className={style.container}>
        <img srcSet={imageSrcSet.desktop ? imageSrcSet.desktop : imageDesktop} alt={imageAlt} className={style.image}/>
        <div className={style.content}>
          <Theme wrap use={["textPrimaryOnDark"]}>
            <div className={style.copy}>
              <Typography use="headline5" tag="div" className={style.headline}>{headline} </Typography>
              <Typography use="body1" tag="div">{subHeadline}</Typography>
            </div>
            </Theme>
            <div className={style.actions}>
              {secondaryAction && renderSecondaryAction()}
              <div className={style.action}>{primaryAction}</div>
            </div>
        </div>
      </div>
    )
    
  }


  if (device?.windowResolution.x <= 599){
    return renderMobile();
  } else return renderDesktop();
}

Hero.defaultProps = {
  headline: "A short statement that matches what was clicked.",
  subHeadline: "A short paragraph or a sub-headline that provides extra information.",
  imageDesktop: [
    {
      src: "https://via.placeholder.com/3200x1800.jpg",
      size: "4x"
    },
    {
      src: "https://via.placeholder.com/2400x1350.jpg",
      size: "3x"
    },
    {
      src: "https://via.placeholder.com/1600x900.jpg",
      size: "2x"
    },
    {
      src: "https://via.placeholder.com/800x450.jpg",
      size: "1x"
    },
    {
      src: "https://via.placeholder.com/400x225.jpg",
      size: "0.5x"
    }
  ],
  imageMobile: [
    {
      src: "https://via.placeholder.com/1800x2400.jpg",
      size: "4x"
    },
    {
      src: "https://via.placeholder.com/1350x1800.jpg",
      size: "3x"
    },
    {
      src: "https://via.placeholder.com/900x1200.png",
      size: "2x"
    },
    {
      src: "https://via.placeholder.com/450x600.png",
      size: "1x"
    },
    {
      src: "https://via.placeholder.com/225x300.jpg",
      size: "0.5x"
    }
  ],
  imageAlt: "Placeholder image"
}

Hero.propTypes = {
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
  primaryAction: PropTypes.element.isRequired,
  secondaryAction: PropTypes.element,
  imageDesktop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
    }))
  ]),
  imageMobile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
    }))
  ]),
  imageAlt: PropTypes.string.isRequired
}
