import React, { useState } from "react";

import { Theme } from "@rmwc/theme"; // Using Sass in main. Css not needed here.
import { Typography } from "@rmwc/typography"; // Using Sass in main. Css not needed here.
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import style from "./InteractiveDemo.module.scss";

import PlayerShadowDom from "../../PlayerShadowDom/PlayerShadowDom";
import { CircularProgress } from "@rmwc/circular-progress";

import demoActivity from "../demoActivity.json";

export const InteractiveDemo = ({ headline }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerLoading, setPlayerLoading] = useState(false);

  const handleStartActivity = () => {
    setPlayerLoading(true);
    setTimeout(() => {
      setIsPlaying(true);
      setPlayerLoading(false);
    }, 1500)
  }

  const activityOptions = {
    title: "",
  };

  const onPlayerCreated = (player) => {
    player.LoadActivityFromJSONString(demoActivity, activityOptions);
  }

  const renderPreview = () => {
    const icon = playerLoading ? <CircularProgress /> : "play_circle_filled"
    return (
      <>
          <img src="https://via.placeholder.com/1600x900/212121/00a8e1.webp?text=Activity+Preview" alt="placeholder" className={style.previewImage}/>
          <div className={style.playerOverlay}>
            <Theme use="textIconOnDark" tag="div" className={style.overlayAction}>
              <Button icon={icon} label="Start Activity" raised theme={['secondaryBg', 'onSecondary']} onClick={handleStartActivity} />
            </Theme>
            
          </div>
      </>
    )
  }
  const renderPlayer = () => {
    return (
      <PlayerShadowDom
        size="fill"
        onPlayerCreated={(player) => onPlayerCreated(player)}
        playerOptions={activityOptions}
        appBar={false}
      />
    )
  }

  const renderContent = () => {
    if (isPlaying) {
      return renderPlayer();
    } else return renderPreview();
  }

  const renderComponent = () => {
    return (
      <div className={style.container}>
      <Typography use="headline5" tag="div" className={style.heading}>
        {headline}
      </Typography>
      <div className={style.player}>
        <div className={style.content}>
          {renderContent()} 
        </div>
      </div>
    </div>
    )
  }

  return renderComponent();
};
