import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";

import style from "./ChannelUsers.module.scss";

import ChannelMembers from "../../components/ChannelMembers/ChannelMembers";
import JoinCodeDisplay from "../../components/JoinCodeDisplay/JoinCodeDisplay";
import PageError from "../../components/Error/PageError";
import { ContentContainer } from "../../components/Layout/Layout";
import { SectionHeadlineRow } from "../../components/Layout/Headings";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import AddMemberToChannel from "../../components/Dialog/AddMemberToChannel/AddMemberToChannel";
import { ChannelUserLimitCount } from "../../components/ChannelUserLimitCount/ChannelUserLimitCount";
import { dialogQueue } from "../../components/Dialog/dialogQueue";

import { CHANNEL_JOIN_CODE_TYPES } from "../../constants/channelJoinCodeTypes";
import { CHANNEL_VISIBILITY_TYPES } from "../../constants/channelVisibilityTypes";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";
import { requestMoreUsers } from "../../Redux/Channels/ChannelActions"
import store from "../../store";

import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useChannel } from "../../hooks/Channel/useChannel";

const ChannelUsers = ({ match }) => {
  //Props
  const { channelId } = useParams()

  //States
  const [error, setError] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [userLimit, setUserLimit] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  //Hooks
  const { role } = useChannelRole(channelId);
  const { channel, error: getChannelError } = useChannel(channelId);
  const history = useHistory();

  const enforceUserLimit =
    channel?.access_pattern === CHANNEL_VISIBILITY_TYPES.PRIVATE;
 

  useEffect(() => {
    if (isReady) return; //This effect only takes care of waiting for everything to get setup

    if (channel && role) {
      if (role === "owner" || role === "admin") {
        setIsReady(true);
      } else {
        //Give 'em the boot! Not supposed to be here
        history.push(`/channel/${channelId}`);
      }
    }
  }, [role, isReady, channel, history, channelId]);

  //Monitor for errors
  useEffect(() => {
    if (getChannelError) {
      setError("Something went wrong while getting channel " + getChannelError);
    }
  }, [getChannelError]);

  useEffect(() => {
    if(channel) {
      setTotalUsers(channel.total_users)
      setUserLimit(channel.user_limit)

      let unsubscrib = store.subscribe(() => {
        let _channel = store.getState().Channels.list[channel.id]
        if(_channel.total_users !== totalUsers) {
          setTotalUsers(_channel.total_users)
        }
      })

      return () => {
        unsubscrib()
      }
    }
  }, [channel, userLimit, totalUsers]);

  const onRequestChangeClick = () => {
    dialogQueue
      .prompt({
        title: "Request change to user limit",
        body: "Enter the total number of users you would like to request for this channel’s user limit.",
        acceptLabel: "Submit Request",
        cancelLabel: "Cancel",
        inputProps: { outlined: true, label: "Quantity" },
      })
      .then((user_limit) => {

        let callback = (result) => {

          if(result.success) {
            snackbarQueue.notify({
              title: <b>Success!</b>,
              body: "Your request has been submitted.",
            });
          } else {
            snackbarQueue.notify({
              title: <b>Error</b>,
              body: "Something went wrong, please try again.",
            });
          }
        }

        if(user_limit) {
          store.dispatch(requestMoreUsers(channelId, user_limit, callback))
        }
      })
  };

  const RenderUserLimit = () => {
    if (enforceUserLimit) {
      return (
        <>
          <SectionHeadlineRow
            title="User limit"
            tag="h2"
            typographyStyle="headline6"
          />
          <ChannelUserLimitCount
            userCount={totalUsers}
            userLimit={userLimit}
            onRequestChangeClick={onRequestChangeClick}
          />
        </>
      );
    } else return null;
  };

  const userLimitReached = (channel) => {
    if (!channel.user_limit || !channel.total_users) {
      // not found
      console.warn(
        "This channel didn't have a user limit or total users: ",
        channel
      );
    }
    if (channel.total_users >= channel.user_limit) {
      return true;
    } else {
      return false;
    }
  };

  const RenderJoinCode = () => {
    if (channel?.join_code_status === CHANNEL_JOIN_CODE_TYPES.ENABLED) {
      return (
        <>
          <SectionHeadlineRow
            title="Channel join code"
            typographyStyle="headline6"
            tag="h2"
          />
          <JoinCodeDisplay
            channelData={channel}
            userLimitReached={userLimitReached(channel)}
          />
        </>
      );
    } else return null;
  };

  const RenderChannelMembers = () => {
    return (
      <>
        <SectionHeadlineRow
          title="Channel users"
          typographyStyle="headline6"
          tag="h2"
          actionButton={
            isInviting ? 
            (
              <CircularProgress />
            ) : (
                <Button
                  outlined
                  label="Invite User"
                  icon="person_add"
                  onClick={
                    enforceUserLimit && userLimitReached(channel)
                      ? fireUserLimitReachedDialog
                      : fireAddMemberDialog
                  }
                />
              )
          }
        />
        <ChannelMembers channelId={channel.id} channelName={channel.name}/>
      </>
    );
  };

  const fireUserLimitReachedDialog = () => {
    dialogQueue.alert({
      title: "User Limit Reached",
      body:
        "This channel has reached or exceeded its user limit. To invite a new user, reduce the user count by removing users or pending users.",
    });
  };

  const fireAddMemberDialog = () => {
    setOpenAddMemberDialog(true);
  };

  const onCloseAddMemberDialog = (evt) => {
    setOpenAddMemberDialog(false);
  };

  const RenderAddMemberDialog = () => {
    return (
      <AddMemberToChannel
        open={openAddMemberDialog}
        onClose={onCloseAddMemberDialog}
        setInviting={setIsInviting}
        channelId={channelId}
        renderToPortal
      />
    );
  };

  const RenderContent = () => {
    return (
      <ContentContainer>
        <SectionHeadlineRow
          title="Manage channel users"
          typographyStyle="headline5"
          tag="h1"
        />
        <div className={style.group}>
          <div className={style.joinCode}>
            <RenderJoinCode />
          </div>
          <div className={style.userLimit}>
            <RenderUserLimit />
          </div>
        </div>
        <RenderChannelMembers />
        <RenderAddMemberDialog />
      </ContentContainer>
    );
  };

  const RenderContainer = () => {
    if (error) {
      return <PageError error={true} errorMessage={error} />;
    } else if (isReady) {
      //Render the main content
      return <RenderContent />;
    } else {    
      return <PageLoadingIndicator />;
    }
  };

  return (
    <PageWrapper fixedAppBar={false}>
      <RenderContainer />
    </PageWrapper>
  );
};

export default ChannelUsers;
