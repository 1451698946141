import React from "react";
import { PropTypes } from "prop-types";
import Error from "../../components/Error/Error";

import style from "./PlayerShadowDom.module.scss";

/**
 * Renders the WebGL player inside a shadowDOM.
 *
 * Usage :
 * ```js
 * <PlayerShadowDom
 *   onPlayerCreated={player => this.onPlayerCreated(player)}
 *   size='normal'
 *   title={activity.title}
 *   appBar
 * />
 * ```
 * @arguments {Component<Props, State>}
 *
 */

class CustomPlayerShadowDom extends React.Component {
  innerHtml = "";
  playerHasBeenCreated = false;
  shadow;
  assetsDiv;
  shadowParent;
  playerContainer;
  mountingInterval = setInterval(()=>{

    if(window[this.props.playerName] && this.playerContainer)
    {
      clearInterval(this.mountingInterval);
      window[this.props.playerName].mount(this.playerContainer, {});
    } else {
      this.setState({attemptsToMount: this.state.attemptsToMount + 1})

      if(this.state.attemptsToMount > 30) {
        clearInterval(this.mountingInterval);
        this.setState({failedToMount: true})// this is just to get it to re-render
      }
    }
  }, 100);

  state = {
    innerHtml: "",
    failedToMount: false,
    attemptsToMount: 0
  };

  componentDidMount() {
    /// Start ShadowDOM code
    this.assetsDiv = document.createElement("div");
    this.shadowParent = document.getElementById("player-shadow");

    if (this.props.shadowDom) {
      this.shadow = this.shadowParent.attachShadow({ mode: "open" });

      this.shadow.innerHTML = `
    <style> .player-container{ display: flex; height: 100%; width: 100%; } </style>
    <div id="player-container-authoring" class="player-container" />
    `;

      this.appendAssets(this.shadow);
    } else {
      this.appendAssets(this.shadowParent);
    }
  }

  componentDidUpdate() {
    if (!this.playerHasBeenCreated) {

      if (this.props.shadowDom) {

        this.playerContainer = this.shadow.getElementById(
            "player-container-authoring"
          );

        if (this.playerContainer) {

          Object.defineProperty(this.playerContainer, "ownerDocument", {
            value: this.shadow,
          });
          this.shadow.createElement = (...args) =>
            document.createElement(...args);
          this.shadow.createElementNS = (...args) =>
            document.createElementNS(...args);
          this.shadow.createTextNode = (...args) =>
            document.createTextNode(...args);
          /// End ShadowDOM code

          this.playerHasBeenCreated = true;
        }
      }
    }
  }

  componentWillUnmount() {
    
    this.unmountPlayer(this.props.playerName, this.playerContainer);
    
    if(this.mountingInterval) {
      clearInterval(this.mountingInterval);
    }
  }

  unmountPlayer(name, element) {
    if(window[name]) {
      window[name].unmount(element);
    }
  }

  appendAssets = (shadow) => {
    this.props.playerAssets.forEach((assetUrl) => {
      let extension = "js"
      if(assetUrl.includes(".css")) {
        extension = "css"
      }
      let node = null;
      if (extension === "js") {
        node = document.createElement("script");
        node.src = assetUrl;
      } else if (extension === "css") {
        node = document.createElement("link");
        node.type = "text/css";
        node.rel = "stylesheet";
        node.href = assetUrl;
      }
      this.assetsDiv.appendChild(node);
    });
    shadow.appendChild(this.assetsDiv);
  };

  render() {
    const classes = [
      style.container,
      this.props.size === "normal" ? style.containerNormal : null,
      this.props.size === "freeplay" ? style.containerFreeplayFullsize : null,
      this.props.size === "authoring" ? style.containerAuthoring : null,
      this.props.size === "embed" ? style.containerEmbed : null,
    ].join(" ");

    if(this.state.failedToMount) {
      return (
        <>
          <Error message="Something has gone wrong, refresh the page to try again"/>
        </>
      );
    } else {
      return (
        <>
          <div id="player-shadow" className={classes} />
        </>
      );
    }
  }
}
CustomPlayerShadowDom.defaultProps = {
  shadowDom: false
}
CustomPlayerShadowDom.propTypes = {
  playerName: PropTypes.string,
  size: PropTypes.oneOf(["normal", "freeplay", "authoring", "embed"])
    .isRequired,
  playerAssets: PropTypes.array,
  shadowDom: PropTypes.bool,
};

export default CustomPlayerShadowDom;
