import {
  call,
} from 'redux-saga/effects';

export const getPlaylists = requestModule => function* getPlaylistsSaga(action) {
  try {
    const { filter } = action.payload;
    const module = 'api/v1/playlists';

    const dispatchAction = 'PLAYLIST_SET_CURRENT_DOCS';

    yield requestModule({
      dispatchAction,
      query: {
        ...filter,
      },
      module,
    });
  } catch (err) {
    throw err;
  }
};

export const playlistSubscribe = requestModule => function* playlistSubscribeSaga(action) {
  try {
    const query = {};
    const { id } = action.payload;
    const module = `api/v1/playlists/${id}/subscribe`;
    yield requestModule({
      query,
      module,
      method: 'put',
      data: {},
    });
    if (action.cb) {
      yield call(action.cb);
    }
  } catch (err) {
    throw err;
  }
};
