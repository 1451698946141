import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export default function useChannelModels(channelId) {
    // State
    const [fetching, setFetching] = useState(false);
    const [channelModelsError, setError] = useState(null);
    const [channelModels, setChannelModels] = useState([]); 
    const collections = useSelector((state) => state.Channels?.list[channelId]?.collections);

    const dispatch = useDispatch();

    //Get collections if we need too
    useEffect(() => {
      if (
        !fetching &&
        !channelModelsError &&
        !collections
      ) {

        setFetching(true);
    
        dispatch(
          Actions.Channels.fetchChannelContent(channelId, (resp) => {
            if (!resp.success) {
              setError(resp.msg);
            }
            setFetching(false);
          })
        );
      }
    }, [channelId, dispatch, fetching, channelModelsError, collections]);

    //Set collections
    useEffect(() => {
      if(collections && collections.length !== channelModels.length) {
        setChannelModels(collections);
      }

    }, [collections, channelModels.length, channelId]);
    
    return { channelModels, fetching, channelModelsError };
}