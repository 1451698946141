import PropTypes from "prop-types";
import React from 'react';

import { Banner, BannerContent, BannerMedia, BannerMessage, BannerActions, BannerActionButton } from "../MaterialBanner/Banner";

const PageBanner = ({open, message, icon, actionPrimary, actionDismissive}) => {
  const renderBannerMedia = () => {
    if (icon){
      return (
        <BannerMedia icon={icon} />
      )
    }
  }

  const renderActionButtonPrimary = () => {
    if (actionPrimary.label && actionPrimary.onClick){
      return (
        <BannerActionButton
          label={actionPrimary.label}
          onClick={actionPrimary.onClick}
        />
      )
    }
    
  }

  const renderActionButtonDismissive = () => {
    if (actionDismissive.label && actionDismissive.onClick){
      return (
        <BannerActionButton
          label={actionDismissive.label}
          onClick={actionDismissive.onClick}
        />
      )
    }

  }
  const renderActions = () => {
    return (
      <BannerActions>
        {renderActionButtonDismissive()}
        {renderActionButtonPrimary()}
      </BannerActions>
    )
  }
  return (
    <Banner open={open}>
      <BannerContent>
        {renderBannerMedia()}
        <BannerMessage message={message} />
        {renderActions()}
      </BannerContent>
    </Banner>
  )
}

/**
 * Props for an action button in the banner component.
 */
const bannerActionPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
})
PageBanner.propTypes = {
  actionDismissive: bannerActionPropType,
  actionPrimary: bannerActionPropType,
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

export default PageBanner