import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { save, load } from 'redux-localstorage-simple';
// import { createLogger } from 'redux-logger';
import reducers from './Redux/reducers';
import sagas from './Redux/sagas';

const composeEchancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const options = {
  namespace: 'ouzel-web',
  states: ['Users'],
};

// const skipLoggerActions = [''];

const reducersCombined = combineReducers(reducers);

const sagaMiddleware = createSagaMiddleware();

// const mylogger = createLogger({
//   predicate: (getState, action) => skipLoggerActions.indexOf(action.type) === -1,
// });


const store = createStore(
  reducersCombined,
  load(options),
  composeEchancers(applyMiddleware(sagaMiddleware, save(options))),
  // composeEchancers(applyMiddleware(sagaMiddleware, mylogger, save(options))),
);

sagaMiddleware.run(sagas);

window.store = store;
export default store;
