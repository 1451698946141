import React, { useState, useEffect } from "react";
import { useClipboard } from "use-clipboard-copy";
import { InlineShareButtons } from "sharethis-reactjs";

import { Dialog, DialogTitle, DialogContent } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { Typography } from "@rmwc/typography"; // Using Sass in main, css not needed
// import { Checkbox } from "@rmwc/checkbox";
// import "@rmwc/checkbox/styles";

import style from "./DialogShare.module.scss";

import { snackbarQueue } from "../Snackbar/snackbarQueue";
import { CHANNEL_EMBED_TYPES } from "../../constants/channelEmbedTypes";
import { useChannelEmbedPermissions } from "../../hooks/Channel/useChannelEmbedPermissions";
import { useLtiUrlParameters } from "../../hooks/LTI/useLtiUrlParameters";

const DialogShare = ({
  open,
  onClose,
  renderToPortal,
  onEmbed,
  onLtiLinkToActivity,
  onLtiLinkToActivityNoAuth,
  activityMeta,
  isLtiUser,
  ltiUser,
  onLtiEmbed,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [linkToShare, setLinkToShare] = useState(window.location.href);
  const [embedDisabled, setEmbedDisabled] = useState(false);
  const {
    ltiSupportsUrl,
    ltiSupportsContentItemIframe,
    ltiSupportsContentItemLaunchUrl,
    canvasCourseId,
    canvasApiDomain
  } = useLtiUrlParameters();

  const clipboard = useClipboard({
    copiedTimeout: 1500, //ms
    onSuccess() {
      snackbarQueue.notify({ body: "Link copied to clipboard" });
    },
    onError() {
      snackbarQueue.notify({
        title: <b>Error</b>,
        body: "Link could not be copied",
      });
    },
  });
  const channelEmbedPermissions = useChannelEmbedPermissions(activityMeta.channelId);

  useEffect(() => {
    if (channelEmbedPermissions) {
      if (
        channelEmbedPermissions.permission ===
        CHANNEL_EMBED_TYPES.DISABLED
      ) {
        setEmbedDisabled(true);
      }
    }
  }, [channelEmbedPermissions])

  const embedMessage = "This channel does not allowing activities to be embedded.";

  const onCopyLink = (text) => {
    clipboard.copy(text);
  };

  const renderShareThis = () => {
    let { title, description, image } = {};
    if (activityMeta) {
      title = activityMeta.title;
      description = activityMeta.description;
      image = activityMeta.image;
    }
    return (
      // <div
      //   className="sharethis-inline-share-buttons"
      //   // Specifies URL (can be shortened URL) that you would like shared
      //   data-url={linkToShare}
      //   // Specifies title that you would like shared
      //   data-title={title}
      //   // Specifies link to image you would like displayed in the shared content
      //   data-image={image}
      //   // Specifies summary text/description you wish to share
      //   data-description={description}
      // />
      <InlineShareButtons
        config={{
          alignment: "left",
          color: "social",
          enabled: true,
          font_size: 16,
          labels: "null",
          language: "en",
          networks: [
            "facebook",
            "twitter",
            "reddit",
            "email",
            "sms",
            "sharethis",
          ],
          padding: 12,
          radius: 4,
          show_total: false,
          // Optional Parameters
          size: 48,
          url: linkToShare,
          image: image,
          description: description,
          title: title,
          message: `Check out this activity from the VIVED Learning Platform: ${title}.`,
          subject: `${title} from VLP`,
          username: "VIVEDLearning",
        }}
      />
    );
  };

  const renderLtiButtons = () => {
    if (isLtiUser) {
      if (ltiSupportsContentItemIframe || ltiSupportsContentItemLaunchUrl || ltiSupportsUrl){
        const lmsName = ltiUser?.tool_consumer_info_product_family_code;
        return (
          <div>
            <Typography use="overline" tag="div" className={style.shareTitle}>
              Share with {lmsName ? lmsName : "Your LMS"}
            </Typography>
            <div className={style.buttonsContainer}>
              {renderLtiLinkToActivityButton()}
              {renderLtiEmbedButton()}
            </div>
          </div>
        );
      }
    }
  };

  const renderLtiLinkToActivityButton = () => {
    if (ltiSupportsContentItemLaunchUrl) {
      return (
        <Button
          raised
          icon="insert_link"
          label="Insert activity link"
          className={style.item}
          onClick={onLtiLinkToActivity}
        />
      );
    }
    else if (ltiSupportsUrl) {
      return (
        <Button
          raised
          icon="insert_link"
          label="Insert activity link (auth required)"
          className={style.item}
          onClick={onLtiLinkToActivityNoAuth}
        />
      );
    }
    else {
      return null;
    }
  }

  const renderLtiEmbedButton = () => {
    if (ltiSupportsContentItemIframe && canvasCourseId && canvasApiDomain) {
      return (
        <Button
          raised
          disabled={embedDisabled}
          icon="insert_drive_file"
          label="Embed activity"
          onClick={onLtiEmbed}
        />
      );
    }
    else {
      return null;
    }
  }

  const renderEmbedButton = () => {
    return (
      <div className={style.buttonsContainer}>
        <Button
          outlined
          label="Embed activity"
          disabled={embedDisabled}
          className={style.item}
          onClick={onEmbed}
        />
        {embedDisabled ? (
          <Typography use="body2" tag="div">
            {embedMessage}
          </Typography>
        ) : null}
      </div>
    );
  };

  const renderShareButtons = () => {
    return (
      <div className={style.shareThisContainer}>
        <Typography use="overline" tag="p" className={style.shareTitle}>
          Share with social
        </Typography>
        {renderShareThis()}
      </div>
    );
  };

  const renderLinkBox = () => {
    return (
      <div>
        <Typography use="overline" tag="div" className={style.shareTitle}>
          Link to activity
        </Typography>

        <div className={style.linkContainer}>
          <TextField
            outlined
            value={linkToShare}
            ref={clipboard.target}
            className={style.linkInput}
            onChange={() => {
              return;
            }} // This event is required, but the input shouldn't be mutated, so do nothing onChange
            readyonly={linkToShare} // Required for a readonly field
          />
          <Button
            label={!clipboard.copied ? "Copy" : "Copied"}
            disabled={clipboard.copied}
            icon="assignment"
            data-mdc-dialog-button-default
            // onClick={clipboard.copy}
            style={{ marginBottom: "8px" }}
            onClick={() => onCopyLink(linkToShare)}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={(evt) => onClose(evt)}
      renderToPortal={renderToPortal}
    >
      <DialogTitle>
        Share Activity
        <IconButton
          icon="close"
          data-mdc-dialog-action="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
        />
      </DialogTitle>
      <DialogContent>
        {renderLtiButtons()}
        {renderShareButtons()}
        {renderEmbedButton()}
        {renderLinkBox()}
      </DialogContent>
    </Dialog>
  );
};

export default DialogShare;
