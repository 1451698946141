import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * @description This effect gets the URL query parameters from React Router (location().search) and returns a URLSearchParams object.
 * @returns { URLSearchParams } URL query parameters as a URLSearchParams object
 */
export const useQuery = () => {
  const initQueries = new URLSearchParams(useLocation().search);
  const [queries, setQueries] = useState(initQueries);
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      setQueries(new URLSearchParams(search));
    }
  }, [search]);

  return queries;
};