import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Typography } from "@rmwc/typography"; // Sass imported in main. CSS not needed.

import PageLoadingIndicator from "../../../components/PageLoadingIndicator/PageLoadingIndicator";
import useChannelModels from "../../../hooks/Channel/useChannelModels";
import { GenericCard } from "../../../components/Cards/GenericCard/GenericCard";
import { useChannel } from "../../../hooks/Channel/useChannel";
import { useChannelRole } from "../../../hooks/Channel/useChannelRole";
import { ListFilter } from "../ListFilter/ListFilter";
import { FlexGrid } from "../../../components/Layout/Layout";
import store from "../../../store";
import { sortInAllDirections } from "../../../utils/helpers";

export const ModelList = ({
  isLtiUser,
  channelId,
  isViewingFromSubscription,
}) => {

  // Hooks
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filterKeys, setFilterKeys] = useState([
    { name: "All channels", key: "all" },
  ]);
  const [sortDirection, setSortDirection] = useState(1);
  const [modelsListNew, setModelsListNew] = useState([]);
  const { channelModels, fetching } = useChannelModels(channelId);
  const channel = useChannel(channelId).channel;
  const { role } = useChannelRole(channelId);
  const history = useHistory();
  
  let canAuthor_asSubscriber = false,
    expiration_forSubscription = null,
    subscriberId = null,
    subscriberRole = null;

  if (history.location.state) {
    subscriberId = history.location.state.subscriberId;
    canAuthor_asSubscriber = history.location.state.canAuthor_asSubscriber;
    expiration_forSubscription =
      history.location.state.expiration_forSubscription;
    subscriberRole = history.location.state.subscriberRole;
  }

  // Effect to build the array of filter keys if applicable
  useEffect(() => {
    if (!channel || !channelModels) {
      return;
    }

    let filterKeysToAdd = [{ name: channel.name, key: channel.id, image: channel.image_url }]; // init with own channel
    let keysAdded = [channel.id];
    Object.values(channelModels).forEach((model) => {
      if (!keysAdded.includes(model.owner)) {
        const subscriptions = channel.subscriptions;

        if (subscriptions !== null && subscriptions !== undefined) {
          subscriptions.forEach(sub => {
            if (sub.id === model.owner) {
              // found the subscription
              if (sub.isActive && !sub.isExpired) {
                // subscription is still valid
                const name = sub.name;
                if (name !== null && name !== undefined) {
                  const image = sub?.image;
                  filterKeysToAdd.push({ name, key: model.owner, image });
                  keysAdded.push(model.owner);
                }
              }
            }
          });
        }
      }
    });

    if (filterKeysToAdd.length === 1) {
      // Don't display filters if there's only one
      setFilterKeys([]);
    } else {
      // More than one channel
      let newFilterKeys = [{ name: "All channels", key: "all" }];
      filterKeysToAdd.forEach((k) => {
        newFilterKeys.push(k);
      });
      setFilterKeys(newFilterKeys);
    }
  }, [channelModels, channel]);

  // Effect to filter and sort the models list
  useEffect(() => {

    const models = [...channelModels]

    // If filter is applied, remove any from list
    let workingList = [];
    if (selectedFilter === "all" || filterKeys.length <= 2) {
      //No filter needed, workingList is all models
      workingList = [...models];
    } else {
      //  Filter models and add to workingList
      models.forEach((m) => {
        if (m.owner === selectedFilter) {
          workingList.push(m);
        }
      });
    }

    // If sort direction, sort the list
    let listSorted = [];
    if (sortDirection) {
      listSorted = sortInAllDirections(workingList, "title", sortDirection)
    } else {
      // If no sort direction, don't sort, just use list as is.
      listSorted = [...workingList];
    }

    // Set final filtered and sorted list
    setModelsListNew(listSorted);
  }, [channelModels, selectedFilter, sortDirection, filterKeys]);

  function SetModelPermissions(model, channelId, role) {
    model.canAuthor = false;
    model.isHidden = false;
    // If model belongs to the channel
    // We need to check if it canAuthor
    if (model.owner === channelId) {
      if (["admin", "owner", "author"].includes(role)) {
        model.canAuthor = true;
      }
    }

    // If the model belongs to a subscription
    // We need to check if it canAuthor, check the expiration and if it exist
    if (model.owner !== channelId) {
      let subscription = store
        .getState()
        .Channels.list[channelId].subscriptions.filter(
          (sub) => sub.id === model.owner
        )[0];

      if (subscription) {
        if (subscription.isExpired) {
          model.isHidden = true; // subscription is expired
        }

        if (subscription.canAuthor && ["admin", "owner", "author"].includes(role)) {
          model.canAuthor = true;
        }
      } else {
        model.isHidden = true;
      }
    }

    // If we are in a subscription
    // We need to check if it canAuthor and the expiration
    if (subscriberId && subscriberRole) {
      if ( canAuthor_asSubscriber && ["admin", "owner", "author"].includes(subscriberRole) ) {
        let now = Date.now(),
          expiration = new Date(expiration_forSubscription).getTime();
        if (now < expiration) {
          // subscription is not expired
          model.canAuthor = true;
        }
      }
    }

    return model;
  }

  /**
   * The content displayed when the list is empty
   */
  
  const emptyListContent = () => {
    return (
      <Typography
        use="body1"
        tag="h2"
        style={{ textAlign: "center", flexGrow: "1", paddingTop: "24px" }}
      >
        This channel doesn't have any models yet.
      </Typography>
    );
  };

  /**
   * Builds an array of list items in JSX
   */
  const buildListItems = () => {
    if (role && modelsListNew) {
      return modelsListNew.map((m) => {
        m = SetModelPermissions(m, channelId, role);

        if (m.isHidden) {
          return null;
        }

        let actions = [];
        if (isLtiUser) {
          //Currently LTI users can't embed models since the embed URL works with an activity ID
        } else if (isViewingFromSubscription && m.canAuthor) {
          actions.push({
            label: "Author from model",
            action: {
              pathname: `/channel/${subscriberId}/new/${m.id}`,
              state: {
                root_owner: m.owner,
                isViewingFromSubscription: isViewingFromSubscription,
              },
            },
          });
        } else if (m.canAuthor) {
          actions.push({
            label: "Author from model",
            action: {
              pathname: `/channel/${channelId}/new/${m.id}`,
              state: {
                root_owner: m.owner,
                isViewingFromSubscription: isViewingFromSubscription,
              },
            },
          });
        }

        let modelChannel = channel;
        if (channel && channel.id !== m.owner) {
          channel.subscriptions.forEach((sub) => {
            if (m.owner === sub.id) {
              sub.image_url = sub.image;
              modelChannel = sub;
            }
          });
        }
        return (
          <React.Fragment key={Math.random()}>
            <GenericCard
              title={m.title}
              image={m.image}
              linkTo={`/models/${m.id}`}
              actions={actions}
              authorImage={modelChannel ? modelChannel.image_url : ""}
              authorName={modelChannel ? modelChannel.name : ""}
              authorLink={
                modelChannel
                  ? {
                    pathname: `/channel/${modelChannel.id}`,
                    state: { channelId, isViewingFromSubscription },
                  }
                  : {}
              }
            />
          </React.Fragment>
        );
      });
    }
  };

  const renderContent = () => {

    if (!fetching && channelModels.length === 0) {
      // If no activities, build no list notice instead of an empty list
      return emptyListContent();
    } else if(modelsListNew && !fetching) {
      return (
        <>
          <ListFilter
            keys={filterKeys}
            selected={selectedFilter}
            setSelected={setSelectedFilter}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
          <FlexGrid style={{ marginTop: "8px" }}>{buildListItems()}</FlexGrid>
        </>
      );
    } else {
      return <PageLoadingIndicator />;
    }
  };

  return renderContent();
};

ModelList.defaultProps = {
  isLtiUser: false,
};

ModelList.propTypes = {
  /**
   * The unique id of the channel being viewed. Required.
   */
  channelId: PropTypes.string.isRequired,
  /**
   * Bool indicating if this is a LTI user viewing the content.
   */
  isLtiUser: PropTypes.bool,
  /**
   * If true, indicates this model list is being viewed on a subscription channel
   */
  isViewingFromSubscription: PropTypes.bool,
};