import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@rmwc/typography"; // Sass used in main. Css not needed here.
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import style from "./Hook.module.scss";

export const Hook = ({ message, actionLabel, actionOnClick }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Typography use="body1" tag="div" className={style.text}>
          {message}
        </Typography>
        <div className={style.actions}>
          <Button
            label={actionLabel}
            raised
            onClick={actionOnClick}
            className={style.button}
          />
        </div>
      </div>
    </div>
  );
};

Hook.defaultProps = {
  actionLabel: "Learn More",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in pharetra arcu. Duis at tellus in augue lacinia tristique. Donec tincidunt dolor semper tortor ornare, euismod interdum justo porta. Pellentesque.",
};

Hook.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  actionOnClick: PropTypes.func.isRequired,
  message: PropTypes.string,
};
