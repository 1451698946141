import React from "react";
import PropTypes from "prop-types";

import style from "./FeaturedImage.module.scss";
import { usePlayerState } from "../../../hooks/usePlayerState";

function getImagesForSrcSet(image) {
  let imageSrcs = [];
  if (typeof image === "object") {
    imageSrcs = image.map((i) => {
      return `${i.src} ${i.size}\n`;
    });
    return imageSrcs;
  }
}

export const FeaturedImage = ({
  imageDesktop,
  imageMobile,
  imageAlt,
  className,
  ...otherProps
}) => {
  const device = usePlayerState();

  const imageSrcs = {};
  imageSrcs.mobile = getImagesForSrcSet(imageMobile);
  imageSrcs.desktop = getImagesForSrcSet(imageDesktop);
  const useMobileImages = device?.windowResolution.x <= 599 ? true : false;

  const divClasses = [style.container, className ? className : null].join(" ");

  return (
    <div className={divClasses} {...otherProps}>
      <img
        alt={imageAlt}
        srcSet={useMobileImages ? imageSrcs.mobile : imageSrcs.desktop}
        className={style.image}
      />
    </div>
  );
};

FeaturedImage.defaultProps = {
  imageDesktop: [
    {
      src: "https://via.placeholder.com/1600x900.jpg",
      size: "2x",
    },
    {
      src: "https://via.placeholder.com/800x450.jpg",
      size: "1x",
    },
    {
      src: "https://via.placeholder.com/400x225.jpg",
      size: "0.5x",
    },
    {
      src: "https://via.placeholder.com/3200x1800.jpg",
      size: "3x",
    },
  ],
  imageMobile: [
    {
      src: "https://via.placeholder.com/1600x900.jpg",
      size: "2x",
    },
    {
      src: "https://via.placeholder.com/800x450.jpg",
      size: "1x",
    },
    {
      src: "https://via.placeholder.com/400x225.jpg",
      size: "0.5x",
    },
    {
      src: "https://via.placeholder.com/3200x1800.jpg",
      size: "3x",
    },
  ],
  imageAlt: "Placeholder image",
};
FeaturedImage.propTypes = {
  imageDesktop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        size: PropTypes.string.isRequired,
      })
    ),
  ]),
  imageMobile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        size: PropTypes.string.isRequired,
      })
    ),
  ]),
  imageAlt: PropTypes.string.isRequired,
};
