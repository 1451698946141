import React from "react";

import { Theme } from "@rmwc/theme"; // Using SASS in main instead of css here
import { Typography } from "@rmwc/typography"; // Using Sass in main. CSS not needed here.

import style from "./KeyFeatures.module.scss";

export const KeyFeatures = ({ title, children }) => {
  return (
    <Theme use={["surface"]} wrap>
      <div className={style.container}>{children}</div>
    </Theme>
  );
};

export const KeyFeaturesTitle = ({ title, ...otherProps }) => {
  return (
    <Typography
      tag="div"
      use="headline5"
      className={style.title}
      {...otherProps}
    >
      {title}
    </Typography>
  );
};

export const KeyFeaturesList = ({ children }) => {
  return <div className={style.itemsContainer}>{children}</div>;
};

export const KeyFeaturesItem = ({ graphic, headline, description }) => {
  return (
    <div className={style.itemsContainer}>
      <Theme use={["surface"]} wrap>
        <div className={style.item}>
          <div className={style.graphic}>{graphic}</div>
          <Typography use="headline6" tag="div">
            {headline}
          </Typography>
          <Typography use="body2" tag="div">
            {description}
          </Typography>
        </div>
      </Theme>
    </div>
  );
};
