
import uuid from "uuid"
import { ImageToBlob } from "./helpers"

export const EndPoint = () => {

  if(process.env.REACT_APP_API_ENV === "local") {
    return "http://localhost:3001/"
  }
  if(process.env.REACT_APP_API_ENV === "test") {
    return "https://api-test.vivedlearning.com/"
  }
  if(process.env.REACT_APP_API_ENV === "staging") {
    return "https://api-staging.vivedlearning.com/"
  }
  if(process.env.REACT_APP_API_ENV === "prod") {
    return "https://api.vivedlearning.com/"
  }
}

export const apiClient = async (params) => {

  let headers = {
    'Content-Type': 'application/json'
  }

  if(params.userToken)
  {
    headers.Authorization = "Bearer " + params.userToken
  }

  const options = {
    method: params.method,
    headers
  };

  var url = new URL(params.path, EndPoint());

  if (params.query && Object.keys(params.query).length > 0) {
    Object.keys(params.query).forEach(key => url.searchParams.append(key, params.query[key]))
    options.params = { ...params.query }
  };
  if (params.data && Object.keys(params.data).length > 0) {
    options.body = JSON.stringify(params.data)
  };

  return await fetch(url, options)
  .then(async (response) => {
    return await response.json()
    .then((body) => {

      if(response.status === 200) {
        return {data: body}
      }
      else {
        throw body.message
      }
    })
  })
  .catch(err => {
    console.error('API ERROR', err)
    throw err
  })
};

export const uploadFile = (file, folder, userToken) => new Promise(async (resolve, reject) => {
  let filenameLength = file.name.length
  let fileN = file.name
  let format = ""
  if(fileN[filenameLength - 4] === ".") {
    format = fileN[filenameLength - 3] + fileN[filenameLength - 2] + fileN[filenameLength - 1]
  } else {
    format = fileN[filenameLength - 4] + fileN[filenameLength - 3] + fileN[filenameLength - 2] + fileN[filenameLength - 1]
  }
  const FileName = `${uuid.v4()}.${format}`

  let FileBlob = await ImageToBlob(file).then((blob) => blob)

  let headers = {
    'Content-Type': 'application/json'
  }

  if(userToken)
  {
    headers.Authorization = "Bearer " + userToken
  }

  await fetch(`${EndPoint()}upload/${folder}/${FileName}`, {
    method: "POST",
    headers: headers,
    body: FileBlob
  }).then(async (response) => {
    await response.json().then((body) => {
      if(response.status === 200) {
        resolve(FileName)
      }
      else {
        throw body.message
      }
    })
  }).catch(err => {
    console.error(err)
    throw err
  })
});
