import * as TYPES from "./types";

/*
Triggers the saga for creating a new activity.  
-activityJSON is the JSON data for the activity - REQUIRED
-channelName is the name of the Channel this activity belongs to - REQUIRED
-channelId is the id of the Channel this activity belongs to - REQUIRED
-callback is an optional function that gets called when the saga ends
Callback will recieve an object with either:
  {success: true, activity: {Activity Data}}
or
  {success: false, msg: "An error message"}
*/
const fetchAddActivity = (activityJSON, channelName, channelId, callback, root_owner=null, isForPlayerV2 = false ) => {
  //Make sure we have activity data
  if (!activityJSON) {
    throw new Error("activityJSON cannot be null or empty");
  }

  //Make sure we have channelName
  if (!channelName) {
    throw new Error("channelName cannot be null or empty");
  }

  //Make sure we have channelId
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }
  
  return {
    type: TYPES.ACTIVITIES_FETCH_INSERT,
    payload: {
      activityJSON,
      channelName,
      channelId,
      root_owner,
      isForPlayerV2, 
    },
    callback
  };
};

/*
Triggers the saga for fetching all the activities for a channel
-channelId is the ID of the channel - REQUIRED
-callback is an optional function that gets called when the saga ends
Callback will recieve an object with either:
  {success: true}
or
  {success: false, msg: "An error message"}
*/
const fetchActivitiesForChannel = (channelId, callback) => {
  //Make sure we have channelId
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }

  return {
    type: TYPES.ACTIVITIES_FETCH_FOR_CHANNEL,
    payload: {
      channelId
    },
    callback
  };
};

/*
Triggers the saga for deleting an activity
-channelId is the ID of the channel
-activityId is the ID of the activity
Callback will recieve an object with either:
  {success: true}
or
  {success: false, msg: "An error message"}
*/
const fetchDeleteActivity = (activityId, channelId, callback) => {
  //Make sure we have activityId
  if (!activityId) {
    throw new Error("activityId cannot be null or empty");
  }
  
  //Make sure we have channelId
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }
  
  return {
    type: TYPES.ACTIVITIES_FETCH_DELETE,
    payload: {
      activityId,
      channelId
    },
    callback
  };
};

/*
Triggers the saga for fetching all activities. Offset and limit are used for pagination. Both are required
Callback is optional will recieve an object with either:
  {success: true}
or
  {success: false, msg: "An error message"}
*/
const fetchAllActivities = (offset, limit, callback) => {
  //Make sure we have a offset
  if (!offset && offset !== 0) {
    throw new Error("offset cannot be null or empty");
  }
  //Make sure we have a limit
  if (!limit) {
    throw new Error("limit cannot be null or empty");
  }
  return {
    type: TYPES.ACTIVITIES_FETCH_ALL,
    payload: {
      offset,
      limit
    },
    callback
  };
};

/*
Triggers the saga for fetching a single activity.
-activityId is the ID of the activity. Requred
-channelId is the ID of the channel the activity belongs to. Requred
Callback is optional and will recieve an object with either:
  {success: true}
or
  {success: false, msg: "An error message"}
*/
const fetchActivity = (activityId, channelId, callback) => {
  //Make sure we have activityId
  if (!activityId) {
    throw new Error("activityId cannot be null or empty");
  }

  //Make sure we have channelId
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }

  return {
    type: TYPES.ACTIVITY_FETCH,
    payload: {
      activityId,
      channelId
    },
    callback
  };
};

/*
Triggers the saga for updating an activities meta.
-activityId is the ID of the activity,
-channelId is the ID of the channel,
-attributes are the attributets you wish to update in an object. eg: title, description, and/or image
Callback will recieve an object with either:
  {success: true}
or
  {success: false, msg: "An error message"}
*/
export const fetchUpdateMeta = (activityId, channelId, attributes, callback) => {

  //Make sure we have activityId
  if (!activityId) {
    throw new Error("activityId cannot be null or empty");
  }

  //Make sure we have channelId
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }

  return {
    type: TYPES.ACTIVITIES_FETCH_UPDATE_META,
    payload: {
      activityId,
      channelId,
      ...attributes
    },
    callback
  };
};

/*
Triggers the saga for updating an activity's JSON data.
-activityId is the ID of the activity,
-channelId is the ID of the channel,
-attributes are the attributets you wish to update in an object. eg: title, description, and/or image
Callback will recieve an object with either:
  {success: true}
or
  {success: false, msg: "An error message"}
*/
const fetchUpdateData = (
  channelId,
  activityId,
  activityData,
  dataId,
  callback
) => {
  //Make sure we have channelId
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }

  //Make sure we have activityId
  if (!activityId) {
    throw new Error("activityId cannot be null or empty");
  }

  //Make sure we have activityData
  if (!activityData) {
    throw new Error("activityData cannot be null or empty");
  }

  //Make sure we have dataId
  if (!dataId) {
    throw new Error("dataId cannot be null or empty");
  }

  return {
    type: TYPES.ACTIVITIES_FETCH_UPDATE_DATA,
    payload: {
      channelId,
      activityId,
      activityData,
      dataId
    },
    callback
  };
};

/*
Stores the current pagination data for getting all activities
-total is the total number of activities out on the API
-offset is essentially the index of the last activity that has been retrieved
-allHaveBeenRetrieved is a bool that is flipped to true if all have been retrieved
*/
const setActivitiesRetrieved = (total, offset, allHaveBeenRetrieved) => {
  //Make sure we have total
  if (total === null || total === undefined) {
    throw new Error("total is required");
  }

  //Make sure we have total
  if (offset === null || offset === undefined) {
    throw new Error("offset is required");
  }

  //Make sure we have total
  if (allHaveBeenRetrieved === null || allHaveBeenRetrieved === undefined) {
    throw new Error("allHaveBeenRetrieved is required");
  }

  return {
    type: TYPES.ACTIVITIES_SET_RETRIEVED,
    payload: {
      total,
      offset,
      allHaveBeenRetrieved
    }
  };
};

/*
Add a list of activities to the state
-activities is an array of activity objects
*/
const addActivities = activities => {
  if (!activities) {
    throw new Error("Activities are required");
  }

  //Make sure activities is an array
  if (!Array.isArray(activities)) {
    throw new Error("Activities must be an array");
  }

  return {
    type: TYPES.ACTIVITIES_ADD_ACTIVITIES,
    payload: {
      activities
    }
  };
};

/*
Removes an activity from the state
-activityId is the ID if the Activity to remove
-channelId is the ID of the Channel this activity belongs to
*/
const removeActivity = (activityId, channelId) => {
  //Make sure we have activityId
  if (!activityId) {
    throw new Error("activityId cannot be null or empty");
  }

  return {
    type: TYPES.ACTIVITY_REMOVE,
    payload: {
      activityId,
      channelId
    }
  };
};

/*
Updates an activity's meta in the state
-activityId is the ID of the activity to update
-title is the activity title. Can be null. If empty (ie "") it will clear the current title
-description is the activity description. Can be null. If empty (ie "") it will clear the current description
-image is the url to the image for this activity. Can be null. If empty (ie "") it will clear the current image
*/
const updateActivityMeta = (activityId, title, description, image) => {
  //Make sure we have activityId
  if (!activityId) {
    throw new Error("activityId cannot be null or empty");
  }

  let payload = {
    activityId
  };

  if (title || title === "") {
    payload.title = title;
  }

  if (description || description === "") {
    payload.description = description;
  }

  if (image || image === "") {
    payload.image = image;
  }

  return {
    type: TYPES.ACTIVITIES_UPDATE_META,
    payload
  };
};

/*
Updates an activity's data in the state
-activityId is the ID of the activity to update
-dataId is the ID of the data
-activityData is the activity data in JSON
*/
const updateActivityData = (activityId, dataId, activityData) => {
  //Make sure we have activityId
  if (!activityId) {
    throw new Error("activityId cannot be null or empty");
  }

  //Make sure we have dataId
  if (!dataId) {
    throw new Error("dataId cannot be null or empty");
  }

  //Make sure we have activityData
  if (!activityData) {
    throw new Error("activityData cannot be null or empty");
  }

  return {
    type: TYPES.ACTIVITIES_UPDATE_DATA,
    payload: {
      activityId,
      dataId,
      activityData
    }
  };
};

/*
Sets the id of the activity we are editing
*/
const setEditingActivity = activityId => {
  //Make sure we have activityId
  if (!activityId) {
    throw new Error("activityId cannot be null or empty");
  }

  return {
    type: TYPES.ACTIVITIES_SET_EDITING,
    payload: {
      activityId
    }
  };
};

/*
Clears the id of the activity we are editing
*/
const clearEditingActivity = () => {
  return {
    type: TYPES.ACTIVITIES_CLEAR_EDITING
  };
};

/*
Clears the id of the activity we are editing
*/
const clearImportActivityData = () => {
  return {
    type: TYPES.ACTIVITES_CLEAR_IMPORT_DATA
  };
};

export const duplicateActivity = (channelId, activityId, root_owner, callback) => {
  return {
    type: TYPES.ACTIVITES_DUPLICATE,
    payload: {
      channelId,
      activityId,
      root_owner
    },
    callback
  }
}

export default {
  fetchAddActivity,
  fetchAllActivities,
  fetchUpdateMeta,
  fetchActivity,
  fetchActivitiesForChannel,
  fetchUpdateData,
  fetchDeleteActivity,
  setActivitiesRetrieved,
  addActivities,
  removeActivity,
  updateActivityMeta,
  updateActivityData,
  setEditingActivity,
  clearEditingActivity,
  clearImportActivityData,
  duplicateActivity
};


//Remove this comment. Just bumping git