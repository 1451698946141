import React, { useState } from "react";
import { SimpleMenu, MenuItem } from "@rmwc/menu";
import { Button } from "@rmwc/button";
import DeleteSubscriptionDialog from "../../Dialog/DeleteSubscriptionDialog/DeleteSubscriptionDialog";
import store from "../../../store";
import { snackbarQueue } from "../../Snackbar/snackbarQueue";
import { deleteLinkedChannelSubscription } from "../../../Redux/Channels/ChannelActions"

export default function ActionsMenu ({ subscription }) {

  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  const deleteSubscription = () => {
    setIsDeletePromptOpen(false)
    setIsDeleteAlertOpen(true)

    store.dispatch(
      deleteLinkedChannelSubscription(subscription.subscriberId, subscription.id, (result)=> {
        setIsDeleteAlertOpen(false)

        if(result.success) {
          snackbarQueue.notify({
            title: <b>Success</b>,
            body: "You have successfully deleted the subscription",
          })
        } else {
          snackbarQueue.notify({
            title: <b>Error</b>,
            body: "Something went wrong while trying to delete a subscription. Please try again.",
          });
        }
      })
    )
  };

  const closeDeletePrompt = () => {
    setIsDeletePromptOpen(false)
  }

  return (
    <>
      <DeleteSubscriptionDialog 
        isAlertOpen={isDeleteAlertOpen}
        isPromptOpen={isDeletePromptOpen}
        deleteSubscription={() => deleteSubscription()}
        closeDeletePrompt={()=> closeDeletePrompt()}
      />

      <SimpleMenu 
        handle={<Button icon="more_horiz"/>}
        renderToPortal
      >
        <MenuItem onClick={() => { 
          setIsDeletePromptOpen(true)
          setIsDeleteAlertOpen(false)
        }}>Delete</MenuItem>
      </SimpleMenu>
    </>
  )
}



