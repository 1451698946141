import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableCell,
  DataTableBody,
} from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import style from "./ResourcesTable.module.scss";

import { SectionHeadlineRow } from "../../Layout/Headings";
import PageLoadingIndicator from "../../PageLoadingIndicator/PageLoadingIndicator";
import { sortInAllDirections } from "../../../utils/helpers";

export const ResourcesTable = ({ channelResources, fetching }) => {
  /// Hooks
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState(1); // null = unsorted, 1 = ascending, -1 = descending
  const [sortColumnSecondary, setSortColumnSecondary] = useState(null);
  const [list, setList] = useState([]);
  
  /// EFFECTS
  // This effect should watch for changes to the combined resources list, the sort column, and sort direction. It will produce a sorted list to be displayed by the component.
  useEffect(() => {
    // Begin effect code
    if (channelResources || channelResources.length > 0) {
      const listMapped = [...channelResources]

      // If both sortColumn and sortDirection are defined, sort array and set state
      if (sortColumn && sortDirection) {
        const listSorted = sortInAllDirections(listMapped, sortColumn, sortDirection, sortColumnSecondary)      
        setList(listSorted);
        // Else just set state to the unsorted list
      } else {
        setList(listMapped);
      }
    }
  }, [channelResources, sortColumn, sortDirection, sortColumnSecondary]);

  /// Constants & other
  const headers = [
    {
      id: 1,
      name: "thumbnail",
      label: "",
      sortable: false,
    },
    {
      id: 2,
      name: "name",
      label: "Name",
      sortable: true,
    },
    {
      id: 3,
      name: "type",
      label: "Type",
      sortable: true,
    },
  ];

  /// Event hander functions
  const handleOnSortChange = (column, sortDir) => {
    const secondarySort = column === "type" ? "name" : null;

    setSortColumn(column);
    setSortDirection(sortDir);
    setSortColumnSecondary(secondarySort);
  };

  /// Render helper functions
  const renderTableHeaders = () => {
    return headers.map((i) => {
      if (i.sortable) {
        return (
          <DataTableHeadCell
            // alignStart
            key={`cr-${i.name}-${i.id}`}
            sort={sortColumn === i.name ? sortDirection : null}
            onSortChange={(sortDir) => handleOnSortChange(i.name, sortDir)}
          >
            {i.label}
          </DataTableHeadCell>
        );
      } else {
        return (
          <DataTableHeadCell key={`cr-${i.name}-${i.id}`}>
            {i.label}
          </DataTableHeadCell>
        );
      }
    });
  };

  const renderTableRows = () => {

    if (!list && fetching) {
      return <PageLoadingIndicator />;
    }

    if (list.length === 0) {
      return (
        <DataTableRow>
          <DataTableCell />
          <DataTableCell>
            <div className={style.tableName}>
              No resources are assigned to this channel.
            </div>
          </DataTableCell>
          <DataTableCell />
        </DataTableRow>
      );
    }

    const rows = list.map((r) => {
      return (
        <DataTableRow key={r.id}>
          <DataTableCell>
            <img
              src={r.image ? r.image : "https://via.placeholder.com/40x40.jpg"}
              className={style.image}
              alt={r.name}
            />
          </DataTableCell>
          <DataTableCell>
            <div className={style.tableName}>{r.name}</div>
          </DataTableCell>
          <DataTableCell>{r.type}</DataTableCell>
        </DataTableRow>
      );
    });
    return rows;
  };

  const renderTable = () => {
    return (
      <DataTable className={style.tableContainer} stickyRows={1}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>{renderTableHeaders()}</DataTableRow>
          </DataTableHead>
          <DataTableBody>{renderTableRows()}</DataTableBody>
        </DataTableContent>
      </DataTable>
    );
  };

  const renderContent = () => {
    return (
      <div className={style.container}>
        <SectionHeadlineRow
          className={style.sectionHeadline}
          title="Channel resources"
          tag="h2"
          typographyStyle="subtitle1"
        />
        <div className={style.tableWrapper}>{renderTable()}</div>
      </div>
    );
  };

  return renderContent();
};
