import React from 'react';
import AwesomeIcon from '../AwesomeIcon';
import Props from './props';

const ButtonIcon = ({
  icon,
  onClick,
  color = 'default',
  iconSize,
  fill,
  ...iconProps
}) => (
  <button
    className={`ButtonIcon--${color}`}
    type="button"
    onClick={onClick}
  >
    <AwesomeIcon icon={icon} width={iconSize} fill={fill} {...iconProps} />
  </button>
);

ButtonIcon.propTypes = Props;
export default ButtonIcon;
