import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@rmwc/typography"; // Sass in main, css not needed here.
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Theme } from "@rmwc/theme"; // Sass in main, css not needed here.

import style from "./ChannelUserLimitCount.module.scss";

export const ChannelUserLimitCount = ({
  userCount,
  userLimit,
  onRequestChangeClick,
}) => {
  const userLimitReached = userCount >= userLimit;
  const userDifference = userLimit - userCount;

  let userCountClass;
  if (userLimitReached && userDifference < 0) {
    userCountClass = style.overLimit;
  } else {
    userCountClass = style.underLimit;
  }

  const limitExceededText = `This channel has exceeded its user limit. To resolve this issue,
  either remove members or request a change to the channel's user limit.`;

  const LimitExceededMessage = () => {
    if (userLimitReached && userDifference < 0) {
      return (
        <Theme wrap use={["error"]}>
          <Typography use="body2" tag="p" className={style.errorMessage}>
            {limitExceededText}
          </Typography>
        </Theme>
      );
    } else return null;
  };

  const RequestChangeButton = () => {
    if (onRequestChangeClick) {
      return (
        <Button
          outlined
          label="Request Change"
          onClick={onRequestChangeClick}
        />
      );
    } else return null;
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.count}>
          <Typography use="subtitle1" tag="div" className={userCountClass}>
            {userCount} of {userLimit} users
          </Typography>
        </div>
        <RequestChangeButton />
      </div>
      <LimitExceededMessage />
    </div>
  );
};

ChannelUserLimitCount.propTypes = {
  userCount: PropTypes.number.isRequired,
  userLimit: PropTypes.number.isRequired,
  onRequestChangeClick: PropTypes.func,
};
