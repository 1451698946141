import * as TYPES from "./ActivityTypes"

export function exportActivity(activityId, channelId, callback) {
  return {
    type: TYPES.ACTIVITIES_EXPORT,
    payload: {
      activityId,
      channelId
    },
    callback
  }
}

export function importActivity(zip, channelId, channelName, callback) {
  if (!zip) {
    throw new Error("zip cannot be null or empty");
  }

  return {
    type: TYPES.ACTIVITIES_IMPORT,
    payload: {
      zip,
      channelId,
      channelName
    },
    callback
  };
}