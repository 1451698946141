import React, { useState, useEffect } from "react";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Typography } from "@rmwc/typography"; // Sass used in Main, css not needed here

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import ChannelCard from "../../components/Cards/ChannelCard/ChannelCard";
import style from "./JoinChannels.module.scss";
import { FlexGrid, ContentContainer } from "../../components/Layout/Layout";
import ChannelJoinLeaveButton from "../../components/ChannelJoinLeaveButton/ChannelJoinLeaveButton";
import PageError from "../../components/Error/PageError";
import { dialogQueue } from "../../components/Dialog/dialogQueue";

import { useAllChannels } from "../../hooks/Channel/useAllChannels";
import { useUserRoles } from "../../hooks/User/useUserRoles";
import { useDispatch } from "react-redux";
import Actions from "../../Redux/actions";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";
import { CircularProgress } from "@rmwc/circular-progress";

const JoinChannels = () => {
  //States
  const [isReady, setIsReady] = useState(false);
  const [channelsToDisplay, setChannelsToDisplay] = useState([]);
  const [error, setError] = useState(null);
  const [isSubmittingCode, setIsSubmittingCode] = useState(false);

  //Hooks
  const [allChannels, fetchChannelsError] = useAllChannels();
  const userRoles = useUserRoles();
  const dispatch = useDispatch();

  const getChannelRole = (channelId) => {
    if (userRoles) {
      return userRoles.hasOwnProperty(channelId)
        ? userRoles[channelId].role
        : "none";
    } else {
      return "none";
    }
  };

  useEffect(() => {
    if (allChannels && userRoles) {
      const channelListToShow = [];
      allChannels.forEach((channel) => {
        let channelRole = "none";
        let isPending = false;

        if(userRoles.hasOwnProperty(channel.id))
        {
          channelRole = userRoles[channel.id].role;
          isPending = userRoles[channel.id].isPending;
        }

        let displayChannel = true;
        if(channelRole === "owner")
        {
          //Don't display owner channels
          displayChannel = false;
        }

        if(isPending)
        {
          //Don't display pending channels
          displayChannel = false;
        }

        if(channel.access_pattern !== "public" && channelRole === "none")
        {
          //Don't display channels that are not public and I have no role in
          displayChannel = false;
        }

        if(displayChannel)
        {
          channelListToShow.push(channel);
        }
      });

      //Sort alphabetically
      channelListToShow.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      setChannelsToDisplay(channelListToShow);
      setIsReady(true);
    }
  }, [allChannels, isReady, userRoles]);

  //Checks for errors
  useEffect(() => {
    if (fetchChannelsError) {
      setError(
        "Something went wrong while fetching channels. " + fetchChannelsError
      );
    }
  }, [fetchChannelsError]);

  const fireChannelJoinPrompt = () => {
    dialogQueue
      .prompt({
        title: "Join channel",
        body:
          "Enter the channel join code you received below and submit to join the channel.",
        acceptLabel: "Submit",
        cancelLabel: "Cancel",
        inputProps: {
          outlined: true,
          label: "Join code",
          type: "text",
        },
      })
      // The response will be `null` if the user cancelled or left the prompt without submitting.
      // The response will be the input's value if the user submits from the dialog
      // ToDo: Submit code to the API, handle success, handle error
      .then((resp) => {
        if (resp) {
          sumbitJoinCode(resp);
        }
      })
  };

  const sumbitJoinCode = (code) => {
    setIsSubmittingCode(true);
    dispatch(
      Actions.Channels.submitJoinCode(code, (resp) => {
        if (resp.success) {
          snackbarQueue.notify({
            title: <b>Success!</b>,
          });
        } else {
          snackbarQueue.notify({
            title: <b>Uh oh. Something went wrong</b>,
            body: resp.msg,
          });
        }
        setIsSubmittingCode(false);
      })
    );
  };

  function renderChannelList() {
    return channelsToDisplay.map((c) => {
      return (
        <React.Fragment key={c.id}>
          <ChannelCard
            name={c.name}
            image={c.image_url}
            channelId={c.id}
            role={getChannelRole(c.id)}
          />
          <ChannelJoinLeaveButton channelId={c.id} />
        </React.Fragment>
      );
    });
  }

  function renderContent() {
    if (error) {
      return <PageError error={true} errorMessage={error} />;
    } else if (isReady) {
      //Render the main content
      return (
        <>
          <div className={style.yourChannels}>
            <div className={style.yourChannelsHeading}>
              <Typography use="headline5" tag="h1">
                Manage Your Channels
              </Typography>
            </div>
            <div className={style.yourChannelsManage}>
              {isSubmittingCode ? (
                <CircularProgress />
              ) : (
                <Button outlined icon="add" onClick={fireChannelJoinPrompt}>
                  Join with code
                </Button>
              )}
            </div>
          </div>
          <FlexGrid>{renderChannelList()}</FlexGrid>
        </>
        // <div className={style.content}>
        //   <Typography use="headline6" tag="h1" className={style.pageTitle}>
        //     Manage Your Channels
        //   </Typography>
        //   <FlexGrid>{renderChannelList()}</FlexGrid>
        // </div>
      );
    } else {
      return <PageLoadingIndicator />;
    }
  }

  return (
    <PageWrapper>
      <ContentContainer>{renderContent()}</ContentContainer>
    </PageWrapper>
  );
};

export default JoinChannels;
