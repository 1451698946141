import React from "react";
import style from "./Banner.module.scss";

import { Typography } from "@rmwc/typography"; // Sass in main. No css import needed.

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

/**
 * Banner
 */
export const Banner = ({ open, children }) => {
  if (open) {
    return <div className={style.container}>{children}</div>;
  } else return null;
};

/**
 * 
 * Banner Content container
 */
export const BannerContent = ({ children }) => {
  return <div className={style.content}>{children}</div>;
};

/**
 * 
 * Banner Message
 */
export const BannerMessage = ({ message }) => {
  return (
    <Typography use="body2" tag="div" className={style.message}>
      {message}
    </Typography>
  );
};

/**
 * 
 * Banner Media container
 */
export const BannerMedia = ({ icon }) => {
  return (
    <div className={style.media}>
      <Icon icon={{ icon: icon, size: "large" }} />
    </div>
  );
};

/**
 * Banner Actions container
 */
export const BannerActions = ({ children }) => {
  return <div className={style.actions}>{children}</div>;
};

/**
 * 
 * Banner Action Button
 */
export const BannerActionButton = ({ label, onClick }) => {
  return <Button className={style.button} label={label} onClick={onClick} />;
};
