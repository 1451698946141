import React from "react";

import { Typography } from "@rmwc/typography"; // No css import needed
import { SimpleDataTable } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

export const DeviceInfo = ({ device }) => {
  return (
    <div id="device-player-info" style={{ paddingTop: "24px" }}>
      <Typography use="headline5" tag="h2">
        Device
      </Typography>
      <SimpleDataTable
        headers={[["Property", "", "Value"]]}
        data={[
          ["Type", "", device.type],
          ["Touch support", "", device.isTouchCapable ? "true" : "false"],
          ["Orientation", "", device.orientation],
          [
            "Screen resolution",
            `x = ${device.screenResolution.x}`,
            `y = ${device.screenResolution.y}`,
          ],
          ["Pixel density", "", device.pixelDensity],
          ["Breakpoint", "", device.breakpoint],
          [
            "Window resolution",
            `x = ${device.windowResolution.x}`,
            `y = ${device.windowResolution.y}`,
          ],
        ]}
      />
    </div>
  );
};
