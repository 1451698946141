import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export function useChannel(channelId) {
  //States
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [channel, setChannel] = useState(null);

  //Hooks
  const channelList = useSelector((state) => state.Channels.list);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!fetching && !error && channelId) {
      if (!channelList.hasOwnProperty(channelId) || !channelList[channelId].activities) {

        setFetching(true);
        dispatch(
          Actions.Channels.fetchAChannel(channelId, (resp) => {
            if (!resp.success) {
              setError(resp.msg);
            }
            setFetching(false);
          })
        );
      } else {
        setChannel(channelList[channelId]);
      }
    }
  }, [channelId, dispatch, channelList, fetching, error]);

  return {channel, error, isFetchingChannel: fetching};
}
