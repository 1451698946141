import React from "react";
import style from "./CollectionItemCard.module.scss";

import {
  Card,
  CardPrimaryAction,
  CardMedia,
  CardActions,
  CardActionButtons,
  CardActionButton
} from "@rmwc/card";
import '@rmwc/card/styles';

import { Typography } from "@rmwc/typography";

export const CollectionGridItemCard = ({
  name,
  image_url,
  isSelected,
  onSelect
}) => {
  const cardClasses = [
    style.collectionCard,
    isSelected ? style.selectedCard : null
  ].join(" ");
  


  return (
    <Card className={cardClasses}>
      <CardPrimaryAction onClick={onSelect}>
        <CardMedia
          sixteenByNine
          style={{ backgroundImage: `url(${image_url})` }}
        />
        <div className={style.contentPrimary}>
          <Typography use="headline6" tag="h2">
            {name}
          </Typography>
        </div>
      </CardPrimaryAction>
    </Card>
  );
};

export const CollectionGridItemCard2 = ({
  name,
  image_url,
  primaryActionText,
  primaryAction,
  secondaryActionText,
  secondaryAction
}) => {
  
  const renderPrimaryActionButton = () => {
    return (
      <CardActionButton onClick={primaryAction}>
        {primaryActionText}
      </CardActionButton>
    );
  };

  const renderSecondaryActionButton = () => {
    return (
      <CardActionButton onClick={secondaryAction}>
        {secondaryActionText}
      </CardActionButton>
    );
  };

  const renderActions = () => {
    if (secondaryActionText) {
      return (
        <CardActions>
          <CardActionButtons>
            {renderPrimaryActionButton()}
            {renderSecondaryActionButton()}
          </CardActionButtons>
        </CardActions>
      );
    } else {
      return <CardActions fullBleed>{renderPrimaryActionButton()}</CardActions>;
    }
  };

  return (
    <Card className={style.collectionCard}>
      <CardMedia
        sixteenByNine
        style={{ backgroundImage: `url(${image_url})` }}
      />
      <div className={style.contentPrimary}>
        <Typography use="headline6" tag="h2">
          {name}
        </Typography>
      </div>
      {renderActions()}
    </Card>
  );
};
