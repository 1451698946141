import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export function useChannelOwnerInfo(channelId) {
  //States
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [owner, setOwner] = useState(null);
  const [channel, setChannel] = useState();

  //Hooks
  const channelList = useSelector((state) => state.Channels.list)
  const dispatch = useDispatch();

  useEffect(() => {
    if(channelList[channelId]) {
      setChannel(channelList[channelId])
    }
    
    if (!fetching && !error) {
      if (channel && !channel.owner) {
        setFetching(true);
        dispatch(
          Actions.Channels.fetchChannelOwner(channelId, (resp) => {
            if (!resp.success) {
              setError(resp.msg);
            }
            setFetching(false);
          })
        );
      } else if(channel && channel.owner) {
        setOwner(channel.owner);
      }
    }
  }, [channelId, dispatch, owner, fetching, error, channelList, channel]);

  return {owner, error};
}
