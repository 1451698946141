import {
    RequestHandler,
    UnsupportedRequestVerion,
} from "@vived/app/lib/Entities";

export class CloseAssetSystemPlugin extends RequestHandler {
    requestType = "CLOSE_PLUGIN";
    payloadVersion = 1;
    history;

    handleRequest = (version) => {
        if (version === this.payloadVersion) {
            this.action();
        } else {
            throw new UnsupportedRequestVerion(this.requestType, version);
        }
    };

    action = () => {       
        this.history.goBack();       
    };

    constructor(hostHandler, history) {
        super();
        this.history = history;
        hostHandler.registerRequestHandler(this);
    }
}

