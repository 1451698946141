import React, { useState, useEffect } from "react";

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableCell,
  DataTableBody,
} from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { Typography } from "@rmwc/typography"; //Sass imported in main. CSS not needed here.
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import "@rmwc/menu/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import { Theme } from "@rmwc/theme"; //Sass imported in main. CSS not needed here.

import style from "./ChannelSubscriptionsTable.module.scss";

import EditChannelSubscriptionDialog from "../../Dialog/EditChannelSubscriptionDialog/EditChannelSubscriptionDialog";
import PageLoadingIndicator from "../../PageLoadingIndicator/PageLoadingIndicator";
import PageError from "../../Error/PageError";
import ActionsMenu from "./actionsMenu"
import { useChannelSubscriptions } from "../../../hooks/Channel/useChannelSubscriptions";

export const ChannelSubscriptionsTable = ({ channelId }) => {

  //Hooks
  const { subscriptions, error: subscriptionsError, isFetchingSubscription } = useChannelSubscriptions(channelId);

  /// State
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState(1); // null = unsorted, 1 = ascending, -1 = descending
  const [sortColumnSecondary, setSortColumnSecondary] = useState(1);
  const [list, setList] = useState([]);
  const [openEditSubscriptionDialog, setOpenEditSubscriptionDialog] = useState(
    false
  );

  const [subscriptionToEdit, setSubscriptionToEdit] = useState("");
  const [processingEditSubscription, setProcessingEditSubscription] = useState(
    false
  );
  
  /// Effects
  // This effect should watch for changes to the available resources list, the sort column, and sort direction. It will produce a sorted list to be displayed by the component.
  useEffect(() => {
    /// Define inline functions first
    /**
     *
     * @param { string } key Required. The key of the primary sort column
     * @param { -1 | 1 | null } sortDir Required. 1 = ascending, -1 = descending, null = unsorted
     * @param { string } secondaryKey Optional. The key of the secondary sort column
     */
    const compareValues = (key, sortDir, secondaryKey) => {
      return function (a, b) {    
        let comparison = 0;

        // Handle primary sort
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          return 0;
        }
        const varA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        } else {
          // Handle secondary sort
          if (
            !a.hasOwnProperty(secondaryKey) ||
            !b.hasOwnProperty(secondaryKey)
          ) {
            return 0;
          }
          const varA2 =
            typeof a[secondaryKey] === "string"
              ? a[secondaryKey].toLowerCase()
              : a[secondaryKey];
          const varB2 =
            typeof b[secondaryKey] === "string"
              ? b[secondaryKey].toLowerCase()
              : b[secondaryKey];

          if (varA2 > varB2) {
            comparison = 1;
          }
          if (varA2 < varB2) {
            comparison = -1;
          }
        }

        // Return the comparison result with the proper sort order
        return sortDir === -1 ? comparison * -1 : comparison;
      };
    };

    // /// Begin effect code
    if (subscriptions) {
    //   const subscriptionsArrary = Object.values(subscriptions.list);
      const listMapped = subscriptions.map((sub) => {
        const subscription = {...sub};
        let date = new Date(sub.expiration);
        subscription.expiration = (date.getMonth() + 1).toString() + "/" + (date.getDate() + 1).toString() + "/" + date.getFullYear();

        return subscription;
      });
      // If both sortColumn and sortDirection are defined, sort array and set state
      if (sortColumn && sortDirection) {
        const listSorted = listMapped.sort(
          compareValues(sortColumn, sortDirection, sortColumnSecondary)
        );
        setList( listSorted );
        // Else just set state to the unsorted list
      } else {
        setList( listMapped );
      }
    }
  }, [subscriptions, sortColumn, sortDirection, sortColumnSecondary]);

  /// Constants & other
  const headers = [
    {
      id: 1,
      name: "name",
      label: "Channel",
      sortable: true,
    },
    {
      id: 2,
      name: "canAuthor",
      label: "Author",
      sortable: true,
    },
    {
      id: 3,
      name: "canEdit",
      label: "Duplicate",
      sortable: true,
    },
    {
      id: 4,
      name: "expiration",
      label: "Expiration",
      sortable: true,
    },
    {
      id: 5,
      name: "actions",
      label: "Actions",
      sortable: false,
    },
  ];

  /// Event handler functions
  const handleOnSortChange = (column, sortDir) => {
    setSortColumn(column);
    setSortDirection(sortDir);

    // Set the secondary Sort column to "name" if desired
    if (
      column === "expiration" ||
      column === "authorActivities" ||
      column === "authorResources"
    ) {
      setSortColumnSecondary("name");
    }
  };

  const onCloseEditSubscriptionDialog = (evt) => {
    setOpenEditSubscriptionDialog(false);
  };

  const onEditSubscription = (subscription) => {
    // Set which subscription is to be edited
    setSubscriptionToEdit(subscription);
    setOpenEditSubscriptionDialog(true);    
  };

  /// Render helper functions
  const renderChannelName = (id, name, image) => {
    return (
      <div className={style.channelContainer}>
        <Avatar
          size="xlarge"
          name={name}
          src={image}
          className={style.channelImage}
        />

        <div className={style.channelNameContainer}>
          <Typography use="body1" tag="div" className={style.channelName}>
            {name ? name : "Untitled channel"}
          </Typography>
          <Theme use={["textSecondaryOnLight"]} wrap>
            <Typography
              use="body2"
              tag="div"
              theme={["secondaryBg, onSecondary"]}
              className={style.channelId}
              title={id}
            >
              {id}
            </Typography>
          </Theme>
        </div>
      </div>
    );
  };

  const renderTableHeaders = () => {
    return headers.map((i) => {
      if (i.sortable) {
        return (
          <DataTableHeadCell
            key={`cs-${i.name}-${i.id}`}
            sort={sortColumn === i.name ? sortDirection : null}
            onSortChange={(sortDir) => handleOnSortChange(i.name, sortDir)}
          >
            {i.label}
          </DataTableHeadCell>
        );
      } else {
        return (
          <DataTableHeadCell
            key={`cs-${i.name}-${i.id}`}
            alignEnd={(i.name = "actions")}
          >
            {i.label}
          </DataTableHeadCell>
        );
      }
    });
  };

  const renderTableRows = () => {
    if (!list) {
      return null;
    }
    if (list.length === 0) {
      return (
        <DataTableRow>
          <DataTableCell>
            <div className={style.tableName}>
              No subscriptions were found for this channel.
            </div>
          </DataTableCell>
          <DataTableCell />
          <DataTableCell />
          <DataTableCell />
          <DataTableCell />
        </DataTableRow>
      );
    }

    const rows = list.map((s) => {
      const dateFormatted = s.expiration
    
      return (
        <DataTableRow key={s.id}>
          <DataTableCell alignStart>
            {renderChannelName(s.id, s.name, s.image)}
          </DataTableCell>
          <DataTableCell alignMiddle>
            <Icon icon={s.canAuthor ? "check" : null} />
          </DataTableCell>
          <DataTableCell alignMiddle>
            <Icon icon={s.canEdit ? "check" : null} />
          </DataTableCell>
          <DataTableCell alignEnd>{dateFormatted}</DataTableCell>
          <DataTableCell>
            <div className={style.actionsContainer}>
              <Button label="Edit" onClick={() => onEditSubscription(s)} />
              <ActionsMenu subscription={s}/>
            </div>
          </DataTableCell>
        </DataTableRow>
      );
    });
    return rows;
  };

  const renderContent = () => {
    if ( isFetchingSubscription ) {
      return <PageLoadingIndicator />;
    } else if (subscriptionsError) {
      return <PageError error={subscriptionsError} />;
    } else {
      return (
        <>
          <DataTable className={style.tableContainer} stickyRows={1}>
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>{renderTableHeaders()}</DataTableRow>
              </DataTableHead>
              <DataTableBody>{renderTableRows()}</DataTableBody>
            </DataTableContent>
          </DataTable>
          <EditChannelSubscriptionDialog
            open={openEditSubscriptionDialog}
            onClose={onCloseEditSubscriptionDialog}
            setProcessing={setProcessingEditSubscription}
            processing={processingEditSubscription}
            channelId={channelId}
            renderToPortal={true}
            subscription={subscriptionToEdit}
          />
        </>
      );
    }
  };

  return <div className={style.wrapper}>{renderContent()}</div>;
};
