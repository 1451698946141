import PropTypes from "prop-types";

/**
 * Custom PropType for a menu's link using a function or React Router Link component. Can accept one of the following: a function, a React Router object, or a react router string representation of the link location. A React Router Link component will be used unless a function is provided. For more information about React Router Link: https://reactrouter.com/web/api/Link.
 */
export const MenuLinkPropType = PropTypes.oneOfType([
  // Link `to` prop as object: https://reactrouter.com/web/api/Link
  PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  // Link `to` prop as string: https://reactrouter.com/web/api/Link
  PropTypes.string.isRequired,
  // Generic function (not using Link)
  PropTypes.func.isRequired,
]);
