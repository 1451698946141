import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { Typography } from "@rmwc/typography"; // Using Sass in Main. CSS not needed here.

import style from "./ChannelUserLimit.module.scss";
import { CHANNEL_VISIBILITY_TYPES } from "../../../constants/channelVisibilityTypes";
import { useDispatch } from "react-redux";
import Actions from "../../../Redux/actions";
import { snackbarQueue } from "../../Snackbar/snackbarQueue";

export const ChannelUserLimit = ({
  userCount,
  userLimit,
  channelVisibility,
  channel
}) => {  
  const [editing, setEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [limit, setLimit] = useState(userLimit ?? 14);
  const limitInputRef = useRef();
  const dispatch = useDispatch();

  const getLimit = () => {
    return limit;
  }

  const fetchUpdateChannel = (data, cb) => {
    dispatch(Actions.Channels.fetchUpdateChannel(data, cb));
  }

  const submitChange = () => {
    setIsSaving(true);    
    
    fetchUpdateChannel(
      {
        name: channel.name,
        uid: channel.id,
        user_limit: parseInt(limit),
      },
      (resp) => {
        if (resp.success) {
          userLimit = limit;
          setIsSaving(false);
          cancelChange();
        } else {
          setIsSaving(false);
          snackbarQueue.notify({
            title: <b>Error</b>,
            body: "Something went wrong updating the channel's user limit. Please try again.",
          });
        }
      }
    );    
  };

  const cancelChange = () => {
    setLimit(userLimit);
    setEditing(false);
  };

  const editLimit = () => {
    setEditing(true);
  };

  const onLimitChange = (evt) => {   
    // TODO: Only allow numbers to be entered into this input. 
    setLimit(evt.currentTarget.value);
  }

  if (channelVisibility === CHANNEL_VISIBILITY_TYPES.PRIVATE) {
    // If editing, display the component with the input
    if (editing) {
      return (
        <div className={style.container}>
          <div className={style.heading}>
            <Typography use="subtitle1" tag="div">
              User limit
            </Typography>
            {isSaving ? (
              <CircularProgress />
            ) : (
                <>
                  <IconButton icon="cancel" onClick={cancelChange} />
                  <IconButton
                    icon="check_circle"
                    onClick={submitChange}
                    disabled={userLimit === limit}
                  />
                </>
              )}
          </div>
          <ul>
            <li>
              <Typography use="body1" tag="span">
                Users: {userCount?.toLocaleString()}
              </Typography>
            </li>
            <li>
              <div className={style.limitInput}>
                <TextField
                  outlined
                  autoComplete="off"
                  label="Limit"
                  type="number"
                  max={1000}
                  min={1}                  
                  onChange={onLimitChange}
                  value={getLimit()}
                  ref={limitInputRef}
                  disabled={isSaving}
                />
              </div>
            </li>
          </ul>
        </div>
      );
    } else {
      // If not editing, only display the current count and limit
      return (
        <div className={style.container}>
          <div className={style.heading}>
            <Typography use="subtitle1" tag="div">
              User limit
            </Typography>
            <IconButton icon="edit" onClick={editLimit} />
          </div>
          <ul>
            <li>
              <Typography use="body1" tag="span">
                Users: {userCount?.toLocaleString()}
              </Typography>
            </li>
            <li>
              <Typography use="body1" tag="span">
                Limit: {userLimit?.toLocaleString() ?? "Unset"}
              </Typography>
            </li>
          </ul>
        </div>
      );
    }
  } else {
    // If channel is not private, then we don't need to display this user count component
    return null;
  }
};

ChannelUserLimit.defaultProps = {
  userLimit: 14,
};
ChannelUserLimit.propTypes = {
  userCount: PropTypes.number.isRequired,
  userLimit: PropTypes.number,
  channelVisibility: PropTypes.string.isRequired,
};
