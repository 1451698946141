import React from 'react'

import { CircularProgress } from "@rmwc/circular-progress";
import '@rmwc/circular-progress/styles';

import style from "./PageLoadingIndicator.module.scss";

const PageLoadingIndicator = () => {
  return (
    <div className={style.container}>
      <CircularProgress size="xlarge"/>
    </div>
  )
}

export default PageLoadingIndicator;