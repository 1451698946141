import React, { Fragment } from 'react';
import {
  Image as Img,
} from 'react-bootstrap';
import LogoIconNobg from '../../assets/images/LogoIconNobg';
import SvgVivedBannerNobkgd from '../../assets/images/LogoBannerNobg';
import Props from './props';

const Image = ({
  src,
  text,
  custom,
  ...props
}) => (<Fragment>
    {custom && <Img src={src} {...props} />}
    {src === 'img' && <Img src={LogoIconNobg} {...props} />}
    {src === 'logo-icon-nobg' && <LogoIconNobg {...props} />}
    {src === 'logo-banner-nobg' && <SvgVivedBannerNobkgd {...props} />}
    {text && <span className="ml-2">{text}</span>}
  </Fragment>);

Image.propTypes = Props;
export default Image;
