import React from "react";


import {
  Dialog,
  DialogTitle,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import AddMemberForm from "./AddMemberForm";

const AddMemberToChannel = ({
  open,
  onClose,
  setInviting,
  renderToPortal,
  channelId
}) => {
  
  return (
    <Dialog
      open={open}
      onClose={(evt) => onClose(evt)}
      renderToPortal={renderToPortal}
    >
      <DialogTitle>
        Add new member
      </DialogTitle>
      <AddMemberForm setInviting={setInviting} channelId={channelId} />
    </Dialog>
  );
};

export default AddMemberToChannel;
