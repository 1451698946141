import React from "react";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

// import style from "./FormInputs.module.scss";

import { InputErrorMessage } from "../Auth/AuthInputFields";

export const FirstName = ({ inputRef, register, errors, disabled }) => {
  const inputLabel = "First name";
  const inputName = "firstName";
  const inputType = "text";
  const inputMaxLength = 35;
  const validationErrMsg = {
    // required: "First name is required.",
    maxLength: `First name must be ${inputMaxLength} characters or less.`,
  };

  return (
    <TextField
      // general style props
      outlined
      // className={style.inputFullWidth}
      // value, configuration, validation props
      // required
      disabled={disabled}
      type={inputType}
      label={inputLabel}
      name={inputName}
      inputRef={(e) => {
        register(e, {
          // required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
        });
        if (inputRef) {
          inputRef.current = e;
        }
      }}
      invalid={errors[inputName]}
      helpText={{
        validationMsg: true,
        persistent: false,
        children: <InputErrorMessage errors={errors} inputName={inputName} />,
      }}
    />
  );
};

FirstName.defaultProps = {
  disabled: false,
}
