import React from 'react';

import { useSelector } from 'react-redux';
import propTypes from './props';

const NetworkWorking = () => {
  const working = useSelector(state => state.Universal.request.process);
  return (
    <div
      style={{
        display: `${working ? 'block' : 'none'}`,
      }}
      className="NetworkWorking"
    >
      Working
    </div>
  );
};

NetworkWorking.propTypes = propTypes;

export default NetworkWorking;
