import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Actions from "../../Redux/actions";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Error from "../../components/Error/Error";
import { LoginEnum } from "../../Redux/Users/UserTypes";

class AccountActivityDelete extends React.Component {
  state = {
    callSubmitted: false,
    error: false,
    errorMsg: ""
  };

  async componentDidMount() {
    while (
      this.props.loginStatus === LoginEnum.init ||
      this.props.loginStatus === LoginEnum.isLoggingIn
    ) {
      await new Promise(resolve => {
        setTimeout(resolve, 100);
      }); //Wait 1/10 second for login process
    }

    if (this.props.loginStatus !== LoginEnum.isLoggedIn) {
      this.props.history.push("/signin"); //Bump us out to the login page if we are not logged in
    } else if (!this.props.activity) {
      //If we get here then the user probably linked directly to this page. Bump them to their channels
      this.props.history.push("/account/channels");
    }
  }

  HandleClickDelete = () => {
    this.setState({ callSubmitted: true });

    this.props.fetchDeleteActivity(
      this.props.activity.id,
      this.props.activity.channel,
      resp => {
        if (resp.success) {
          this.props.history.push(`/channel/${resp.channelId}`);
        } else {
          this.setState({
            callSubmitted: false,
            error: true,
            errorMsg: resp.msg
          });
        }
      }
    );
  };

  HandleClickCancel = () => {
    this.props.history.push(`/channel/${this.props.activity.channel}`);
  };

  renderContent() {
    if (this.state.error) {
      return <Error message={this.state.errorMsg} />;
    } else if (this.state.callSubmitted) {
      return (
        <Col xs={12} className="x-center Activities__no">
          <span>Deleting activity...</span>
        </Col>
      );
    } else if (this.props.canDeleteActivity) {
      return (
        <div>
          <p>Are you sure you want to delete this Activity?</p>
          <button onClick={this.HandleClickDelete}>Yes, delete</button>
          <button onClick={this.HandleClickCancel}>No, don't delete</button>
        </div>
      );
    } else if (
      this.props.loginStatus === LoginEnum.init ||
      this.props.loginStatus === LoginEnum.isLoggingIn
    ) {
      return (
        <div>
          <p>Logging in...</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>You are not authorized to delete this activity</p>
        </div>
      );
    }
  }

  render() {
    return (
      <PageWrapper>
        <Container fluid>
          <Row className="AccountChannels">
            <Col xs={12} className="AccountChannels__title">
              <p>Delete Activity</p>
            </Col>
            <Col xs={12} className="AccountChannels__channels">
              <Row>{this.renderContent()}</Row>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    );
  }
}

const MapStateToProps = (state, ownProps) => {
  let activity = null;
  let canDeleteActivity = false;

  if (state.Activities.list.hasOwnProperty(ownProps.match.params.activityId)) {
    activity = state.Activities.list[ownProps.match.params.activityId];

    if (state.User.channels.hasOwnProperty(activity.channel)) {
      const role = state.User.channels[activity.channel];

      if (
        role === "owner" ||
        role === "admin" ||
        role === "author"
      ) {
        canDeleteActivity = true;
      }
    }
  }

  return {
    userId: state.User._id,
    loginStatus: state.User.loginStatus,
    history: ownProps.history,
    activity,
    canDeleteActivity
  };
};

export default connect(MapStateToProps, {
  fetchDeleteActivity: (activityID, channelId, callback) =>
    Actions.Activities.fetchDeleteActivity(activityID, channelId, callback)
})(AccountActivityDelete);