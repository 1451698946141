/* eslint-disable max-len */
import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const LockIcon = ({
  fill = colors.gray.dark,
  width = '28px',
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...iconProps}>
    <title>ic_lock_outline_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z" />
    </g>
  </svg>
);

LockIcon.propTypes = Props;
export default LockIcon;
