import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elevation } from "@rmwc/elevation";
import "@rmwc/elevation/styles";
import store from "../../store";

import style from "./Author.module.scss";
import Actions from "../../Redux/actions";
import { ChannelRole, LoginEnum } from "../../Redux/Users/UserTypes";
import Error from "../../components/Error/Error";
import Player2ShadowDom from "../../components/PlayerShadowDom/Player2ShadowDom";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { ActivityMetadataBar } from "../../components/Authoring/ActivityMetadataBar/ActivityMetadataBar";
import { EditActivityMetadataDialog } from "../../components/Dialog/EditActivityMetadataDialog/EditActivityMetadataDialog";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";

const AuthorPlayerV2 = (props) => {
  //Props
  const channelId = props.match.params.channelId;
  const channel = props.channel;
  const fetchChannel = props.fetchChannel;
  const clearEditingActivity = props.clearEditingActivity;
  const clearImportActivityData = props.clearImportActivityData;

  //States
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editMetaDialogOpen, setEditMetaDialogOpen] = useState(false);
  const [editMetaProcessing, setEditMetaProcessing] = useState(false);

  //Hooks
  const { role } = useChannelRole(channelId);
  const history = useHistory();

  //CHECK FOR CHANNEL
  useEffect(() => {
    if (!channel) {
      fetchChannel(channelId, (resp) => {
        if (!resp.success) {
          setError(true);
          setErrorMessage(resp.msg);
        }
      });
    }
  }, [channel, channelId, fetchChannel]);

  //CLEAN UP
  useEffect(() => {
    return () => {
      clearEditingActivity();
      clearImportActivityData();
    };
  }, [clearImportActivityData, clearEditingActivity]);

  //CHECK PERMISSIONS
  useEffect(() => {
    let notLoggedIn = props.loginStatus === LoginEnum.isNotLoggedIn;
    if (notLoggedIn) {
      setError(true);
      setErrorMessage("You must be logged in to create a new activity");
    }

    let badPermissions = ![
      ChannelRole.owner,
      ChannelRole.admin,
      ChannelRole.author,
    ].includes(role);
    if (badPermissions && props.channel && role) {
      setError(true);
      setErrorMessage("You do not have permission to save to this channel");
    }
  }, [props.loginStatus, role, props.channel]);

  const update = (activity, activityJSON) => {
    return new Promise((pass, failed) => {
      props.uploadLargeFile(activityJSON, (resultA) => {
        if (!resultA.success) {
          setError(true);
          setErrorMessage(resultA.msg);
          failed();
        }

        props.fetchUpdateData(
          activity.channel,
          activity.id,
          resultA.filename,
          activity.dataId,
          (resultB) => {
            if (!resultB.success) {
              setError(true);
              setErrorMessage(resultB.msg);
              failed();
            }

            pass();
          }
        );
      });
    });
  };

  const create = (activityJSON) => {
    return new Promise((pass, failed) => {
      let isForPlayerV2 = true;

      props.uploadLargeFile(activityJSON, (resultA) => {
        if (!resultA.success) {
          setError(true);
          setErrorMessage(resultA.msg);
          failed();
        }

        let root_owner = history.location.state?.root_owner;

        //Make a new activity
        props.addActivity(
          resultA.filename,
          props.channel.name,
          props.channelId,
          root_owner && root_owner !== channelId ? root_owner : null,
          isForPlayerV2,
          (resultB) => {
            if (resultB.success) {
              props.setEditingActivity(resultB.activity.id);
              setEditMetaDialogOpen(true);

              pass();
            } else {
              setError(true);
              setErrorMessage(resultB.msg);
              failed();
            }
          }
        );
      });
    });
  };

  const getActivityToEdit = () => {
    let weAreEditing = store.getState().Activities.editingActivity;
    if (weAreEditing) {
      let activityId = store.getState().Activities.editingActivity,
        activity = store.getState().Activities.list[activityId];

      return activity;
    } else {
      return null;
    }
  };

  const onSaveCallback = async (activityJSON) => {
    let activityToEdit = getActivityToEdit();
    if (activityToEdit) {
      await update(activityToEdit, activityJSON);
    } else {
      await create(activityJSON);
    }
  };

  const renderContent = () => {
    if (error) {
      return (
        <div className={style.pageContentContainer}>
          <Error message={errorMessage} />;
        </div>
      );
    } else {
      let activity = getActivityToEdit();

      return (
        <div className={style.pageContentContainer}>
          <ActivityMetadataBar
            title={activity?.title}
            unsaved={activity ? false : true}
            onEdit={() => setEditMetaDialogOpen(true)}
          />
          <EditActivityMetadataDialog
            open={editMetaDialogOpen}
            onClose={() => setEditMetaDialogOpen(false)}
            setProcessing={setEditMetaProcessing}
            processing={editMetaProcessing}
            renderToPortal={true}
            activityMeta={
              activity
                ? {
                    id: activity.id,
                    channelId: props.channelId,
                    title: activity.title,
                    description: activity.description,
                    image: activity.image,
                  }
                : {
                    id: "",
                    channelId: props.channelId,
                    title: "",
                    description: "",
                    image: "",
                  }
            }
          />
          <Elevation z={1} wrap>
            <Player2ShadowDom
              size="authoring"
              playerOptions={{
                activityData: props.importData ?? null,
                activityTitle: activity?.title,
                activityDescription: activity?.description,
                canAuthor: true,
                startInAuthorMode: true,
                saveActivityFn: onSaveCallback,
                channelId
              }}
            />
          </Elevation>
        </div>
      );
    }
  };

  return <PageWrapper>{renderContent()}</PageWrapper>;
};

const MapStateToProps = (state, ownProps) => {
  const channelId = ownProps.match.params.channelId;

  let channel = null,
    haveChannel = state.Channels.list.hasOwnProperty(channelId);
  if (haveChannel) {
    channel = state.Channels.list[channelId];
  }

  let importData = null,
    importDataExists = state.Activities.importData;
  if (importDataExists) {
    importData = JSON.stringify(JSON.parse(state.Activities.importData));
  }

  return {
    channelId: channelId,
    collectionId: ownProps.match.params.collectionId,
    loginStatus: state.User.loginStatus,
    history: ownProps.history,
    channel,
    importData,
  };
};

export default connect(MapStateToProps, {
  fetchChannel: (channelId, callback) =>
    Actions.Channels.fetchAChannel(channelId, callback),
  addActivity: (
    activityJSON,
    channelName,
    channelId,
    root_owner = null,
    isForPlayerV2,
    callback
  ) =>
    Actions.Activities.fetchAddActivity(
      activityJSON,
      channelName,
      channelId,
      callback,
      root_owner,
      isForPlayerV2
    ),
  fetchUpdateData: (channelId, activityId, activityData, dataId, callback) =>
    Actions.Activities.fetchUpdateData(
      channelId,
      activityId,
      activityData,
      dataId,
      callback
    ),
  uploadLargeFile: (file, callback) =>
    Actions.Universal.uploadLargeFile(file, callback),
  setEditingActivity: (activitId) =>
    Actions.Activities.setEditingActivity(activitId),
  clearEditingActivity: () => Actions.Activities.clearEditingActivity(),
  clearImportActivityData: () => Actions.Activities.clearImportActivityData(),
})(AuthorPlayerV2);
