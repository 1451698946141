export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_SET_LTI_INFO = "USER_SET_LTI_INFO";
export const USER_SET_LTI_SESSION = "USER_SET_LTI_SESSION";
export const FETCH_USER_CHANNELS = "FETCH_USER_CHANNELS";
export const SET_USER_CHANNEL_LIST = "SET_USER_CHANNEL_LIST";
export const SET_PENDING_CHANNEL_LIST = "SET_PENDING_CHANNEL_LIST";
export const ADD_USER_CHANNEL = "ADD_USER_CHANNEL";
export const REMOVE_USER_CHANNEL = "REMOVE_USER_CHANNEL";
export const SET_USER_LOGIN_STATUS = "SET_USER_LOGIN_STATUS";
export const CONFIRM_CHANNEL_INVITE = "CONFIRM_CHANNEL_INVITE";
export const DECLINE_CHANNEL_INVITE = "DECLINE_CHANNEL_INVITE";
export const REMOVE_PENDING_CHANNEL = "REMOVE_PENDING_CHANNEL";
export const CHECK_IF_MAIN_USER = "CHECK_IF_MAIN_USER";

export const LoginEnum = Object.freeze({
  init: "init",
  isLoggingIn: "isLoggingIn",
  isLoggedIn: "isLoggedIn",
  isNotLoggedIn: "isNotLoggedIn",
});

export const ChannelRole = Object.freeze({
  none: "none",
  member: "member",
  author: "author",
  admin: "admin",
  owner: "owner",
});
