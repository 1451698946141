import React from "react";
import { PropTypes } from "prop-types";

import basicFetch from "../../utils/basicFetch";
import playerLoader from "../../utils/playerLoader";
import style from "./PlayerShadowDom.module.scss";
import { GetAssetFolderURL } from "../../UseCases/HostHandlers/GetAssetFolderURL";
import { CloseAssetSystemPlugin } from "../../UseCases/HostHandlers/CloseAssetSystemPlugin";
import assetSystemPlugin from "../../Data/assetSystemPlugin.json";
import { makeHostDispatcher } from "@vived/vivedlearning_host";

class NewModelShadowDom extends React.Component {
  playerContainer = null;
  app = null;

  constructor(props) {
    super(props);
    this.state = {
      styles: [],
    };
  }

  async componentDidMount() {
    const asset_manifest = await basicFetch(
      `https://apps-bucket-${assetSystemPlugin.stage}-6655.s3.amazonaws.com/apps/${assetSystemPlugin.id}/${assetSystemPlugin.version}/asset-manifest.json`,
      {
        method: "get",
      }
    );

    const shadow = this.buildShadow();

    let entrypoints = asset_manifest.entrypoints.map((path) => {
      return `https://apps-bucket-${assetSystemPlugin.stage}-6655.s3.amazonaws.com/apps/${assetSystemPlugin.id}/${assetSystemPlugin.version}/${path}`;
    });
    const styles = await playerLoader(entrypoints, shadow);

    this.setState({
      styles,
    });

    this.startPlayer(shadow);
  }

  buildShadow() {
    let shadowParent = document.getElementById("asset-system-plugin-shadow");
    let shadow = shadowParent.attachShadow({ mode: "open" });

    shadow.innerHTML = `
    <link type="text/css" href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <style> .player-container{ display: flex; position: relative; height: 100%; width: 100%; } </style>
    <div id="asset-system-plugin-container" class="player-container" />
    `;

    this.playerContainer = shadow.getElementById(
      "asset-system-plugin-container"
    );

    shadow.createElement = (...args) => document.createElement(...args);
    shadow.createElementNS = (...args) => document.createElementNS(...args);
    shadow.createTextNode = (...args) => document.createTextNode(...args);

    return shadow;
  }

  startPlayer() {
    try {
      this.app = window[assetSystemPlugin.id];

      const hostHandler = this.props.hostHandler;
      const dispatchType = this.props.dispatchType;

      new GetAssetFolderURL(hostHandler);
      new CloseAssetSystemPlugin(hostHandler, this.props.history);

      const appHandler = this.app.mount(hostHandler.handler);
      const hostDispatcher = makeHostDispatcher();
      hostDispatcher.registerAppHandler(appHandler);

      const payload = {
        channelID: this.props.channelId,
        channelName: this.props.channelName,
        userToken: this.props.userToken,
        container: this.playerContainer,
        baseApiUrl: "https://api.vivedlearning.com",
      };

      hostDispatcher.dispatch({
        version: 1,
        type: dispatchType,
        payload,
      });
    } catch (e) {
      console.log("[NewModelShadowDom] error caught: ", e);
    }
  }

  render() {
    let styles = [];

    this.state.styles.forEach((style) => {
      styles.push(style);
    });

    const styles2 = [
      style.container,
      this.props.size === "normal" ? style.containerNormal : null,
      this.props.size === "freeplay" ? style.containerFreeplayFullsize : null,
      this.props.size === "authoring" ? style.containerAuthoring : null,
      this.props.size === "embed" ? style.containerEmbed : null,
      this.props.size === "fill" ? style.containerFill : null,
    ];

    styles2.forEach((style) => {
      styles.push(style);
    });

    const classes = styles.join(" ");

    return <div id="asset-system-plugin-shadow" className={classes} />;
  }
}

NewModelShadowDom.defaultProps = {
  appBar: false,
};

NewModelShadowDom.propTypes = {
  appBar: PropTypes.bool,
  size: PropTypes.oneOf(["normal", "freeplay", "authoring", "embed", "fill"])
    .isRequired,
  title: PropTypes.string,
  channelId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  hostHandler: PropTypes.object.isRequired,
  dispatchType: PropTypes.string.isRequired,
};

export default NewModelShadowDom;
