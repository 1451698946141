import Universal from '../reducers/universal';
import Forms from '../reducers/forms';
import Playlist from '../reducers/playlist';
import Activities from '../reducers/activities';
import Channels from './Channels/ChannelReducers';
import Temporal from '../reducers/temporal';
import Collections from './Collections/CollectionReducers';
import Player from '../reducers/player';
import User from "./Users/UserReducers"
import Apps from "./Apps/AppsReduces"

export default {
  Universal,
  User,
  Forms,
  Playlist,
  Activities,
  Channels,
  Temporal,
  Collections,
  Player,
  Apps
};
