import React from "react";
import { connect } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper/PageWrapper";

import Props from "./props";

class Home extends React.Component {

  render() {
    return (
      <PageWrapper>
        <div className="Home">
          <div className="Home__coming">
            <span>VIVED Learning Platform</span>
            <span>Preview</span>
          </div>
          <Container fluid className="Home__presentation">
            <Row>
              <Col xs={12} className="Home__presentation__desc">
                <span>VIVED Learning Platform</span>
                <span>Created by VIVED.</span>
              </Col>
              <Col xs={12} className="Home__presentation__stories">
                <span>
                  Create stories and lessons around 3d content in minutes, not
                  weeks.
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    history: ownProps.history
  };
};

Home.propTypes = Props;

export default connect(mapStateToProps, {})(Home);
