import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@rmwc/typography"; // Css not needed here. Using Sass in main.
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import style from "./EmbeddedActivityPreview.module.scss";

export const EmbeddedActivityPreview = ({
  title,
  image,
  description,
  channelName,
  channelImage,
  onLoadActivity,
}) => {
  const RenderBarOverlay = () => {
    return (
      <div className={style.titleBar}>
        <div className={style.titleBarContent}>
          <Avatar
            size="large"
            src={channelImage}
            name={channelName}
          />
          <Typography use="headline6" tag="div" className={style.titleBarText}>
            {channelName} | {title}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className={style.container}>
      <img src={image} alt={description} className={style.image} />
      <RenderBarOverlay />
      <div className={style.startButton}>
          <Button raised label="Load Activity" onClick={onLoadActivity} />
      </div>
    </div>
  );
};

EmbeddedActivityPreview.defaultProps = {
};

EmbeddedActivityPreview.propTypes = {
  channelImage: PropTypes.string,
  channelName: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  onLoadActivity: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};
