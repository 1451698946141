import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { Elevation } from "@rmwc/elevation";
import "@rmwc/elevation/styles";

import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import Error from "../../components/Error/Error";
import PlayerShadowDom from "../../components/PlayerShadowDom/PlayerShadowDom";
import Player2ShadowDom from "../../components/PlayerShadowDom/Player2ShadowDom";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { ActivityMetadataBar } from "../../components/Authoring/ActivityMetadataBar/ActivityMetadataBar";
import { EditActivityMetadataDialog } from "../../components/Dialog/EditActivityMetadataDialog/EditActivityMetadataDialog";
import { useActivity } from "../../hooks/useActivity";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import Actions from "../../Redux/actions";
import { ChannelRole, LoginEnum } from "../../Redux/Users/UserTypes";

export const ActivityEdit = () => {
  // State
  const [channelId, setChannelId] = useState("");
  const [userAuthorized, setUserAuthorized] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [activityIsPlayerV2, setActivityIsPlayerV2] = useState(false);
  const [activityRetrieved, setActivityRetrieved] = useState(false);
  const [contentReady, setContentReady] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [editMetaDialogOpen, setEditMetaDialogOpen] = useState(false);
  const [editMetaProcessing, setEditMetaProcessing] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const loginStatus = useSelector((state) => state.User.loginStatus);
  const { activityId, channelId: channelIdFromParamMatch } = useParams();
  const { activity, error: activityError } = useActivity(activityId);
  const channelIdFromLocationState = location?.state?.channelId ?? "";
  const channelIdFromActivityData = activity?.channel ?? "";
  const { role: userChannelRole } = useChannelRole(channelId);

  useEffect(() => {
    if (!channelId) {
      if (channelIdFromLocationState) {
        setChannelId(channelIdFromLocationState);
      } else if (channelIdFromParamMatch) {
        setChannelId(channelIdFromParamMatch);
      } else if (channelIdFromActivityData) {
        setChannelId(channelIdFromActivityData);
      }
    }
  }, [
    channelId,
    channelIdFromLocationState,
    channelIdFromParamMatch,
    channelIdFromActivityData,
  ]);

  useEffect(() => {
    // user must be logged in
    if (
      loginStatus === LoginEnum.init ||
      loginStatus === LoginEnum.isLoggingIn
    ) {
      return;
    } else if (loginStatus === LoginEnum.isNotLoggedIn) {
      history.push({
        pathname: "/signin",
        search: `?redirect=${location.pathname + location.search}`,
      });
    } else if (loginStatus === LoginEnum.isLoggedIn) {
      setUserLoggedIn(true);
      // User is signed in and the process can continue
    }
  }, [history, location.pathname, location.search, loginStatus]);

  useEffect(() => {
    // User must have permission to author
    if (userLoggedIn && userChannelRole) {
      if (
        userChannelRole === ChannelRole.none ||
        userChannelRole === ChannelRole.member
      ) {
        setError(true);
        setErrorMessage(
          "User does not have permission to author in this channel."
        );
      } else if (
        userChannelRole === ChannelRole.author ||
        userChannelRole === ChannelRole.admin ||
        userChannelRole === ChannelRole.owner
      ) {
        setUserAuthorized(true);
      } else {
        console.warn(
          `[ActivityEdit] - ERROR: An unknown channel role error has occured.`
        );
        setError(true);
        setErrorMessage("An unknown channel role error has occurred.");
      }
    }
  }, [userLoggedIn, userChannelRole, channelId]);

  useEffect(() => {
    // We must have the activity data to proceed
    if (activityError) {
      setError(true);
      setErrorMessage(activityError);
    } else if (activity) {
      if (activity.isPlayerV2) {
        setActivityIsPlayerV2(true);
      }
      setActivityRetrieved(true);
    }
  }, [activity, activityError]);

  useEffect(() => {
    if (userLoggedIn && userAuthorized && activityRetrieved) {
      setContentReady(true);
    }
  }, [userLoggedIn, userAuthorized, activityRetrieved]);

  const fetchUpdateData = (channelId, activityId, activityData, dataId, cb) => {
    dispatch(
      Actions.Activities.fetchUpdateData(
        channelId,
        activityId,
        activityData,
        dataId,
        cb
      )
    );
  };
  const uploadLargeFile = (file, cb) => {
    dispatch(Actions.Universal.uploadLargeFile(file, cb));
  };

  const playerOptionsV1 = {
    onSaveCallback: (activityJSON) => onSaveCallbackV1(activityJSON),
  };

  const onPlayerCreatedV1 = (player) => {
    const activityOptions = {
      canEdit: true,
      startInAuthor: true,
    };

    player.LoadActivityFromJSONString(activity.data, activityOptions);
  };

  const onSaveCallbackV1 = (activityJSON) => {
    uploadLargeFile(activityJSON, (result) => {
      if (!result.success) {
        setError(true);
        setErrorMessage(result.msg);
        return;
      }

      fetchUpdateData(
        activity.channel,
        activity.id,
        result.filename,
        activity.dataId,
        (resp) => {
          if (!resp.success) {
            setError(true);
            setErrorMessage(result.msg);
          }

          window.VIVEDPlayer.OnSaveActivityIsComplete(resp.success);
        }
      );
    });
  };

  const onSaveCallbackV2 = (activityJSON) => {
    return new Promise((pass, failed) => {
      uploadLargeFile(activityJSON, (result) => {
        if (!result.success) {
          setError(true);
          setErrorMessage(result.msg);
          failed();
        }

        fetchUpdateData(
          activity.channel,
          activity.id,
          result.filename,
          activity.dataId,
          (resp) => {
            if (!resp.success) {
              setError(true);
              setErrorMessage(result.msg);
              failed();
            }

            pass();
          }
        );
      });
    });
  };

  const renderPlayerShadowDom = () => {
    if (activityIsPlayerV2) {
      return (
        <Player2ShadowDom
          size="authoring"
          playerOptions={{
            activityData: activity?.data,
            activityTitle: activity?.title,
            canAuthor: true,
            startInAuthorMode: true,
            saveActivityFn: onSaveCallbackV2,
            channelId
          }}
        />
      );
    } else {
      return (
        <PlayerShadowDom
          onPlayerCreated={(player) => onPlayerCreatedV1(player)}
          playerOptions={playerOptionsV1}
          size="authoring"
        />
      );
    }
  };

  const renderContent = () => {
    if (error) {
      return <Error message={errorMessage} />;
    } else if (contentReady) {
      return (
        <>
          <ActivityMetadataBar
            title={activity?.title}
            unsaved={activity ? false : true}
            onEdit={() => setEditMetaDialogOpen(true)}
          />
          <EditActivityMetadataDialog
            open={editMetaDialogOpen}
            onClose={() => setEditMetaDialogOpen(false)}
            setProcessing={setEditMetaProcessing}
            processing={editMetaProcessing}
            renderToPortal={true}
            activityMeta={{
              id: activity.id,
              channelId: activity.channel,
              title: activity.title,
              description: activity.description,
              image: activity.image,
            }}
          />
          <Elevation z={1} wrap>
            {renderPlayerShadowDom()}
          </Elevation>
        </>
      );
    } else {
      return <PageLoadingIndicator />;
    }
  };

  return <PageWrapper>{renderContent()}</PageWrapper>;
};
