import { takeEvery } from "redux-saga/effects";

import { requestModule, uploadImage } from "./requestmodule";

import { deleteFile, uploadFile, uploadLargeFile } from "../sagas/universal";
import {
  uploadAnyLargeFile
} from "./Universal/sagas"

import { getPlaylists, playlistSubscribe } from "../sagas/playlist";

import {
  insertActivity,
  getAllActivities,
  deleteActivity,
  getActivity,
  fetchActivitiesForChannel,
  fetchUpdateActivityData,
  fetchUpdateActivityMeta,
  duplicateActivity
} from "../sagas/activities";

import {
  exportActivity,
  importActivity
} from "./Activities/ActivitySagas"

import * as ActivityTypes from "./Activities/ActivityTypes"

import {
  insertChannel,
  getAllChannels,
  getAChannel,
  fetchUpdateChannel,
  getLtiChannels,
  getChannelMembers,
  deleteChannel,
  editJoinCode,
  submitJoinCode,
  joinChannel,
  leaveChannel,
  updateChannelMemebersRole,
  deleteChannelMember,
  getChannelOwner,
  getChannelContent,
  channelAddCollection,
  channelAddBundle,
  channelRemoveCollection,
  channelRemoveBundle,
  requestMoreUsers,
  inviteUser,
  addChannelSubscription,
  getChannelLinkedChannels,
  deleteChannelSubscription,
  editChannelSubscription
} from "./Channels/ChannelSagas";

import * as AppSagas from "./Apps/AppsSagas"
import * as AppTypes from "./Apps/AppsTypes"

import { getBundleCollections, getAllCollections, getCollectionBundle, getCollection, getAllBundles } from "./Collections/CollectionSagas";

import * as TYPES from "../actions/types";
import * as COLLECTION from './Collections/CollectionTypes'; 
import * as CHANNELS from './Channels/ChannelTypes';
import {
  FETCH_USER_CHANNELS,
  CONFIRM_CHANNEL_INVITE,
  DECLINE_CHANNEL_INVITE,
  CHECK_IF_MAIN_USER
} from "./Users/UserTypes";
import {
  getUserChannels,
  fetchAcceptChannelInvite,
  fetchDeclineChannelInvite,
  checkIfMainUser
} from "./Users/UserSagas";


import * as UniversalTypes from "./Universal/types"

export function* mySaga() {
  try {
    /* PLAYLIST */
    yield takeEvery("PLAYLIST_FETCH_ALL", getPlaylists(requestModule));
    yield takeEvery(
      "PLAYLIST_FETCH_SUBSCRIBE",
      playlistSubscribe(requestModule)
    );
    /* ACTIVITIES */
    yield takeEvery(
      TYPES.ACTIVITIES_FETCH_INSERT,
      insertActivity(requestModule)
    );
    yield takeEvery(
      TYPES.ACTIVITIES_FETCH_ALL,
      getAllActivities(requestModule)
    );
    yield takeEvery(
      TYPES.ACTIVITIES_FETCH_UPDATE_META,
      fetchUpdateActivityMeta(requestModule, uploadImage)
    );
    yield takeEvery(TYPES.ACTIVITY_FETCH, getActivity(requestModule));
    yield takeEvery(
      TYPES.ACTIVITIES_FETCH_FOR_CHANNEL,
      fetchActivitiesForChannel(requestModule)
    );
    yield takeEvery(
      TYPES.ACTIVITIES_FETCH_UPDATE_DATA,
      fetchUpdateActivityData(requestModule)
    );
    yield takeEvery(
      TYPES.ACTIVITIES_FETCH_DELETE,
      deleteActivity(requestModule)
    );

    yield takeEvery(
      TYPES.ACTIVITES_DUPLICATE,
      duplicateActivity(requestModule)
    );

    yield takeEvery(
      ActivityTypes.ACTIVITIES_EXPORT,
      exportActivity(requestModule)
    );

    yield takeEvery(
      ActivityTypes.ACTIVITIES_IMPORT,
      importActivity(requestModule)
    );
    
    /* CHANNELS */
    yield takeEvery(CHANNELS.CHANNELS_FETCH_INSERT, insertChannel(requestModule));
    yield takeEvery(CHANNELS.CHANNELS_FETCH_ALL, getAllChannels(requestModule));
    yield takeEvery(CHANNELS.CHANNEL_FETCH, getAChannel(requestModule));
    yield takeEvery(CHANNELS.CHANNEL_FETCH_OWNER, getChannelOwner(requestModule));
    yield takeEvery(CHANNELS.CHANNEL_FETCH_CONTENT, getChannelContent(requestModule));
    yield takeEvery(CHANNELS.CHANNEL_ADD_COLLECTION, channelAddCollection(requestModule));
    yield takeEvery(CHANNELS.CHANNEL_ADD_BUNDLE, channelAddBundle(requestModule));
    yield takeEvery(CHANNELS.CHANNEL_REMOVE_COLLECTION, channelRemoveCollection(requestModule));
    yield takeEvery(CHANNELS.CHANNEL_REMOVE_BUNDLE, channelRemoveBundle(requestModule));
    yield takeEvery(
      CHANNELS.CHANNEL_FETCH_UPDATE,
      fetchUpdateChannel(requestModule, uploadImage)
    );
    yield takeEvery(
      CHANNELS.CHANNELS_FETCH_USER_CHANNELS,
      getUserChannels(requestModule)
    );
    yield takeEvery(
      CHANNELS.CHANNELS_FETCH_LTI_CHANNELS,
      getLtiChannels(requestModule)
    );
    yield takeEvery(
      CHANNELS.CHANNEL_FETCH_MEMBERS,
      getChannelMembers(requestModule)
    );
    yield takeEvery(
      CHANNELS.CHANNEL_DELETE_MEMBER,
      deleteChannelMember(requestModule)
    );
    yield takeEvery(CHANNELS.CHANNEL_DELETE, deleteChannel(requestModule));
    yield takeEvery(
      CHANNELS.CHANNELS_EDIT_JOIN_CODE,
      editJoinCode(requestModule)
    );
    yield takeEvery(
      CHANNELS.CHANNELS_SUBMIT_JOIN_CODE,
      submitJoinCode(requestModule)
    );
    yield takeEvery(CHANNELS.CHANNELS_JOIN, joinChannel(requestModule));
    yield takeEvery(CHANNELS.CHANNELS_LEAVE, leaveChannel(requestModule));

    yield takeEvery(
      CHANNELS.UPDATE_CHANNEL_MEMBERS_ROLE,
      updateChannelMemebersRole(requestModule)
    );

    yield takeEvery(
      CHANNELS.CHANNEL_REQUEST_MORE_USERS,
      requestMoreUsers(requestModule)
    );

    yield takeEvery(
      CHANNELS.CHANNEL_INVITE_USERS,
      inviteUser(requestModule)
    );

    yield takeEvery(
      CHANNELS.CHANNEL_ADD_LINKED_CHANNEL_SUBSCRIPTION,
      addChannelSubscription(requestModule)
    )

    yield takeEvery(
      CHANNELS.CHANNEL_DELETE_LINKED_CHANNEL_SUBSCRIPTION,
      deleteChannelSubscription(requestModule)
    )

    yield takeEvery(
      CHANNELS.CHANNEL_EDIT_LINKED_CHANNEL_SUBSCRIPTION,
      editChannelSubscription(requestModule)
    )

    yield takeEvery(
      CHANNELS.CHANNEL_GET_LINKED_CHANNELS,
      getChannelLinkedChannels(requestModule)
    )

    /* USERS */
    yield takeEvery(FETCH_USER_CHANNELS, getUserChannels(requestModule));
    yield takeEvery(
      CONFIRM_CHANNEL_INVITE,
      fetchAcceptChannelInvite(requestModule)
    );
    yield takeEvery(
      DECLINE_CHANNEL_INVITE,
      fetchDeclineChannelInvite(requestModule)
    );

    yield takeEvery(
      CHECK_IF_MAIN_USER,
      checkIfMainUser(requestModule)
    );

    /* COLLECTIONS */
    yield takeEvery(
      COLLECTION.COLLECTION_BUNDLE_FETCH,
      getBundleCollections(requestModule)
    );

    yield takeEvery(
      COLLECTION.COLLECTIONS_GET_ALL_COLLECTIONS,
      getAllCollections(requestModule)
    );

    yield takeEvery(
      COLLECTION.COLLECTIONS_GET_ALL_BUNDLES,
      getAllBundles(requestModule)
    );

    yield takeEvery(
      COLLECTION.COLLECTION_GET_BUNDLE,
      getCollectionBundle(requestModule)
    );

    yield takeEvery(
      COLLECTION.COLLECTION_GET_COLLECTION,
      getCollection(requestModule)
    );

    yield takeEvery(
      [
        "UNIVERSAL_REMOVE_MODULE",
        "UNIVERSAL_UPDATE_MODULE",
        "UNIVERSAL_INSERT_MODULE",
        "UNIVERSAL_FETCH_MODULE",
      ],
      requestModule
    );

    yield takeEvery(TYPES.UNIVERSAL_UPLOAD_FILE, uploadFile(uploadImage));
    yield takeEvery(
      TYPES.UNIVERSAL_UPLOAD_LARGE_FILE,
      uploadLargeFile(requestModule)
    );
    yield takeEvery(TYPES.UNIVERSAL_DELETE_FILE, deleteFile(requestModule));

    yield takeEvery(UniversalTypes.UNIVERSAL_UPLOAD_ANY_LARGE_FILE, uploadAnyLargeFile(requestModule));

    yield takeEvery(AppTypes.APPS_GET_ALL, AppSagas.getAllApps(requestModule));

    yield takeEvery(AppTypes.APPS_ADD_TO_CHANNEL, AppSagas.addAppToChannel(requestModule));
    
    yield takeEvery(AppTypes.APPS_REMOVE_TO_CHANNEL, AppSagas.removeAppFromChannel(requestModule));

    
  } catch (err) {
    console.error("mySaga Error: ", err);
  }
}

export default mySaga;
