import React from "react";

import { connect } from "react-redux";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Typography } from "@rmwc/typography"; // Sass used in Main, css not needed here
import { Redirect } from "react-router-dom";

import style from "./Channels.module.scss";

import Actions from "../../Redux/actions";
import { LoginEnum } from "../../Redux/Users/UserTypes";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Error from "../../components/Error/Error";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";

import { FlexGrid } from "../../components/Layout/Layout";
import ChannelCard from "../../components/Cards/ChannelCard/ChannelCard";

/**
 * Local states
 */
const STATE_ENUM = Object.freeze({
  INIT: "INIT",
  ERROR: "ERROR",
  READY: "READY",
  FETCHING_CHANNELS: "FETCHING_CHANNELS",
});

class Channels extends React.Component {
  state = {
    current: STATE_ENUM.INIT,
    msg: "",
  };

  componentDidMount() {

    //If the login status is not init, go ahead and kick off getting everything we need
    //Otherwise we will kick it all off in the component did update function
    if (this.props.loginStatus !== LoginEnum.init) {
      this.fetchChannelsIfNeeded();
    }
  }

  componentDidUpdate() {
    //State machine
    const currentState = this.state.current;
    if (
      currentState === STATE_ENUM.INIT &&
      this.props.loginStatus !== LoginEnum.init
    ) {
      //Done getting the user login status, now we can kick it all off
      this.fetchChannelsIfNeeded();
    }
  }

  //This starts all the chain of event needed for this page.
  //If the channels are already in the redux store we will move on to checking user channels
  //Otherwize we will kick off the fetch all channels call to the api
  fetchChannelsIfNeeded() {
    if (this.props.allHaveBeenRetrieved) {
      this.setState({ current: STATE_ENUM.READY });
    } else {
      this.setState({ current: STATE_ENUM.FETCHING_CHANNELS });
      this.props.fetchAllChannels(0, 500, (resp) => {
        if (resp.success) {
          this.setState({ current: STATE_ENUM.READY });
        } else {
          this.setState({
            current: STATE_ENUM.ERROR,
            msg: resp.msg,
          });
        }
      });
    }
  }

  renderFetchingAndButton = () => {
    if (this.state.isFetching) {
      //TODO probably add a spinner. This gets rendered when we are hitting up the API for a list of activites
      return (
        <Typography use="body1" tag="p" style={{ textAlign: "center" }}>
          Loading channels...
        </Typography>
      );
    } else if (!this.props.allHaveBeenRetrieved) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={this.getNextCollectionList} outlined>
            Load more
          </Button>
        </div>
      );
    } else return <></>;
  };

  renderChannelList = () => {
    return this.props.channels.map((c) => {
      let showChannel = false;

      if (c.access_pattern === "public") {
        //This is a public channel
        showChannel = true;
      } else if (c.role !== "none") {
        //This user is a member of this channel
        showChannel = true;
      }

      if (showChannel) {
        return (
          <React.Fragment key={c.id}>
            <ChannelCard
              name={c.name}
              image={c.image_url}
              channelId={c.id}
              role={c.role}
            />
          </React.Fragment>
        );
      } else {
        return null;
      }
    });
  };

  renderChannelsContent = () => {
    return (
      <div className={style.content}>
        <Typography use="headline6" tag="h1" className={style.pageTitle}>
          Channels
        </Typography>
        <FlexGrid>
          {this.renderChannelList()}
          {this.renderFetchingAndButton()}
        </FlexGrid>
      </div>
    );
  };

  renderContent = () => {
    const currentState = this.state.current;
    if (currentState === STATE_ENUM.ERROR) {
      return <Error message={this.state.msg} />;
    } else if (currentState === STATE_ENUM.READY) {
      return this.renderChannelsContent();
    } else {
      return <PageLoadingIndicator />;
    }
  };

  render() {
    let redirectToOnlyChannel = false;
    if (this.props.isLtiUser && this.props.channels.length === 1) {
      redirectToOnlyChannel = true;
    }

    return redirectToOnlyChannel ? (
      <Redirect to={`/channel/${this.props.channels[0].id}`} />
    ) : (
      <PageWrapper>
        {this.renderContent()}
      </PageWrapper>
    );
  }
}

const MapStateToProps = (state, ownProps) => {
  const { allHaveBeenRetrieved, channelsRetrieved } = state.Channels;

  const loginStatus = state.User.loginStatus;

  const channelList = Object.values(state.Channels.list);
  const channelListToShow = [];
  channelList.forEach((channel) => {
    if (state.User.isLtiUser) {      
      // for LTI users only add LTI channels
      if (
        state.Channels.ltiChannels[channel.id]        
      ) {
        channelListToShow.push(channel);
      }
    } else {
      // regular users
      if (channel.access_pattern === "public") {
        channelListToShow.push(channel);
      } else {
        if (
          loginStatus === LoginEnum.isLoggedIn &&
          channel.role &&
          channel.role !== "none"
        ) {
          channelListToShow.push(channel);
        }
      }
    }
  });  

  //Sort alphabetically
  channelListToShow.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return {
    channels: channelListToShow,
    allHaveBeenRetrieved,
    channelsRetrieved,
    history: ownProps.history,
    loginStatus: state.User.loginStatus,
    isLtiUser: state.User.isLtiUser,
  };
};

export default connect(MapStateToProps, {
  fetchAllChannels: (offset, limit, callback) =>
    Actions.Channels.fetchAllChannels(offset, limit, callback),
})(Channels);
