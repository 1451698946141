import React, { useState } from "react";
import { useChannel } from "../../hooks/Channel/useChannel";
import { CircularProgress } from "@rmwc/circular-progress";
import ChannelCard from "../Cards/ChannelCard/ChannelCard";
import { Button } from "@rmwc/button";
import { useDispatch } from "react-redux";
import {
  declineChannelInvite,
  confirmChannelInvite,
} from "../../Redux/Users/UserActions";
import { dialogQueue } from "../Dialog/dialogQueue";
import { snackbarQueue } from "../Snackbar/snackbarQueue";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";

const PendingChannelItem = ({ channelId }) => {
  const { channel, error } = useChannel(channelId);
  const [submitting, setSubmitting] = useState(false);
  const { role } = useChannelRole(channelId);

  const dispatch = useDispatch();

  function handleJoinClick() {
    setSubmitting(true);
    dispatch(
      confirmChannelInvite(channelId, (resp) => {
        setSubmitting(false);
        if (resp.success) {
          snackbarQueue.notify({
            title: <b>Success!</b>,
            body: role === "owner" ? `You are now the owner of the channel ${channel.name}.` : `You have joined the channel ${channel.name}.`,
          });
        } else {
          snackbarQueue.notify({
            title: <b>Uh oh!</b>,
            body: `Unable to join channel`,
          });
        }
      })
    );
  }

  function handleDismissClick() {
    dialogQueue
      .confirm({
        title: "Dismiss Invite?",
        body: "Are you sure you want to dismiss your invite to this channel?",
        acceptLabel: "Yes, dismiss",
      })
      .then((resp) => {
        if (resp === true) {
          setSubmitting(true);
          dispatch(
            declineChannelInvite(channelId, role, (resp) => {

              if (resp.success) {
                snackbarQueue.notify({
                  title: <b>Success!</b>,
                  body: role === "owner" ? `You have rejected ownership of channel ${channel.name}` : `You have declined joining the channel ${channel.name}`,
                });
              } else {
                snackbarQueue.notify({
                  title: <b>Uh oh!</b>,
                  body: `Unable to decline channel invite`,
                });
              }
              setSubmitting(false);
            })
          );
        }
      });
  }

  function renderButtons() {
    if (submitting) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <Button onClick={handleJoinClick}>Accept</Button>
          <Button onClick={handleDismissClick}>Decline</Button>
        </>
      );
    }
  }

  if (channel) {
    return (
      <>
        <ChannelCard
          name={channel.name}
          image={channel.image_url}
          channelId={channel.id}
          role={channel.role}
        />
        {renderButtons()}
      </>
    );
  } else if (error) {
    return <div>Something is not right</div>;
  } else {
    return <CircularProgress>Fetching channel...</CircularProgress>;
  }
};

export default PendingChannelItem;
