import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import {  
  DialogContent,
  DialogActions,
  DialogButton,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";
// import { IconButton } from "@rmwc/icon-button";
// import "@rmwc/icon-button/styles";
import { Select } from "@rmwc/select";
import "@rmwc/select/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import { InputErrorMessage } from "../../Auth/AuthInputFields";
// import { Button } from "@rmwc/button";

import { currentUser } from "../../../utils/AmplifyAuthController";
import { snackbarQueue } from "../../Snackbar/snackbarQueue";
import Actions from "../../../Redux/actions";
import store from "../../../store"

const defaultValues = {
  firstName: "",
  lastName: "",
  role: ""
};

const AddMemberForm = ({ setInviting, channelId }) => {
  const { register, handleSubmit, control, errors, formState } = useForm({
    mode: "onChange",
    defaultValues: defaultValues
  })
  const { isDirty, isValid  } = formState;

  const dispatch = useDispatch();

  const roleOptions = {
    member: "Member",
    author: "Author",
    admin: "Admin",
  }

  const styleFullWidth = {width: "100%"};

  const renderFirstName = () => {
    const inputLabel = "First name";
    const inputName = "firstName";
    const inputType = "text";
    const inputMaxLength = 35;
    const validationErrMsg = {
      // required: "First name is required.",
      maxLength: `First name must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        // required
        type={inputType}
        label={inputLabel}
        name={inputName}
        // data-mdc-dialog-initial-focus // will focus this input initially after the dialog has opened.
        inputRef={(e) => {
          register(e, {
            // required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderLastName = () => {
    const inputLabel = "Last name";
    const inputName = "lastName";
    const inputType = "text";
    const inputMaxLength = 40;
    const validationErrMsg = {
      // required: "Last name is required.",
      maxLength: "Last name must be 40 characters or less.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        // required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          // required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderEmail = () => {
    const inputLabel = "Email";
    const inputName = "email";
    const inputType = "email";
    const inputMaxLength = 255;
    // eslint-disable-next-line no-useless-escape
    const inputPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validationErrMsg = {
      required: "An email address is required.",
      maxLength: "The email address must be 254 characters or less.",
      pattern: "Email requires a valid email address.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern,
          },
        })}
        invalid={errors[inputName]}
        // onChange={() => triggerValidation(inputName)}
        // disabled={step === 2}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderRole = () => {
    const inputLabel = "Role";
    const inputName = "role";
    const validationErrMsg = {
      required: "Member role is required."
    };
    return (
      <Controller
        as={
          <Select
            outlined
            required
            label={inputLabel}
            options={roleOptions}
            invalid={errors[inputName]}
            helpText={{
              validationMsg: true,
              persistent: false,
              children: <InputErrorMessage errors={errors} inputName={inputName} />,
            }}
          />
        }
        name={inputName}
        control={control}
        rules={{required: validationErrMsg.required}}
      />
    )

  }

  const onSubmit = async (data) => {
    setInviting(true);

    let callback = (result) => {
      if(result.success) {

        dispatch(Actions.Channels.fetchMembers(channelId, (resp) => {
          if (!resp.success) {
              console.error("error: ", resp);           
          }
          else{
              //success
          }        
        }));

        setInviting(false);
        snackbarQueue.notify({
          title: <b>Success!</b>,
          body: `${data.email} has been invited`,
        });
      } else {
        setInviting(false);
        snackbarQueue.notify({
          title: <b>Uh oh!</b>,
          body: "Unable to send invite",
        });  
      }
    }

    let nameToUse = data.firstName + " " + data.lastName;
    nameToUse = nameToUse.trim();

    const user = await currentUser();
    if (!user) throw Error("Could not get user");
    let isResending = false

    const channelMembers = store.getState().Channels.list[channelId].members
    channelMembers.forEach(member => {
      if(member.email === data.email) {
        isResending = true
      }
    })

    dispatch(Actions.Channels.inviteUserToChannel(channelId, nameToUse, data.email, data.role, user.attributes.sub, isResending, callback))
  }

  const isSubmitDisabled = () => {
    let submitDisabled = true;
    if (isDirty && isValid){
      submitDisabled = false;
    } else submitDisabled = true;
    return submitDisabled;
  }

  const renderDialogContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          Use the form below to invite a new user to this channel.
          <div style={{paddingTop: "16px"}}>{renderFirstName()}</div>
          <div>{renderLastName()}</div>
          <div>{renderEmail()}</div>
          <div>{renderRole()}</div>
        </DialogContent>

        <DialogActions>
          <DialogButton action="cancel" type="button">Cancel</DialogButton>
          <DialogButton type="submit" isDefaultAction disabled={isSubmitDisabled()}>Add member</DialogButton>
        </DialogActions>
      </form>
    );
  };

  return (
    <>
      {renderDialogContent()}
    </>
  );
};

export default AddMemberForm;
