import { useState, useEffect } from "react";
import { useUserGroups } from "./useUserGroups";

/**
 * @description This hook looks at the current user's groups and determines if the user is included in the Admin group. Initializes as undefined/null.
 * @returns { boolean } isEmployee
 */
export const useUserIsVivedEmployee = () => {
  const userGroups = useUserGroups();
  const [isEmployee, setIsEmployee] = useState();

  useEffect(() => {
    if (userGroups && userGroups.includes(process.env.REACT_APP_VIVED_EMPLOYEE_GROUP)) {
      setIsEmployee(true);
    } else {
      setIsEmployee(false);
    }
  }, [userGroups]);

  return isEmployee;
};
