import React from "react";
import { connect } from "react-redux";

import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Typography } from "@rmwc/typography"; //Using Sass in main. No css needed here.

import style from "./Dashboard.module.scss";
import { BrowseChannelsCard, ContentCreatorCard, CardContainer} from "./Cards";

import ChannelCard from "../../components/Cards/ChannelCard/ChannelCard";
import Error from "../../components/Error/Error";
import { FlexGrid, ContentContainer } from "../../components/Layout/Layout";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PendingChannelsList from "../../components/PendingChannels/PendingChannelsList";
import { sortAphabetically_forObjects } from "../../utils/helpers";

class Dashboard extends React.Component {
  state = {
    error: false,
    msg: "",
  };  

  renderCurrentYear = () => {
    return new Date().getFullYear();
  };

  handleManageChannels = () => {
    this.props.history.push("/join");
  };

  handleClickMakeChannel = () => {
    if (this.props.user.groups.includes("Admin")) {
      this.props.history.push("/account/channels/new");
    } else {
      this.props.history.push("/requestChannel");
    }
  };

  renderSalutation = () => {
    let user = this.props.user,
    name = user.email,
    userNameIsGivenName = user?.name && !user.name.includes(" ")

    if(user?.given_name) {
      name = user.given_name
    } 
    else if(userNameIsGivenName) {
      name = user.name
    }

    return <h1>{"Welcome " + name}</h1>;
  };

  renderChannelsList = () => {
    if (this.props.channels.length === 0) {
      return <span>You don't have any Channels yet. </span>;
    } else {
      return (
        <FlexGrid>
          {this.props.channels.map((c) => {
            return (
              <React.Fragment key={c.id}>
                <ChannelCard
                  name={c.name}
                  image={c.image_url}
                  channelId={c.id}
                  role={c.role}
                />
              </React.Fragment>
            );
          })}
        </FlexGrid>
      );
    }
  };

  renderYourChannels = () => {

    return (
      <div className={style.yourChannelsContainer}>
      <div className={style.yourChannelsHeading}>
        <div className={style.yourChannelsTitle}>
        <Typography use="headline5" tag="h1">
          Your Channels
        </Typography>
        </div>
        {/* <div className={style.yourChannelsManage}>
          <Button outlined onClick={this.handleManageChannels}>Manage</Button>
        </div>  */}
      </div>
      {this.renderChannelsList()}
      </div>
    )
  }

  renderCallToActions = () => {
    return (
      <CardContainer>
        <BrowseChannelsCard className={style.browse} toPath="/join" />
        <ContentCreatorCard className={style.creator} toPath="/content-creators" />
      </CardContainer>
    )
  }

  renderContent() {
    if (this.state.error) {
      return <Error message={this.state.msg} />;
    } else {
      //Render the main content
      return (
        <ContentContainer>
          {/* {this.renderSalutation()} */}
          <PendingChannelsList/>
          {this.renderYourChannels()}
          {this.renderCallToActions()}
        </ContentContainer>
      );
    } 
  }

  render() {
    return (
      <PageWrapper>
        {this.renderContent()}

        <div className={style.footer} >
          <Button onClick={() => this.props.history.push("/about")}>About</Button>
          <Button onClick={() => this.props.history.push("/terms")}>Terms</Button>
          <Button onClick={() => this.props.history.push("/privacy")}>Privacy</Button>
          <label>&copy; {this.renderCurrentYear()} VIVED</label>
        </div>
        
      </PageWrapper>
    );
  }
}

const MapStateToProps = (state, ownProps) => { 
  let channels = [];  
  let myChannels = []

  for (let index = 0; index < Object.values(state.User.channels).length; index++) {
    const chanPermission = Object.values(state.User.channels)[index];
    const chanId = Object.keys(state.User.channels)[index];

    if(chanPermission !== "subscriber") {
      myChannels.push(chanId)
    }
  }

  myChannels.forEach((channelId) => {
    if (state.Channels.list.hasOwnProperty(channelId)) {
      channels.push(state.Channels.list[channelId]);
    }
  });  

  channels = sortAphabetically_forObjects(channels, "name")

  return {
    channels,
    history: ownProps.history,
    user: state.User    
  };
};

export default connect(MapStateToProps, {})(Dashboard);
