import { useState, useEffect } from "react";
import { usePrevious } from "./usePrevious";

export function useIframe() {
  const [iframe, setIframe] = useState(null);
  const [parentUrl, setParentUrl] = useState(null);
  const prevIframe = usePrevious(iframe);
  const prevParentUrl = usePrevious(parentUrl);

  useEffect(() => {
    // If window.parent doesn't equal window, then we are in an iframe
    const isIframe = window.parent !== window;
    if (prevIframe !== isIframe) {
      setIframe(isIframe);
    }
  }, [prevIframe]);

  useEffect(() => {
    if (iframe) {
      const referrerUrl = document.referrer;
      if (referrerUrl && prevParentUrl !== referrerUrl) {
        setParentUrl(referrerUrl);
      }
    }
  }, [iframe, prevParentUrl]);

  return {
    iframe,
    parentUrl,
  };
}
