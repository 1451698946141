import React from "react";
import { useForm } from "react-hook-form";

import { DialogContent, DialogActions, DialogButton } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";
import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { Typography } from "@rmwc/typography"; // Using Sass in main, no css needed here
import { useDispatch } from "react-redux";
import { addLinkedChannelSubscription } from "../../../Redux/Channels/ChannelActions";
import { snackbarQueue } from "../../../components/Snackbar/snackbarQueue";

import style from "./AddChannelSubscriptionForm.module.scss";

import { InputErrorMessage } from "../../Auth/AuthInputFields";

const AddChannelSubscriptionForm = ({
  channelId,
  setProcessing,
  processing,
  onClose
}) => {
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
    // defaultValues: defaultValues
  });
  const { isValid: formIsValid } = formState;

  const dispatch = useDispatch();

  /// Constants
  const styleFullWidth = { width: "100%" };

  /// Event handler functions
  const onSubmit = async (data) => {
    setProcessing(true);

    const { subscriptionChannelId, authorResources, duplicateActivities, expirationDate } = data;

    dispatch(
      addLinkedChannelSubscription(channelId, subscriptionChannelId, authorResources, duplicateActivities, expirationDate, (result) => {
        setProcessing(false);
        if (result.success) {
          snackbarQueue.notify({
            title: <b>Success!</b>,
            body: "New subscription has been added.",
          });
          onClose();
        } else {
          snackbarQueue.notify({
            title: <b>Error</b>,
            body: result.msg
          });
        }
      })
    );
  };

  /// Render helper functions
  const renderSubscriptionChannelIdInput = () => {
    const inputLabel = "Subscription channel id";
    const inputName = "subscriptionChannelId";
    const inputType = "text";
    // const inputPattern // ToDo: don't allow white space
    const validationErrMsg = {
      required: "The subscription channel id is required.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderCanAuthorFromResourcesInput = () => {
    // const inputLabel = "Allow authoring from subscription channel resources";
    const inputLabel = "Authoring";
    const inputName = "authorResources";
    const inputType = "checkbox";

    return (
      <Checkbox
        className={style.checkbox}
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register}
      />
    );
  };
  const renderCanDupicateActivitiesInput = () => {
    // const inputLabel = "Allow duplicating subscription channel activities to subscriber channel";
    const inputLabel = "Duplicate";
    const inputName = "duplicateActivities";
    const inputType = "checkbox";

    return (
      <Checkbox
        className={style.checkbox}
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register}
      />
    );
  };

  const renderExpirationDateInput = () => {
    const inputLabel = "Expiration date";
    const inputName = "expirationDate";
    const inputType = "date";
    const validationErrMsg = {
      required: "An expiration date is required.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderDialogContent = () => {
    if (processing) {
      return (
        <DialogContent>
          <CircularProgress /> processing...
        </DialogContent>
      );
    }
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          Use the form below to create a new channel subscription.
          <div style={{ paddingTop: "16px" }}>
            {renderSubscriptionChannelIdInput()}
          </div>
          <div>
            <Typography use="overline" tag="div">
              Features
            </Typography>
            <div>{renderCanAuthorFromResourcesInput()}</div>
            <div>{renderCanDupicateActivitiesInput()}</div>
            <div style={{ paddingTop: "16px" }}>
              {renderExpirationDateInput()}
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <DialogButton action="cancel" type="button">
            Cancel
          </DialogButton>
          <DialogButton type="submit" isDefaultAction disabled={!formIsValid}>
            Add subscription
          </DialogButton>
        </DialogActions>
      </form>
    );
  };

  return <>{renderDialogContent()}</>;
};

export default AddChannelSubscriptionForm;
