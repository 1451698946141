import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useHistory,
  Redirect,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { TabBar, Tab } from "@rmwc/tabs";
import "./custom-tab-style.scss";

import Actions from "../../Redux/actions";
import DuplicateActivityDialog from "../../components/Dialog/DuplicateActivityDialog/DuplicateActiviteDialog";
import PageError from "../../components/Error/PageError";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PendingChannelBanner from "../../components/PendingChannels/PendingChannelBanner";
import store from "../../store";
import useChannelContent from "../../hooks/Channel/useChannelContent";
import { ActivityList } from "./ActivityList/ActivityList";
import { LinkedChannelList } from "./LinkedChannelList/LinkedChannelList";
import { ModelList } from "./ModelList/ModelList";
import { UploadActivityDialog } from "../../components/Dialog/UploadActivityDialog/UploadActivityDialog";
import { dialogQueue } from "../../components/Dialog/dialogQueue";
import { duplicateActivity } from "../../actions/activities";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";
import { useChannel } from "../../hooks/Channel/useChannel";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useIsLTIUser } from "../../hooks/User/useIsLTIUser";
import {
  ChannelHeader,
  ChannelBanner,
} from "../../components/ChannelHeader/ChannelHeader";

import style from "./Channel.module.scss";

const Channel = (props) => {
  //Props
  let { channelId } = useParams();

  //States
  const [isLoading, setIsLoading] = useState(true);
  const [canAuthor, setCanAuthor] = useState(false);
  const [canEditChannel, setCanEditChannel] = useState(false);
  const [error, setError] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [_activities, setActivities] = useState(null);
  const [showLinkedChannels, setShowLinkedChannels] = useState(false);
  const [storedChannelId, setStoredChannelId] = useState("");
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDuplicatingSucessfull, setIsDuplicatingSucessfull] = useState(false);
  const [ActivityToRedirectTo, setActivityToRedirectTo] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [channelHasApps, setChannelHasApps] = useState(false);

  //Hooks for nested routes in tab bar
  const { path, url } = useRouteMatch();
  const matchTab0Route = useRouteMatch(`${path}/activities`);
  const matchTab1Route = useRouteMatch(`${path}/models`);
  const matchTab2Route = useRouteMatch(`${path}/channels`);

  //Hooks other
  const { role } = useChannelRole(channelId);
  let {
    channel,
    error: getChannelError,
    isFetchingChannel,
  } = useChannel(channelId);
  const isLTIUser = useIsLTIUser();
  const history = useHistory();
  const { myOrganizedResources, fetching: isFetchingChannelContent } =
    useChannelContent(channelId);

  let canAuthor_asSubscriber = false,
    canEdit_asSubscriber = false,
    expiration_forSubscription = null,
    subscriberId = null,
    subscriberRole = null;

  if (props.location.state) {
    subscriberId = props.location.state.subscriberId;
    canAuthor_asSubscriber = props.location.state.canAuthor_asSubscriber;
    canEdit_asSubscriber = props.location.state.canEdit_asSubscriber;
    expiration_forSubscription =
      props.location.state.expiration_forSubscription;
    subscriberRole = props.location.state.subscriberRole;
  }

  // ToDo: Identify if this channel has more than 1 tab
  const multipleTabs = true;
  const showModelsTab = channel?.view_collections ? true : false;

  // reset things if we change channels
  useEffect(() => {
    if (channelId !== storedChannelId) {
      setStoredChannelId(channelId);
      setIsLoading(true);
      setActiveTabIndex(null);
      setActivities(null);
      setSubscriptions(null);
    }
  }, [channelId, storedChannelId]);

  // set up subscriptions
  useEffect(() => {
    if (!isFetchingChannel && channel && !subscriptions) {
      if (channel.subscriptions && channel.subscriptions.length > 0) {
        let _showLinkedChannels = false;
        let updatedSubscriptions = channel.subscriptions.map((sub) => {
          sub.isActive = !sub.isExpired;

          if (sub.isActive) {
            _showLinkedChannels = true;
          }

          return sub;
        });

        setSubscriptions(updatedSubscriptions);
        setShowLinkedChannels(_showLinkedChannels);
      } else {
        setShowLinkedChannels(false);
        setSubscriptions([]);
      }
    } else if (!isFetchingChannel && channel && showLinkedChannels) {
      // see if subscriptions need reset when user clicks on a linked channel
      if (!channel.subscriptions || channel.subscriptions.length <= 0) {
        setShowLinkedChannels(false);
        setSubscriptions([]);
      }
    }
  }, [showLinkedChannels, channel, isFetchingChannel, subscriptions]);

  // handle set up activity permission
  useEffect(() => {
    if (
      !isFetchingChannel &&
      channel &&
      subscriptions &&
      role &&
      !_activities
    ) {
      //I have to know the correct channel id and subscriber id
      let updateActivities = channel.activities.map((actId) => {
        let act = store.getState().Activities.list[actId];

        act.isLocked = false;
        act.isHidden = false;
        act.canDuplicate = false;
        act.canEdit_Delete = false;

        // If this activity belongs to this channel
        // check if we can edit and delete it
        if (
          act.channel === channelId &&
          ["owner", "admin", "author"].includes(role)
        ) {
          act.canEdit_Delete = true;
        }

        // If we are in a subscription
        // check if we can duplicate and the expiration
        if (subscriberId && subscriberRole) {
          if (
            canEdit_asSubscriber &&
            ["admin", "owner", "author"].includes(subscriberRole)
          ) {
            let now = Date.now(),
              expiration = new Date(expiration_forSubscription).getTime();
            if (now < expiration) {
              // subscription is not expired
              act.canDuplicate = true;
              act.canEdit_Delete = false;
            }
          }
        }

        // If this channel is displaying an activity that came from a subscription
        // check if we can duplicate it and the experation
        if (act.channel !== channelId) {
          let subscription = store
            .getState()
            .Channels.list[channelId].subscriptions.filter(
              (sub) => sub.id === act.channel
            )[0];

          if (subscription) {
            if (subscription.isExpired) {
              act.isHidden = true; // subscription is expired
            }

            if (
              subscription.canEdit &&
              ["owner", "admin", "author"].includes(role)
            ) {
              act.canDuplicate = true;
            }
          } else {
            act.isHidden = true;
          }
        }

        // If this channel has an activity the originaly came from a subscription
        // check if the subscription has not expired and exists
        if (act.hasOwnProperty("root_owner")) {
          let root_owner = subscriptions.filter(
            (sub) => sub.id === act.root_owner
          )[0];

          if (root_owner) {
            if (root_owner.isExpired) {
              act.isLocked = true; // subscription is expired
            }
          } else {
            act.isLocked = true;
          }
        }

        // Hide draft only activities for users who are just members
        if (role === "member" && act.isPlayerV2 && act.hasDraft && !act.hasPublished) {
          act.isHidden = true;
        }

        return act;
      });

      setActivities(updateActivities);
    }
  }, [
    channel,
    subscriptions,
    _activities,
    isFetchingChannel,
    role,
    subscriberRole,
    canEdit_asSubscriber,
    channelId,
    subscriberId,
    expiration_forSubscription,
  ]);

  //handle setup for channel permissions
  useEffect(() => {
    if (!isFetchingChannel) {
      if (
        role === "none" &&
        channel?.access_pattern === "private" &&
        !isLTIUser
      ) {
        if (subscriberId) {
          // Check if we are in a subscription

          setCanAuthor(false);
          setCanEditChannel(false);
        } else {
          //Give 'em the boot! Not supposed to be here
          history.push("/dashboard");
        }
      } else {
        //Good to go! Setup some permissions
        if (role === "owner" || role === "admin") {
          setCanEditChannel(true);
          setCanAuthor(true);
        } else if (role === "author") {
          setCanAuthor(true);
        } else {
          setCanAuthor(false);
          setCanEditChannel(false);
        }
      }
    }
  }, [role, channel, history, isLTIUser, isFetchingChannel, subscriberId]);

  //handle is loading
  useEffect(() => {
    if (isLoading && !isFetchingChannel) {
      if (channel && _activities && subscriptions) {
        setIsLoading(false);
      }
    }
  }, [isLoading, channel, _activities, subscriptions, isFetchingChannel]);

  //Monitor for errors
  useEffect(() => {
    if (getChannelError) {
      setError("Something went wrong while getting channel " + getChannelError);
    }
  }, [getChannelError]);

  // Initialize the active tab if user didn't initiate tab change (ie browser back/refresh)
  useEffect(() => {
    if (matchTab0Route) {
      setActiveTabIndex(0);
    } else if (matchTab1Route) {
      setActiveTabIndex(1);
    } else if (matchTab2Route) {
      setActiveTabIndex(2);
    }
  }, [matchTab0Route, matchTab1Route, matchTab2Route]);

  //handle setup for channel content
  useEffect(() => {
    if (!isFetchingChannelContent) {
      let hasApps = false;
      myOrganizedResources.forEach((resource) => {
        if (resource.type === "channel-app") {
          hasApps = true;
        }
      });
      setChannelHasApps(hasApps);
    }
  }, [isFetchingChannelContent, myOrganizedResources]);

  const resetDuplicatActivityProcess = () => {
    setIsDuplicating(false);
    setIsDuplicatingSucessfull(false);
    setActivityToRedirectTo(null);
  };

  // Event helper functions
  const duplicateActivityToSubscriberChannel = (
    activityId,
    subscriptionChannelId,
    subscriberId
  ) => {
    setIsDuplicating(true);
    store.dispatch(
      duplicateActivity(
        subscriberId,
        activityId,
        subscriptionChannelId,
        (result) => {
          if (result.success) {
            setActivityToRedirectTo(result.activity.id);
            setIsDuplicating(false);
            setIsDuplicatingSucessfull(true);
            setStoredChannelId(null);
          } else {
            if (
              result.msg === "We currently do not support duplicating apps."
            ) {
              setIsDuplicating(false);
              snackbarQueue.notify({
                title: <b>Sorry</b>,
                body: result.msg,
              });
            } else {
              setIsDuplicating(false);
              snackbarQueue.notify({
                title: <b>Oh No</b>,
                body: "Something went wrong, please try again",
              });
            }
          }
        }
      )
    );
  };

  const deleteActivity = (activityId, channelId) => {
    dialogQueue
      .confirm({
        title: "Permanently delete this activity?",
        body: "Are you sure you want to delete this activity? This action can't be undone.",
        acceptLabel: "Delete Activity",
      })
      .then((success) => {
        if (success) {
          store.dispatch(
            Actions.Activities.fetchDeleteActivity(
              activityId,
              channelId,
              (result) => {
                if (result.success) {
                  snackbarQueue.notify({
                    title: <b>Success!</b>,
                    body: "Activity has been deleted",
                  });
                  setStoredChannelId("this is just to reset the channel");
                } else {
                  snackbarQueue.notify({
                    title: <b>Uh, oh!</b>,
                    body: "We were unable to delete the activity, please try again",
                  });
                }
              }
            )
          );
        }
      });
  };

  const handleTabInteraction = (index) => {
    if (index === "mdc-tab-activities") {
      history.push({
        pathname: `${url}/activities`,
        search: "?",
        state: {
          subscriberId,
          subscriberRole,
          canAuthor_asSubscriber,
          canEdit_asSubscriber,
          expiration_forSubscription,
        },
      });
    }
    if (index === "mdc-tab-models") {
      history.push({
        pathname: `${url}/models`,
        search: "?",
        state: {
          subscriberId,
          subscriberRole,
          canAuthor_asSubscriber,
          canEdit_asSubscriber,
          expiration_forSubscription,
        },
      });
    }
    if (index === "mdc-tab-linked-channels") {
      history.push({
        pathname: `${url}/channels`,
        search: "?",
        state: {
          subscriberId,
          subscriberRole,
          canAuthor_asSubscriber,
          canEdit_asSubscriber,
          expiration_forSubscription,
        },
      });
    }
  };

  const renderLinkedChannelsList = () => {
    return (
      <Route exact path={`${url}/channels`}>
        {/* Todo: connect real linked channel data to linkedChannel prop below */}
        <LinkedChannelList
          linkedChannels={subscriptions}
          subscriberRole={role}
        />
      </Route>
    );
  };

  const renderModelsList = () => {
    return (
      <Route exact path={`${url}/models`}>
        <ModelList
          isLtiUser={isLTIUser}
          channelId={channel.id}
          subscriberId={subscriberId}
          isViewingFromSubscription={subscriberId ? true : false}
        />
      </Route>
    );
  };

  const renderActivitiesList = () => {
    return (
      <Route exact path={`${url}/activities`}>
        <ActivityList
          activities={_activities}
          isLtiUser={isLTIUser}
          channelId={channelId}
          subscriberId={subscriberId}
          duplicateActivity={duplicateActivityToSubscriberChannel}
          isViewingFromSubscription={subscriberId ? true : false}
          deleteActivity={deleteActivity}
        />
      </Route>
    );
  };

  function renderContent() {
    if (error) {
      return <PageError error={true} errorMessage={error} />;
    } else if (!isLoading) {
      return (
        <div>
          <ChannelBanner
            banner={channel.banner_url}
            channelId={channel.id}
            canEditChannel={canEditChannel}
          />
          <PendingChannelBanner channelId={channelId} history={props.history} />
          <div className={style.content}>
            <ChannelHeader
              channelId={channel.id}
              channelName={channel.name}
              channelImage={channel.image_url}
              canAuthor={canAuthor}
              setUploadDialogOpen={setUploadDialogOpen}
              hasApps={channelHasApps}
            />
            <TabBar
              activeTabIndex={activeTabIndex ? activeTabIndex : 0}
              className={multipleTabs ? null : style.oneTabOnly}
              onActivate={(evt) => setActiveTabIndex(evt.detail.index)}
            >
              <Tab
                label="Activities"
                id="mdc-tab-activities"
                onInteraction={(evt) => {
                  handleTabInteraction(evt.detail.tabId);
                }}
              />
              {channel.view_collections ? (
                <Tab
                  label="Models"
                  id="mdc-tab-models"
                  onInteraction={(evt) => {
                    handleTabInteraction(evt.detail.tabId);
                  }}
                />
              ) : null}
              {showLinkedChannels ? (
                <Tab
                  label="Channels"
                  id="mdc-tab-linked-channels"
                  onInteraction={(evt) => {
                    handleTabInteraction(evt.detail.tabId);
                  }}
                />
              ) : null}
            </TabBar>

            <Switch>
              {renderActivitiesList()}
              {showModelsTab ? renderModelsList() : null}
              {showLinkedChannels ? renderLinkedChannelsList() : null}
              <Route exact path={path}>
                <Redirect
                  from={`${url}`}
                  to={{
                    pathname: `${url}/activities`,
                    state: {
                      subscriberId,
                      subscriberRole,
                      canAuthor_asSubscriber,
                      canEdit_asSubscriber,
                      expiration_forSubscription,
                    },
                  }}
                />
              </Route>
            </Switch>
          </div>
        </div>
      );
    } else if (isLoading && channel && channel.id === storedChannelId) {
      return (
        <div>
          <ChannelBanner
            banner={channel.banner_url}
            channelId={channel.id}
            canEditChannel={canEditChannel}
          />
          <PendingChannelBanner channelId={channelId} history={props.history} />
          <div className={style.content}>
            <ChannelHeader
              channelId={channel.id}
              channelName={channel.name}
              channelImage={channel.image_url}
              canAuthor={canAuthor}
              setUploadDialogOpen={setUploadDialogOpen}
              hasApps={channelHasApps}
            />
            <TabBar
              activeTabIndex={activeTabIndex ? activeTabIndex : 0}
              className={multipleTabs ? null : style.oneTabOnly}
              onActivate={(evt) => setActiveTabIndex(evt.detail.index)}
            >
              <Tab
                label="Activities"
                id="mdc-tab-activities"
                onInteraction={(evt) => {
                  handleTabInteraction(evt.detail.tabId);
                }}
              />
              {channel.view_collections ? (
                <Tab
                  label="Models"
                  id="mdc-tab-models"
                  onInteraction={(evt) => {
                    handleTabInteraction(evt.detail.tabId);
                  }}
                />
              ) : null}
              {showLinkedChannels ? (
                <Tab
                  label="Channels"
                  id="mdc-tab-linked-channels"
                  onInteraction={(evt) => {
                    handleTabInteraction(evt.detail.tabId);
                  }}
                />
              ) : null}
            </TabBar>

            <PageLoadingIndicator />
          </div>
        </div>
      );
    } else {
      return <PageLoadingIndicator />;
    }
  }

  return (
    <PageWrapper>
      {renderContent()}
      <DuplicateActivityDialog
        isAlertOpen={isDuplicating}
        isRedirectOpen={!isDuplicating && isDuplicatingSucessfull}
        ActivityToRedirectTo={ActivityToRedirectTo}
        resetDuplicatActivityProcess={resetDuplicatActivityProcess}
      />
      <UploadActivityDialog
        open={uploadDialogOpen}
        onClosed={() => setUploadDialogOpen(false)}
        channel={{
          id: channel?.id,
          name: channel?.name,
          image: channel?.image_url,
        }}
      />
    </PageWrapper>
  );
};

export default Channel;
