import store from "./store";
import Actions from "./Redux/actions";
import jwt from "jsonwebtoken";
import { setLtiSession, setLtiInfo } from "./Redux/Users/UserActions";
import { EndPoint } from "./utils/apiClient";
import { lmsMockLtiData } from "./LTI_mock_data";

export default class LTI {
  constructor() {
    const verbose = false;
    let isLti = false;

    // Set `useMockLtiData` to true to use Mock LTI data locally.
    const useMockLtiData = false;
    // Sets which mock LTI data string to use when mocking. See all option in the `lmsMockLtiData` object
    const mockLtiData = lmsMockLtiData.canvas.deepLinking;

    if (verbose) {
      console.log("env: ", process.env);
      console.log("secret: ", process.env.REACT_APP_JWT_SECRET);
    }
    let secret = process.env.REACT_APP_JWT_SECRET;

    const urlParams = new URLSearchParams(window.location.search);
    const sessionToken = urlParams.get("session");
    //const sessionToken = query.get("session");
    if (sessionToken) {
      if (verbose) {
        console.log("Found LTI session: ", sessionToken);
      }

      // verify token
      jwt.verify(sessionToken, secret, function (err, payload) {
        if (err) {
          return console.log("ERROR: ", err);
        }

        if (verbose) {
          console.log("JWT is valid and payload is\n", payload);
        }

        // lti processing
        const lti_obj = payload;

        if (verbose) {
          console.log("LTI object: ", lti_obj);
        }

        store.dispatch(setLtiInfo(lti_obj));
        store.dispatch(setLtiSession(sessionToken));
        store.dispatch(
          Actions.Channels.fetchLtiChannels(lti_obj.oauth_consumer_key)
        );
        isLti = true;
      });
    } else {
      if (verbose) {
        console.log("LTI session not found in session");
      }
    }

    let lti_mock_data = null;
    if (useMockLtiData && mockLtiData) {
      lti_mock_data = mockLtiData;
    }

    if (lti_mock_data && !isLti) {
      // do lti testing override if it hasn't already been setup
      console.log("LTI mock data override string: ", lti_mock_data);
      const lti_obj = JSON.parse(lti_mock_data);
      console.log("LTI object: ", lti_obj);
      store.dispatch(setLtiInfo(lti_obj));
      store.dispatch(
        Actions.Channels.fetchLtiChannels(lti_obj.oauth_consumer_key)
      );
      isLti = true;
    }
  }
}

export const GetEmbedActivityUrl = (
  channelId,
  activityId,
  activityTitle,
  ltiReturnUrl,
  canvasCourseId,
  canvasApiDomain
) => {
  const apiEndpoint = EndPoint();

  const contentUrl = new URL("/ltiaccess", `${apiEndpoint}`);
  contentUrl.searchParams.set("channelId", channelId);
  contentUrl.searchParams.set("activityId", activityId);
  contentUrl.searchParams.set("embed", 1);

  const ltiToolsUrl = new URL(
    `/courses/${canvasCourseId}/external_tools/retrieve`,
    `https://${canvasApiDomain}`
  );
  ltiToolsUrl.searchParams.set("display", "borderless");
  ltiToolsUrl.searchParams.set("url", contentUrl.href);

  const finalRedirectUrl = new URL(ltiReturnUrl);
  finalRedirectUrl.searchParams.set("return_type", "iframe");
  finalRedirectUrl.searchParams.set("frameborder", 0);
  finalRedirectUrl.searchParams.set("allowfullscreen", true);
  finalRedirectUrl.searchParams.set("title", activityTitle);
  finalRedirectUrl.searchParams.set("width", "100%");
  finalRedirectUrl.searchParams.set("height", "640px");
  finalRedirectUrl.searchParams.set("url", ltiToolsUrl.href);

  return finalRedirectUrl;
};

export const GetActivityLinkUrl = (
  channelId,
  activityId,
  activityTitle,
  ltiReturnUrl
) => {
  const apiEndpoint = EndPoint();

  const contentUrl = new URL("/ltiaccess", `${apiEndpoint}`);
  contentUrl.searchParams.set("channelId", channelId);
  contentUrl.searchParams.set("activityId", activityId);

  const finalRedirectUrl = new URL(ltiReturnUrl);
  finalRedirectUrl.searchParams.set("return_type", "lti_launch_url");
  finalRedirectUrl.searchParams.set("display", "borderless");
  finalRedirectUrl.searchParams.set("title", activityTitle);
  finalRedirectUrl.searchParams.set("text", activityTitle);
  finalRedirectUrl.searchParams.set("target", "_blank");
  finalRedirectUrl.searchParams.set("url", contentUrl.href);

  return finalRedirectUrl;
};

export const GetActivityLinkUrlNoAuth = (
  activityId,
  activityTitle,
  ltiReturnUrl
) => {
  const contentUrl = new URL(
    `${window.location.protocol}//${window.location.hostname}/activity/${activityId}`
  );

  const finalRedirectUrl = new URL(ltiReturnUrl);
  finalRedirectUrl.searchParams.set("return_type", "url");
  finalRedirectUrl.searchParams.set("title", activityTitle);
  finalRedirectUrl.searchParams.set("text", activityTitle);
  finalRedirectUrl.searchParams.set("target", "_blank");
  finalRedirectUrl.searchParams.set("url", contentUrl.href);

  return finalRedirectUrl;
};
