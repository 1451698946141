import { call } from "redux-saga/effects";
import basicPutFile from "../../utils/basicPutFile";

export const uploadAnyLargeFile = (requestModule) =>
  function* (action) {
    let { payload, callback } = action,
    { folder, file, filename } = payload
    try {
      let req = yield call(requestModule, {
        method: "get",
        path: `upload/large/${folder}/${filename}`,
        process: true,
        query: {
          function_version: "2"
        }
      })

      const uploadUrl = req.data.url,
      contentType = req.data.contentType

      
      file = new File([file], filename, {
        lastModified: Date.now(),
        type: contentType
      })
      
      yield call (basicPutFile, uploadUrl, file, contentType)

      if (callback) {
        yield call(callback, { success: true });
      }
    } catch (err) {
      if (callback) {
        yield call(callback, { success: false, msg: err });
      } else {
        throw err;
      }
    };
  }