import { call, select } from "redux-saga/effects";
import {LoginEnum} from '../Redux/Users/UserTypes';
import uuid from "uuid"

export const deleteFile = requestModule =>
  function* deleteFileSaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      let filename = action.filename
      filename = filename.replace(`https://collections-bucket-prod-5566.s3.us-east-1.amazonaws.com/Thumbnails/`, '')
      // or
      filename = filename.replace(`https://collections-bucket-staging-5566.s3.us-east-1.amazonaws.com/Thumbnails/`, '')
      // or
      filename = filename.replace(`https://collections-bucket-dev-5566.s3.us-east-1.amazonaws.com/Thumbnails/`, '')

      yield call(requestModule, {
        method: "DELETE",
        path: `upload/Thumbnails/${filename}`
      });

      if (action.cb) {
        yield call(action.cb, { success: true });
      }
    } catch (err) {
      if (action.cb) {
        yield call(action.cb, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const uploadFile = uploadImage =>
  function* uploadFileSaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      yield call(uploadImage, action);

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error(err)
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const uploadLargeFile = requestModule =>
  function* uploadLargeFileSaga(action) {
    const { data } = action

    let file = new File([data], `${uuid.v4()}.viveddata`, {
      lastModified: Date.now()
    })

    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      const req = yield call(requestModule, {
        method: "GET",
        path: `upload/large/DataVariants/${file.name}`
      }),
      url = req.data

      yield call(UploadTheLargeFile, { url, file })

      if (action.callback) {
        yield call(action.callback, { success: true, filename: file.name });
      }
    } catch (err) {
      console.error(err)
      if (action.callback) {
        yield call(action.callback, { success: false, msg: "failed to upload file" });
      } else {
        throw err;
      }
    }
  };

async function UploadTheLargeFile (params) {
  
  return await fetch(params.url, {
    method: "PUT",
    body: params.file
  })
  .then(async (response) => {
    if(response.status === 200) {
      return true
    } else {
      throw new Error("failed to upload large file")
    }
  })
  .catch(err => {
    throw new Error(err)
  })
}