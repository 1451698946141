import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const DotsIcon = ({
  fill = colors.gray.dark,
  width = '24px',
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...iconProps}>
    <title>ic_more_vert_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  </svg>
);

DotsIcon.propTypes = Props;
export default DotsIcon;
