import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableCell,
  DataTableBody,
} from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import style from "./ChannelResources.module.scss";

import { SectionHeadlineRow } from "../../Layout/Headings";

import { SuperCentered } from "../../Layout/OneLineLayouts/SuperCentered/SuperCentered";
import { sortInAllDirections } from "../../../utils/helpers";

export const  ChannelResources = ({ onRemove, resources }) => {
  // Hooks

  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState(1); // null = unsorted, 1 = ascending, -1 = descending
  const [sortColumnSecondary, setSortColumnSecondary] = useState(null);
  const [list, setList] = useState([]);
  const [removingArray, setRemovingArray] = useState([]);
  const [toRemoveFromRemovingArray, setToRemoveFromRemovingArray] = useState([]);

  // Effect for keeping track of which spinners to show for bundles/collections being removed
  useEffect(() => {
    if (toRemoveFromRemovingArray.length > 0) {
      // got some stuff to remove
      const tempToRemoveArrary = [...toRemoveFromRemovingArray];
      for (let i = 0; i < tempToRemoveArrary.length; i++) {
        const resouceId = tempToRemoveArrary[i];
        if (removingArray.includes(resouceId)) {
          // found the one to remove
          let newRemovingArray = [...removingArray];
          const index = newRemovingArray.indexOf(resouceId);
          if (index > -1) {
            newRemovingArray.splice(index, 1);
            setRemovingArray([...newRemovingArray]);
          }
        }
        // found the one to remove
        let newToRemoveRemovingArray = [...toRemoveFromRemovingArray];
        const index = newToRemoveRemovingArray.indexOf(resouceId);
        if (index > -1) {
          newToRemoveRemovingArray.splice(index, 1);
          setToRemoveFromRemovingArray([...newToRemoveRemovingArray]);
        }
      }
    }

  }, [removingArray, toRemoveFromRemovingArray]); 

  //Sort Resources
  useEffect(() => { 
    if(list.length !== resources.length) {

      if (sortColumn && sortDirection) {
        let list = sortInAllDirections(resources, sortColumn, sortDirection, sortColumnSecondary)
        setList(list);
      } else {
        setList(resources);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, list, sortColumn, sortDirection, sortColumnSecondary]);

  /// Constants & other
  const headers = [
    {
      id: 1,
      name: "thumbnail",
      label: "",
      sortable: false,
    },
    {
      id: 2,
      name: "name",
      label: "Name",
      sortable: true,
    },
    {
      id: 3,
      name: "type",
      label: "Type",
      sortable: true,
    },
    {
      id: 4,
      name: "action",
      label: "Action",
      sortable: false,
    },
  ];

  /// Event hander functions
  const handleOnSortChange = (column, sortDir) => {
    const secondarySort = column === "type" ? "name" : null;

    setSortColumn(column);
    setSortDirection(sortDir);
    setSortColumnSecondary(secondarySort);
  };

  /// Render helper functions
  const renderTableHeaders = () => {
    return headers.map((i) => {
      if (i.sortable) {
        return (
          <DataTableHeadCell
            // alignStart
            key={`ar-${i.name}-${i.id}`}
            sort={sortColumn === i.name ? sortDirection : null}
            onSortChange={(sortDir) => handleOnSortChange(i.name, sortDir)}
          >
            {i.label}
          </DataTableHeadCell>
        );
      } else {
        return (
          <DataTableHeadCell key={`ar-${i.name}-${i.id}`}>
            {i.label}
          </DataTableHeadCell>
        );
      }
    });
  };

  const onRemoveCallback = (resouceId) => {
    if (!toRemoveFromRemovingArray.includes(resouceId)) {
      let newToRemoveFromRemovingArrary = [...toRemoveFromRemovingArray];
      newToRemoveFromRemovingArrary.push(resouceId);
      setToRemoveFromRemovingArray([...newToRemoveFromRemovingArrary]);
    }
  }

  const renderRemoveButton = r => {
    if (removingArray.includes(r.id)) {
      // spinner
      return (
        <SuperCentered >
          <CircularProgress />
        </SuperCentered>
      );
    }
    else {
      // button
      return (
        <Button
          label={"Remove"}
          onClick={() => {
            let newRemovingArray = [...removingArray];
            newRemovingArray.push(r.id);
            setRemovingArray([...newRemovingArray]);
            onRemove(r, onRemoveCallback);
          }}
        />
      );
    }
  }

  const renderTableRows = () => {
    if (!list) {
      return null;
    }
    if (list.length === 0) {
      return (
        <DataTableRow>
          <DataTableCell />
          <DataTableCell>
            <div className={style.tableName}>
              No resources are assigned to this channel.
            </div>
          </DataTableCell>
          <DataTableCell />
          <DataTableCell />
        </DataTableRow>
      );
    }

    const rows = list.map((r) => {
      return (
        <DataTableRow key={r.id}>
          <DataTableCell>
            <img
              src={r.image ? r.image : "https://via.placeholder.com/40x40.jpg"}
              className={style.image}
              alt={r.name}
            />
          </DataTableCell>
          <DataTableCell>
            <div className={style.tableName}>{r.name}</div>
          </DataTableCell>
          <DataTableCell>{r.type}</DataTableCell>
          <DataTableCell>
            {renderRemoveButton(r)}
          </DataTableCell>
        </DataTableRow>
      );
    });
    return rows;
  };

  const renderTable = () => {
    return (
      <DataTable className={style.tableContainer} stickyRows={1}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>{renderTableHeaders()}</DataTableRow>
          </DataTableHead>
          <DataTableBody>{renderTableRows()}</DataTableBody>
        </DataTableContent>
      </DataTable>
    );
  };

  const renderContent = () => {
    return (
      <div className={style.container}>
        <SectionHeadlineRow
          className={style.sectionHeadline}
          title="Channel resources"
          tag="h2"
          typographyStyle="subtitle1"
        />
        <div className={style.tableWrapper}>{renderTable()}</div>
      </div>
    );
  };

  return renderContent();
};
