import {
  CHANNELS_FETCH_INSERT,
  CHANNELS_FETCH_ALL,
  CHANNEL_FETCH,
  CHANNEL_FETCH_UPDATE,
  CHANNELS_FETCH_LTI_CHANNELS,
  CHANNEL_FETCH_MEMBERS,
  CHANNEL_DELETE_MEMBER,
  CHANNEL_DELETE,
  CHANNELS_SUBMIT_JOIN_CODE,
  CHANNELS_UPDATE,
  CHANNELS_ADD_CHANNELS,
  CHANNELS_JOIN,
  CHANNELS_LEAVE,
  CHANNELS_EDIT_JOIN_CODE,
  CHANNELS_RESET,
  UPDATE_CHANNEL_MEMBERS_ROLE,
  CHANNEL_FETCH_OWNER,
  CHANNEL_SET_FETCHED_OWNER,
  CHANNEL_FETCH_CONTENT,
  CHANNEL_SET_CONTENT,
  CHANNEL_ADD_COLLECTION,
  CHANNEL_REMOVE_BUNDLE,
  CHANNEL_ADD_BUNDLE,
  CHANNEL_REMOVE_COLLECTION,
  CHANNEL_SET_ADD_CONTENT_COLLECTION,
  CHANNEL_SET_REMOVE_CONTENT_COLLECTION,
  CHANNEL_SET_ADD_CONTENT_BUNDLE,
  CHANNEL_SET_REMOVE_CONTENT_BUNDLE,
  CHANNEL_REQUEST_MORE_USERS,
  CHANNEL_SET_TOTAL_USERS,
  CHANNEL_INVITE_USERS,
  CHANNEL_ADD_LINKED_CHANNEL_SUBSCRIPTION,
  CHANNEL_GET_LINKED_CHANNELS,
  CHANNEL_SET_LINKED_CHANNELS,
  CHANNEL_DELETE_LINKED_CHANNEL_SUBSCRIPTION,
  CHANNEL_REMOVE_CHANNEL_SUBSCRIPTION_FROM_STORE,
  CHANNEL_EDIT_LINKED_CHANNEL_SUBSCRIPTION,
  CHANNEL_SET_CHANNEL_APP,
  CHANNEL_REMOVE_CHANNEL_APP
} from "./ChannelTypes";

const fetchAddChannel = (data, callback) => {
  return {
    type: CHANNELS_FETCH_INSERT,
    payload: {
      ...data,
    },
    callback,
  };
};

const fetchAllChannels = (offset, limit, callback) => {
  if (!offset && offset !== 0) {
    throw new Error("offset cannot be null or empty");
  }

  if (!limit && limit !== 0) {
    throw new Error("limit cannot be null or empty");
  }

  return {
    type: CHANNELS_FETCH_ALL,
    payload: {
      offset,
      limit,
    },
    callback,
  };
};

const fetchAChannel = (channelId, callback) => {
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }

  return {
    type: CHANNEL_FETCH,
    payload: {
      channelId,
    },
    callback,
  };
};

const fetchUpdateChannel = (data, callback) => {
  return {
    type: CHANNEL_FETCH_UPDATE,
    payload: {
      ...data,
    },
    callback,
  };
};

const fetchLtiChannels = (consumerKey, callback) => {
  return {
    type: CHANNELS_FETCH_LTI_CHANNELS,
    payload: {
      consumerKey,
    },
    callback,
  };
};

const fetchMembers = (channelId, callback) => {
  return {
    type: CHANNEL_FETCH_MEMBERS,
    payload: {
      channelId,
    },
    callback,
  };
};

const deleteMember = (channelId, userId, callback) => {
  return {
    type: CHANNEL_DELETE_MEMBER,
    payload: {
      channelId,
      userId,
    },
    callback,
  };
};

const deleteChannel = (channelId, callback) => {
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }

  return {
    type: CHANNEL_DELETE,
    payload: {
      channelId,
    },
    callback,
  };
};

const submitJoinCode = (code, callback) => {
  if (!code) {
    throw new Error("code cannot be null or empty");
  }

  return {
    type: CHANNELS_SUBMIT_JOIN_CODE,
    payload: {
      code,
    },
    callback,
  };
};

const updateChannel = (
  channelId,
  name,
  image_url,
  banner_url,
  access_pattern,
  embed_permission,
  join_code,
  join_code_status,
  view_collections,
  user_limit,
  total_users
) => {
  if (!channelId) {
    throw new Error("channelId cannot be null or empty");
  }

  return {
    type: CHANNELS_UPDATE,
    payload: {
      channelId,
      name,
      image_url,
      banner_url,
      access_pattern,
      embed_permission,
      join_code,
      join_code_status,
      view_collections,
      user_limit,
      total_users
    },
  };
};

const addChannels = (channels) => {
  return {
    type: CHANNELS_ADD_CHANNELS,
    payload: {
      channels,
    },
  };
};

const joinChannel = (channelId, callback) => {
  return {
    type: CHANNELS_JOIN,
    payload: {
      channelId,
    },
    callback,
  };
};

const leaveChannel = (channelId, callback) => {
  return {
    type: CHANNELS_LEAVE,
    payload: {
      channelId,
    },
    callback,
  };
};

const editJoinCode = (
  channelId,
  status = "enabled",
  reset = false,
  callback
) => {
  return {
    type: CHANNELS_EDIT_JOIN_CODE,
    payload: {
      channelId,
      status,
      reset,
    },
    callback,
  };
};

const flush = () => ({
  type: CHANNELS_RESET,
});

export const updateChannelMembersRole = (channelId, role, user, callback) => {
  return {
    type: UPDATE_CHANNEL_MEMBERS_ROLE,
    payload: {
      channelId,
      role,
      user,
    },
    callback,
  };
};

const fetchChannelOwner = (channelId, callback) => {
  return {
    type: CHANNEL_FETCH_OWNER,
    payload: {
      channelId,
    },
    callback,
  };
};

export const setFetchedOwner = (channelId, owner, callback) => {
  return {
    type: CHANNEL_SET_FETCHED_OWNER,
    payload: {
      channelId,
      owner,
    },
    callback,
  };
};

const fetchChannelContent = (channelId, callback) => {
  return {
    type: CHANNEL_FETCH_CONTENT,
    payload: {
      channelId,
    },
    callback,
  };
};

export const setContent = (channelId, collections, bundles, apps) => {
  return {
    type: CHANNEL_SET_CONTENT,
    payload: {
      channelId,
      collections,
      bundles,
      apps
    },
  };
};

export const addCollectionToChannel = (channelId, collectionId, callback) => {
  return {
    type: CHANNEL_ADD_COLLECTION,
    payload: {
      channelId,
      collectionId,
    },
    callback,
  };
};

export const removeCollectionFromChannel = (channelId, collectionId, callback) => {
  return {
    type: CHANNEL_REMOVE_COLLECTION,
    payload: {
      channelId,
      collectionId,
    },
    callback,
  };
};

export const addBundleToChannel = (channelId, bundleId, callback) => {
  return {
    type: CHANNEL_ADD_BUNDLE,
    payload: {
      channelId,
      bundleId,
    },
    callback,
  };
};

export const removeBundleFromChannel = (channelId, bundleId, callback) => {
  return {
    type: CHANNEL_REMOVE_BUNDLE,
    payload: {
      channelId,
      bundleId,
    },
    callback,
  };
};

export const setAddCollectionToChannel = (channelId, collection, callback) => {
  return {
    type: CHANNEL_SET_ADD_CONTENT_COLLECTION,
    payload: {
      channelId,
      collection,
    },
    callback,
  };
};

export const setRemoveCollectionFromChannel = (channelId, collectionId, callback) => {
  return {
    type: CHANNEL_SET_REMOVE_CONTENT_COLLECTION,
    payload: {
      channelId,
      collectionId,
    },
    callback,
  };
};

export const setAddBundleToChannel = (channelId, bundle, callback) => {
  return {
    type: CHANNEL_SET_ADD_CONTENT_BUNDLE,
    payload: {
      channelId,
      bundle,
    },
    callback,
  };
};

export const setRemoveBundleFromChannel = (channelId, bundleId, callback) => {
  return {
    type: CHANNEL_SET_REMOVE_CONTENT_BUNDLE,
    payload: {
      channelId,
      bundleId,
    },
    callback,
  };
};

export const requestMoreUsers = (channelId, user_limit, callback) => {
  return {
    type: CHANNEL_REQUEST_MORE_USERS,
    payload: {
      channelId,
      user_limit,
    },
    callback,
  };
};

export const setTotalUsers = (channelId, addOrSubtract, callback) => {

  return {
    type: CHANNEL_SET_TOTAL_USERS,
    payload: {
      channelId,
      addOrSubtract,
    },
    callback,
  };
};

export const inviteUserToChannel = (channelId, name, email, role, userId, isResending, callback) => {

  return {
    type: CHANNEL_INVITE_USERS,
    payload: {
      channelId, name, email, role, userId, isResending
    },
    callback,
  };
};

export const addLinkedChannelSubscription = (
  channelId,
  subscriptionId,
  canAuthor,
  canEdit,
  expirationDate,
  callback) => {

  return {
    type: CHANNEL_ADD_LINKED_CHANNEL_SUBSCRIPTION,
    payload: {
      channelId,
      subscriptionId,
      canAuthor,
      canEdit,
      expirationDate
    },
    callback
  }
}

export const editLinkedChannelSubscription = (
  channelId,
  subscriptionId,
  canAuthor,
  canEdit,
  expirationDate,
  callback) => {
  return {
    type: CHANNEL_EDIT_LINKED_CHANNEL_SUBSCRIPTION,
    payload: {
      channelId,
      subscriptionId,
      canAuthor,
      canEdit,
      expirationDate
    },
    callback
  }
}

export const getLinkedChannels = (channelId, callback) => {
  return {
    type: CHANNEL_GET_LINKED_CHANNELS,
    payload: {
      channelId
    },
    callback
  }
}

export const setLinkedChannels = (channelId, data, callback) => {
  return {
    type: CHANNEL_SET_LINKED_CHANNELS,
    payload: {
      channelId,
      data
    },
    callback
  }
}

export const removeChannelSubscription_fromStore = (channelId, subscriptionId) => {
  return {
    type: CHANNEL_REMOVE_CHANNEL_SUBSCRIPTION_FROM_STORE,
    payload: {
      channelId,
      subscriptionId
    }
  }
}

export const deleteLinkedChannelSubscription = (channelId, subscriptionId, callback) => {
  return {
    type: CHANNEL_DELETE_LINKED_CHANNEL_SUBSCRIPTION,
    payload: {
      channelId,
      subscriptionId
    },
    callback
  }
}

export function setChannelAppInState(channelId, app) {
  return {
    type: CHANNEL_SET_CHANNEL_APP,
    payload: {
      channelId,
      app
    }
  }
}

export function removeChannelAppFromState(channelId, appId) {
  return {
    type: CHANNEL_REMOVE_CHANNEL_APP,
    payload: {
      channelId,
      appId
    }
  }
}

export default {
  fetchAddChannel,
  fetchAllChannels,
  fetchAChannel,
  flush,
  fetchUpdateChannel,
  fetchLtiChannels,
  fetchMembers,
  deleteMember,
  deleteChannel,
  updateChannel,
  submitJoinCode,
  joinChannel,
  leaveChannel,
  addChannels,
  editJoinCode,
  updateChannelMembersRole,
  fetchChannelOwner,
  setFetchedOwner,
  fetchChannelContent,
  setContent,
  addCollectionToChannel,
  removeCollectionFromChannel,
  addBundleToChannel,
  removeBundleFromChannel,
  setAddCollectionToChannel,
  setRemoveCollectionFromChannel,
  setAddBundleToChannel,
  setRemoveBundleFromChannel,
  requestMoreUsers,
  setTotalUsers,
  inviteUserToChannel,
  getLinkedChannels,
  setLinkedChannels,
  deleteLinkedChannelSubscription,
  removeChannelSubscription_fromStore,
  editLinkedChannelSubscription,
  setChannelAppInState,
  removeChannelAppFromState
};
