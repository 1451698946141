import * as TYPES from "./CollectionTypes";

const fetchCollectionBundle = (bundleId, offset, limit, callback) => {
  if (!bundleId) {
    throw new Error("bundleId cannot be null or empty");
  }

  if (!offset && offset !== 0) {
    throw new Error("offset cannot be null or empty");
  }

  if (!limit && limit !== 0) {
    throw new Error("limit cannot be null or empty");
  }

  return {
    type: TYPES.COLLECTION_BUNDLE_FETCH,
    payload: {
      bundleId: bundleId,
      offset,
      limit,
    },
    callback,
  };
};

const setCollections = (collections) => {
  if (!collections) {
    throw new Error("bundles cannot be null or empty");
  }

  return {
    type: TYPES.COLLECTIONS_SET_COLLECTIONS,
    payload: {
      collections,
    },
  };
};

const setBundles = (bundles) => {
  return {
    type: TYPES.COLLECTIONS_SET_BUNDLES,
    payload: {
      bundles,
    },
  };
};

const setCollectionsRetrieved = (
  total,
  offset,
  allHaveBeenRetrieved,
  callback
) => {
  if (!total && total !== 0) {
    throw new Error("total cannot be null or empty");
  }

  if (!offset && offset !== 0) {
    throw new Error("offset cannot be null or empty");
  }

  if (allHaveBeenRetrieved === null || allHaveBeenRetrieved === undefined) {
    throw new Error("allHaveBeenRetrieved cannot be null or undefined");
  }

  return {
    type: TYPES.COLLECTIONS_SET_RETRIEVED,
    payload: {
      total,
      offset,
      allHaveBeenRetrieved,
    },
    callback,
  };
};

const getCollection = (collectionId, callback) => {
  return {
    type: TYPES.COLLECTION_GET_COLLECTION,
    payload: {
      collectionId
    },
    callback
  }
}

const setBundle = (bundle, callback) => {
  return {
    type: TYPES.COLLECTION_SET_BUNDLE,
    payload: {
      bundle
    },
    callback
  }
}

const getBundle = (bundleId, callback) => {  
  return {
    type: TYPES.COLLECTION_GET_BUNDLE,
    payload: {
      bundleId
    },
    callback
  }
}

const setCollection = (collection, callback) => {
  return {
    type: TYPES.COLLECTION_SET_COLLECTION,
    payload: {
      collection
    },
    callback
  }
}

const getAllCollections = (callback) => {
  return {
    type: TYPES.COLLECTIONS_GET_ALL_COLLECTIONS,
    callback
  }
}

const getAllBundles = (callback) => {
  return {
    type: TYPES.COLLECTIONS_GET_ALL_BUNDLES,
    callback
  }
}

export default {
  setCollections,
  setCollectionsRetrieved,
  fetchCollectionBundle,
  setBundles,
  getCollection,
  setCollection,
  getBundle,
  setBundle,
  getAllCollections,
  getAllBundles
};
