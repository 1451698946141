import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const NavigatePrevious = ({
  fill = colors.gray.dark,
  width = '24px',
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...iconProps}>
    <title>ic_keyboard_arrow_left_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
    </g>
  </svg>
);

NavigatePrevious.propTypes = Props;
export default NavigatePrevious;
