import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

/**
 * Returns the access pattern for a given channel by ID. If the channel does not exist or the
 * access_pattern has not been retireved, it will call out and get it. During this time the returned value
 * will be null
 * @param {string} channelId
 * 
 * @return {null | string} Something cool
 */
export function useChannelAccessPattern(channelId) {
  //States
  const [access_pattern, setAccessPattern] = useState(null);
  const [isFetching, setIsFetching] = useState(null);
  const [error, setError] = useState(null);

  //Hooks
  const channelList = useSelector((state) => state.Channels.list);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFetching && !error) {
      if (
        !channelList.hasOwnProperty(channelId) ||
        !channelList[channelId].hasOwnProperty("access_pattern") ||
        !channelList[channelId].access_pattern
      ) {
        setIsFetching(true);
        dispatch(
          Actions.Channels.fetchAChannel(channelId, (resp) => {
            if (!resp.success) {
              setError(resp.msg);
            }
            setIsFetching(false);
          })
        );
      } else {
        setAccessPattern(channelList[channelId].access_pattern);
      }
    }
  }, [channelId, dispatch, channelList, access_pattern, error, isFetching]);

  return {access_pattern, error};
}
