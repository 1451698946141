
export const setTemporal = (name, value) => ({
  type: 'SET_TEMPORAL_STATE',
  name,
  value,
});

export default {
  setTemporal,
};
