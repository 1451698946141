import React from "react";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useChannel } from "../../hooks/Channel/useChannel";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { snackbarQueue } from "../Snackbar/snackbarQueue";
import { dialogQueue } from "../Dialog/dialogQueue";
import { declineChannelInvite, removePendingChannel, confirmChannelInvite } from "../../Redux/Users/UserActions";
import PageBanner from "../PageWrapper/PageBanner";


const PendingChannelBanner = (props) => {
  const { channelId, history } = props;
  const [forceHide, setForceHide] = useState(false);
  const { role, isPending } = useChannelRole(channelId);
  const { channel } = useChannel(channelId);

  const dispatch = useDispatch();

  function handleAcceptInvite() {
    setForceHide(true);

    dispatch(
      confirmChannelInvite(channelId, (resp) => {
        if (resp.success) {
          snackbarQueue.notify({
            title: <b>Success!</b>,
            body: role === "owner" ? `You are now the owner of the channel ${channel.name}.` : `You have joined the channel ${channel.name}.`,
          });
        } else {
          snackbarQueue.notify({
            title: <b>Uh oh!</b>,
            body: "Unable to leave channel",
          });
        }
      })
    );
  }

  function handleDeclineInvite() {
    dialogQueue
      .confirm({
        title: "Decline Invite?",
        body: "Are you sure you want to decline your invite to this channel?",
        acceptLabel: "Yes, Decline",
      })
      .then((resp) => {
        if (resp === true) {
          setForceHide(true);
          dispatch(removePendingChannel(channelId));
          history.push("/dashboard");
          dispatch(
            declineChannelInvite(channelId, role, (resp) => {
              if (resp.success) {
                snackbarQueue.notify({
                  title: <b>Success!</b>,
                  body: role === "owner" ? `You have rejected ownership of channel ${channel.name}` : `You have declined joining the channel ${channel.name}`,
                });
                
              } else {
                snackbarQueue.notify({
                  title: <b>Uh oh!</b>,
                  body: "Unable to decline channel invite",
                });
              }
            })
          );
        }
      });
  }

  if (role && isPending && !forceHide) {
    return (
      <PageBanner
        message="You have a pending invite to join this channel."
        // message="Tree line text string example with two actions. One to two lines is preferable. Three lines should be considered the maximum string length on desktop in order to keep messages short and actionable."
        actionPrimary={{
          label: "Accept",
          onClick: handleAcceptInvite,
        }}
        actionDismissive={{
          label: "Decline",
          onClick: handleDeclineInvite,
        }}
        icon="mail"
        open={true}
      />
    );
  } else {
    return <></>;
  }
};

export default PendingChannelBanner;
