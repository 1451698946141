import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import "@rmwc/icon-button/styles";
import "@rmwc/select/styles";

import { CircularProgress } from "@rmwc/circular-progress";

const DeleteSubscriptionDialog = ({
  isAlertOpen,
  isPromptOpen,
  deleteSubscription,
  closeDeletePrompt
}) => {

  const renderAlert = () => {
    return (
      <Dialog
        open={isAlertOpen}
        preventOutsideDismiss
      >
        <DialogTitle>
          Deleteing subscription
        </DialogTitle>
        <DialogContent><CircularProgress /> processing...</DialogContent>
      </Dialog>
    );
  };

  const renderRedirectDialog = () => {

    return (
      <Dialog
        open={isPromptOpen}
        preventOutsideDismiss
      >
        <DialogTitle>
            Delete Subscription
        </DialogTitle>
        <DialogContent>Are you sure you want to permantly delete this subscription? <br/> This action cannot be undone.</DialogContent>
        <DialogActions>
          <DialogButton action="close" onClick={()=> closeDeletePrompt()}>Cancel</DialogButton>
          <DialogButton onClick={()=>deleteSubscription()} isDefaultAction>
            DELETE
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {renderAlert()}
      {renderRedirectDialog()}
    </>
  );
};

export default DeleteSubscriptionDialog
