import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export function useChannelMembers(channelId) {
  //Hooks   
  const dispatch = useDispatch();
  let channel = useSelector((state) => state.Channels.list[channelId])

  //States
  const [members, setMembers] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [StoredChannelId, setStoredChannelId] = useState(null);

  useEffect(() => {
    //Have we changed channels and the new channel has no members
    if(StoredChannelId !== channelId && channel && !channel.members) {
      setFetching(false)
      setError(null)
    }

    if (!fetching && !error) {
      setStoredChannelId(channelId)
      setFetching(true);
      dispatch(
        Actions.Channels.fetchMembers(channelId, (resp) => {
          if (!resp.success) {
            setError(resp.msg);
          }
          setFetching(false);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  let totaltNumberOfUsers = channel?.members?.length 
  useEffect(()=> {

    if(channel && channel.members) {
      setMembers(channel.members)
    }
  }, [channel, fetching, totaltNumberOfUsers])

  return [members, fetching, error];
} 