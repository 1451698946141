import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import store from "../../../store";
import { useSelector } from "react-redux";

import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import Actions from "../../../Redux/actions";
import { SectionHeadlineRow } from "../../Layout/Headings";
import { InputErrorMessage } from "../../Auth/AuthInputFields";
import imagePlaceholder from "../../../assets/images/placeholder-image_16x9_Generic_400x255.svg";
import { ImageToBlob } from "../../../utils/helpers";
import { UpdateUserMeta } from "../../../utils/AmplifyAuthController";
import { snackbarQueue } from "../../Snackbar/snackbarQueue";

export const ProfileAvatar = ({ name, image, editingProhibited }) => {
  const userData = useSelector((state) => state.User);
  const sectionTitle = "Profile image";
  const [editingImage, setEditingImage] = useState(false);
  const [newImage, setNewImage] = useState(undefined);
  const [newImagePreview, setNewImagePreview] = useState(null);
  const [processing, setProcessesing] = useState(false);
  const { register, handleSubmit, reset, watch, errors, formState } = useForm({
    mode: "onChange",
  });
  const { isValid: formIsValid } = formState;
  const imageInputRef = useRef();
  const watchImage = watch("image");

  useEffect(() => {
    if (newImage) {
      ImageToBlob(newImage[0])
        .then((resp) => {
          setNewImagePreview(resp);
        })
        .catch((err) => {
          console.error(
            "[ProfileAvatar] onNewImageChange] ImageToBlob failed! ",
            err
          );
        });
    }
  }, [newImage]);

  const uploadFile = (file, folder, callback) => {
    if (file) {
      store.dispatch(Actions.Universal.upload({ file, folder }, callback));
    } else {
      callback("");
    }
  };

  const deleteFile = (filename, callback) =>
    store.dispatch(Actions.Universal.deleteFile(filename, callback));

  const onSubmit = async (data) => {
    const imageToSubmit = data.image[0];

    setProcessesing(true);

    uploadFile(imageToSubmit, "Thumbnails", (newFile) => {
      if (newFile && userData.avatar) {
        //delete old file
        deleteFile(userData.avatar);
      }

      UpdateUserMeta(null, null, newFile)
        .then(() => {
          setProcessesing(false);
          resetForm();
          snackbarQueue.notify({
            title: <b>Success!</b>,
            body: "You profile image has been updated",
          });
        })
        .catch((e) => {
          setProcessesing(false);
          resetForm();
          snackbarQueue.notify({
            title: <b>Uh Oh!</b>,
            body:
              "Something went wrong updating your profile image. Please try again later.",
          });
        });
    });
  };

  const resetForm = () => {
    reset({ image: null }); // resets input using react-hook-form
    setNewImage(null); // resets our other image variables
    setNewImagePreview(""); // resets our other image variables
  };

  const renderImageInput = () => {
    const inputName = "image";
    const inputType = "file";
    const inputTypeAccept = "image/png, image/jpeg";
    const validationErrMsg = {
      fileSize: `Image file size must be less than 2MB.`,
    };

    return (
      <div>
        <TextField
          type={inputType}
          accept={inputTypeAccept}
          name={inputName}
          inputRef={(e) => {
            register(e, {
              validate: {
                maxFileSize: (value) =>
                  validateImageInputFileSize(value) ||
                  validationErrMsg.fileSize,
              },
            });
            imageInputRef.current = e;
          }}
          invalid={errors[inputName]}
          helpText={{
            // persistent: true,
            validationMsg: true,
            children: (
              <InputErrorMessage errors={errors} inputName={inputName} />
            ),
          }}
        />
      </div>
    );
  };

  /**
   * Function to validate if the selected file is under the maximum file size limit. If no file is found, then the validation will pass. Image is not a required field.
   * @param {FileList} value The value returned by the input.
   */
  const validateImageInputFileSize = (value) => {
    const maxImageSizeBytes = 2100000; // 2 MB = 2,097,152 Bytes (in binary): rounded up

    if (value.length === 1 && value[0]) {
      const imageSizeBytes = value[0].size;

      // If the file size is larger than 2MB, validation should fail.
      // Note: Error message is defined in the input's register function
      if (imageSizeBytes > maxImageSizeBytes) {
        return false; // Validation failed
      } else {
        setNewImage(value);
        return true; // Validation passed
      }
    } else {
      return true; // No file passes validation
    }
  };

  const isSaveDisabled = () => {
    if (newImagePreview) {
      if (formIsValid) {
        return false;
      } else return true;
    } else return true;
  };
  const isResetDisabled = () => {
    if (processing) {
      return true;
    } else if (newImagePreview || watchImage) {
      return false;
    } else return true;
  };

  const renderEdit = () => {
    if (editingImage) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>{renderImageInput()}</div>
          <Button
            type="button"
            label="Reset"
            onClick={resetForm}
            disabled={isResetDisabled()}
          />
          {processing ? (
            <Button raised type="button" label="Saving..." disabled />
          ) : (
            <Button
              raised
              type="submit"
              label="Save"
              disabled={isSaveDisabled()}
            />
          )}
        </form>
      );
    } else {
      return (
        <div>
          <Button
            outlined
            label="Edit Image"
            onClick={() => setEditingImage(true)}
            disabled={editingProhibited}
          />
        </div>
      );
    }
  };

  return (
    <div style={{ marginBottom: "24px" }}>
      <SectionHeadlineRow
        title={sectionTitle}
        tag="h2"
        typographyStyle="subtitle1"
        typographyTheme="textSecondaryOnLight"
      />
      <div style={{ display: "flex", flexFlow: "column nowrap" }}>
        <div style={{ marginBottom: "8px" }}>
          <Avatar
            square
            name={name}
            src={
              newImagePreview
                ? newImagePreview
                : image
                ? image
                : imagePlaceholder
            }
            size="xlarge"
            style={{ fontSize: "8rem" }}
          />
        </div>
        {renderEdit()}
      </div>

      <div></div>
    </div>
  );
};

ProfileAvatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
};
