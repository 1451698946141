import React from "react";
import PropTypes from "prop-types";

import { Dialog, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { EditActivityMetadataForm } from "./EditActivityMetadataForm";
import { activityMetadataProps } from "./activityMetadataProps";

export const EditActivityMetadataDialog = ({
  open,
  onClose,
  processing,
  setProcessing,
  renderToPortal,
  activityMeta,
}) => {
  let formDefaultValues;
  if (activityMeta) {
    formDefaultValues = {
      title: activityMeta.title,
      description: activityMeta.description,
      image: null,
    };
  }

  return (
    <Dialog
      open={open}
      onClose={(evt) => onClose(evt)}
      renderToPortal={renderToPortal}
      preventOutsideDismiss={processing}
    >
      <DialogTitle>Edit activity details</DialogTitle>
      <EditActivityMetadataForm
        processing={processing}
        setProcessing={setProcessing}
        activityMeta={activityMeta}
        formDefaultValues={formDefaultValues}
        onClose={onClose}
        open={open}
      />
    </Dialog>
  );
};

EditActivityMetadataDialog.defaultProps = {
  renderToPortal: true,
  preventOutsideDismiss: false,
};
EditActivityMetadataDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  renderToPortal: PropTypes.bool,
  preventOutsideDismiss: PropTypes.bool,
  activityMeta: activityMetadataProps,
};
