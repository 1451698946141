import React, { useEffect } from "react";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useState } from "react";
import { CircularProgress } from "@rmwc/circular-progress";
import { Button } from "@rmwc/button";
import { useChannelAccessPattern } from "../../hooks/Channel/useChannelAccessPattern";
import { useDispatch } from "react-redux";
import { snackbarQueue } from "../Snackbar/snackbarQueue";
import { dialogQueue } from "../Dialog/dialogQueue";
import Actions from "../../Redux/actions";
import { useChannel } from "../../hooks/Channel/useChannel";

const ChannelJoinLeaveButton = ({ channelId, className }) => {
  const { role, isPending } = useChannelRole(channelId);
  const { access_pattern, error } = useChannelAccessPattern(channelId);
  const dispatch = useDispatch();
  const { channel } = useChannel(channelId);

  /**
   * Modes
   * hidden: Don't show the button.
   * join: Join this channel. This chanel must be public but the user does not have a role
   * leave: Leave this channel. The user has a role in this channel
   */
  const [mode, setMode] = useState("hidden"); //Can be either join, leave, accept, decline, or hidden
  const [submitting, setSubmitting] = useState(false);

  function handleClickLeave() {
    dialogQueue
      .confirm({
        title: "Leave Channel?",
        body: "Are you sure you want to leave this Channel",
        acceptLabel: "Yes, leave",
      })
      .then((resp) => {
        if (resp === true) {
          setSubmitting(true);
          dispatch(
            Actions.Channels.leaveChannel(channelId, (resp) => {
              setSubmitting(false);
              if (resp.success) {
                snackbarQueue.notify({
                  title: <b>Success!</b>,
                  body: `You have left the channel ${channel.name}`,
                });
              } else {
                snackbarQueue.notify({
                  title: <b>Uh oh!</b>,
                  body: "Unable to leave channel",
                });
              }
            })
          );
        }
      });
  }

  function handleClickJoin() {
    setSubmitting(true);
    dispatch(
      Actions.Channels.joinChannel(channelId, (resp) => {
        setSubmitting(false);
        if (resp.success) {
          snackbarQueue.notify({
            title: <b>Success!</b>,
            body: role === "owner" ? `You are now the owner of the channel ${channel.name}.` : `You have joined the channel ${channel.name}.`,
          });
        } else {
          snackbarQueue.notify({
            title: <b>Uh oh!</b>,
            body: "Unable to join channel",
          });
        }
      })
    );
  }

  useEffect(() => {
    let desiredMode = "hidden";

    if (role === "none" && access_pattern === "public") {
      desiredMode = "join";
    } else if (role === "admin" || role === "author" || role === "member") {
      desiredMode = "leave";
    }

    //We use a different component for pending channels
    if (isPending) {
      desiredMode = "hidden";
    }

    if (error) {
      desiredMode = "hidden";
    }

    if (desiredMode !== mode) {
      setMode(desiredMode);
    }
  }, [role, mode, access_pattern, isPending, error]);

  if (submitting) {
    return <CircularProgress />;
  }

  if (mode === "join") {
    return (
      <Button className={className} onClick={handleClickJoin}>
        Join
      </Button>
    );
  } else if (mode === "leave") {
    return (
      <Button className={className} onClick={handleClickLeave}>
        Leave
      </Button>
    );
  } else {
    return <></>;
  }
};

export default ChannelJoinLeaveButton;
