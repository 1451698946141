import PropTypes from "prop-types";
import React from "react";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

// import style from "./FormInputs.module.scss";

import { InputErrorMessage } from "../Auth/AuthInputFields";

export const EmailAddress = ({ inputRef, register, errors, disabled }) => {
  const inputLabel = "Email";
  const inputName = "email";
  const inputType = "email";
  const inputMaxLength = 255;
  // eslint-disable-next-line no-useless-escape
  const inputPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validationErrMsg = {
    required: "An email address is required.",
    maxLength: "The email address must be 254 characters or less.",
    pattern: "Email requires a valid email address.",
  };

  return (
    <TextField
      // general style props
      outlined
      // className={style.inputFullWidth}
      // value, configuration, validation props
      required
      disabled={disabled}
      type={inputType}
      label={inputLabel}
      name={inputName}
      inputRef={(e) => {
        register(e, {
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern,
          },
        });
        if (inputRef) {
          inputRef.current = e;
        }
      }}
      invalid={errors[inputName]}
      // onChange={() => triggerValidation(inputName)}
      // disabled={step === 2}
      helpText={{
        validationMsg: true,
        persistent: false,
        children: <InputErrorMessage errors={errors} inputName={inputName} />,
      }}
    />
  );
};
EmailAddress.defaultProps = {
  disabled: false,
}
EmailAddress.propTypes = {
  disabled: PropTypes.any,
  errors: PropTypes.any,
  inputRef: PropTypes.shape({
    current: PropTypes.any
  }),
  register: PropTypes.func
}
