import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import style from "./AdminChannelResourcesEdit.module.scss";
import Actions from "../../Redux/actions";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { AvailableResources } from "../../components/Admin/AvailableResources/AvailableResources";
import { ChannelResources } from "../../components/Admin/ChannelResources/ChannelResources";
import { ContentContainer } from "../../components/Layout/Layout";
import { SectionHeadlineRow } from "../../components/Layout/Headings";
import { useChannel } from "../../hooks/Channel/useChannel";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";
import PageError from "../../components/Error/PageError";
import useAllAvailableResources from "../../hooks/useAllAvailableResources";
import useChannelContent from "../../hooks/Channel/useChannelContent";

const AdminChannelResourcesEdit = () => {

  /// Hooks
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const { allResources, waitingForContent, error: resources_error } = useAllAvailableResources(channelId)
  const { myOrganizedResources_obj, myOrganizedResources, isFetchingChanContent, channelContentError} = useChannelContent(channelId);
  const { channel, error: getChannelError } = useChannel(channelId);

  const [error, setError] = useState(null);
  const [resourceAvailable, setResourceAvailable] = useState([]);
  const [numOfAssignedResources, setNumOfAssignedResources] = useState(0);

  useEffect(() => {
    if(!waitingForContent && resourceAvailable.length !== allResources.length) {

      let _allResources = allResources.map(resource => {
        if(myOrganizedResources_obj[resource.id]) {
          resource.assigned = true
        } else {
          resource.assigned = false
        }
        return resource
      })
      setResourceAvailable(_allResources)
      setNumOfAssignedResources(myOrganizedResources.length)
    }

  }, [allResources, waitingForContent, resourceAvailable, myOrganizedResources_obj, myOrganizedResources]);

  useEffect(() => {
    if(myOrganizedResources.length !== numOfAssignedResources) {
      setResourceAvailable([])
    }

  }, [myOrganizedResources, numOfAssignedResources]);  

  //Monitor for errors
  useEffect(() => {

    if(error) {
      return
    }

    if (resources_error) {
      console.error(resources_error)
      setError(`${resources_error}`);
    }

    if (getChannelError) {
      console.error(getChannelError)
      setError(`${getChannelError}`);
    }

    if (channelContentError) {
      console.error(channelContentError)
      setError(`${channelContentError}`);
    }

  }, [ resources_error, getChannelError, error, channelContentError ]);

  /// Constants & other
  const pageTitle = "VLP Administration";

  /// Event handler functinos
  const assignResource = (resource, callback) => {
    if (resource.type === "bundle") {
      dispatch(
        Actions.Channels.addBundleToChannel(channelId, resource.id, (result) => {
          if (result.success) {

            snackbarQueue.notify({
              title: <b>Success!</b>,
              body: `${resource.name} bundle was added to the channel ${channel.name}`,
            });
          }
          else {
            snackbarQueue.notify({
              title: <b>Error</b>,
              body: `Encountered a problem trying to add this bundle to channel resources`,
            });
          }
          if (callback) {
            callback(resource.id); // this resource is done being assigned
          }
        }
        )
      );
    } else if (resource.type === "collection" || resource.type === "channel-collection") {
      dispatch(
        Actions.Channels.addCollectionToChannel(channelId, resource.id, (result) => {
          if (result.success) {
            snackbarQueue.notify({
              title: <b>Success!</b>,
              body: `${resource.name} collection was added to the channel ${channel.name}`,
            });
          }
          else {
            snackbarQueue.notify({
              title: <b>Error</b>,
              body: `Encountered a problem trying to add this collection to channel resources`,
            });
          }
          if (callback) {
            callback(resource.id); // this resource is done being assigned
          }
        }
        )
      );
    } else if (resource.type === "app") {
      dispatch(
        Actions.Apps.addAppToChannel(channelId, resource.key, (result) => {
          if (result.success) {
            snackbarQueue.notify({
              title: <b>Success!</b>,
              body: `${resource.name} app was added to the channel ${channel.name}`,
            });
          }
          else {
            snackbarQueue.notify({
              title: <b>Error</b>,
              body: `Encountered a problem trying to add this app to channel resources`,
            });
          }
          if (callback) {
            callback(resource.id); // this resource is done being assigned
          }
        }
        )
      );
    }
  };

  const removeResource = (resource, callback) => {
    if (resource.type === "bundle") {
      dispatch(
        Actions.Channels.removeBundleFromChannel(channelId, resource.id, (result) => {
          if (result.success) {
            snackbarQueue.notify({
              title: <b>Success!</b>,
              body: `${resource.name} bundle was removed from the channel ${channel.name}`,
            });
          }
          else {
            snackbarQueue.notify({
              title: <b>Error</b>,
              body: `Encountered a problem trying to remove this bundle from channel resources`,
            });
          }
          if (callback) {
            callback(resource.id); // this resource is done being removed
          }
        }
        )
      );
    } else if (resource.type === "channel-collection") {
      dispatch(
        Actions.Channels.removeCollectionFromChannel(channelId, resource.id, (result) => {
          if (result.success) {
            snackbarQueue.notify({
              title: <b>Success!</b>,
              body: `${resource.name} collection was removed from the channel ${channel.name}`,
            });
          }
          else {
            snackbarQueue.notify({
              title: <b>Error</b>,
              body: `Encountered a problem trying to remove this collection from channel resources`,
            });
          }
          if (callback) {
            callback(resource.id); // this resource is done being assigned
          }
        }
        )
      );
    } else if (resource.type === "channel-app") {
      dispatch(
        Actions.Apps.removeAppFromChannel(channelId, resource.key, (result) => {
          if (result.success) {
            snackbarQueue.notify({
              title: <b>Success!</b>,
              body: `${resource.name} app was removed from channel ${channel.name}`,
            });
          }
          else {
            snackbarQueue.notify({
              title: <b>Error</b>,
              body: `Encountered a problem trying to remove this app from this channel's resources`,
            });
          }
          if (callback) {
            callback(resource.id); // this resource is done being assigned
          }
        }
        )
      );
    }
  };

  // Render helper functions
  const renderContent = () => {

    if (error) {
      return <PageError error={true} errorMessage={error} />;
    } else if (waitingForContent || isFetchingChanContent) {
      return (<PageLoadingIndicator />);
    } else {
      return (
        <>
          <SectionHeadlineRow title="Edit channel resources" />
          <div className={style.contentContainer}>
            <AvailableResources
              availableResources={resourceAvailable}
              onAssign={assignResource}
            />
            <ChannelResources resources={myOrganizedResources} onRemove={removeResource}/>
          </div>
        </>
      );
    }
  };

  // Render page function
  const renderPage = () => {
    return <ContentContainer>{renderContent()}</ContentContainer>;
  };

  return (
    <PageWrapper
      protectedContent={true}
      fixedAppBar={false}
      appBarTitle={pageTitle}
    >
      {renderPage()}
    </PageWrapper>
  );
};

export default AdminChannelResourcesEdit;
