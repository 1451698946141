import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import { EmailAddress } from "../../FormInputs/EmailAddress";
import { FirstName } from "../../FormInputs/FirstName";
import { LastName } from "../../FormInputs/LastName";
import { UpdateUserMeta } from "../../../utils/AmplifyAuthController";
import { snackbarQueue } from "../../Snackbar/snackbarQueue";

export const BasicInformationForm = ({ defaultValues, editingProhibited }) => {
  const { register, handleSubmit, reset, watch, errors, formState } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  const { isValid: formIsValid } = formState;
  const watchEmail = watch("email");
  const watchFirstName = watch("firstName");
  const watchLastName = watch("lastName");
  const [isFormModified, setIsFormModified] = useState(false);

  // This effect checks for changes to the form data and updates the isFormModified state appropriately.
  useEffect(() => {
    if (
      watchEmail.trim() !== defaultValues.email ||
      watchFirstName.trim() !== defaultValues.firstName ||
      watchLastName.trim() !== defaultValues.lastName
    ) {
      setIsFormModified(true);
    } else {
      setIsFormModified(false);
    }
  }, [
    watchEmail,
    watchFirstName,
    watchLastName,
    defaultValues.email,
    defaultValues.firstName,
    defaultValues.lastName,
  ]);

  /// Event handers
  const onSubmit = (data, e) => {
    const changedAttributes = [];

    if (data.firstName.trim() !== defaultValues.firstName) {
      changedAttributes.given_name = data.firstName.trim();
    }
    if (data.lastName.trim() !== defaultValues.lastName) {
      changedAttributes.family_name = data.lastName.trim();
    }

    UpdateUserMeta(
      changedAttributes?.given_name,
      changedAttributes?.family_name
    )
      .then(() => {
        snackbarQueue.notify({
          title: <b>Success!</b>,
          body: "Your user profile has been updated",
        });
      })
      .catch((e) => {
        snackbarQueue.notify({
          title: <b>Uh Oh!</b>,
          body:
            "Something went wrong updating your user profile. Please try again later.",
        });
      });
  };

  const isSaveDisabled = isFormModified && formIsValid ? false : true;
  const isResetDisabled = isFormModified ? false : true;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexFlow: "column nowrap", maxWidth: "640px" }}
    >
      <EmailAddress register={register} errors={errors} disabled />
      <FirstName
        register={register}
        errors={errors}
        disabled={editingProhibited}
      />
      <LastName
        register={register}
        errors={errors}
        disabled={editingProhibited}
      />

      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-end",
        }}
      >
        <Button
          disabled={isResetDisabled}
          type="button"
          label="Reset"
          onClick={() => reset()}
          style={{ width: "120px", marginLeft: "24px" }}
        />
        <Button
          raised
          disabled={isSaveDisabled}
          type="submit"
          label="Save"
          style={{ width: "120px", marginLeft: "24px" }}
        />
      </div>
    </form>
  );
};

BasicInformationForm.propTypes = {
  defaultValues: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  editingProhibited: PropTypes.bool,
};
