import { useSelector } from "react-redux";

export const usePlayerState = () => {
  let device = {
    type: "",
    orientation: "",
    isTouchCapable: null,
    screenResolution: {},
    windowResolution: {},
    breakpoint: 0,
    pixelDensity: 0
  };

  device.type = useSelector(state => state.Player.deviceType);
  device.orientation = useSelector(state => state.Player.orientation);
  device.isTouchCapable = useSelector(state => state.Player.isTouchCapable);
  device.screenResolution = useSelector(state => state.Player.screenResolution);
  device.windowResolution = useSelector(state => state.Player.windowResolution);
  device.breakpoint = useSelector(state => state.Player.breakpoint);
  device.pixelDensity = useSelector(state => state.Player.pixelDensity);

  return device;
};
