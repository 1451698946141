import React from "react";

import { Dialog, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import EditChannelSubscriptionForm from "./EditChannelSubscriptionForm";

const EditChannelSubscriptionDialog = ({
  open,
  onClose,
  processing,
  setProcessing,
  renderToPortal,
  channelId,
  subscription,
}) => {
  let formDefaultValues;
  if (subscription) {
    formDefaultValues = {
      subscriptionChannelId: subscription?.id,
      authorResources: subscription.canAuthor,
      duplicateActivities: subscription.canEdit,
      expirationDate: subscription.expiration,
    };
  }

  return (
    <Dialog
      open={open}
      onClose={(evt) => onClose(evt)}
      renderToPortal={renderToPortal}
      preventOutsideDismiss={processing}
    >
      <DialogTitle>Edit subscription</DialogTitle>
      <EditChannelSubscriptionForm
        processing={processing}
        setProcessing={setProcessing}
        channelId={channelId}
        subscription={subscription}
        formDefaultValues={formDefaultValues}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default EditChannelSubscriptionDialog;
