import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from "aws-amplify";
import TagManager from 'react-gtm-module';

import App from './App';
import './scss/main.scss';
import "@rmwc/tooltip/styles"; // Temp workaround for RMWC tooltips Player 2 and VP2 Apps in the VLP

import Config from "./aws-exports";
import { getGtmConfig } from "./utils/gtmConfig";
import * as serviceWorker from './serviceWorker';
import MainContext, { makeAppContext } from "./ReactAppContext";

Amplify.configure(Config(window.location.pathname));

// Initializes Google Tag Manager
const tagManagerOptions = getGtmConfig();
TagManager.initialize(tagManagerOptions);

const appContext = makeAppContext();

ReactDOM.render(
  <MainContext.Provider value={appContext}>
    <App />
  </MainContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
