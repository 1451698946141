

export function ClientId() {
  let env = process.env.REACT_APP_DEPLOYMENT_ENV
  if(env === "localhost") {
    return "7f1tojs0fv57e4mp8pt8oi5of" // Vived_Dev_Local
  }
  return "58ohtjfbraemdcu29q0cv9sdmo" // Vived_Master
}


export function GoogleSignInUrl(redirectURL, state) {
  return `https://vived-users.auth.us-east-1.amazoncognito.com/oauth2/authorize?redirect_uri=${redirectURL}&state=${state}&response_type=code&client_id=${ClientId()}&identity_provider=Google`
}

export function RedirectUrl_SignIn() {
  return document.location.origin + "/signInRedirect"
}

export function RedirectUrl_SignOut() {
  return document.location.origin + "/"
}