export const APPS_GET_ALL = "APPS_GET_ALL"
export const APPS_SET_APPS_IN_STATE = "APPS_SET_APPS_IN_STATE"
export const APPS_ADD_TO_CHANNEL = "APPS_ADD_TO_CHANNEL"
export const APPS_REMOVE_TO_CHANNEL = "APPS_REMOVE_TO_CHANNEL"

export function prepAppForState(app) {
  let newApp = {...app}

  delete newApp.PK
  delete newApp.SK

  newApp.type = "app"
  newApp.id = app.PK
  
  return newApp
}

export function prepChannelAppForState(app) {
  let newApp = {...app}

  delete newApp.PK
  delete newApp.SK

  // newApp.type = "channel-app"
  newApp.id = app.SK

  newApp.enabled = true
  
  return newApp
}