import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

import { TextField } from "@rmwc/textfield";
import '@rmwc/textfield/styles';
import { Button } from "@rmwc/button";
import '@rmwc/button/styles';
import { Typography } from "@rmwc/typography"; // Using SASS in main, so no css needed

import style from "./SignIn.module.scss";

import {
  AuthPageContainer,
  AuthPageHeading,
  AuthLogoBanner,
  AuthErrorMessage,
  AuthForgotPassword,
  AuthActionButtons,
  AuthGoogleSso
} from "../../components/Auth/Auth";
import {
  InputErrorMessage
} from "../../components/Auth/AuthInputFields";
import { GoogleSignInUrl } from "../../utils/CognitoCredentials";
import { useQuery } from "../../hooks/useQuery";

const SignIn = props => {

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange"
  });

  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const query = useQuery();
  const redirectParam = query.get("redirect");

  const cognito = new CognitoIdentityServiceProvider({ region: 'us-east-1', credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  } });

  const getRedirect = () => {
    if (redirectParam) {
      return redirectParam;
    } else {
      //We want to send them back to the homepage. Using `history.push()` doesn't do a full page refresh
      return window.location.href.replace(props.location.pathname, "/");
    }
  };

  const handleGoogleSignin = () => {
    const state = uuidv4();
    if (redirectParam) {
      const time = Date.now();
      const item = JSON.stringify({ path: redirectParam, timestamp: time });
      localStorage.setItem(state, item);
    }

    // Question: Any reason we aren't using `history.push("/")`?
    const redirectURL = document.location.origin + "/signInRedirect";
    window.location.href = GoogleSignInUrl(redirectURL, state);
  };

  const onSubmit = data => {
    setLoginError(false);
    setLoginErrorMessage("");
    setIsLoggingIn(true);

    Auth.signIn({
      username: data.email.toLowerCase(),
      password: data.password
    })
    .then(user => {
      window.location.href = getRedirect();
    })
    .catch(async err => {
      const params = {
        UserPoolId: 'us-east-1_ifGPigjqM',
        Filter: `email = "${data.email.toLowerCase()}"`,
        Limit: 1
      };

      const userData = await cognito.listUsers(params).promise();

      if (userData.Users[0]?.Username && userData.Users[0].Username.includes("Google")) {
        const errorMessage = "Your account was created using single sign-on with a Google Account. Please try again using the 'Sign in with Google' button.";
        setLoginErrorMessage(errorMessage);
      } else {
        setLoginErrorMessage(err.message);
      }

      setLoginError(true);
      setIsLoggingIn(false);
    });
  };

  const renderEmail = () => {
    const inputLabel = "Email";
    const inputName = "email";
    const inputType = "email";
    const validationErrMsg = {
      required: "An email address is required."
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required
        })}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />
        }}
      />
    );
  };

  const renderPassword = () => {
    const inputLabel = "Password";
    const inputName = "password";
    const inputType = "password";
    const validationErrMsg = {
      required: "A password is required."
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required
        })}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />
        }}
      />
    );
  };

  const renderCreateAccount = () => {
    return (
      <div className={style.createAccount}>
        <Typography use="body1" tag="span">
          New to VIVED?
        </Typography>
        <Link to="/register">
          <Button label="Create Account" type="button" />
        </Link>
      </div>
    );
  };

  return (
    <AuthPageContainer elevation={0} linearProgress={{ closed: isLoggingIn ? false : true }}>
      <form>
        <AuthLogoBanner align="center" />
        <AuthPageHeading align="center" title="Sign in" />
        <AuthErrorMessage isShown={loginError} message={loginErrorMessage} />
        <div>
          <div>{renderEmail()}</div>
          <div>{renderPassword()}</div>
          <AuthForgotPassword />
          <AuthActionButtons
            primaryAction={
              <Button
                raised
                label="Sign in"
                onClick={handleSubmit(onSubmit)}
                type="button"
                disabled={isLoggingIn}
              />
            }
          />
        </div>
      </form>
      <AuthGoogleSso label={"sign in"} onClick={handleGoogleSignin} />
      {renderCreateAccount()}
    </AuthPageContainer>
  );
};

export default SignIn;
