import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { Select } from "@rmwc/select";
import "@rmwc/select/styles";

import style from "./Register.module.scss";

import {
  AuthPageContainer,
  AuthPageHeading,
  AuthLogoBanner,
  AuthErrorMessage,
  AuthActionButtons,
  AuthGoogleSso,
} from "../../components/Auth/Auth";
import { InputErrorMessage } from "../../components/Auth/AuthInputFields";

import { GoogleSignInUrl } from "../../utils/CognitoCredentials";
import { checkIfMainUser } from "../../Redux/Users/UserActions"
import store from "../../store"

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Register = () => {
  const [step, setStep] = useState(1);
  const [registerError, setRegisterError] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [country, setCountry] = useState("US");

  const { register, handleSubmit, getValues, errors, setError } = useForm({
    mode: "onBlur",
    defaultValues: { country: "US", phone: "+1" },
  });

  const firstNameRef = useRef();
  const history = useHistory();

  const countryList = countries.getNames("en");
  const dateToday = getTodaysDate();

  const renderEmail = () => {
    const inputLabel = "Email";
    const inputName = "email";
    const inputType = "email";
    const inputMaxLength = 255;
    // eslint-disable-next-line no-useless-escape
    const inputPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validationErrMsg = {
      required: "An email address is required.",
      maxLength: "The email address must be 254 characters or less.",
      pattern: "Email requires a valid email address.",
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern,
          },
        })}
        invalid={errors[inputName]}
        // onChange={() => triggerValidation(inputName)}
        // disabled={step === 2}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderFirstName = () => {
    const inputLabel = "First name";
    const inputName = "firstName";
    const inputType = "text";
    const inputMaxLength = 35;
    const validationErrMsg = {
      // required: "First name is required.",
      maxLength: `First name must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined
        // className={style.inputFullWidth}
        // value, configuration, validation props
        // required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            // required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
          firstNameRef.current = e;
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderLastName = () => {
    const inputLabel = "Last name";
    const inputName = "lastName";
    const inputType = "text";
    const inputMaxLength = 40;
    const validationErrMsg = {
      // required: "Last name is required.",
      maxLength: "Last name must be 40 characters or less.",
    };

    return (
      <TextField
        // general style props
        outlined
        // className={style.inputFullWidth}
        // value, configuration, validation props
        // required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          // required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderBirthDate = () => {
    const inputLabel = "Birth date";
    const inputName = "birthdate";
    const inputType = "date";
    const inputMin = "1900-01-01";
    const inputMax = dateToday;
    const inputPattern = /^(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/;
    const validationErrMsg = {
      // required: "Birth date is required.",
      min: "Birth date may not be before 1900-01-01.",
      max: "Birth date may not be in the future.",
      pattern: "Birth date must be formatted as yyyy-MM-dd.",
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        // required
        type={inputType}
        label={inputLabel}
        name={inputName}
        min={inputMin}
        max={inputMax}
        inputRef={register({
          // required: validationErrMsg.required,
          min: {
            value: inputMin,
            message: validationErrMsg.min,
          },
          max: {
            value: inputMax,
            message: validationErrMsg.max,
          },
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern,
          },
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderCountry = () => {
    const inputLabel = "Country";
    const inputName = "country";

    return (
      <Select
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        value={country}
        onChange={(e) => {
          setCountry(e.currentTarget.value);
        }}
        label={inputLabel}
        name={inputName}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
        options={countryList}
      />
    );
  };

  const renderPassword = () => {
    const inputLabel = "Password";
    const inputName = "password";
    const inputType = passwordVisible ? "text" : "password";
    const inputMinLength = 8;
    const inputMaxLength = 99;
    const inputPattern = /^(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/;
    const validationErrMsg = {
      required: "Password is required.",
      maxLength: "Password has a maximum length of 99 characters.",
      minLength: "Password requires a minimum of 8 characters.",
      pattern: `Password must be a mix of digits, letters, special characters and cannot contain spaces. Characters that meet the special character requirement: (^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \`) `,
    };


    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
          minLength: {
            value: inputMinLength,
            message: validationErrMsg.minLength,
          },
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern,
          },
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
        trailingIcon={{
          icon: passwordVisible ? "visibility_off" : "visibility",
          tabIndex: 0,
          onClick: () => setPasswordVisible(!passwordVisible),
        }}
      />
    );
  };

  const renderPasswordConfirm = () => {
    const inputLabel = "Confirm";
    const inputName = "passwordconfirm";
    const inputType = passwordConfirmVisible ? "text" : "password";
    const inputValidate = doPasswordsMatch;
    const validationErrMsg = {
      required: "Password confirmation is required.",
      validate: "Passwords do not match.",
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          validate: inputValidate,
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
        trailingIcon={{
          icon: passwordConfirmVisible ? "visibility_off" : "visibility",
          tabIndex: 0,
          onClick: () => setPasswordConfirmVisible(!passwordConfirmVisible),
        }}
      />
    );
  };

  /**
   * Returns today's date base on the local datetime in yyyy-MM-dd format.
   * @returns {string} Today's date in format yyyy-MM-dd
   */
  function getTodaysDate() {
    let today = new Date();

    const yyyy = today.getFullYear();
    const MM = String(today.getMonth() + 1).padStart(2, "0"); // January is 0.
    const dd = String(today.getDate()).padStart(2, "0");

    today = [yyyy, MM, dd].join("-");

    return today;
  }

  /**
   * Retreives the value of the password and passwordconfirm fields and checks to see if they match.
   * @returns {bool|sring} Returns true if the password match. If false, returns the error message as a string.
   */
  const doPasswordsMatch = () => {
    const p1 = getValues().password;
    const p2 = getValues().passwordconfirm;
    if (p1 === p2) {
      return true;
    } else return "Passwords do not match.";
  };

  const renderStepTwo = () => {
    if (step === 2) {
      return (
        <>
          <div className={style.name}>
            <div className={style.firstName}>{renderFirstName()}</div>
            <div className={style.lastName}>{renderLastName()}</div>
          </div>
          <div>{renderBirthDate()}</div>
          <div>{renderCountry()}</div>
          <div>{renderPassword()}</div>
          <div>{renderPasswordConfirm()}</div>
        </>
      );
    } else return null;
  };

  const onSubmit = (data, e) => {
    // If on step one and email is valid, continue to step 2.
    if (step === 1){
      if (!errors.email && data.email) {
        store.dispatch(checkIfMainUser(data.email, "cognitoRequest", (result) => {
          if(result.success) {
            if(result.isMainUser) {
              setStep(2);
            } else {
              const emailInUseErrorMessage = "An account already exists for this email address. Please sign in instead."
              setError("email", "manual", emailInUseErrorMessage);
            }
          }
        }))
      }
    }

    // If on step two and form is valid, submit
    if (step === 2){
      submitRegistration(data)
    }
  };

  const submitRegistration = (data) => {

    data.email = data.email.toLowerCase()

    // ToDo: Add Gender (Male, Female, Non-binary)

    const username = data.email;
    const password = data.password;
    const attributes = {
      email: data.email,
      "custom:date_created" : Date.now().toString()
    };

    if (data.firstName) {
      attributes["name"] = data.firstName;
    }
    if (data.lastName) {
      attributes.family_name = data.lastName;
    }
    if (data.birthdate) {
      attributes.birthdate = data.birthdate;
    }
    if (country) {
      attributes["custom:Country"] = country;
    }

    setRegisterError(false);
    setRegisterErrorMessage("");
    setIsRegistering(true);

    Auth.signUp({
      username,
      password,
      attributes: attributes,
    })
    .then((response) => {
      setIsRegistering(false);
      history.push({
        pathname: "/verify-email",
        state: { signUpEmail: response.user.username },
      });
    })
    .catch((err) => {
      setRegisterError(true);
      setRegisterErrorMessage(err.message);
      setIsRegistering(false);
    });
  };

  const handleGoogleSignUp = () => {
    const state = uuidv4();

    const redirectURL = document.location.origin + "/signInRedirect";
    window.location.href = GoogleSignInUrl(redirectURL, state);
  };

  const renderGoogleSignUp = () => {
    if (step === 1) {
      return <AuthGoogleSso label={"sign up"} onClick={handleGoogleSignUp} />;
    } else return;
  };

  return (
    <AuthPageContainer
      elevation={0}
      linearProgress={{ closed: isRegistering ? false : true }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthLogoBanner align="left" />
        <AuthPageHeading title="Create your VIVED account" />
        <AuthErrorMessage
          isShown={registerError}
          message={registerErrorMessage}
        />
        <div>
          <div>{renderEmail()}</div>
          <div>{renderStepTwo()}</div>
          <AuthActionButtons
            primaryAction={
              <Button
                raised
                label={step === 2 ? "Submit" : "Next"}
                type="submit"
              />
            }
            secondaryAction={
              <Link to="/signin">
                <Button
                  label="Sign in instead"
                  type="button"
                  style={{ textTransform: "none" }}
                />
              </Link>
            }
          />
        </div>
      </form>
      {renderGoogleSignUp()}
    </AuthPageContainer>
  );
};

export default Register;
