import React, { useState } from "react";

import { Typography } from "@rmwc/typography"; // No css import needed
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import { DeviceInfo } from "./DeviceInfo/DeviceInfo";
import { FeatureDetection } from "./FeatureDetection/FeatureDetection";
import { Environment } from "./Environment/Environment";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { ContentContainer } from "../../components/Layout/Layout";
import { dialogQueue } from "../../components/Dialog/dialogQueue";
import DialogShare from "../../components/Dialog/DialogShare";
import DialogEmbed from "../../components/Dialog/DialogEmbed";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";
import { usePlayerState } from "../../hooks/usePlayerState";

import EditMemberRole from "../../components/Dialog/EditMemberRole/EditMemberRole";
import AddMemberToChannel from "../../components/Dialog/AddMemberToChannel/AddMemberToChannel";

const Debug = (props) => {
  const [dialogResponse, setDialogResponse] = useState("");
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openEmbedDialog, setOpenEmbedDialog] = useState(false);
  const [openEditMemberRoleDialog, setOpenEditMemberRoleDialog] = useState(
    false
  );
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [dataAddMember, setDataAddMember] = useState(null);
  const device = usePlayerState();

  const fireAlert = (title, body) => {
    dialogQueue
      .alert({
        title: title,
        body: body,
      })
      .then((resp) => setDialogResponse(resp))
      .catch((err) => console.error("Dialog error: ", err));
  };

  const fireConfirm = (title, body, acceptLabel) => {
    dialogQueue
      .confirm({
        title: title,
        body: body,
        acceptLabel: acceptLabel,
      })
      .then((resp) => {
        setDialogResponse(resp.toString());
      })
  };

  const firePrompt = (title, body, acceptLabel, cancelLabel) => {
    dialogQueue
      .prompt({
        title: title,
        body: body,
        acceptLabel: acceptLabel,
        cancelLabel: cancelLabel,
        inputProps: { outlined: true, label: "Code" },
      })
      .then((resp) => {
        setDialogResponse(resp === null ? "null" : resp);
      })
  };

  const fireShareDialog = () => {
    setOpenShareDialog(true);
  };
  const onCloseShareDialog = (evt) => {
    setDialogResponse(evt.detail.action);
    setOpenShareDialog(false);
  };

  const fireEditMemberRoleDialog = () => {
    setOpenEditMemberRoleDialog(true);
  };
  const onCloseEditMemberRoleDialog = (evt) => {
    setDialogResponse(evt.detail.action);
    setOpenEditMemberRoleDialog(false);
  };

  const fireAddMemberDialog = () => {
    setOpenAddMemberDialog(true);
  };
  const onCloseAddMemberDialog = (evt) => {
    setDialogResponse(evt.detail.action);
    setOpenAddMemberDialog(false);
  };

  const fireEmbedDialog = () => {
    setOpenShareDialog(false);
    setOpenEmbedDialog(true);
  };
  const onCloseEmbedDialog = (evt) => {
    setDialogResponse(evt.detail.action);
    setOpenEmbedDialog(false);
  };

  const renderAddMemberDialog = () => {
    return (
      <AddMemberToChannel
        open={openAddMemberDialog}
        onClose={onCloseAddMemberDialog}
        setData={setDataAddMember}
        renderToPortal
      />
    );
  };

  const renderEditMemberRoleDialog = () => {
    return (
      <EditMemberRole
        open={openEditMemberRoleDialog}
        onClose={onCloseEditMemberRoleDialog}
        renderToPortal
        member={{
          nasme: "Tom Nicknish",
          role: "member",
          email: "tom.nicknish@vivedlearning.com",
        }}
      />
    );
  };

  const renderShareDialog = () => {
    return (
      <DialogShare
        open={openShareDialog}
        onClose={onCloseShareDialog}
        onEmbed={fireEmbedDialog}
        renderToPortal
        activityMeta={{
          title: "Example title",
          description: "This is what a description could look like",
          image:
            "https://yt3.ggpht.com/Z3S2fdQwmaOHXLLIVd78fYqp9Pp4dsWi7WlhRD8YbCOQqYen7B2eO3tA_2jhV3mdWOJKe8UT=w2276-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj",
          channelId: "",
        }}
      />
    );
  };
  const renderEmbedDialog = () => {
    return (
      <DialogEmbed
        open={openEmbedDialog}
        onClose={onCloseEmbedDialog}
        renderToPortal
      />
    );
  };

  const renderDialogTests = () => {
    return (
      <div id="dialog" style={{ paddingTop: "24px" }}>
        <Typography use="headline5" tag="h2">
          Dialog Tests
        </Typography>
        <div>
          <Button
            outlined
            onClick={() =>
              fireAlert(
                "Verification code sent",
                "A verification code has been sent to your email address. Enter the code we emailed you on the next screen to activate your account."
              )
            }
          >
            Dialog Alert Test
          </Button>
          <Button
            outlined
            onClick={() =>
              fireConfirm(
                "Delete activity",
                "Are you sure you want to delete this activity? This action cannot be undone.",
                "Delete"
              )
            }
          >
            Dialog Confirm Test
          </Button>
          <Button
            outlined
            onClick={() =>
              firePrompt(
                "Confirmation code",
                "Enter the code we emailed to email@domain.com",
                "Confirm",
                "Cancel"
              )
            }
          >
            Dialog Prompt Test
          </Button>
          <Button outlined onClick={fireShareDialog}>
            Share Dialog
          </Button>
          <Button outlined onClick={fireEditMemberRoleDialog}>
            Edit Member Role Dialog
          </Button>
          <Button outlined onClick={fireAddMemberDialog}>
            Add Member
          </Button>
        </div>
        <Typography use="body1" tag="p">
          Dialog callback response: {dialogResponse}
        </Typography>
        {dataAddMember ? (
          <Typography use="body1" tag="p">
            {JSON.stringify(dataAddMember)}
          </Typography>
        ) : null}
      </div>
    );
  };

  const renderSnackbarTests = () => {
    return (
      <div id="snackbar" style={{ paddingTop: "24px" }}>
        <Typography use="headline5" tag="h2">
          Snackbar Tests
        </Typography>
        <div>
          <div>
            <Button onClick={sendToast1} outlined>
              Send toast 1
            </Button>
            <Button onClick={sendToast2} outlined>
              Send toast 2
            </Button>
            <Button onClick={sendToast3} outlined>
              Send toast 3
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const sendToast1 = () => {
    snackbarQueue.notify({
      title: <b>Got toast?</b>,
      body: "Yeah toast!",
    });
  };

  const sendToast2 = () => {
    snackbarQueue.notify({
      title: <b>Activity saved</b>,
      body: "Your activity has been succfully saved.",
      dismissesOnAction: true,
      actions: [
        {
          title: "Dismiss",
          icon: "done",
        },
      ],
    });
  };

  const sendToast3 = () => {
    snackbarQueue.notify({
      title: <b>"Confirmation code sent"</b>,
      body: "A verification code has been sent to email@yourdomain.com.",
      dismissesOnAction: true,
      stacked: true,
      leading: true,
      actions: [
        {
          title: "Enter code",
          icon: "vpn_key",
          action: "enterCode",
        },
        {
          label: "Dismiss",
        },
      ],
    });
  };

  return (
    <PageWrapper fixedAppBar={false} protectedContent={false}>
      <ContentContainer>
        <Typography use="headline4" tag="h1">
          Debug Information
        </Typography>
        <Environment />
        <DeviceInfo device={device} />
        <FeatureDetection />
        {renderDialogTests()}
        {renderSnackbarTests()}
      </ContentContainer>
      {renderShareDialog()}
      {renderEmbedDialog()}
      {renderEditMemberRoleDialog()}
      {renderAddMemberDialog()}
    </PageWrapper>
  );
};

export default Debug;
