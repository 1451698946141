import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

/**
 * This will return null if the user is not logged or is in the process of logging in.
 * Otherwise it will return an object where each property is the channel ID and the value is an object
 * containing the role and a flag if that role is pending.
 *
 * Example Return
 * {
 *      121-456-789: {role: "admin", isPending: false},
 *      987-656-321: {role: "member", isPending: true}
 * }
 *
 * If a user is not logged in, this will return an empty object
 */

export const useUserRoles = () => {
  const [roles, setRoles] = useState(null);
  const user = useSelector((state) => state.User);

  useEffect(() => {
    if (user.loginStatus === "isLoggedIn") {
      const rolesToSet = {};

      const channelIds = user.channels ? Object.keys(user.channels) : [];
      channelIds.forEach((channelId) => {
        rolesToSet[channelId] = {
          role: user.channels[channelId],
          isPending: false,
        };
      });

      const pendingChannelIds = user.pendingChannels
        ? Object.keys(user.pendingChannels)
        : [];
      pendingChannelIds.forEach((channelId) => {
        rolesToSet[channelId] = {
          role: user.pendingChannels[channelId],
          isPending: true,
        };
      });

      setRoles(rolesToSet);
    } else if (user.loginStatus === "isNotLoggedIn") {
      setRoles({}); //A non logged in user has no roles
    }
  }, [user.loginStatus, user.channels, user.pendingChannels]);

  return roles;
};
