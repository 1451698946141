import React from "react";

import { Typography } from "@rmwc/typography"; // Sass imported in main. CSS not needed.

import ChannelCard from "../../../components/Cards/ChannelCard/ChannelCard";
import { FlexGrid } from "../../../components/Layout/Layout";

export const LinkedChannelList = ({ linkedChannels, subscriberRole }) => {
    let list;
  if (
    !linkedChannels ||
    linkedChannels === null ||
    linkedChannels === undefined ||
    Object.values(linkedChannels).length === 0
  ) {
    return (
      <Typography
        use="body1"
        tag="h2"
        style={{ textAlign: "center", flexGrow: "1", paddingTop: "24px" }}
      >
        This channel doesn't feature any other channels.
      </Typography>
    );
  } else {
    list = linkedChannels.map((c) => {
      if (c.isActive) {
        return (
          <React.Fragment key={c.id}>
            <ChannelCard
              channelId={c.id}
              image={c.image}
              name={c.name}
              subscriberId={c.subscriberId}
              canAuthor_asSubscriber={c.canAuthor}
              canEdit_asSubscriber={c.canEdit}
              expiration_forSubscription={c.expiration}
              subscriberRole={subscriberRole}
            />
          </React.Fragment>
        );
      } else {
        return null;
      }
    });

    return (
        <FlexGrid>
            {list}
        </FlexGrid>
    )
  }
};

LinkedChannelList.defaultProps = {};
LinkedChannelList.propTypes = {};
