import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useClipboard } from "use-clipboard-copy";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Typography } from "@rmwc/typography"; // Sass used in main. No css needed here.

import style from "./JoinCodeDisplay.module.scss";

import Error from "../Error/Error";
import { snackbarQueue } from "../Snackbar/snackbarQueue";
import Actions from "../../Redux/actions";

const JoinCodeDisplay = ({ channelData, userLimitReached }) => {
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const joinCode = channelData.join_code;

  const clipboard = useClipboard({
    copiedTimeout: 1500, //ms
    onSuccess() {
      snackbarQueue.notify({ body: "Join code copied to clipboard" });
    },
    onError() {
      snackbarQueue.notify({
        title: <b>Error</b>,
        body: "Join code could not be copied",
      });
    },
  });

  const resetCallBack = (result) => {
    if (!result.success) {
      setIsError(true);
    }
  };
  const onReset = () => {
    dispatch(
      Actions.Channels.editJoinCode(
        channelData.id,
        channelData.join_code_status,
        true,
        (result) => resetCallBack(result)
      )
    );
  };

  const onCopyJoinCode = (evt) => {
    clipboard.copy(joinCode);
  };

  const RenderJoinCodeInput = () => {
    return (
      <TextField
        value={joinCode ? joinCode : "CODE NOT AVAILABLE"}
        label="Join code"
        onChange={() => {
          return;
        }}
        trailingIcon={
          // Don't display the trailing button if there's no code to copy
          joinCode
            ? {
                icon: "assignment",
                onClick: (evt) => onCopyJoinCode(evt),
              }
            : null
        }
        readOnly={true}
        disabled={!joinCode}
        outlined
      />
    );
  };

  const RenderResetButton = () => {
    return (
      <Button
        label="Reset Code"
        outlined
        onClick={onReset}
        className={style.resetButton}
      />
    );
  };

  const RenderJoinCodeMessage = () => {
    return (
      <Typography use="body2" tag="p">
        Join code is not available because this channel has reached its user limit.
      </Typography>
    );
  };

  const renderContent = () => {
    if (userLimitReached) {
      return <RenderJoinCodeMessage />;
    } else {
      return (
        <div className={style.container}>
          <RenderJoinCodeInput />
          <RenderResetButton />
        </div>
      );
    }
  };

  if (isError) {
    return <Error message="An Error orccured while resetting the join code" />;
  } else if (channelData.join_code_status === "disabled") {
    return null;
  } else {
    return renderContent();
  }
};

export default JoinCodeDisplay;
