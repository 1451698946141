import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link} from "react-router-dom";
import { Auth } from "aws-amplify";

import { TextField } from "@rmwc/textfield";
import '@rmwc/textfield/styles';
import { Button } from "@rmwc/button";
import '@rmwc/button/styles';
import { Typography } from "@rmwc/typography"; // Using SASS in main, so no css needed

import style from "./PasswordRecovery.module.scss";

import {
  AuthPageContainer,
  AuthPageHeading,
  AuthLogoBanner,
  AuthErrorMessage,
  AuthActionButtons
} from "../../components/Auth/Auth";
import { InputErrorMessage } from "../../components/Auth/AuthInputFields";

const PasswordRecovery = () => {
  const { register, handleSubmit, errors, getValues } = useForm({
    mode: "onBlur"
    // defaultValues: { email2: "youremail@domain.com" }
  });
  // const [displayEmail, setDisplayEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [step, setStep] = useState(1);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [isRequestingReset, setIsRequestingReset] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [resetErrorMessage, setResetErrorMessage] = useState("");
  const [isResetting, setIsResetting] = useState(false);

  const onRequestPasswordReset = data => {
    setIsRequestingReset(true);
    setResetError(false);
    setResetErrorMessage("");

    const username = data.email;
    setUserEmail(username);

    Auth.forgotPassword(username)
      .then(resp => {
        setStep(2);
        setIsRequestingReset(false);
      })
      .catch(err => {
        setResetError(true);
        setResetErrorMessage(err.message);
        setIsRequestingReset(false);
      });
  };
  const onSubmitPasswordReset = data => {
    setIsResetting(true);
    setResetError(false);
    setResetErrorMessage("");

    const username = data.email;
    const code = data.code;
    const new_password = data.password;

    Auth.forgotPasswordSubmit(username, code, new_password)
      .then(response => {
        setResetPasswordSuccess(true);
      })
      .catch(err => {
        setResetError(true);
        setResetErrorMessage(err.message);
        setIsResetting(false);
      });
  };
  const renderContent = () => {
    if (!resetPasswordSuccess) {
      return (
        <>
          {renderStepOne()}
          {renderStepTwo()}
        </>
      );
    } else return null;
  };

  const renderMessage = () => {
    if (step === 2 && resetPasswordSuccess) {
      return (
        <Typography use="body1" tag="p">
          You have successfully reset your password.
        </Typography>
      );
    } else if (step === 2 && !resetPasswordSuccess) {
      return (
        <>
          <Typography use="body1" tag="p">
            We sent a confirmation code to your email in the previous step.
            Enter the code below along with a new password below to reset your
            password.
          </Typography>
          <Typography use="body1" tag="p">
            If you don’t see the email within 2 minutes, check your spam folder
            for an email from <i>autoresponder@vivedlearning.com</i>.
          </Typography>
        </>
      );
    } else {
      return (
        <Typography use="body1" tag="p">
          Enter the email address for your VIVED account below and submit. If an
          account exists, we will send a confirmation code to your email. You
          will use that code to reset your password in the next step.
        </Typography>
      );
    }
  };

  const renderStepOne = () => {
    if (!resetPasswordSuccess) {
      return <div>{renderEmail()}</div>;
    }
  };
  const renderStepTwo = () => {
    if (step === 2 && !resetPasswordSuccess) {
      return (
        <>
          <div>{renderCode()}</div>
          <div>{renderPassword()}</div>
          <div>{renderPasswordConfirm()}</div>
        </>
      );
    }
  };
  const renderActionButtons = () => {
    if (resetPasswordSuccess) {
      return (
        <AuthActionButtons
          primaryAction={
            <Link to={{ pathname: "/signin", state: { userEmail: userEmail } }}>
              <Button raised label="Continue" type="button" />
            </Link>
          }
        />
      );
    } else if (step === 2) {
      return (
        <AuthActionButtons
          primaryAction={
            <Button
              raised
              label="Submit"
              disabled={isResetting}
              onClick={handleSubmit(onSubmitPasswordReset)}
              type="button"
            />
          }
        />
      );
    } else {
      return (
        <AuthActionButtons
          primaryAction={
            <Button
              raised
              label="Next"
              disabled={isRequestingReset}
              onClick={handleSubmit(onRequestPasswordReset)}
              type="button"
            />
          }
        />
      );
    }
  };

  const renderEmail = () => {
    const inputLabel = "Email";
    const inputName = "email";
    const inputType = "email";
    // const inputMaxLength = 255;
    // eslint-disable-next-line no-useless-escape
    const inputPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validationErrMsg = {
      required: "An email address is required.",
      // maxLength: "The email address must be 254 characters or less.",
      pattern: "Email requires a valid email address."
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern
          }
        })}
        invalid={errors[inputName]}
        disabled={step === 2 ? true : false}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />
        }}
      />
    );
  };

  const renderCode = () => {
    const inputLabel = "Confirmation code";
    const inputName = "code";
    const inputType = "number";
    const inputPattern = /[\S]+/;
    const validationErrMsg = {
      required: "The confirmation code is required.",
      pattern: "The confirmation code can't contain whitespace."
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern
          }
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />
        }}
      />
    );
  };

  const renderPassword = () => {
    const inputLabel = "New password";
    const inputName = "password";
    const inputType = "password";
    const inputMinLength = 8;
    const inputMaxLength = 99;
    // eslint-disable-next-line no-useless-escape
    const inputPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
    const validationErrMsg = {
      required: "Password is required.",
      maxLength: "Password has a maximum length of 99 characters.",
      minLength: "Password requires a minimum of 8 characters.",
      pattern: `Password must be a mix of numbers, uppercase letters, lowercase letters, special characters and cannot contain spaces. Special characters allowed: (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \`) `
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength
          },
          minLength: {
            value: inputMinLength,
            message: validationErrMsg.minLength
          },
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern
          }
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />
        }}
      />
    );
  };

  const renderPasswordConfirm = () => {
    const inputLabel = "Confirm";
    const inputName = "passwordconfirm";
    const inputType = "password";
    const inputValidate = doPasswordsMatch;
    const validationErrMsg = {
      required: "Password is required.",
      validate: "Passwords do not match."
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          validate: inputValidate
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />
        }}
      />
    );
  };

  const doPasswordsMatch = () => {
    const p1 = getValues().password;
    const p2 = getValues().passwordconfirm;
    if (p1 === p2) {
      return true;
    } else return "Passwords do not match.";
  };
  return (
    <AuthPageContainer
      elevation={0}
      linearProgress={{ closed: isRequestingReset ? false : true }}
    >
      <form>
        <AuthLogoBanner align="left" />
        <AuthPageHeading
          title={
            resetPasswordSuccess ? "Password reset" : "Reset your passowrd"
          }
        />
        <AuthErrorMessage isShown={resetError} message={resetErrorMessage} />
        {renderMessage()}
        <div>{renderContent()}</div>
        {renderActionButtons()}
      </form>
    </AuthPageContainer>
  );
};

export default PasswordRecovery;
