
import { useSelector } from "react-redux";

export const useUserState = () => {
  let userMeta = {
      loginStatus: null,
      name: "",
      givenName: "",
      familyName: "",
      fullName: "",
      email: "",
      avatar: "",
      groups: [],
      isGoogleUser: false,
    },
    user = useSelector((state) => state.User),
    ifNameIsFullName = user.name && user.name.includes(" ");
  
  userMeta.id = user.id
  userMeta.loginStatus = user.loginStatus;
  userMeta.givenName = user.given_name ? user.given_name : user.name;
  userMeta.familyName = user.family_name;
  userMeta.fullName = ifNameIsFullName
    ? user.name
    : [user.name, user.family_name].join(" ");
  userMeta.email = user.email;
  userMeta.avatar = user.avatar;
  userMeta.groups = user.groups;
  userMeta.isGoogleUser = user.isGoogleUser;

  return userMeta;
};
