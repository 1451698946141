import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const CloseIcon = ({
  width = '22',
  fill = colors.gray.darker,
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...iconProps}>
    <title>ic_close_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </g>
  </svg>
);

CloseIcon.propTypes = Props;
export default CloseIcon;
