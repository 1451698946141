import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

//Simply returns the role of the current user for a given channel
export const usePendingChannels = () => {
  const [pendingChannels, setPendingChannels] = useState({});
  const user = useSelector((state) => state.User);

  useEffect(() => {
    let desriedPendingChannels = {};

    if (user.loginStatus === "isLoggedIn" && user.pendingChannels) {
      desriedPendingChannels = user.pendingChannels;
    }

    if (desriedPendingChannels !== pendingChannels) {
      setPendingChannels(desriedPendingChannels);
    }
  }, [user, pendingChannels]);

  return pendingChannels;
};
