import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableCell,
  DataTableBody,
} from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import style from "./AvailableResources.module.scss";

import { SectionHeadlineRow } from "../../Layout/Headings";
import { SuperCentered } from "../../Layout/OneLineLayouts/SuperCentered/SuperCentered";
import { sortInAllDirections } from "../../../utils/helpers";


export const AvailableResources = ({ availableResources, onAssign }) => {

  /// Hooks
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState(1); // null = unsorted, 1 = ascending, -1 = descending
  const [sortColumnSecondary, setSortColumnSecondary] = useState(null);
  const [list, setList] = useState([]);
  const [assigningArray, setAssigningArray] = useState([]);
  const [toRemoveFromAssigningArray, setToRemoveFromAssigningArray] = useState([]);

  // Effects
  // This effect should watch for changes to the available resources list, the sort column, and sort direction. It will produce a sorted list to be displayed by the component.
  useEffect(() => {
    let _availableResources = [...availableResources]

    // If both sortColumn and sortDirection are defined, sort array and set state
    if (sortColumn && sortDirection) {
      let list = sortInAllDirections(_availableResources, sortColumn, sortDirection, sortColumnSecondary)
      setList(list)
    } else {
      setList(_availableResources)
    }
  }, [availableResources, sortColumn, sortDirection, sortColumnSecondary])
  
  // Effect for keeping track of which spinners to show for bundles/collections being assigned
  useEffect(() => {
    if(toRemoveFromAssigningArray.length > 0){
      // got some stuff to remove
      const tempToRemoveArrary = [...toRemoveFromAssigningArray];
      for(let i=0; i<tempToRemoveArrary.length; i++){
        const resouceId = tempToRemoveArrary[i];
        if (assigningArray.includes(resouceId)) {
          // found the one to remove
          let newAssigningArray = [...assigningArray];
          const index = newAssigningArray.indexOf(resouceId);
          if (index > -1) {
            newAssigningArray.splice(index, 1);           
            setAssigningArray([...newAssigningArray]);            
          }
        }
        // found the one to remove
        let newToRemoveAssigningArray = [...toRemoveFromAssigningArray];
        const index = newToRemoveAssigningArray.indexOf(resouceId);
        if (index > -1) {
          newToRemoveAssigningArray.splice(index, 1);           
          setToRemoveFromAssigningArray([...newToRemoveAssigningArray]);            
        }
      }
    }
    
  }, [assigningArray,toRemoveFromAssigningArray]);

  // Constants & other
  const headers = [
    {
      id: 1,
      key: 1,
      name: "thumbnail",
      label: "",
      sortable: false,
    },
    {
      id: 2,
      key: 2,
      name: "name",
      label: "Name",
      sortable: true,
    },
    {
      id: 3,
      key: 3,
      name: "type",
      label: "Type",
      sortable: true,
    },
    {
      id: 4,
      key: 4,
      name: "action",
      label: "Action",
      sortable: false,
    },
  ];

  // Event hander functions
  const handleOnSortChange = (column, sortDir) => {
    const secondarySort = column === "type" ? "name" : null;

    setSortColumn(column);
    setSortDirection(sortDir);
    setSortColumnSecondary(secondarySort);
  };

  // Render helper functions
  const renderTableHeaders = () => {
    return headers.map((i) => {
      if (i.sortable) {
        return (
          <DataTableHeadCell
            // alignStart
            key={`ar-${i.name}-${i.id}`}
            sort={sortColumn === i.name ? sortDirection : null}
            onSortChange={(sortDir) => handleOnSortChange(i.name, sortDir)}
          >
            {i.label}
          </DataTableHeadCell>
        );
      } else {
        return (
          <DataTableHeadCell key={`ar-${i.name}-${i.id}`}>
            {i.label}
          </DataTableHeadCell>
        );
      }
    });
  };

  const renderTableRows = () => {
    if (!list) {
      return null;
    }
    if (list.length === 0) {
      return (
        <DataTableRow>
          <DataTableCell />
          <DataTableCell>
            <div className={style.tableName}>
              No available resources were found.
            </div>
          </DataTableCell>
          <DataTableCell />
          <DataTableCell />
        </DataTableRow>
      );
    }

    const onAssignCallback = (resouceId) => {
      if(!toRemoveFromAssigningArray.includes(resouceId)){
        let newToRemoeFromAssigningArrary = [...toRemoveFromAssigningArray];
        newToRemoeFromAssigningArrary.push(resouceId);
        setToRemoveFromAssigningArray([...newToRemoeFromAssigningArrary]);
      }         
    }

    const renderAssignButton = r => {
      if (assigningArray.includes(r.id)) {
        // spinner
        return (
          <SuperCentered >
            <CircularProgress />
          </SuperCentered>
        );
      }
      else {
        // button
        return (
          <Button
            label={r.assigned ? "Assigned" : "Assign"}
            disabled={r.assigned}
            onClick={() => {
              let newAssigningArray = [...assigningArray];
              newAssigningArray.push(r.id);
              setAssigningArray([...newAssigningArray]);
              onAssign(r, onAssignCallback);
            }}
          />
        );
      }
    }

    const rows = list.map((r) => {
      return (
        <DataTableRow key={r.id}>
          <DataTableCell>
            <img
              src={r.image ? r.image : "https://via.placeholder.com/40x40.jpg"}
              className={style.image}
              alt={r.name}
            />
          </DataTableCell>
          <DataTableCell>
            <div className={style.tableName}>{r.name}</div>
          </DataTableCell>
          <DataTableCell>{r.type}</DataTableCell>
          <DataTableCell>
            {renderAssignButton(r)}
          </DataTableCell>
        </DataTableRow>
      );
    });
    return rows;
  };

  const renderTable = () => {
    return (
      <DataTable className={style.tableContainer} stickyRows={1}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>{renderTableHeaders()}</DataTableRow>
          </DataTableHead>
          <DataTableBody>{renderTableRows()}</DataTableBody>
        </DataTableContent>
      </DataTable>
    );
  };

  const renderContent = () => {

    return (
      <div className={style.container}>
        <SectionHeadlineRow
          className={style.sectionHeadline}
          title="Available resources"
          tag="h2"
          typographyStyle="subtitle1"
        />
        <div className={style.tableWrapper}>{renderTable()}</div>
      </div>
    );
  };

  return renderContent();
};
