import React from "react";

import {
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from "@rmwc/top-app-bar";
import "@rmwc/top-app-bar/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Elevation } from "@rmwc/elevation";
import "@rmwc/elevation/styles";

import style from "./ActivityNewToolbar.module.scss";

import { ListFilter } from "../../containers/Channel/ListFilter/ListFilter";

export const ActivityNewToolbar = ({
  filterKeys,
  selectedFilter,
  setSelectedFilter,
  sortDirection,
  setSortDirection,
  primaryAction
}) => {
  return (
    <Elevation z={1} theme={["surface"]} wrap>
      <div className={style.toolbarSticky}>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarTitle>New Activity: Select a model</TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <Button
              // outlined
              raised
              label="Next"
              className={style.toolbarActionButton}
              disabled={primaryAction ? false : true}
              onClick={primaryAction}
            />
          </TopAppBarSection>
        </TopAppBarRow>
        <TopAppBarRow>
          <TopAppBarSection>
            <ListFilter
              keys={filterKeys}
              selected={selectedFilter}
              setSelected={setSelectedFilter}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
            />
          </TopAppBarSection>
        </TopAppBarRow>
      </div>
    </Elevation>
  );
};

export const ActivityNewToolbarFixedOffset = () => {
  return (
    <div style={{width: "100%", height: "128px"}} />
  )
}
