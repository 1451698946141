export const gtmConfig = {
  local: {
    gtmId: 'GTM-K8S57PN',
    auth: 'n8pKnrP4Tt7TxoKqWyZySw',
    preview: 'env-5'
  },
  test: {
    gtmId: 'GTM-K8S57PN',
    auth: 'NqkMAZ4bShsDaT59ZAypfg',
    preview: 'env-4'
  },
  staging: {
    gtmId: 'GTM-K8S57PN',
    auth: 'E7WyccB4atBR59acDxpw0Q',
    preview: 'env-3'
  },
  production: {
    gtmId: 'GTM-K8S57PN',
    auth: 'orD1qKnAz6TYQQxVrNSnFw',
    preview: 'env-1'
  },
}

export const getGtmConfig = () => {
  if (process.env.REACT_APP_DEPLOYMENT_ENV === "localhost") {
    return gtmConfig.local;
  }
  if (process.env.REACT_APP_DEPLOYMENT_ENV === "test") {
    return gtmConfig.test;
  }
  if (process.env.REACT_APP_DEPLOYMENT_ENV === "staging") {
    return gtmConfig.staging;
  }
  if (process.env.REACT_APP_DEPLOYMENT_ENV === "production") {
    return gtmConfig.production;
  }
}