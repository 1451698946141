import React from "react";
import { usePendingChannels } from "../../hooks/usePendingChannels";
import { Typography } from "@rmwc/typography";
import PendingChannelItem from "./PendingChannelItem";
import { FlexGrid } from "../Layout/Layout";
import style from "./PendingChannel.module.scss";

const PendingChannelsList = () => {
  const pendingChannels = usePendingChannels();
  const pendingChannelIds = pendingChannels ? Object.keys(pendingChannels) : [];

  if (pendingChannels && pendingChannelIds.length > 0) {
    return (
      <>
        <Typography use="headline6" tag="h1" className={style.pageTitle}>
          Pending Channels
        </Typography>
        <FlexGrid>
          {pendingChannelIds.map((channelId) => {
            return (
              <PendingChannelItem
                key={channelId}
                channelId={channelId}
              />
            );
          })}
        </FlexGrid>
      </>
    );
  } else {
    //Nothing to show
    return <></>;
  }
};

export default PendingChannelsList;
