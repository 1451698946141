import React from "react";

function VivedIconCurriculumAlignment(props) {
  const fill = props.fill || "currentColor";
  const width = props.width || "100%";
  const height = props.height || "100%";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <defs />
        <path d="M12.11 4a.47.47 0 0 0-.3.12l-9.44 8.6a.5.5 0 0 0-.2.29 5.92 5.92 0 0 0-.17 1.5A6.56 6.56 0 0 0 2.17 16a.47.47 0 0 0 .29.32l9.4 3.68a.47.47 0 0 0 .48-.09l9.5-8.65a.48.48 0 0 0 0-.68.38.38 0 0 0-.18-.13l-.31-.12v-.5a5.6 5.6 0 0 1 .07-.93l.42-.38a.49.49 0 0 0 0-.68.46.46 0 0 0-.18-.13L12.29 4a.57.57 0 0 0-.18 0zm2.16 4a1.31 1.31 0 0 1 .53.08.55.55 0 0 1 .37.36.46.46 0 0 1-.19.46 1.23 1.23 0 0 1-.63.29 1.29 1.29 0 0 1-.71-.05c-.43-.17-.51-.54-.19-.83a1.39 1.39 0 0 1 .82-.31zm-3.81 0l.43 1.9 2.18-1.07a.69.69 0 0 0 .16.23 1.2 1.2 0 0 0 .27.16h.06l.31.07a2.08 2.08 0 0 0 .43 0L13.53 11l3.09-.53.25 1.09-1.27.22-.12-.51h-.21l.12.51-1.28.22v-.26h-.21l.06.25-1 .17-.56 1.19a.54.54 0 0 1-.25.31 1.65 1.65 0 0 1-.42.22l-.05-.07v-.08a1.17 1.17 0 0 0-.05-.11c0-.11 0-.15.06-.34l.45-.94-.58.1h-.3l-.06-.26h-.21l.06.25-1.27.22-.29-.65.84-.14v-.13l-.84.14v-.18l-.08-.35-1.34.66a1.14 1.14 0 0 1-.49.17 1.44 1.44 0 0 1-.52 0s.05-.15.17-.32.13-.13.38-.26l1.61-.78-.14-.59.84-.22v-.12l-.83.14-.18-.78.42-.07v-.13l-.42.07-.17-.78 1.78-.31zm3 1.25L11 10.52l.2.86 1.4-.24zm7.27.16v.32a5.84 5.84 0 0 0 .09 1.07L11.93 19l-8.87-3.47a4 4 0 0 1-.11-1 4.87 4.87 0 0 1 0-.72l8.86 3.43a.47.47 0 0 0 .49-.1l8.4-7.65z" />
      </g>
    </svg>
  );
}

export default VivedIconCurriculumAlignment;
