import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import ActivityDetails from "../../components/ActivityDetails/ActivityDetails";
import CustomPlayerShadowDom from "../../components/PlayerShadowDom/CustomPlayerShadowDom";
import PageError from "../../components/Error/PageError";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PlayerShadowDom from "../../components/PlayerShadowDom/PlayerShadowDom";
import { Player2 } from "../Player2/Player2";
import { useActivity } from "../../hooks/useActivity";
import { useChannel } from "../../hooks/Channel/useChannel";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useIsLTIUser } from "../../hooks/User/useIsLTIUser";
import { useUserIsVivedEmployee } from "../../hooks/User/useUserIsVivedEmployee";

import style from "./ActivityConsumption.module.scss";

const ActivityConsumption = (props) => {
  const activityId = props.match.params.activityId;
  const isViewingFromSubscription =
    props.location.state?.isViewingFromSubscription;
  const subscriberId = props.location.state?.subscriberId;
  const player2ActivityLaunchAs = props.location.state?.launchAs;
  const isNewActivityV2 = player2ActivityLaunchAs === "newActivity";

  //States
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [channelId, setChannelId] = useState("");

  //Hooks
  const { channel, error: getChannelError } = useChannel(channelId);
  const { activity, error: getActivityError } = useActivity(
    activityId,
    "channelId"
  );
  const { role } = useChannelRole(channelId);
  const useIsAnEmployee = useUserIsVivedEmployee();
  const history = useHistory();
  const isLtiUser = useIsLTIUser();

  //REDIRECTS for depricated route ("/channel/:channelId/activity/:activityId")
  useEffect(() => {
    if (
      props.match.params.channelId &&
      props.location?.pathname.includes("channel")
    ) {
      history.push({
        pathname: `/activity/${activityId}`,
        state: { channelId: props.match.params.channelId, activityId },
      });
    }
  }, [activityId, history, props.location, props.match.params.channelId]);

  useEffect(() => {
    if (!isLoading) return; //This effect only takes care of waiting for everything to get setup

    if (channel && role && activity) {
      if (channel.access_pattern === "public") {
        if (role === "owner" || role === "admin" || role === "author") {
          setCanEdit(true);
        }
        setIsLoading(false);
      } else {
        if (role === "none" && !isLtiUser && !isViewingFromSubscription) {
          if (useIsAnEmployee) {
            setIsLoading(false);
            setCanEdit(true);
          } else if (!useIsAnEmployee) {
            //If the channel is not public and your not a member or is not an lti user
            //Give 'em the boot! Not supposed to be here
            history.push("/dashboard");
          }
        } else {
          if (role === "owner" || role === "admin" || role === "author") {
            setCanEdit(true);
          }
          setIsLoading(false);
        }
      }
    } else if (channel && role && isNewActivityV2) {
      if (channel.access_pattern === "public") {
        if (role === "owner" || role === "admin" || role === "author") {
          setCanEdit(true);
        }
        setIsLoading(false);
      } else {
        if (role === "none" && !isLtiUser && !isViewingFromSubscription) {
          if (useIsAnEmployee) {
            setIsLoading(false);
            setCanEdit(true);
          } else if (!useIsAnEmployee) {
            //If the channel is not public and your not a member or is not an lti user
            //Give 'em the boot! Not supposed to be here
            history.push("/dashboard");
          }
        } else {
          if (role === "owner" || role === "admin" || role === "author") {
            setCanEdit(true);
          }
          setIsLoading(false);
        }
      }
    }
  }, [
    role,
    channel,
    isLoading,
    history,
    activity,
    canEdit,
    isLtiUser,
    isViewingFromSubscription,
    props.location.pathname,
    useIsAnEmployee,
    isNewActivityV2,
  ]);

  //Monitor for errors
  useEffect(() => {
    if (getChannelError) {
      setError("Something went wrong while getting channel " + getChannelError);
    }

    if (getActivityError && !isNewActivityV2) {
      setError(
        "Something went wrong while getting activity " + getActivityError
      );
    }
  }, [getChannelError, getActivityError, isNewActivityV2]);

  useEffect(() => {
    if (props.location?.state?.channelId) {
      setChannelId(props.location?.state?.channelId);
    } else if (props.match.params?.channelId) {
      setChannelId(props.match.params?.channelId);
    } else if (activity) {
      setChannelId(activity.channel);
    }
  }, [channelId, activity, props.location, props.match]);

  function onPlayerCreated(player) {
    const activityOptions = {
      title: activity.title ? activity.title : "",
    };

    player.LoadActivityFromString(activity.data, activityOptions);
  }

  function renderContent() {
    if (error) {
      return <PageError error={true} errorMessage={error} />;
    } else if (!isLoading) {
      if (activity?.data.includes("app_id")) {
        return (
          <>
            <CustomPlayerShadowDom
              size="normal"
              playerName={activity.app_mount_name}
              playerAssets={activity.app_files}
              shadowDom={true}
            />
            <ActivityDetails
              activityDescription={activity.description}
              activityId={activity.id}
              activityImage={activity.image}
              activityTitle={activity.title}
              canEditActivity={canEdit}
              channelAvatar={channel.image_url}
              channelId={activity.channel}
              channelName={activity.channel_name}
              history={history}
              isViewingFromSubscription={isViewingFromSubscription}
              subscriberId={subscriberId ?? channelId}
            />
          </>
        );
      } else if (activity?.isPlayerV2 || isNewActivityV2) {
        return (
          <Player2
            activity={activity}
            activityId={activityId}
            canAuthor={canEdit}
            channelAvatar={channel.image_url}
            channelId={channelId}
            channelName={channel.name}
            history={history}
            isViewingFromSubscription={isViewingFromSubscription}
            launchAs={player2ActivityLaunchAs}
            subscriberId={subscriberId ?? channelId}
          />
        );
      } else {
        return (
          <>
            <PlayerShadowDom
              onPlayerCreated={(player) => onPlayerCreated(player)}
              size="normal"
              playerOptions={{ showTitleBar: false }}
            />
            <ActivityDetails
              activityDescription={activity.description}
              activityId={activity.id}
              activityImage={activity.image}
              activityTitle={activity.title}
              canEditActivity={canEdit}
              channelAvatar={channel.image_url}
              channelId={activity.channel}
              channelName={activity.channel_name}
              history={history}
              isViewingFromSubscription={isViewingFromSubscription}
              subscriberId={subscriberId ?? channelId}
            />
          </>
        );
      }
    } else {
      return <PageLoadingIndicator />;
    }
  }

  return (
    <PageWrapper>
      <div className={style.container}>{renderContent()}</div>
    </PageWrapper>
  );
};

export default ActivityConsumption;
