import React from "react";

import { SectionHeadlineRow } from "../../Layout/Headings";
import { BasicInformationForm } from "./BasicInformationForm";

export const BasicInformation = ({defaultValues, editingProhibited}) => {
  const sectionTitle = "Basic information";
  

  return (
    <div style={{ marginBottom: "24px" }}>
      <SectionHeadlineRow
        title={sectionTitle}
        typographyStyle="subtitle1"
        typographyTheme="textSecondaryOnLight"
        tag="h2"
      />
      <BasicInformationForm
        defaultValues={defaultValues}
        editingProhibited={editingProhibited}
      />
    </div>
  );
};
