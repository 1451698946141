import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import ButtonIcon from '../ButtonIcon';
import Props from './props';

const AccountWrapper = ({
  closeHandle = () => {},
  children,
  title = 'Account',
  icon = 'close',
}) => (
  <Container className="Account">
    <Row className="Account__header">
      <Col xs={2} className="Account__header__close">
        <ButtonIcon
          icon={icon}
          iconSize="30px"
          onClick={closeHandle}
        />
      </Col>
      <Col xs={10} className="Account__header__title">
        <span>{title}</span>
      </Col>
    </Row>
    {children}
  </Container>
);

AccountWrapper.propTypes = Props;
export default AccountWrapper;
