import { call, select, put } from "redux-saga/effects";
import Actions from "../actions/activities";
import { LoginEnum } from "../Redux/Users/UserTypes";
import { CHANNELS_ADD_ACTIVITIES } from "../Redux/Channels/ChannelTypes";

export const insertActivity = (requestModule) =>
  function* insertActivitySaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { activityJSON, channelName, channelId, root_owner, isForPlayerV2 } = action.payload;

      if (!activityJSON) {
        throw new Error("action needs action.payload.activityJSON");
      }

      if (!channelName) {
        throw new Error("action needs action.payload.channelName");
      }

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }
      
      let newData = {
        data: activityJSON,
        channel_name: channelName,
        isPlayerV2: isForPlayerV2
      }

      if(root_owner) {
        newData.root_owner = root_owner
      }
      
      const req = yield call(requestModule, {
        data: newData,
        method: "post",
        path: `channels/${channelId}/activities`,
      });

      const activity = FormActivityFromResponseData(req.data);

      activity.data = yield processActivityData(activity.data)

      yield put(Actions.addActivities([activity]));

      yield put({
        type: CHANNELS_ADD_ACTIVITIES,
        payload: {
          channelId: channelId,
          activities: [activity.id],
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true, activity });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const fetchActivitiesForChannel = (requestModule) =>
  function* fetchActivitiesForChannelSaga(action) {

    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      const req = yield call(requestModule, {
        path: `channels/${channelId}/activities`,
      });

      const activityIds = [];
      const activities = [];
      req.data.activities.forEach((activityData) => {
        const activity = FormActivityFromResponseData(activityData);
        activities.push(activity);
        activityIds.push(activity.id);
      });

      yield put(Actions.addActivities(activities));

      yield put({
        type: CHANNELS_ADD_ACTIVITIES,
        payload: {
          channelId: channelId,
          activities: activityIds,
          allActivitiesFetched: true,
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const fetchUpdateActivityData = (requestModule) =>
  function* fetchUpdateActivityDataSaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { activityId, channelId, activityData, dataId } = action.payload;

      if (!activityId) {
        throw new Error("action needs action.payload.activityId");
      }

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      if (!activityData) {
        throw new Error("action needs action.payload.activityData");
      }

      if (!dataId) {
        throw new Error("action needs action.payload.dataId");
      }

      const req = yield call(requestModule, {
        data: {
          data: activityData,
        },
        method: "PATCH",
        path: `channels/${channelId}/activities/${activityId}/data/${dataId}`,
      });

      if (req.data.data.includes(".viveddata")) {
        let realData = yield basicFetch(req.data.data);
        if (realData) {
          req.data.data = JSON.stringify(realData);
        } else {
          throw new Error("error getting activity data");
        }
      }

      yield put(
        Actions.updateActivityData(req.data.SK, req.data.PK, req.data.data)
      );

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const fetchUpdateActivityMeta = (requestModule) =>
  function* fetchUpdateActivityMetaSaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const {
        activityId,
        channelId,
        title,
        description,
        image
      } = action.payload;

      if (!activityId) {
        throw new Error("action needs action.payload.activityId");
      }

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      const req = yield call(requestModule, {
        data: {
          title,
          description,
          image
        },
        method: "PATCH",
        path: `channels/${channelId}/activities/${activityId}/meta`,
      });

      yield put(
        Actions.updateActivityMeta(
          req.data.SK,
          req.data.title,
          req.data.description,
          req.data.image
        )
      );

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const deleteActivity = (requestModule) =>
  function* deleteActivitySaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { activityId, channelId } = action.payload;

      if (!activityId) {
        throw new Error("action needs action.payload.activityId");
      }

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      yield call(requestModule, {
        path: `channels/${channelId}/activities/${activityId}`,
        method: "DELETE",
      });

      yield put(Actions.removeActivity(activityId, channelId));

      if (action.callback) {
        yield call(action.callback, { success: true, channelId });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const getAllActivities = (requestModule) =>
  function* getAllActivitiesSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      let { offset, limit } = action.payload;

      if (!offset && offset !== 0) {
        throw new Error("action needs action.payload.offset");
      }

      if (!limit) {
        throw new Error("action needs action.payload.limit");
      }

      const req = yield call(requestModule, {
        path: `/channels/all/activities`,
        query: { offset, limit },
      });

      const total = req.data.total_count;
      let totalRetrieved = offset + limit;
      let allHaveBeenRetrieved = false;
      if (totalRetrieved >= total) {
        totalRetrieved = total;
        allHaveBeenRetrieved = true;
      }

      yield put(
        Actions.setActivitiesRetrieved(
          total,
          totalRetrieved,
          allHaveBeenRetrieved
        )
      );

      const activities = [];
      for (let index = 0; index < req.data.activities.length; index++) {
        const activityData = req.data.activities[index];
        let activity = FormActivityFromResponseData(activityData);

        activity.data = yield processActivityData(activity.data)

        activities.push(activity);
      }

      yield put(Actions.addActivities(activities));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const getActivity = (requestModule) =>
  function* getActivitySaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, activityId } = action.payload;

      if (!channelId) {
        throw new Error("channelId is required");
      }

      if (!activityId) {
        throw new Error("activityId is required");
      }

      const req = yield call(requestModule, {
        path: `channels/${channelId}/activities/${activityId}`,
      });
      const activity = FormActivityFromResponseData(req.data);
      
      activity.data = yield call( processActivityData, activity.data)

      if(activity.data.includes("app_id")) {
        let app_id = JSON.parse(activity.data).app_id
        const app = yield call(requestModule, {
          path: `apps/${app_id}`
        }); 
        if(app.data) {

          activity.app_files = app.data.app.files
          activity.app_mount_name = app.data.app.mount_name
        }
      }

      yield put(Actions.addActivities([activity]));

      yield put({
        type: CHANNELS_ADD_ACTIVITIES,
        payload: {
          channelId: activity.channel,
          activities: [activity.id],
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const FormActivityFromResponseData = (data) => {

  const result = {
    id: data.SK,
    channel: data.PK,
    hasDraft: false,
    hasPublished: false
  }

  if (data.hasOwnProperty("isPlayerV2")) {
    result.isPlayerV2 = data.isPlayerV2;
  }

  if (data.title) {
    result.title = data.title;
  }

  if (data.description) {
    result.description = data.description;
  }

  if (data.image) {
    result.image = data.image;
  }

  if (data.data) {
    result.data = data.data;
  }
  else if (data.draftData) {
    result.data = data.draftData;
  }

  if (data.dataId) {
    result.dataId = data.dataId;
  }
  else if (data.draftDataId) {
    result.dataId = data.draftDataId;
  }

  if (data.channel_name) {
    result.channel_name = data.channel_name;
  }

  if (data.customPlayer) {
    result.customPlayer = data.customPlayer;
  }

  if (data.root_owner) {
    result.root_owner = data.root_owner;
  }

  if (data.hasDraft) {
    result.hasDraft = data.hasDraft;
  }

  if (data.hasPublished) {
    result.hasPublished = data.hasPublished;
  } 
  
  return result;
};

export const duplicateActivity = (requestModule) =>
  function* duplicateActivitySaga(action) {
    try {
      //Ensure the user is logged in
      const state = yield select();
      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { activityId, root_owner, channelId } = action.payload;

      if (!activityId) {
        throw new Error("action needs action.payload.activityId");
      }

      if (!root_owner) {
        throw new Error("action needs action.payload.root_owner");
      }

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      const req = yield call(requestModule, {
        data: {
          root_owner
        },
        method: "post",
        path: `channels/${channelId}/activities/${activityId}/duplicate`,
      });


      const activity = FormActivityFromResponseData(req.data);

      activity.data = yield processActivityData(activity.data)

      yield put(Actions.addActivities([activity]));

      yield put({
        type: CHANNELS_ADD_ACTIVITIES,
        payload: {
          channelId: channelId,
          activities: [activity.id],
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true, activity });
      }
    } catch (err) {
      console.error(err)
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
};

async function basicFetch(url) {

  return await fetch(url)
    .then(async (response) => {

      return await response.json().then((body) => {
        if (response.status === 200) {
          return body;
        } else {
          return null;
        }
      });
    })
    .catch((err) => {
      console.error("API ERROR", err);
      return null;
    });
}

async function processActivityData(data) {
  if (data.includes(".viveddata") || data.includes(".json")) {
    let realData = await basicFetch(data);

    if (realData) {
      return JSON.stringify(realData);
    } else {
      throw new Error("error getting activity data");
    }
  } else {
    return data
  }
}
