import React from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { Typography } from "@rmwc/typography"; // Sass used in Main, css not needed here

import style from "./Collections.module.scss";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { CollectionGridItemCard2 } from "../../components/CollectionItem/CollectionItemCard";

import Actions from "../../Redux/actions";
import { AllBundlesCollectionId } from "../../utils/helpers";
import { FlexGrid } from "../../components/Layout/Layout";

class Collections extends React.Component {
  state = {
    isFetching: true, //Lets us know if we are waiting on the API
    error: false, //Lets us know that something went wrong
    errorMessage: "" //A message about what went wring
  };

  componentDidMount() {
    if (this.props.collections.length === 0) {
      this.fetchCollectionBundle(0);
    } else {
      this.setState({ isFetching: false });
    }
  }

  getNextCollectionList = () => {
    if (this.state.isFetching) {
      // already fetching more
      return;
    }

    this.setState({ isFetching: true });
    this.fetchCollectionBundle(this.props.collectionsRetrieved);
  };

  fetchCollectionBundle = collectionsRetrieved => {
    this.setState({ isFetching: true });

    this.props.fetchCollectionBundle(
      AllBundlesCollectionId,
      collectionsRetrieved,
      25,
      resp => {
        if (resp.success) {
          this.setState({ isFetching: false });
        } else {
          this.setState({
            isFetching: false,
            error: true,
            errorMessage: resp.msg
          });
        }
      }
    );
  };

  renderFetchingAndButton = () => {
    if (this.state.isFetching) {
      return (
        <Typography use="body2" tag="p" style={{ textAlign: "center" }}>
          Loading more models...
        </Typography>
      );
    } else if (!this.props.allHaveBeenRetrieved) {
      return (
        <div>
          <button onClick={this.getNextCollectionList}>Load more</button>
        </div>
      );
    } else return <></>;
  };

  renderCollections = () => {
    return this.props.collections.map(collection => (
      <React.Fragment key={collection.id}>
        <CollectionGridItemCard2
          image_url={collection.image}
          name={collection.title}
          primaryActionText="View"
          primaryAction={() =>
            this.props.history.push(`/models/${collection.id}`)
          }
        />
      </React.Fragment>
    ));
  };

  renderError = () => {
    return (
      <Alert
        variant="danger"
        onClose={() => this.props.history.push("/")}
        dismissible
      >
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>Error message: {this.state.errorMessage}</p>
      </Alert>
    );
  };

  renderConditional() {
    if (this.state.error) {
      return this.renderError();
    } else {
      return (
        <>
          {this.renderCollections()}
          {this.renderFetchingAndButton()}
        </>
      );
    }
  }

  renderCollectionsContent = () => {
    return (
      <div className={style.content}>
        <Typography use="headline6" tag="h1" className={style.pageTitle}>
          Models
        </Typography>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.getNextCollectionList}
          hasMore={!this.props.allHaveBeenRetrieved}
        >
          <FlexGrid>{this.renderConditional()}</FlexGrid>
        </InfiniteScroll>
      </div>
    );
  };

  render() {
    return (
      <PageWrapper>
        {this.renderCollectionsContent()}
      </PageWrapper>
    );
  }
}

const MapStateToProps = (state, ownProps) => {
  const { allHaveBeenRetrieved, collectionsRetrieved } = state.Collections;

  return {
    collections: Object.values(state.Collections.list),
    allHaveBeenRetrieved,
    collectionsRetrieved,
    history: ownProps.history
  };
};

export default connect(MapStateToProps, {
  fetchCollectionBundle: (bundleId, offset, limit, callback) =>
    Actions.Collections.fetchCollectionBundle(bundleId, offset, limit, callback)
})(Collections);
