import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Typography } from "@rmwc/typography"; //Using Sass in Main, no css needed.

import PageError from "../../components/Error/PageError";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import {
  ActivityNewToolbar,
  ActivityNewToolbarFixedOffset,
} from "../../components/ActivityNewToolbar/ActivityNewToolbar";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import { ContentContainer, FlexGrid } from "../../components/Layout/Layout";
import { GenericCard } from "../../components/Cards/GenericCard/GenericCard";

import useChannelModels from "../../hooks/Channel/useChannelModels";
import { useChannel } from "../../hooks/Channel/useChannel";
import { sortInAllDirections } from "../../utils/helpers";

export const ActivityNew = () => {

  //Props
  const { channelId } = useParams();

  //States
  const [isReady, setIsReady] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filterKeys, setFilterKeys] = useState([
    { name: "All channels", key: "all" },
  ]);
  const [sortDirection, setSortDirection] = useState(1);
  const [modelsListNew, setModelsListNew] = useState([]);

  const [selectedItem, setSelectedItem] = useState(""); //The selected collection
  const [selectedItemChannelId, setSelectedItemChannelId] = useState("");
  const [error, setError] = useState(false); //Lets us know that something went wrong
  const [errorMessage, setErrorMessage] = useState(""); //A message about what went wrong

  const { channelModels, fetching, channelModelsError } = useChannelModels(
    channelId
  );
  const channel = useChannel(channelId).channel;
  const history = useHistory();

  /// Hooks
  useEffect(() => {
    if (channelModels) {
      setIsReady(true);
    }
  }, [channelModels]);

  useEffect(() => {
    if (channelModelsError && String(channelModelsError).length > 0) {
      setError(true);
      setErrorMessage(channelModelsError);
    }
  }, [channelModelsError]);

  // Effect to build the array of filter keys if applicable
  useEffect(() => {
    if (!channel || !channelModels) {
      return;
    }

    let filterKeysToAdd = [
      { name: channel.name, key: channel.id, image: channel.image_url },
    ]; // init with own channel
    let keysAdded = [channel.id];

    const subscriptions = channel.subscriptions;
    if (subscriptions !== null && subscriptions !== undefined) {
      subscriptions.forEach((sub) => {
        if (!sub.isExpired) {
          // subscription is still valid
          const name = sub.name;

          if (name) {
            const image = sub?.image;
            filterKeysToAdd.push({ name, key: sub.id, image });
            keysAdded.push(sub.id);
          }
        }
      });
    }

    if (filterKeysToAdd.length === 1) {
      // Don't display filters if there's only one
      setFilterKeys([]);
    } else {
      // More than one channel
      let newFilterKeys = [{ name: "All channels", key: "all" }];
      filterKeysToAdd.forEach((k) => {
        newFilterKeys.push(k);
      });
      setFilterKeys(newFilterKeys);
    }
  }, [channelModels, channel]);

  // Effect to filter and sort the models list
  useEffect(() => {
    const models = [...channelModels];

    // If filter is applied, remove any from list
    let workingList = [];
    if (selectedFilter === "all" || filterKeys.length <= 2) {
      //No filter needed, workingList is all models
      workingList = [...models];
    } else {
      //  Filter models and add to workingList
      models.forEach((m) => {
        if (m.owner === selectedFilter) {
          workingList.push(m);
        }
      });
    }

    // If sort direction, sort the list
    let listSorted = [];
    if (sortDirection) {
      listSorted = sortInAllDirections(workingList, "title", sortDirection);
    } else {
      // If no sort direction, don't sort, just use list as is.
      listSorted = [...workingList];
    }

    // Set final filtered and sorted list
    setModelsListNew(listSorted);
  }, [channelModels, selectedFilter, sortDirection, filterKeys]);

  /// Event Handler Functions
  /**
   * Handles setting the state of the selected item
   * @param {string} collectionId The id of the selected collection
   * @param {string} rootChannelId The id of the channel which owns the selected collection
   */
  const handleOnSelect = (collectionId, rootChannelId) => {
    if (
      selectedItem !== collectionId ||
      selectedItemChannelId !== rootChannelId
    ) {
      setSelectedItem(collectionId);
      setSelectedItemChannelId(rootChannelId);
    } else {
      setSelectedItem("");
      setSelectedItemChannelId("");
    }
  };

  /// Render Helper Functions
  /**
   * The content displayed when the list is empty
   */
  const emptyListContent = () => {
    return (
      <Typography
        use="body1"
        tag="h2"
        style={{ textAlign: "center", flexGrow: "1", paddingTop: "24px" }}
      >
        This channel doesn't have any models yet.
      </Typography>
    );
  };

  /**
   * Builds an array of list items in JSX
   */
  const buildListItems = () => {
    if (modelsListNew) {
      return modelsListNew.map((m) => {
        if (m.isHidden) {
          return null;
        }

        let modelChannel = channel;
        if (channel && channel.id !== m.owner) {
          channel.subscriptions.forEach((sub) => {
            if (m.owner === sub.id) {
              sub.image_url = sub.image;
              modelChannel = sub;
            }
          });
        }
        return (
          <React.Fragment key={Math.random()}>
            <GenericCard
              title={m.title}
              image={m.image}
              authorImage={modelChannel ? modelChannel.image_url : ""}
              authorName={modelChannel ? modelChannel.name : ""}
              primaryAction={() => handleOnSelect(m.id, m.owner)}
              selected={
                selectedItem === m.id && selectedItemChannelId === m.owner
              }
            />
          </React.Fragment>
        );
      });
    }
  };

  const renderContent = () => {
    if (error) {
      return <PageError error={true} errorMessage={errorMessage} />;
    } else if (!fetching && isReady && channelModels.length === 0) {
      // If no activities, build no list notice instead of an empty list
      return emptyListContent();
    } else if (modelsListNew && !fetching & isReady) {
      return (
        <>
          <ActivityNewToolbar
            filterKeys={filterKeys}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            primaryAction={
              selectedItem && selectedItemChannelId
                ? () =>
                    history.push(`/channel/${channelId}/new/${selectedItem}`, {
                      root_owner: selectedItemChannelId,
                    })
                : null
            }
          />
          <ActivityNewToolbarFixedOffset />
          <ContentContainer>
            <FlexGrid style={{ marginTop: "8px" }}>{buildListItems()}</FlexGrid>
          </ContentContainer>
        </>
      );
    } else {
      return <PageLoadingIndicator />;
    }
  };

  return <PageWrapper fixedAppBar={true}>{renderContent()}</PageWrapper>;
};

export default ActivityNew;
