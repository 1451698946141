import React from "react";
import PropTypes from "prop-types";

import NewModelShadowDom from "../../components/PlayerShadowDom/NewModelShadowDom";

export const NewChannelModelPlayer = (props) => {
  const channelId = props.channelId;
  const channelName = props.channelName;
  const userToken = props.userToken;
  const history = props.history;
  const hostHandler = props.hostHandler;
  const dispatchType = props.dispatchType;

  return (
    <>
      <NewModelShadowDom
        size="freeplay"
        channelId={channelId}
        channelName={channelName}
        userToken={userToken}
        history={history}
        hostHandler={hostHandler}
        dispatchType={dispatchType}
      />
    </>
  );
};

NewChannelModelPlayer.propTypes = {
  channelId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  history: PropTypes.any,
  userToken: PropTypes.string.isRequired,
  hostHandler: PropTypes.any,
  dispatchType: PropTypes.string.isRequired,
};
