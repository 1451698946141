import React from "react";

function VivedIconCustomSharePerson(props) {
  const fill = props.fill || "currentColor";
  const width = props.width || "100%";
  const height = props.height || "100%";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <path d="M7.74 12.84A5.73 5.73 0 0 0 2 18.57h11.47a5.72 5.72 0 0 0-5.73-5.73zm0-1.06a3.18 3.18 0 1 0-3.18-3.17 3.18 3.18 0 0 0 3.18 3.17zm13.06 1.46a1.17 1.17 0 0 0-.95.48l-3.59-1.58a1.17 1.17 0 0 0 0-.43l3.59-1.58a1.19 1.19 0 0 0 .95.48 1.2 1.2 0 1 0-1.2-1.2 1.49 1.49 0 0 0 0 .21L16 11.2a1.2 1.2 0 1 0 0 1.44l3.58 1.58a1.5 1.5 0 0 0 0 .22 1.2 1.2 0 1 0 1.2-1.2z" />
      </g>
    </svg>
  );
}

export default VivedIconCustomSharePerson;
