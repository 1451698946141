import * as TYPES from "./CollectionTypes";

const initialState = {
  list: {},
  totalCollectionCount: 0,
  collectionsRetrieved: 0,
  allHaveBeenRetrieved: false,
  bundles: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.COLLECTIONS_SET_COLLECTIONS:
      if (!action.hasOwnProperty("payload")) {
        throw new Error(
          "COLLECTIONS_SET_COLLECTIONS action needs action.payload"
        );
      }

      if (!action.payload.collections) {
        throw new Error(
          "COLLECTIONS_SET_COLLECTIONS action needs action.payload.collections"
        );
      }

      return SetCollections(state, action.payload.collections);

    case TYPES.COLLECTIONS_SET_BUNDLES:
      return SetBundles(state, action.payload.bundles);

    case TYPES.COLLECTIONS_SET_RETRIEVED:
      if (!action.hasOwnProperty("payload")) {
        throw new Error(
          "COLLECTIONS_SET_RETRIEVED action needs action.payload"
        );
      }

      if (!action.payload.total && action.payload.total !== 0) {
        throw new Error("total cannot be null or empty");
      }

      if (!action.payload.offset && action.payload.offset !== 0) {
        throw new Error("offset cannot be null or empty");
      }

      if (
        action.payload.allHaveBeenRetrieved === null ||
        action.payload.allHaveBeenRetrieved === undefined
      ) {
        throw new Error("allHaveBeenRetrieved cannot be null or undefined");
      }

      return {
        ...state,
        totalCollectionCount: action.payload.total,
        collectionsRetrieved: action.payload.offset,
        allHaveBeenRetrieved: action.payload.allHaveBeenRetrieved,
      };

    case TYPES.COLLECTION_SET_COLLECTION:
      if (!action.hasOwnProperty("payload")) {
        throw new Error(
          "COLLECTION_SET_COLLECTION action needs action.payload"
        );
      }

      if (!action.payload.collection) {
        throw new Error("collection cannot be null or empty");
      }

      const {collection} = action.payload;

      const newCollectionList = { ...state.list };
      newCollectionList[collection.id] = collection;

      return {
        ...state,
        list: newCollectionList
      };

    case TYPES.COLLECTION_SET_BUNDLE:
      if (!action.hasOwnProperty("payload")) {
        throw new Error(
          "COLLECTION_SET_BUNDLE action needs action.payload"
        );
      }

      if (!action.payload.bundle) {
        throw new Error("bundle cannot be null or empty");
      }

      const {bundle} = action.payload;

      const newBundleList = { ...state.bundles };
      newBundleList[bundle.id] = bundle;

      return {
        ...state,
        bundles: newBundleList
      };

    default:
      return state;
  }
};

export default reducer;

function SetCollections(state, collections) {

  const newCollectionList = { ...state.list };
  collections.forEach((collection) => {
    newCollectionList[collection.id] = {
      ...state.list[collection.id],
      ...collection,
    };
  });

  return {
    ...state,
    list: newCollectionList,
  };
}

function SetBundles(state, bundles) { 
  const newBundleList = { ...state.bundles };
  bundles.forEach((bundle) => {
    newBundleList[bundle.id] = {
      ...state.bundles[bundle.id],
      ...bundle,
    };
  });

  return {
    ...state,
    bundles: newBundleList,
  };
}
