import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

export const useUserGroups = () => {
  const [groups, setGroups] = useState(null);
  const user = useSelector((state) => state.User);

  useEffect(() => {
    if (user.loginStatus === "isLoggedIn") {
        setGroups([...user.groups]);
    } else if (user.loginStatus === "isNotLoggedIn") {
        setGroups([]); //A non logged in user has no groups
    }
  }, [user.loginStatus, user.groups]);

  return groups;
};
