import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Typography } from "@rmwc/typography"; // Sass imported in main. CSS not needed.

import store from "../../../store";
import { EditActivityMetadataDialog } from "../../../components/Dialog/EditActivityMetadataDialog/EditActivityMetadataDialog";
import { EndPoint } from "../../../utils/apiClient";
import { FlexGrid } from "../../../components/Layout/Layout";
import { GenericCard } from "../../../components/Cards/GenericCard/GenericCard";
import {
  GetActivityLinkUrl,
  GetActivityLinkUrlNoAuth,
  GetEmbedActivityUrl,
} from "../../../LTI";
import { ListFilter } from "../ListFilter/ListFilter";
import { exportActivity } from "../../../Redux/Activities/ActivityActions";
import { snackbarQueue } from "../../../components/Snackbar/snackbarQueue";
import { sortInAllDirections } from "../../../utils/helpers";
import { useChannel } from "../../../hooks/Channel/useChannel";
import { useLtiUrlParameters } from "../../../hooks/LTI/useLtiUrlParameters";
import { useUserIsVivedEmployee } from "../../../hooks/User/useUserIsVivedEmployee";

const defaultActivityMetaToEdit = {
  id: "",
  channelId: "",
  title: "",
  description: "",
  image: "",
};
export const ActivityList = ({
  activities,
  isLtiUser,
  channelId,
  subscriberId,
  duplicateActivity,
  isViewingFromSubscription,
  deleteActivity,
}) => {
  // State
  const [renderMetaDialog, setRenderMetaDialog] = useState(false);
  const [editMetaDialogOpen, setEditMetaDialogOpen] = useState(false);
  const [editMetaProcessing, setEditMetaProcessing] = useState(false);
  const [activityMetaToEdit, setActivityMetaToEdit] = useState(
    defaultActivityMetaToEdit
  );
  // Hooks
  const UserIsEmployee = useUserIsVivedEmployee();

  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filterKeys, setFilterKeys] = useState([
    { name: "All channels", key: "all" },
  ]);
  const [sortDirection, setSortDirection] = useState(1);
  const [activitiesList, setActivitieslist] = useState([]); // The final list to be displayed

  const {
    ltiReturnUrl,
    ltiSupportsUrl,
    ltiSupportsContentItemIframe,
    ltiSupportsContentItemLaunchUrl,
    canvasCourseId,
    canvasApiDomain,
  } = useLtiUrlParameters();
  let channel = useChannel(channelId).channel;
  const apiEndpoint = EndPoint();

  // Effect to build the array of filter keys if applicable
  useEffect(() => {
    if (!channel) {
      return;
    }

    let filterKeysToAdd = [
      { name: channel.name, key: channel.id, image: channel.image_url },
    ]; // init with own channel
    let keysAdded = [channel.id];
    activities.forEach((a) => {
      if (!keysAdded.includes(a.channel)) {
        const subscriptions = channel.subscriptions;

        if (subscriptions !== null && subscriptions !== undefined) {
          subscriptions.forEach((element) => {
            if (element.id === a.channel) {
              // found the subscription
              if (element.isActive && !element.isExpired) {
                // subscription is still valid
                const name = element.name;
                if (name !== null && name !== undefined) {
                  const image = element?.image;
                  filterKeysToAdd.push({ name, key: a.channel, image });
                  keysAdded.push(a.channel);
                }
              }
            }
          });
        }
      }
    });

    if (filterKeysToAdd.length === 1) {
      // Don't display filters if there's only one
      setFilterKeys([]);
    } else {
      // More than one channel
      let newFilterKeys = [{ name: "All channels", key: "all" }];
      filterKeysToAdd.forEach((k) => {
        newFilterKeys.push(k);
      });
      setFilterKeys(newFilterKeys);
    }
  }, [activities, channel]);

  // Effect to filter and sort the activities list
  useEffect(() => {
    // If filter is applied, remove any from list
    let workingList = [];
    if (selectedFilter === "all" || filterKeys.length <= 2) {
      //No filter needed, workingList is all activities
      workingList = [...activities];
    } else {
      //  Filter activities and add to workingList
      activities.forEach((a) => {
        if (a.channel === selectedFilter) {
          workingList.push(a);
        }
      });
    }

    // If sort direction, sort the list
    let listSorted = [];
    if (sortDirection) {
      listSorted = sortInAllDirections(
        workingList,
        "title",
        sortDirection,
        "channel_name"
      );
    } else {
      // If no sort direction, don't sort, just use list as is.
      listSorted = [...workingList];
    }

    // Set final filtered and sorted list
    setActivitieslist(listSorted);
  }, [activities, selectedFilter, sortDirection, filterKeys]);

  const onOpenEditActivityMetaDialog = (activity) => {
    setActivityMetaToEdit({
      id: activity.id,
      channelId: activity.channel,
      title: activity.title,
      description: activity.description,
      image: activity.image,
    });
    setRenderMetaDialog(true);
    setEditMetaDialogOpen(true);
  };
  const onCloseEditActivityMetaDialog = () => {
    setEditMetaDialogOpen(false);
    setActivityMetaToEdit(defaultActivityMetaToEdit);
    setRenderMetaDialog(false);
  };

  /**
   * The content displayed when the list is empty
   */
  const emptyListContent = () => {
    return (
      <Typography
        use="body1"
        tag="h2"
        style={{ textAlign: "center", flexGrow: "1", paddingTop: "24px" }}
      >
        This channel doesn't have any activities yet.
      </Typography>
    );
  };

  /**
   * Builds the array of action item objects
   */
  function setUpCardActions(activity) {
    let result = [];
    function setUpLTIActions() {
      // LTI Embed Activity with custom built url for canvas (/courses/{courseId}/external_tools/retrieve)
      if (ltiSupportsContentItemIframe && canvasCourseId && canvasApiDomain) {
        result.push({
          label: "Embed Activity",
          action: () => {
            const contentUrl = new URL("/ltiaccess", `${apiEndpoint}`);
            contentUrl.searchParams.set("channelId", channelId);
            contentUrl.searchParams.set("activityId", activity.id);
            contentUrl.searchParams.set("embed", 1);

            const ltiToolsUrl = new URL(
              `/courses/${canvasCourseId}/external_tools/retrieve`,
              `https://${canvasApiDomain}`
            );
            ltiToolsUrl.searchParams.set("display", "borderless");
            ltiToolsUrl.searchParams.set("url", contentUrl.href);

            const finalRedirectUrl = new URL(ltiReturnUrl);
            finalRedirectUrl.searchParams.set("return_type", "iframe");
            finalRedirectUrl.searchParams.set("frameborder", 0);
            finalRedirectUrl.searchParams.set("allowfullscreen", true);
            finalRedirectUrl.searchParams.set("title", activity.title);
            finalRedirectUrl.searchParams.set("width", "100%");
            finalRedirectUrl.searchParams.set("height", "640px");
            finalRedirectUrl.searchParams.set("url", ltiToolsUrl.href);

            const embedActivityUrl = GetEmbedActivityUrl(
              channelId,
              activity.id,
              activity.title,
              ltiReturnUrl,
              canvasCourseId,
              canvasApiDomain
            );

            window.location.href = embedActivityUrl.href;
          },
        });
      }

      // LTI lti_launch_url
      if (ltiSupportsContentItemLaunchUrl) {
        result.push({
          label: "Link to Activity",
          action: () => {
            const contentUrl = new URL("/ltiaccess", `${apiEndpoint}`);
            contentUrl.searchParams.set("channelId", channelId);
            contentUrl.searchParams.set("activityId", activity.id);

            const finalRedirectUrl = new URL(ltiReturnUrl);
            finalRedirectUrl.searchParams.set("return_type", "lti_launch_url");
            finalRedirectUrl.searchParams.set("display", "borderless");
            finalRedirectUrl.searchParams.set("title", activity.title);
            finalRedirectUrl.searchParams.set("text", activity.title);
            finalRedirectUrl.searchParams.set("target", "_blank");
            finalRedirectUrl.searchParams.set("url", contentUrl.href);

            const activityLinkUrl = GetActivityLinkUrl(
              channelId,
              activity.id,
              activity.title,
              ltiReturnUrl
            );

            window.location.href = activityLinkUrl.href;
          },
        });
        // LTI url (just a normal unauthenticated link)
      } else if (ltiSupportsUrl) {
        result.push({
          label: "Link to Activity (auth required)",
          action: () => {
            const contentUrl = new URL(
              `${window.location.protocol}//${window.location.hostname}/activity/${activity.id}`
            );

            const finalRedirectUrl = new URL(ltiReturnUrl);
            finalRedirectUrl.searchParams.set("return_type", "url");
            finalRedirectUrl.searchParams.set("title", activity.title);
            finalRedirectUrl.searchParams.set("text", activity.title);
            finalRedirectUrl.searchParams.set("target", "_blank");
            finalRedirectUrl.searchParams.set("url", contentUrl.href);

            const activityLinkUrl = GetActivityLinkUrlNoAuth(
              activity.id,
              activity.title,
              ltiReturnUrl
            );

            window.location.href = activityLinkUrl.href;
          },
        });
      }
    }

    if (isLtiUser && !isViewingFromSubscription) {
      setUpLTIActions();
    } else if (isViewingFromSubscription && activity.canDuplicate) {
      result.push({
        // Duplicate activity
        label: "Duplicate to my channel",
        action: () => duplicateActivity(activity.id, channelId, subscriberId),
      });
    } else if (!isViewingFromSubscription && activity.canDuplicate) {
      result.push({
        // Duplicate activity
        label: "Duplicate to my channel",
        action: () =>
          duplicateActivity(activity.id, activity.channel, channelId),
      });
    } else if (activity.canEdit_Delete) {
      if (!activity.isLocked && !activity.isPlayerV2) {
        result.push({
          // Edit activity
          label: "Edit",
          action: {
            pathname: `/activity/${activity.id}/edit`,
            state: {
              channelId: channelId,
              isViewingFromSubscription: isViewingFromSubscription,
            },
          },
        });
      } else if (
        !activity.isLocked &&
        activity.isPlayerV2 &&
        activity.hasPublished &&
        activity.hasDraft
      ) {
        result.push({
          // View draft activity (player2)
          label: "View draft",
          action: {
            pathname: `/activity/${activity.id}`,
            state: {
              channelId: channelId,
              isViewingFromSubscription: isViewingFromSubscription,
              launchAs: "draft",
            },
          },
        });
      }

      result.push({
        // Open EditActivityMetadataDialog
        label: "Edit details",
        action: () => onOpenEditActivityMetaDialog(activity),
      });

      result.push({
        // Delete activity
        label: "Delete",
        action: () => deleteActivity(activity.id, activity.channel),
      });
    }

    if (UserIsEmployee) {
      function exportCallBack(result) {
        if (result.success) {
          if (result.activity.isPlayerV2) {
            snackbarQueue.notify({
              timeout: 3000,
              title: <b>Successfully Exported Activity for PLAYER TWO</b>,
              body: `${result.activity.title} activity was export to your computer.`,
            });
          } else {
            snackbarQueue.notify({
              timeout: 3000,
              title: <b>Successfully Exported Activity</b>,
              body: `${result.activity.title} activity was export to your computer.`,
            });
          }
        } else {
          snackbarQueue.notify({
            timeout: 3000,
            title: <b>Failed to Export Activity!</b>,
          });
        }
      }

      result.push({
        label: "Export Activity",
        action: () => {
          snackbarQueue.notify({
            title: <b>Exporting Activity</b>,
            timeout: 1000,
          });
          store.dispatch(
            exportActivity(activity.id, activity.channel, exportCallBack)
          );
        },
      });
    }

    return result;
  }

  /**
   * Builds an array of list items in JSX
   */
  const buildListItems = () => {
    // Build aray of list items and returnsjsx
    return activitiesList.map((activity, index) => {
      let actions = setUpCardActions(activity);
      if (activity.isHidden) {
        return null;
      }

      let activityChannel = channel;
      if (channel && channel.id !== activity.channel) {
        channel.subscriptions.forEach((sub) => {
          if (activity.channel === sub.id) {
            sub.image_url = sub.image;
            activityChannel = sub;
          }
        });
      }

      // Define the linkTo prop which is used as the card's primary action
      let linkTo = {};
      if (activity.isPlayerV2) {
        let launchAs = "";
        if (activity.hasDraft && !activity.hasPublished) {
          launchAs = "draft";
        } else launchAs = "published";

        linkTo = {
          pathname: `/activity/${activity.id}`,
          state: {
            channelId,
            isViewingFromSubscription,
            subscriberId,
            launchAs,
          },
        };
      } else {
        linkTo = {
          pathname: `/activity/${activity.id}`,
          state: { channelId, isViewingFromSubscription, subscriberId },
        };
      }

      let hasDraftOnly = false;
      if (activity.isPlayerV2) {
        if (activity.hasDraft && !activity.hasPublished) {
          hasDraftOnly = true;
        }
      }

      // Return the JSX for this list item to be added to the listItems array
      return (
        <React.Fragment key={activity.id}>
          <GenericCard
            draftOnly={hasDraftOnly}
            title={activity.title}
            image={activity.image}
            linkTo={linkTo}
            actions={actions}
            isLocked={activity.isLocked}
            authorImage={activityChannel ? activityChannel.image_url : ""}
            authorName={activityChannel ? activityChannel.name : ""}
            authorLink={
              activityChannel
                ? {
                    pathname: `/channel/${activityChannel.id}`,
                    state: { channelId, isViewingFromSubscription },
                  }
                : {}
            }
          />
        </React.Fragment>
      );
    });
  };

  const renderEditMetaDialog = () => {
    if (renderMetaDialog) {
      return (
        <EditActivityMetadataDialog
          open={editMetaDialogOpen}
          onClose={onCloseEditActivityMetaDialog}
          setProcessing={setEditMetaProcessing}
          processing={editMetaProcessing}
          renderToPortal={true}
          activityMeta={activityMetaToEdit}
        />
      );
    }
  };

  const renderContent = () => {
    if (!activities || activities.length === 0) {
      // If no activities, build no list notice instead of an empty list
      return emptyListContent();
    } else {
      return (
        <>
          <ListFilter
            keys={filterKeys}
            selected={selectedFilter}
            setSelected={setSelectedFilter}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
          <FlexGrid style={{ marginTop: "8px" }}>{buildListItems()}</FlexGrid>
          {renderEditMetaDialog()}
        </>
      );
    }
  };

  return renderContent();
};

ActivityList.propTypes = {
  /**
   * The array containing the activities to be displayed in the list.
   */
  activities: PropTypes.array,

  /**
   * The unique id of the channel. Required.
   */
  channelId: PropTypes.string.isRequired,
  /**
   * The function that duplicates the subsription activity onto the subscriber channel.
   */
  duplicateActivity: PropTypes.func,
  /**
   * Bool indicating if the user is an LTI user.
   */
  isLtiUser: PropTypes.bool,
  /**
   * If true, indicates this activity list is being viewed on a subscription channel
   */
  isViewingFromSubscription: PropTypes.bool,
};
