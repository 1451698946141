import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from "@rmwc/top-app-bar";
import "@rmwc/top-app-bar/styles";
import { Elevation } from "@rmwc/elevation";
import "@rmwc/elevation/styles";
import { Theme } from "@rmwc/theme"; // Using SASS in main instead of css here

import style from "./AppBar.module.scss";

import globalStyle from "../../styles/global.module.scss";
import VivedCorpLogoIcon from "../../assets/nucleo/icons/VivedCorpLogoIcon";
import { usePlayerState } from "../../hooks/usePlayerState";
import AccountMenuMobile from "../AccountMenu/AccountMenuMobile"
import AccountMenu from "../AccountMenu/AccountMenu"
import { useIsLTIUser } from "../../hooks/User/useIsLTIUser";

const AppBar = ({
  title,
  userMeta,
  elevation,
  fixed,
  history,
  isSignedIn,
  className,
}) => {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const device = usePlayerState();
  const [isMobile, setIsMobile] = useState(false);
  const isLTIUser = useIsLTIUser();

  if (!userMeta.fullName || String(userMeta.fullName).trim().length <= 0) {
    userMeta.fullName = userMeta.email;
  }

  //Effect hook for mobile setting
  useEffect(() => {
    let mobile = false;
    if (device.type === "mobile") {
      mobile = true;
    } else if (device.windowResolution.x < 600) {
      //We are not on a mobile device but we are in a small window. Go ahead and use it
      mobile = true;
    }

    if(mobile !== isMobile) {
      setIsMobile(mobile);
    }

  }, [device.type, device.windowResolution.x, isMobile]);

  function openAccountMenu() {
    setIsAccountMenuOpen(true);
  }

  function closeAccountMenu() {
    setIsAccountMenuOpen(false)
  }

  const renderContent = () => {
    if(!isLTIUser) {
      if(isMobile ) {
        return (
          <AccountMenuMobile
            isSignedIn={isSignedIn}
            isAccountMenuOpen={isAccountMenuOpen}
            openAccountMenu={openAccountMenu}
            closeAccountMenu={closeAccountMenu}
            history={history}
            userMeta={userMeta}
          />
        )
      } else {
        return (
          <AccountMenu
            isSignedIn={isSignedIn}
            isAccountMenuOpen={isAccountMenuOpen}
            openAccountMenu={openAccountMenu}
            closeAccountMenu={closeAccountMenu}
            history={history}
            userMeta={userMeta}
          />
        )
      }
    }
  }

  return (
    <Elevation wrap transition z={elevation ? elevation : 1}>
      <TopAppBar
        style={{ backgroundColor: "var(--mdc-theme-surface)" }}
        fixed={fixed}
        className={[style.container, className].join(" ")}
      >
        <Theme use={["onSurface", "surface"]} wrap>
          <TopAppBarRow>
            <TopAppBarSection alignStart>
              <TopAppBarTitle>
                <Link to="/" className={globalStyle.linkNoStyle}>
                  <VivedCorpLogoIcon
                    width="24px"
                    height="24px"
                    className={style.logo}
                  />
                  {!title ? "VLP" : null}
                </Link>
                {title}
              </TopAppBarTitle>
            </TopAppBarSection>
            <TopAppBarSection alignEnd>
              {renderContent()}
            </TopAppBarSection>
          </TopAppBarRow>
        </Theme>
      </TopAppBar>
    </Elevation>
  );
};

AppBar.defaultProps = {
  elevation: 1,
  fixed: false,
  isSignedIn: false,
};

AppBar.propTypes = {
  /**
   * Class names to be appended to the classes in the root element of
   * the component. Multiple classes can be passed as a single string. Optional.
   */
  className: PropTypes.string,
  /**
   * The elevation of the App Bar's shadow as defined by Material Design Standards.
   * Doesn't affect the z-index property. Only accept integers from 0-24.
   * Optional. Default Value: `1`.
   */
  elevation: PropTypes.number,
  /**
   * When true, sets the App Bar position to fixed so it is always
   * displayed on the page. When false the App Bar will scroll away
   * with the rest of the page when scrolling.
   * Optional. Default Value: `false`.
   */
  fixed: PropTypes.bool,
  /**
   * The history object from react-router. Required.
   */
  history: PropTypes.object.isRequired,
  /**
   * When true, the App Bar will be displayed in the signed in state.
   * Optional. Default Value: `false`.
   */
  isSignedIn: PropTypes.bool,
  /**
   * The text to be displayed in the App Bar. Optional. No default value.
   */
  title: PropTypes.string,
  /**
   * An object containing all the properties about the User required by the AppBar. For details on the data look at the useUserState hook. Required.
   */
  userMeta: PropTypes.object.isRequired,
};

export default AppBar;
