import React, { useEffect } from "react";

// import style from './Admin.module.scss';

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { ContentContainer } from "../../components/Layout/Layout";
import { SectionHeadlineRow } from "../../components/Layout/Headings";
import { ChannelsTable } from "../../components/Admin/ChannelsTable/ChannelsTable";
import { useAllChannels } from "../../hooks/Channel/useAllChannels";
import { useUserGroups } from "../../hooks/User/useUserGroups";
import { useHistory } from "react-router";

const Admin = () => {
  /// Hooks

  const userGroups = useUserGroups();
  const history = useHistory();
  const [
    allChannels,
    allChannelsError,
    isFetching,
    isProcessing,
  ] = useAllChannels();

  const channels = {
    list: allChannels,
    fetching: isFetching,
    error: allChannelsError,
    processing: isProcessing,
  };

  useEffect(() => {
    if (userGroups) {
      if (!userGroups.includes("Admin")) {
        history.push("/");
      }
    }
  }, [userGroups, history]);

  /// Constants & other
  const pageTitle = "VLP Administration";

  /// Event handler functions
  const sortChannels = (column, sortdir) => {
  };

  /// Render helper functions
  const renderContent = () => {
    return (
      <ContentContainer>
        <SectionHeadlineRow title="Channel Administration" />
        <ChannelsTable channels={channels} onSort={sortChannels} />
      </ContentContainer>
    );
  };

  /// Render Page function
  const renderPage = () => {
    return renderContent();
  };

  return (
    <PageWrapper
      protectedContent={true}
      fixedAppBar={true}
      appBarTitle={pageTitle}
    >
      {renderPage()}
    </PageWrapper>
  );
};

export default Admin;
