import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import store from "../../../store";

import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { Typography } from "@rmwc/typography"; // Using SASS in main, so no css needed
import { Button } from "@rmwc/button";
import "@rmwc/checkbox/styles";
import "@rmwc/button/styles";

import style from "./UploadActivityDialog.module.scss";
import Actions from "../../../Redux/actions";
import { SuperCentered } from "../../Layout/OneLineLayouts/SuperCentered/SuperCentered";
import PageLoadingIndicator from "../../PageLoadingIndicator/PageLoadingIndicator";


export const UploadActivityDialog = ({
  open,
  onClosed,
  renderToPortal,
  channel,
}) => {

  const history = useHistory();

  const [file, setFile] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);


  const {
    getRootProps,
    getInputProps,
    open: fileBrowserOpen,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept:
      "\u002ezip, \u002ecsSession, \u002ecaSession, \u002evivedSession, text/xml, \u002eviveddata, \u002ejson, \u002ecas",
    maxFiles: 1,
    multiple: false,
    noClick: true,
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles);
    },
  });

  const handleErrors = useCallback(() => {
    const errors = fileRejections;
    const totatleHeldFiles = errors.length + file.length;
    const isFileTypeError =
      errors.length === 1 && errors[0].errors[0].code === "file-invalid-type";

    if (totatleHeldFiles > 1) {
      setFileError(true);
      setFileErrorMessage("Only 1 file may be uploaded at a time.");
    } else if (isFileTypeError) {
      setFileError(true);
      setFileErrorMessage(
        "File must be of type .csSession, .caSession, .vivedSession, .json, or .xml."
      );
    } else {
      setFileError(false);
      setFileErrorMessage("");
    }
  }, [fileRejections, file]);

  //handle errors for file drop
  useEffect(() => {
    handleErrors();
  }, [handleErrors]);

  // Event handler function
  const onDialogClose = () => {
    setFile([]);
    setFileError(false);
    setFileErrorMessage("");
  };

  /// Render helper function
  const renderChannelIconName = () => {
    return (
      <span>
        <Avatar
          size="medium"
          name={channel.name}
          src={channel.image}
          className={style.channelAvatar}
        />
        <span style={{ paddingLeft: "4px" }}>{channel.name}</span>
      </span>
    );
  };

  const renderFileInput = () => {
    return <TextField type="file" outlined {...getInputProps()} />;
  };

  const renderBrowseButton = () => {
    return (
      <SuperCentered>
        <Button raised onClick={fileBrowserOpen} label="Browse" />
      </SuperCentered>
    );
  };

  const renderAcceptedFileName = () => {
    if (file && file[0] && !fileError) {
      return (
        <Typography use="body2" theme="textSecondaryOnLight">
          {file[0].name} - {file[0].size} bytes
        </Typography>
      );
    } else return <div></div>;
  };

  const renderInputError = () => {
    if (fileError && fileErrorMessage) {
      return (
        <Typography
          use="body1"
          tag="span"
          style={{ display: "inline-block", marginBottom: "8px" }}
          theme={["error"]}
        >
          {fileErrorMessage}
        </Typography>
      );
    } else return null;
  };

  const isUploadDisabled = () => {
    if (file[0]?.name && file[0]?.size > 0 && !fileError) {
      return false;
    } else return true;
  };

  const renderDialogContent = () => {
    const contentClassNames = [
      style.base,
      isDragActive ? style.baseActive : "",
      isDragAccept ? style.baseAccept : "",
      isDragReject ? style.baseReject : "",
    ].join(" ");
    return (
      <DialogContent {...getRootProps({ className: contentClassNames })}>
        <SuperCentered>
          <div className={style.uploadContent}>
            <SuperCentered className={style.uploadGraphic}>
              <Icon icon="upload" className={style.uploadIcon} />
            </SuperCentered>
            <div style={{ paddingTop: "24px" }}>
              Drag and drop the activity file or click to browse.
            </div>
            <div style={{ paddingTop: "24px" }}>{renderBrowseButton()}</div>
            <div style={{ paddingTop: "24px" }}>{renderFileInput()}</div>
            <div style={{ paddingTop: "24px" }}>{renderAcceptedFileName()}</div>
            <div style={{ paddingTop: "24px" }}>{renderInputError()}</div>
          </div>
        </SuperCentered>
      </DialogContent>
    );
  };

  const importActivity = async () => {
    function importCallBack (result) {
      if(result.success) {
        history.push(`/activity/${result.activityId}`);
      } else {
        setIsLoading(false)
        setFileError(true)
        setFileErrorMessage("Failed to Upload. Please try again.")
      }
    }

    store.dispatch(
      Actions.Activities.importActivity(file[0], channel.id, channel.name, importCallBack)
    )

    setIsLoading(true)
  }

  if(isLoading) {
    return (
      <Dialog
        open={open}
        onClose={onDialogClose}
        onClosed={onClosed}
        renderToPortal={renderToPortal}
        className={style.customDialog}
      >
        <DialogTitle>Upload activity to {renderChannelIconName()}</DialogTitle>
        <PageLoadingIndicator/>
      </Dialog>
    )
  } else {
    return (
      <Dialog
        open={open}
        onClose={onDialogClose}
        onClosed={onClosed}
        renderToPortal={renderToPortal}
        className={style.customDialog}
      >
        <DialogTitle>Upload activity to {renderChannelIconName()}</DialogTitle>
        {renderDialogContent()}
        <DialogActions>
          <DialogButton action="cancel">Cancel</DialogButton>
          <DialogButton
            disabled={isUploadDisabled()}
            onClick={async () => {
              await importActivity();
            }}
          >
            Upload
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }
};

UploadActivityDialog.defaultProps = {
  renderToPortal: true,
};
UploadActivityDialog.propTypes = {

  channel: PropTypes.object,
};