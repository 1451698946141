import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper/PageWrapper";

const About = () => {
  return (
    <PageWrapper
      protectedContent={false}
    >
      <Container fluid className="Terms">
        <Row>
          <Col xs={12} className="Terms__title">
            <span>About Us</span>
          </Col>
          <Col xs={12}>
            <p>
              VIVED Learning is leader in interactive 3D STEM learning. VIVED
              Science, Anatomy and Chemistry actively engage students in
              learning science in more than 500 schools and institutions in K12
              and Higher Education. Six of the top ten schools districts in the
              US use VIVED solutions for biology, earth science, human anatomy,
              and chemistry coursework. Some of the largest publishers in
              education, including McGraw-Hill, Cengage Gale, and Elsevier as
              well as edtech firms like Safari Montage and zSpace trust and
              market VIVED solutions to improve student outcomes in science.
              With over 500 3D models that can pulled apart or virtually
              dissected teachers can present information that excites student
              curiosity, pushing them to ask questions, guiding their discovery
              process and finally assessing their understanding. Ouzel3D is a
              new offering from VIVED Learning that make it easy and fun to
              create, publish and share knowledge with visual, interactive 3D
              learning presentations that can stored on your Ouzel learning
              channel. For more information about VIVED Learning and Ouzel3D,
              please visit
              <a href="www.vivedlearning.com."> www.vivedlearning.com.</a>
            </p>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default About;
