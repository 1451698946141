import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Typography } from "@rmwc/typography"; // Sass imported into main so css import not needed.

import ChannelJoinLeaveButton from "../ChannelJoinLeaveButton/ChannelJoinLeaveButton";

import style from "./ChannelHeader.module.scss";
import globalStyle from "../../styles/global.module.scss";

const generateActivityIdV2 = () => {
  return uuidv4();
};

export const ChannelHeader = ({
  channelId,
  channelName,
  channelImage,
  canAuthor,
  setUploadDialogOpen,
  hasApps,
}) => {
  const buildNewActivityV2MenuItem = () => {
    if (hasApps) {
      return (
        <Link
          to={{
            pathname: `/activity/${generateActivityIdV2()}`,
            state: {
              launchAs: "newActivity",
              channelId: channelId,
            },
          }}
          className={globalStyle.linkNoStyle}
        >
          <MenuItem>New activity (v2)</MenuItem>
        </Link>
      );
    } else return null;
  };
  const renderNewMenu = () => {
    // If the user has permission to author onto this channel, this button should be displayed.
    // If a linked-user (aka a user from a subscriber channel) has author permissions on their channel, they do not get access to this button. It is reserved for users of this channel with authoring permission.
    if (canAuthor) {
      return (
        <SimpleMenu
          rootProps={{
            style: { whiteSpace: "nowrap" },
          }}
          handle={
            <Button
              icon="add"
              label="New"
              outlined
              onSelect={(evt) => console.log(evt)}
              style={{ width: "120px" }}
            />
          }
        >
          <Link
            to={`/channel/${channelId}/new`}
            className={globalStyle.linkNoStyle}
          >
            <MenuItem>New activity</MenuItem>
          </Link>
          {buildNewActivityV2MenuItem()}
          <MenuItem onClick={() => setUploadDialogOpen(true)}>
            Upload activity
          </MenuItem>
          {renderNewChannelModel()}
          {renderEditChannelModel()}
        </SimpleMenu>
      );
    } else return null;
  };
  const renderNewChannelModel = () => {
    if (canAuthor) {
      return (
        <Link
          to={{
            pathname: `/channel/${channelId}/addChannelModel`,
            state: {
              channelId: channelId,
              dispatchType: "START_ADD_CHANNEL_MODEL"
            },
          }}
          className={globalStyle.linkNoStyle}
        >
          <MenuItem>New channel model</MenuItem>
        </Link>
      );
    } else return null;
  };
  const renderEditChannelModel = () => {
    if (canAuthor) {
      return (
        <Link
          to={{
            pathname: `/channel/${channelId}/editChannelModels`,
            state: {
              channelId: channelId,
              dispatchType: "START_EDIT_CHANNEL_MODEL"
            },
          }}
          className={globalStyle.linkNoStyle}
        >
          <MenuItem>Edit channel models</MenuItem>
        </Link>
      );
    } else return null;
  };

  const renderContent = () => {
    return (
      <div className={style.detailsContainer}>
        <div className={style.details}>
          <Avatar
            src={channelImage}
            style={{ width: "80px", height: "80px" }}
            name={channelName}
            className={style.avatar}
          />
          <Typography use="headline6" tag="h1" className={style.name}>
            {channelName}
          </Typography>
          <ChannelJoinLeaveButton
            channelId={channelId}
            className={style.detailsJoinLeave}
          />
        </div>
        <div className={style.detailsAction}>{renderNewMenu()}</div>
      </div>
    );
  };
  return <div className={style.container}>{renderContent()}</div>;
};

ChannelHeader.defaultProps = {
  canAuthor: false,
  channelName: "Untitled channel",
  hasApps: false,
};

ChannelHeader.propTypes = {
  /**
   * Does the user have the ability to author new activities and edit
   * existing activities? When true, the 'New Activity' button is displayed.
   * Defaults to false if excluded.
   */
  canAuthor: PropTypes.bool,
  /**
   * The unique id of the channel. Required.
   */
  channelId: PropTypes.string.isRequired,
  /**
   * The url to the channel image. Optional.
   */
  channelImage: PropTypes.string,
  /**
   * The name of the channel. If not provided the name will
   * default to `Untitled channel`.
   */
  channelName: PropTypes.string,
  /**
   * The function to be called when the 'Upload Activity' button is selected from the 'New' dropdown menu.
   */
  setUploadDialogOpen: PropTypes.func,
  /**
   * Does the channel have apps assigned to it?
   * Defaults to false if excluded.
   */
  hasApps: PropTypes.bool,
};

export const ChannelBanner = ({ banner, canEditChannel, channelId }) => {
  const renderChannelActions = () => {
    // This link should only be displayed if they are a user of this channel with admin permission. A subscriber user should never see this button.
    if (canEditChannel) {
      return (
        <div className={style.channelActions}>
          <Link to={`/account/channels/edit/${channelId}`}>
            <Button icon="brush" label="Customize" raised />
          </Link>
          <Link
            to={`/channel/${channelId}/users`}
            style={{ paddingLeft: "8px" }}
          >
            <Button icon="people" label="Users" raised />
          </Link>
        </div>
      );
    } else {
      return null;
    }
  };

  if (banner) {
    return (
      <div
        id="channel-banner"
        className={style.banner}
        style={{ backgroundImage: `url(${banner})` }}
      >
        {renderChannelActions()}
      </div>
    );
  } else {
    return <>{renderChannelActions()}</>;
  }
};

ChannelBanner.defaultProps = {
  canEditChannel: false,
};

ChannelBanner.propTypes = {
  /**
   * The url to the channel image. Optional. Banner is
   * not displayed when an image is not provided.
   */
  banner: PropTypes.string,
  /**
   * Does the user have the permission required to edit the channel being viewed?
   * When true, the 'Customize channel' button is displayed.
   * Defaults to false if excluded.
   */
  canEditChannel: PropTypes.bool,
  /**
   * The unique id of the channel being viewed. Required.
   */
  channelId: PropTypes.string.isRequired,
};
