import { call, put } from "redux-saga/effects";
import basicFetch from "../../utils/basicFetch";
import basicFetch_blob from "../../utils/basicFetch_blob";
import JSZIP from "jszip"
import { v4 as uuidv4 } from "uuid";
import mimeTypes from "mime-types"
import ActivityActions from "../../actions/activities"
import { uploadAnyLargeFile } from "../Universal/sagas";
import { requestModule } from "../requestmodule";

function* UploadAnyLargeFile(folder, file, filename, callback) {
  
  let uploadFile = uploadAnyLargeFile(requestModule)
  return yield call( uploadFile, {
    payload: {
      folder,
      file,
      filename
    },
    callback
  } )
}

export const exportActivity = (requestModule) =>
  function* (action) {
    async function zipItAndShipIt(meta, filename) {
      
      async function zipAssets(assets) {
        let newList = []
    
        for (let index = 0; index < assets.length; index++) {
    
          const asset = assets[index],
          assetFile = await basicFetch_blob(asset.fileUrl),
          fileExtention = mimeTypes.extension(asset.asset_type),
          newAssetName = `${uuidv4()}.${fileExtention}`
          zippedFile.file(newAssetName, assetFile)
          newList.push(newAssetName)
        }
    
        return newList
      }
    
      async function zipImage(image) {
        if(!image) {
          return null
        }
        const imageFile = await basicFetch_blob(image),
        newName = `${uuidv4()}.jpg`
        zippedFile.file(newName, imageFile)

        return newName
      }
      
      async function zipDataFile(data) {
    
        if (data.includes(".viveddata")) {
          let realData = await basicFetch(data);
    
          if (realData) {
            data = JSON.stringify(realData);
          } else {
            throw new Error("error getting activity data");
          }
        }
    
        const newName = `${uuidv4()}.json`
    
        zippedFile.file(newName, data)
    
        return newName
      }
    
      function downloadFile(file, filename) {
        let a = document.createElement("a"),
        url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    
      const zip = new JSZIP(),
      zippedFile = zip.folder(filename)
    
      meta.data = await zipDataFile(meta.data)
      meta.assets = await zipAssets(meta.assets)
      meta.image = await zipImage(meta.image)
    
      zippedFile.file("meta.json", JSON.stringify(meta))
    
      zip.generateAsync({type:"blob"}).then(function(content) {
          downloadFile(content, filename);
      });
    }

    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, activityId } = action.payload;

      if (!channelId) {
        throw new Error("channelId is required");
      }

      if (!activityId) {
        throw new Error("activityId is required");
      }

      const req = yield call(requestModule, {
        path: `channels/${channelId}/activities/${activityId}`,
      });

      let activity = req.data

      let title = activity.title.replaceAll(" ", "_"),
      filename = activity.isPlayerV2 ? `${title}_Player2.zip` : `${title}.zip`,
      meta = {
        title: activity.title ?? "",
        description : activity.description ?? "",
        assets: activity.assets,
        data: activity.data,
        image: activity.image,
        isPlayerV2: activity.isPlayerV2 ?? false
      }

      yield call( zipItAndShipIt, meta, filename )

      if (action.callback) {
        yield call(action.callback, { success: true,  activity});
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const importActivity = (requestModule) => {
  return function* (action) {
    const fileIsZip = action.payload.zip.type.includes("zip")
    if(fileIsZip) {
      yield call( importZip, action )
    } else {
      yield call( importFile, action )
    }
  }
}

function* importZip(action) {

  let activity
  try {
    function getZipFiles(zipFile) {
      return new Promise((res) => {
        let zipJs = new JSZIP(),
        reader = new FileReader()
    
        reader.onload = function() {
          zipJs.loadAsync(reader.result).then(function (zipFiles) {
            res(zipFiles)
          })
          .catch(err => {
            throw new Error(err)
          })
        };
    
        reader.onerror = function() {
          throw new Error(reader.error)
        }
    
        reader.readAsArrayBuffer(zipFile);
      })
    }

    async function getMeta(zipFiles) {
      
      return new Promise((res => {
        zipFiles.forEach(async (path, file) => {
          if(path.includes("meta.json")) {
            
            let meta = await zipFiles.file(path).async("string")
            meta = JSON.parse(meta)

            res(meta)
          }
        })
      }))
    }
    
    async function getFile(zipFiles, filename) {
      return new Promise((res => {
        zipFiles.forEach(async (path, file) => {
          if(path.includes(filename)) {
            
            let file = await zipFiles.file(path).async("arrayBuffer")

            res(file)
          }
        })
      }))
    }

    function* downloadFiles(fileNames, newNames, location, zipFiles, zip) {
      for (let index = 0; index < fileNames.length; index++) {
        let filename = fileNames[index],
        file = yield call(getFile, zipFiles, filename),
        newFileName = newNames[index]

        newFileName = newFileName.replace(`https://collections-bucket-prod-5566.s3.us-east-1.amazonaws.com/Thumbnails/`, '')
        // or
        newFileName = newFileName.replace(`https://collections-bucket-staging-5566.s3.us-east-1.amazonaws.com/Thumbnails/`, '')
        // or
        newFileName = newFileName.replace(`https://collections-bucket-dev-5566.s3.us-east-1.amazonaws.com/Thumbnails/`, '')

        try{
          yield call(UploadAnyLargeFile, location, file, newFileName, (res)=>{
            if(!res.success) {
              throw new Error(res.msg)
            }
          })
        } catch(e) {
          throw new Error(e)
        }
      }
    }

    function createFileName(oldname) {
      let type = mimeTypes.contentType(oldname),
      exstention = mimeTypes.extension(type),
      newFileName = `${uuidv4()}.${exstention}`

      return newFileName
    }

    function* saveActivity(channelId, channelName, meta) {
      let newActivity = {
        ...meta,
        channel_name: channelName,
        data: meta.data ? createFileName(meta.data) : null,
        image: meta.image ? createFileName(meta.image) : null
      }

      try{
        let res = yield call( requestModule, {
          data: newActivity,
          method: "post",
          path: `channels/${channelId}/activities`,
        });
        let result = res.data

        result.assets = meta.assets.length > 0 ? meta.assets.map(asset => createFileName(asset)) : []
        
        return result
      } catch (e) {
        throw new Error(e)
      }
    }

    function* saveAssets(channelId, activityId, assets) {
      for (let index = 0; index < assets.length; index++) {
        const filename = assets[index];

        let body = {
          filename
        }
  
        try{
          yield call( requestModule, {
            data: body,
            method: "post",
            path: `channels/${channelId}/activities/${activityId}/asset`,
          });
        } catch (e) {
          throw new Error(e)
        }
      }
    }

    let zip = action.payload.zip,
    zipFiles = yield call(getZipFiles, zip),
    meta = yield call(getMeta, zipFiles)

    activity = yield call(saveActivity, action.payload.channelId, action.payload.channelName, meta)

    yield call(downloadFiles, [meta.data], [activity.data], "DataVariants", zipFiles, zip)
    yield call(downloadFiles, [meta.image], [activity.image], "Thumbnails", zipFiles, zip)
    yield call(downloadFiles, [...meta.assets], [...activity.assets], "assets", zipFiles, zip)

    yield call(saveAssets, action.payload.channelId, activity.SK, activity.assets)
    
    action.callback({ success: true,  activityId: activity.SK})
  } catch (err) {
    console.error(err)
    if(activity) {
      yield put(ActivityActions.fetchDeleteActivity(activity.SK, activity.PK))
    }
    action.callback({ success: false, err })
  }
}

function* importFile(action) {

  function* downloadFile(file, filename) {
    try{
      yield call(UploadAnyLargeFile, "DataVariants", file, filename, (res)=>{
        if(!res.success) {
          throw new Error(res.msg)
        }
      })
    } catch(e) {
      throw new Error(e)
    }
  }

  function* saveActivity(channelId, channelName, isPlayerV2) {
    
    const newActivity = {
      title: "Newly Imported Activity",
      data: `${uuidv4()}.json`,
      channel_name: channelName,
      isPlayerV2
    }

    try{
      let res = yield call( requestModule, {
        data: newActivity,
        method: "post",
        path: `channels/${channelId}/activities`,
      });
      
      return res.data
    } catch (e) {
      throw new Error(e)
    }
  }

  function isForPlayerV2(file) {
    return new Promise((res) => {
      let reader = new FileReader()
  
      reader.onload = function() {
        const isVersion2 = JSON.parse(reader.result).version === 2
        if(isVersion2) {
          res(true)
        } else {
          res(false)
        }
      };
  
      reader.onerror = function() {
        throw new Error(reader.error)
      }
  
      reader.readAsText(file);
    })
  }

  let activity
  try {
    let file = action.payload.zip,
    isPlayerV2 = yield call( isForPlayerV2, file)

    activity = yield call(saveActivity, action.payload.channelId, action.payload.channelName, isPlayerV2)
    yield call(downloadFile, file, activity.data)
    
    action.callback({ success: true,  activityId: activity.SK})
  } catch (err) {
    console.error(err)
    if(activity) {
      yield put(ActivityActions.fetchDeleteActivity(activity.SK, activity.PK))
    }
    action.callback({ success: false, err })
  }
}