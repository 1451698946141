import { call, put } from "redux-saga/effects";
import * as APPACTIONS from "./AppsActions";
import ChannelActions from "../Channels/ChannelActions"
import { prepAppForState, prepChannelAppForState } from "./AppsTypes";

export const getAllApps = (requestModule) =>
  function* (action) {
    const { callback } = action;

    try {
      const req = yield call(requestModule, {
        method: "get",
        path: "apps",
        process: true,
      });

      let apps = {}
      req.data.apps.forEach(app => {
        if(!app.hasOwnProperty("files")) {
          app = prepAppForState(app)
          apps[app.id] = app
        }
      })

      yield put( APPACTIONS.setAppsInState(apps) ) 
      
      if (callback) {
        yield call(callback, { success: true, apps });
      }
    } catch (err) {
      if (callback) {
        yield call(callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const addAppToChannel = (requestModule) =>
  function* (action) {
    const { payload, callback } = action;

    try {
      let req = yield call(requestModule, {
        method: "post",
        path: `channels/${payload.channelId}/app/${payload.appId}`,
        process: true,
      }),
      channelApp = req.data

      channelApp = prepChannelAppForState(channelApp)
      yield put( ChannelActions.setChannelAppInState(payload.channelId, channelApp) ) 
      
      if (callback) {
        yield call(callback, { success: true, channelApp });
      }
    } catch (err) {
      if (callback) {
        yield call(callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const removeAppFromChannel = (requestModule) =>
function* (action) {
  const { payload, callback } = action;

  try {
    let req = yield call(requestModule, {
      method: "delete",
      path: `channels/${payload.channelId}/app/${payload.appId}`,
      process: true,
    }),
    channelApp = req.data

    yield put( ChannelActions.removeChannelAppFromState(payload.channelId, payload.appId) ) 
    
    if (callback) {
      yield call(callback, { success: true, channelApp });
    }
  } catch (err) {
    if (callback) {
      yield call(callback, { success: false, msg: err });
    } else {
      throw err;
    }
  }
};