import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { Select } from "@rmwc/select";
import "@rmwc/select/styles";
import { Typography } from "@rmwc/typography"; // Using Sass in main, css not needed


const EditMemberRole = ({
  member,
  open,
  onClose,
  onClosed,
  renderToPortal,
}) => {
  const [selectedRole, setSelectedRole] = useState(member?.role);
  const [showOriginalRole, setShowOriginalRole] = useState(true);

  useEffect(() => {
    if(showOriginalRole) {
      if(selectedRole !== member.role) {
        setSelectedRole(member.role)
      }
    }
  }, [member.role, selectedRole, showOriginalRole])

  const roleOptions = {
    member: "Member",
    author: "Author",
    admin: "Admin",
  }

  const renderContent = () => {
    return (
      <div>
        <div>
          {member.name ? (
          <Typography use="body2" tag="div">
            {member.name} 
          </Typography>) : null}
          <Typography use="body2" tag="p">
            {member.email}
          </Typography>
        </div>
        <div>
          <Select
            // outlined            
            label="Role"
            value={selectedRole}
            options={roleOptions}
            onChange={(evt) => {              
              setShowOriginalRole(false)
              setSelectedRole(evt.currentTarget.value);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={(evt) => {
        setShowOriginalRole(true)
        onClose(evt)
      }}
      onClosed={(evt) => onClosed(evt, member)}
      renderToPortal={renderToPortal}
    >
      <DialogTitle>
        Edit member role
        <IconButton
          icon="close"
          data-mdc-dialog-action="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
        />
      </DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <DialogButton action="close">Cancel</DialogButton>
        <DialogButton action={selectedRole} isDefaultAction>
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditMemberRole;
