import React from "react";
import PropTypes from "prop-types";

import { Elevation } from "@rmwc/elevation";
import "@rmwc/elevation/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { Typography } from "@rmwc/typography"; // Sass imported in main, no css needed.

import style from "./ActivityMetadataBar.module.scss";

export const ActivityMetadataBar = ({ title, unsaved, onEdit }) => {
  const buttonTitle = unsaved ? "You must save the activity to edit activity details." : "edit activity details";
  
  return (
    <Elevation z={1} wrap>
      <div className={style.container}>
        <div className={style.title}>
          <Typography use="body1" tag="span" theme="textSecondaryOnLight">
            {title}
          </Typography>
          <span title={buttonTitle}>
            <IconButton
              icon="edit"
              label="edit details"
              theme={unsaved ? "textIconOnLight" : "textSecondaryOnLight"}
              disabled={unsaved}
              onClick={onEdit}
            />
          </span>
        </div>
      </div>
    </Elevation>
  );
};

ActivityMetadataBar.defaultProps = {
  title: "Untitled activity",
  unsaved: true,
};
ActivityMetadataBar.propTypes = {
  /**
   * The title of the item to be displayed in the metadata bar.
   */
  title: PropTypes.string,
  /**
   * A bool indicating if this activity has been saved yet exists in the database yet. Activity metadata cannot be edited until the activity has been created. Defaults to true indicated the activity has not been saved yet.
   */
  unsaved: PropTypes.bool,
  /**
   * The function to be called when the user selects the 'edit details' button which will trigger the EditActivityMetadataDialog.
   */
  onEdit: PropTypes.func,
};
