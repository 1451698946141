import Actions from './CollectionActions'
import { call, put, select } from "redux-saga/effects";
import * as TYPES from "./CollectionTypes";
import { AllBundlesCollectionId } from "../../utils/helpers";

export const getBundleCollections = requestModule =>
  function* getBundleCollectionsSaga(action) {
    try {

      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      if (!action.payload.hasOwnProperty("bundleId")) {
        throw new Error("bundleId is required");
      }

      if (!action.payload.hasOwnProperty("offset")) {
        throw new Error("offset is required");
      }

      if (!action.payload.hasOwnProperty("limit")) {
        throw new Error("limit is required");
      }

      const { bundleId, offset, limit } = action.payload;

      const req = yield call(requestModule, {
        path: `bundle/${bundleId}/all`
      });

      const total = req.data.totalCollections;
      let totalRetrieved = offset + limit;
      let allHaveBeenRetrieved = false;
      if (totalRetrieved >= total) {
        totalRetrieved = total;
        allHaveBeenRetrieved = true;
      }
      yield put(Actions.setCollectionsRetrieved(total, totalRetrieved, allHaveBeenRetrieved));

      const collectionArray = req.data.collections;
      const collections = collectionArray.map(c => TYPES.ReduxCollectionFromAPICollection(c));

      yield put(Actions.setCollections(collections));

      const state = yield select();
      if(state.Collections.bundles && state.Collections.bundles[bundleId]){
        const collectionIds = collections.map(c => { return c.id})
        let currentBundle = state.Collections.bundles[bundleId];
        currentBundle.collections = [...collectionIds]
        yield put(Actions.setBundle(currentBundle));
      }

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    }
    catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
      else {
        throw err;
      }
    }
  };

export const getAllCollections = requestModule =>
  function* getAllCollectionsSaga(action) {
    try {
      // hit the bundle api with the ID for all collections
      const req = yield call(requestModule, {
        path: `bundle/${AllBundlesCollectionId}/all`
      });

      const total = req.data.totalCollections;
      let totalRetrieved = req.data.collections.length;
      let allHaveBeenRetrieved = false;
      if (totalRetrieved >= total) {

        allHaveBeenRetrieved = true;
      }
      yield put(Actions.setCollectionsRetrieved(total, totalRetrieved, allHaveBeenRetrieved));

      const collectionArray = req.data.collections;
      const collections = collectionArray.map(c => {
        c = TYPES.ReduxCollectionFromAPICollection(c)
        c.type = "collection"
        return c
      });

      yield put(Actions.setCollections(collections));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    }
    catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
      else {
        throw err;
      }
    }
  };

export const getAllBundles = requestModule =>
  function* getAllBundlesSaga(action) {
    try {
      // hit the bundle api with the ID for all collections
      const req = yield call(requestModule, {
        path: `bundle`
      });     

      const bundlesArray = req.data.bundles;          
      const bundles = bundlesArray.map(c => TYPES.ReduxBundleFromAPIBundle(c));        

      // make sure collections don't get overwritten
      const state = yield select();
      for(let i=0; i<bundles.length; i++){
        const currentBundleId = bundles[i].id;
        if(state.Collections.bundles && state.Collections.bundles[currentBundleId] && state.Collections.bundles[currentBundleId].collections){
          bundles[i].collections = [...state.Collections.bundles[currentBundleId].collections];
        }
      }


      yield put(Actions.setBundles(bundles));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    }
    catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
      else {
        throw err;
      }
    }
  };

export const getCollectionBundle = requestModule =>
  function* getCollectionBundleSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      if (!action.payload.hasOwnProperty("bundleId")) {
        throw new Error("bundleId is required");
      }

      const { bundleId } = action.payload;

      const req = yield call(requestModule, {
        path: `bundle/${bundleId}`
      });
      
      const reduxBundle = TYPES.ReduxBundleFromAPIBundle(req.data);      

      yield put(Actions.setBundle(reduxBundle));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    }
    catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
      else {
        throw err;
      }
    }
  };

export const getCollection = requestModule =>
  function* getCollectionSaga(action) {
    try {

      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      if (!action.payload.hasOwnProperty("collectionId")) {
        throw new Error("collectionId is required");
      }

      const { collectionId } = action.payload;

      const req = yield call(requestModule, {
        path: `collection/${collectionId}`
      });

      const reduxCollection = TYPES.ReduxCollectionFromAPICollection(req.data);

      yield put(Actions.setCollection(reduxCollection));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    }
    catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
      else {
        throw err;
      }
    }
  };