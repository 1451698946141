import React from "react";
import PropTypes from "prop-types";

import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import "@rmwc/elevation/styles";
import { MenuSurfaceAnchor, Menu, MenuItem } from "@rmwc/menu";
import "@rmwc/menu/styles";
import { ListDivider, ListItemGraphic, ListItemText } from "@rmwc/list";
import "@rmwc/list/styles";
import { Typography } from "@rmwc/typography"; // Sass used in main. No CSS needed here.
import { useUserCanCreateChannel } from "../../hooks/User/useUserCanCreateChannel";
import { useUserIsVivedEmployee } from "../../hooks/User/useUserIsVivedEmployee";
import { Theme } from "@rmwc/theme"; // Using SASS in main instead of css here
import {
  TopAppBarActionItem,
} from "@rmwc/top-app-bar";
import "@rmwc/top-app-bar/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import style from "./AccountMenu.module.scss";

const AccountMenu = ({
  isSignedIn,
  isAccountMenuOpen,
  openAccountMenu, 
  closeAccountMenu, 
  userMeta,
  history,
}) => {
  const userIsEmployee = useUserIsVivedEmployee();
  const canCreateChannel = useUserCanCreateChannel();

  const renderAvatar = () => {
    if(isSignedIn) {
      return (
        <Avatar
          src={userMeta.avatar}
          size={"xlarge"}
          name={userMeta.fullName}
          interactive
          onClick={() => openAccountMenu()}
        />
      );
     } else {
      return (
        <React.Fragment>
          <Button
            label="Sign In"
            outlined
            icon="account_circle"
            onClick={() => {
              history.push({
                pathname: "/signin",
                search: history.location.search,
              })
            }}
            style={{ marginRight: "8px", marginTop: "-15px" }}
          />
          <Theme use={["textSecondaryOnBackground"]} wrap>
            <TopAppBarActionItem 
              icon="account_circle" 
              onClick={() => openAccountMenu()} 
            />
          </Theme>
        </React.Fragment>
      );
     }
  };

  const renderMenu_ifSignedOut = () => {
    // Should only be rendered when no user is signed in.
    if (!isSignedIn) {
      return (
        <MenuSurfaceAnchor>
          <Menu
            open={isAccountMenuOpen}
            onClose={() => closeAccountMenu()}
          >
            <MenuItem onClick={() => history.push({
              pathname: "/signin",
              search: history.location.search,
            })}>
              <ListItemGraphic icon="account_circle" />
              <ListItemText>Sign in</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => history.push({
              pathname: "/register",
              search: history.location.search,
            })}>
              <ListItemGraphic icon="person_add" />
              <ListItemText>Register</ListItemText>
            </MenuItem>
            <ListDivider />
            <MenuItem onClick={() => history.push("/about")}>
              <ListItemGraphic icon="new_releases" />
              <ListItemText>About</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => history.push("/terms")}>
              <ListItemGraphic icon="lock" />
              <ListItemText>{"Privacy & terms"}</ListItemText>
            </MenuItem>
          </Menu>
          {renderAvatar()}
        </MenuSurfaceAnchor>
      );
    }
  };

  const renderMenu_ifSignedIn = () => {
    // Should only be rendered if the user is signed in
    if (isSignedIn) {
      return (
        <MenuSurfaceAnchor>
          <Menu
            open={isAccountMenuOpen}
            onClose={() => closeAccountMenu()}
          >
            <div className={style.accountMenuDesktopHeader}>
              <div className={style.accountMenuDesktopHeaderAvatar}>
                {renderAvatar()}
              </div>
              <div className={style.accountMenuDesktopHeaderUser}>
                <Typography use="subtitle1" tag="p">
                  {userMeta.fullName}
                </Typography>
                <Typography use="subtitle2" tag="p">
                  {userMeta.email}
                </Typography>
              </div>
            </div>
            <MenuItem onClick={() => history.push("/account/edit")}>
              <ListItemGraphic icon="account_circle" />
              <ListItemText>Manage your VIVED account</ListItemText>
            </MenuItem>
            <ListDivider />
            <MenuItem onClick={() => history.push("/dashboard")}>
              <ListItemGraphic icon="subscriptions" />
              <ListItemText>Your dashboard</ListItemText>
            </MenuItem>

            {canCreateChannel ? (
              <MenuItem onClick={() => history.push("/account/channels/new")}>
                <ListItemGraphic icon="add_box" />
                <ListItemText>Create channel</ListItemText>
              </MenuItem>
            ) : (
              <></>
            )}

            <MenuItem onClick={() => history.push("/account/logout")}>
              <ListItemGraphic icon="exit_to_app" />
              <ListItemText>Sign out</ListItemText>
            </MenuItem>
            <ListDivider />
            <MenuItem onClick={() => history.push("/about")}>
              <ListItemGraphic icon="new_releases" />
              <ListItemText>About</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => history.push("/terms")}>
              <ListItemGraphic icon="lock" />
              <ListItemText>{`Privacy & terms`}</ListItemText>
            </MenuItem>

            {userIsEmployee ? (
              <>
                <ListDivider />
                <MenuItem onClick={() => history.push("/admin")}>
                  <ListItemGraphic icon="dashboard" />
                  <ListItemText>Admin</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => history.push("/debug")}>
                  <ListItemGraphic icon="bug_report" />
                  <ListItemText>Debug</ListItemText>
                </MenuItem>
              </>
            ) : null}
          </Menu>
          {renderAvatar()}
        </MenuSurfaceAnchor>
      );
    }
  };

  if(isSignedIn) {
    return renderMenu_ifSignedIn()
  } else{
    return renderMenu_ifSignedOut()
  }
};

AccountMenu.defaultProps = {
  isSignedIn: false,
};

AccountMenu.propTypes = {
  openAccountMenu: PropTypes.func.isRequired,

  closeAccountMenu: PropTypes.func.isRequired,

  isAccountMenuOpen: PropTypes.bool.isRequired,
 
  history: PropTypes.object.isRequired,

  isSignedIn: PropTypes.bool,

  userMeta: PropTypes.object.isRequired,
};

export default AccountMenu;
