import * as AppTypes from "./AppsTypes"

const initialState = {
  list: {}
};

export default function AppReducer (state = initialState, action) {

  let currentType = action.type,
  payload = action.payload,
  newList = {}

  if(currentType === AppTypes.APPS_SET_APPS_IN_STATE) {
    newList = payload.apps
    return {
      ...state,
      list: newList
    }
  }
  else {
    return state
  }
}