import * as TYPES from './types'; 

export const checkId = id => ({
  payload: {
    id,
  },
});

const setDeviceInfo = () => {  
  return {
    type: TYPES.PLAYER_SET_DEVICE_INFO
  };
}

export default {
  checkId,
  setDeviceInfo  
};
