import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export default function useGetAllApps(params) {
  const dispatch = useDispatch()
  const [isFetching, setIsFetching] = useState(null);
  const [error, setError] = useState(null);
  const [apps, setApps] = useState({});

  const appsInState = useSelector(
    (state) => Object.values(state.Apps.list)
  );
  
  useEffect(() => {
    let currentAppsNum = Object.values(apps).length
    if(appsInState.length > 0 && currentAppsNum === 0) {
      setApps(appsInState)
    }
  }, [appsInState, apps])

  useEffect(() => {
    let shouldGetApps = !isFetching && appsInState.length === 0

    if(shouldGetApps) {

      setIsFetching(true)

      dispatch(
        Actions.Apps.getAllApps((res) => {
          if(res.success) {

            setApps(res.apps)
            setIsFetching(false)
          } else {

            setError(res.msg)
          }
        })
      )
    }
  }, [isFetching, appsInState, dispatch, apps])

  return {
    apps,
    getAllAppsError: error
  }
}