import * as TYPES from "../actions/types";

const initialState = {
    deviceType: "",
    orientation: "",
    isTouchCapable: false,
    screenResolution: {x:0, y:0},
    windowResolution: {x:0, y:0},
    breakpoint: 0,
    pixelDensity: 0    
};

const reducer = (state = initialState, action) => {    
    switch (action.type){
        case TYPES.PLAYER_SET_DEVICE_INFO:
            // windowResolution
            let windowResolutionToSet = {x:0, y:0};
            windowResolutionToSet.x = window.innerWidth;
            windowResolutionToSet.y = window.innerHeight;

            // screenResolution
            let screenResolutionToSet = {x:0, y:0};
            screenResolutionToSet.x = window.screen.width;
            screenResolutionToSet.y = window.screen.height;

            // isTouchCapable:
            let isTouchCapableToSet = ('ontouchstart' in window) ||
                (window.DocumentTouch && document instanceof window.DocumentTouch) ||
                (navigator.maxTouchPoints > 0) ||
                (window.navigator.msMaxTouchPoints > 0);

            // orientation
            let orientationToSet = "landscape";
            if(screenResolutionToSet.y > screenResolutionToSet.x){
                orientationToSet = "portrait";
            }

            // device
            let deviceToSet = "desktop";
            if(orientationToSet === "portrait"){
                // portrait
                if(screenResolutionToSet.x < 600){
                    // mobile
                    deviceToSet = "mobile"
                }
                else if(screenResolutionToSet.x >= 600 && screenResolutionToSet.x <= 959){
                    // tablet
                    deviceToSet = "tablet"
                }                
            }
            else{
                // landscape
                if(screenResolutionToSet.x < 960){
                    // mobile
                    deviceToSet = "mobile"
                }
                else if(screenResolutionToSet.x >= 960 && screenResolutionToSet.x <= 1439){
                    // tablet
                    deviceToSet = "tablet"
                }   
            }

            // pixelDensity            
            let pixelDensityToSet = window.devicePixelRatio;

            // breakpoint
            const w = windowResolutionToSet.x; // value to use for breakpoints
            let breakpointToSet = 0;
            if(w >= 360){ breakpointToSet = 360; }
            if(w >= 400){ breakpointToSet = 400; }
            if(w >= 480){ breakpointToSet = 480; }
            if(w >= 600){ breakpointToSet = 600; }
            if(w >= 720){ breakpointToSet = 720; }
            if(w >= 840){ breakpointToSet = 840; }
            if(w >= 960){ breakpointToSet = 960; }
            if(w >= 1024){ breakpointToSet = 1024; }
            if(w >= 1280){ breakpointToSet = 1280; }
            if(w >= 1440){ breakpointToSet = 1440; }
            if(w >= 1600){ breakpointToSet = 1600; }
            if(w >= 1920){ breakpointToSet = 1920; }

            return {
                ...state,
                deviceType: deviceToSet,
                orientation: orientationToSet,
                isTouchCapable: isTouchCapableToSet,
                screenResolution: screenResolutionToSet,
                windowResolution: windowResolutionToSet,
                breakpoint: breakpointToSet,
                pixelDensity: pixelDensityToSet
            };         
        default:
            return state;
    }    
}

export default reducer;