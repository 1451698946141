export const COLLECTION_BUNDLE_FETCH = "COLLECTION_BUNDLE_FETCH";
export const COLLECTIONS_SET_COLLECTIONS = "COLLECTIONS_SET_COLLECTIONS";
export const COLLECTIONS_SET_RETRIEVED = "COLLECTIONS_SET_RETRIEVED";
export const COLLECTIONS_SET_BUNDLES = "COLLECTIONS_SET_BUNDLES";
export const COLLECTION_GET_COLLECTION = "COLLECTION_GET_COLLECTION";
export const COLLECTION_SET_COLLECTION = "COLLECTION_SET_COLLECTION";
export const COLLECTION_GET_BUNDLE = "COLLECTION_GET_BUNDLE";
export const COLLECTION_SET_BUNDLE = "COLLECTION_SET_BUNDLE";
export const COLLECTIONS_GET_ALL_COLLECTIONS = "COLLECTIONS_GET_ALL_COLLECTIONS";
export const COLLECTIONS_GET_ALL_BUNDLES = "COLLECTIONS_GET_ALL_BUNDLES";

//This is the best way to from up the collection object that will be stored in the global list (ie Collections.list)
export function ReduxCollectionFromAPICollection(apiObject) {
  const collection = {
    type: "collection"
  };

  if (apiObject.type === "bundle-collection") {
    collection.id = apiObject.SK;
    collection.bundleId = apiObject.PK
  } else if (apiObject.type === "channel-collection") {
    collection.id = apiObject.SK;
  } else if (apiObject.type === "collection") {
    collection.id = apiObject.PK;
  } else {
    console.warn("API TYPE NOT SUPPORTED");
  }

  if (apiObject.image) {
    collection.image = apiObject.image;
  }

  if (apiObject.name) {
    collection.name = apiObject.name;
  }

  if (apiObject.title) {
    collection.title = apiObject.title.en;
  }

  if (apiObject.description) {
    collection.description = apiObject.description.en;
  }

  if (apiObject.enabled) {
    collection.enabled = apiObject.enabled;
  }

  if (apiObject.legacyname) {
    collection.legacyname = apiObject.legacyname;
  }

  if (apiObject.initialView) {
    collection.initialView = apiObject.initialView;
  }

  if (apiObject.owner) {
    collection.owner = apiObject.owner;
  }

  if (apiObject.type) {
    collection.type = apiObject.type

  }
  return collection;
}

//This is the best way to from up the bundle object that will be stored in the global list (ie Collections.bundles)
export function ReduxBundleFromAPIBundle(apiObject) {

  const bundle = {
    id: apiObject.PK,
    collections: [],
    type: "bundle"
  };

  if (apiObject.description) {
    bundle.description = apiObject.description;
  }

  if (apiObject.enabled) {
    bundle.enabled = apiObject.enabled;
  }

  if (apiObject.collections) {
    bundle.collections = apiObject.collections
  }

  if (apiObject.owner) {
    bundle.owner = apiObject.owner
  }

  return bundle;
}
