import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import PageError from "../../components/Error/PageError";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { useChannel } from "../../hooks/Channel/useChannel";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useIsLTIUser } from "../../hooks/User/useIsLTIUser";
import { useUserIsVivedEmployee } from "../../hooks/User/useUserIsVivedEmployee";

import { NewChannelModelPlayer } from "./NewChannelModelPlayer";
import { currentUser } from "../../utils/AmplifyAuthController"
import ReactAppContext from "../../ReactAppContext";

const NewChannelModel = (props) => {
  const isViewingFromSubscription =
    props.location.state?.isViewingFromSubscription;

  //States
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState("");
  const [dispatchType, setDispatchType] = useState("");

  //Hooks
  const { channel, error: getChannelError } = useChannel(channelId);
  const { role } = useChannelRole(channelId);
  const useIsAnEmployee = useUserIsVivedEmployee();
  const history = useHistory();
  const isLtiUser = useIsLTIUser();
  const appContext = useContext(ReactAppContext);
  const hostHandler = appContext.hostHandler;

  useEffect(() => {
    async function fetchData() {
      const user = await currentUser();
      setUser(user);
      setUserToken(user.signInUserSession.idToken.jwtToken);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!isLoading) return; //This effect only takes care of waiting for everything to get setup

    if (channel && role) {
      if (channel.access_pattern === "public") {
        if (role === "owner" || role === "admin" || role === "author") {
          setCanEdit(true);
        }
        setIsLoading(false);
      } else {
        if (role === "none" && !isLtiUser && !isViewingFromSubscription) {
          if (useIsAnEmployee) {
            setIsLoading(false);
            setCanEdit(true);
          } else if (!useIsAnEmployee) {
            //If the channel is not public and your not a member or is not an lti user
            //Give 'em the boot! Not supposed to be here
            history.push("/dashboard");
          }
        } else {
          if (role === "owner" || role === "admin" || role === "author") {
            setCanEdit(true);
          }
          setIsLoading(false);
        }
      }
    }
  }, [
    role,
    channel,
    isLoading,
    history,
    canEdit,
    isLtiUser,
    isViewingFromSubscription,
    props.location.pathname,
    useIsAnEmployee
  ]);

  //Monitor for errors
  useEffect(() => {
    if (getChannelError) {
      setError("Something went wrong while getting channel " + getChannelError);
    }
  }, [getChannelError]);

  useEffect(() => {
    if (props.location?.state?.channelId) {
      setChannelId(props.location?.state?.channelId);
    } else if (props.match.params?.channelId) {
      setChannelId(props.match.params?.channelId);
    }
  }, [channelId, props.location, props.match]);

  useEffect(() => {
    if (props.location?.state?.dispatchType) {     
      setDispatchType(props.location?.state?.dispatchType);
    }
  }, [dispatchType, props.location]);

  useEffect(() => {
    if (channel) {
      setChannelName(channel.name);
    }
  }, [channel]);

  function renderContent() {
    if (error) {
      return <PageError error={true} errorMessage={error} />;
    } else if (!isLoading && user && history && hostHandler && dispatchType && channelName) {    
      return (
        <NewChannelModelPlayer
          channelId={channelId}
          channelName={channelName}
          history={history}
          userToken={userToken}
          hostHandler={hostHandler}
          dispatchType={dispatchType}          
        />
      );
    } else {
      return <PageLoadingIndicator />;
    }
  }

  return (
    <PageWrapper>
      {renderContent()}
    </PageWrapper>
  );
};

export default NewChannelModel;
