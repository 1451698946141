import PropTypes from "prop-types";

/**
 * Prop options for the @rmwc/typography component's 'use' prop.
 */
export const TypographyUsePropType = PropTypes.oneOf([
  "headline1",
  "headline2",
  "headline3",
  "headline4",
  "headline5",
  "headline6",
  "subtitle1",
  "subtitle2",
  "body1",
  "body2",
  "caption",
  "button",
  "overline",
]);

/**
 * Prop options for the @rmwc/elevation component's 'use' prop.
 */
export const ElevationUsePropType = PropTypes.oneOf([
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  12,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
]);

/**
 * Theme 'use' options available for the theme prop in @rmwc components
 */
export const ThemeUseOptions = [
  "background",
  "error",
  "onError",
  "onPrimary",
  "onSecondary",
  "onSurface",
  "primary",
  "primaryBg",
  "secondary",
  "secondaryBg",
  "surface",
  "textDisabledOnBackground",
  "textDisabledOnDark",
  "textDisabledOnLight",
  "textHintOnBackground",
  "textHintOnDark",
  "textHintOnLight",
  "textIconOnBackground",
  "textIconOnDark",
  "textIconOnLight",
  "textPrimaryOnBackground",
  "textPrimaryOnDark",
  "textPrimaryOnLight",
  "textSecondaryOnBackground",
  "textSecondaryOnDark",
  "textSecondaryOnLight",
  undefined,
];

/**
 * PropType for the 'theme' prop in @rmwc components or the @rmwc/theme's 'use' prop.
 */
export const ThemeUsePropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.oneOf(ThemeUseOptions)),
  PropTypes.oneOf(ThemeUseOptions),
]);
