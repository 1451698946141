import OldUniversal from '../actions/universal';
import Forms from '../actions/forms';
import UserActions from "./Users/UserActions"
import Channels from './Channels/ChannelActions';
import Temporal from '../actions/temporal';
import Collections from './Collections/CollectionActions';
import Player from '../actions/player';
import * as Apps from "./Apps/AppsActions"
import OldActivities from '../actions/activities';
import * as NewActivities from "./Activities/ActivityActions"
import * as NewUniversal from "./Universal/actions"

let Activities = {
  ...NewActivities,
  ...OldActivities
}

let Universal = {
  ...OldUniversal,
  ...NewUniversal
}

const Actions = {
  Universal,
  UserActions,
  Forms,
  Activities,
  Channels,
  Temporal,
  Collections,
  Player,
  Apps
};

window.actions = Actions;
export default Actions;
