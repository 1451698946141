//Activites
export const ACTIVITY_FETCH = "ACTIVITY_FETCH";
export const ACTIVITY_REMOVE = "ACTIVITY_REMOVE";
export const ACTIVITIES_FETCH_INSERT = "ACTIVITIES_FETCH_INSERT";
export const ACTIVITIES_FETCH_FOR_CHANNEL = "ACTIVITIES_FETCH_FOR_CHANNEL";
export const ACTIVITIES_FETCH_DELETE = "ACTIVITIES_FETCH_DELETE";
export const ACTIVITIES_FETCH_ALL = "ACTIVITIES_FETCH_ALL";
export const ACTIVITIES_FETCH_UPDATE_META = "ACTIVITIES_FETCH_UPDATE_META";
export const ACTIVITIES_FETCH_UPDATE_DATA = "ACTIVITIES_FETCH_UPDATE_DATA";
export const ACTIVITIES_FLUSH_CURRENT = "ACTIVITIES_FLUSH_CURRENT";
export const ACTIVITIES_SET_RETRIEVED = "ACTIVITIES_SET_RETRIEVED"; 
export const ACTIVITIES_ADD_ACTIVITIES = "ACTIVITIES_ADD_ACTIVITIES";
export const ACTIVITIES_UPDATE_META = "ACTIVITIES_UPDATE_META";
export const ACTIVITIES_UPDATE_DATA = "ACTIVITIES_UPDATE_DATA";
export const ACTIVITIES_SET_EDITING = "ACTIVITIES_SET_EDITING";
export const ACTIVITIES_CLEAR_EDITING = "ACTIVITIES_CLEAR_EDITING";
export const ACTIVITES_CLEAR_IMPORT_DATA = "ACTIVITES_CLEAR_IMPORT_DATA";
export const ACTIVITES_DUPLICATE = "ACTIVITES_DUPLICATE";

//Universal
export const UNIVERSAL_UPLOAD_FILE = "UNIVERSAL_UPLOAD_FILE";
export const UNIVERSAL_UPLOAD_LARGE_FILE = "UNIVERSAL_UPLOAD_LARGE_FILE";
export const UNIVERSAL_DELETE_FILE = "UNIVERSAL_DELETE_FILE";

//Player
export const PLAYER_SET_DEVICE_INFO = "PLAYER_SET_DEVICE_INFO";