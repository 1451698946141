import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableCell,
  DataTableBody,
} from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { Typography } from "@rmwc/typography"; //Sass imported in main. CSS not needed here.
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Theme } from "@rmwc/theme"; //Sass imported in main. CSS not needed here.

import style from "./ChannelsTable.module.scss";

import PageLoadingIndicator from "../../PageLoadingIndicator/PageLoadingIndicator";
import PageError from "../../Error/PageError";
import { sortInAllDirections } from "../../../utils/helpers";

export const ChannelsTable = ({ channels, onSort }) => {
  /// Hooks
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState(1); // null = unsorted, 1 = ascending, -1 = descending
  const [list, setList] = useState([]);

  /// Effects
  // This effect should watch for changes to the available resources list, the sort column, and sort direction. It will produce a sorted list to be displayed by the component.
  useEffect(() => {

    if (channels.list) {
      const listMapped = channels.list.map((i) => {
        const channels = {};

        channels.key = i.id;
        channels.id = i.id;
        channels.image = i.image_url;
        channels.name = i.name;

        return channels;
      });

      // If both sortColumn and sortDirection are defined, sort array and set state
      if (sortColumn && sortDirection) {
        const listSorted = sortInAllDirections(listMapped, sortColumn, sortDirection)
        setList(listSorted);
        // Else just set state to the unsorted list
      } else {
        setList(listMapped);
      }
    }
  }, [channels.list, sortColumn, sortDirection]);

  /// Constants & other
  const headers = [
    {
      id: 1,
      name: "name",
      label: "Channel",
      sortable: true,
    },
    {
      id: 2,
      name: "action",
      label: "Action",
      sortable: false,
    },
  ];

  /// Event handler function
  const handleOnSortChange = (column, sortDir) => {
    setSortColumn(column);
    setSortDirection(sortDir);
    onSort(column, sortDir);
  };

  /// Render helper functions
  const renderChannelName = (id, name, image) => {
    return (
      <div className={style.channelContainer}>
        {/* <div className={style.channelImage}> */}
        <Avatar
          size="xlarge"
          name={name}
          src={image}
          className={style.channelImage}
        />
        {/* </div> */}

        <div className={style.channelNameContainer}>
          <Typography use="body1" tag="div" className={style.channelName}>
            {name ? name : "Untitled channel"}
          </Typography>
          <Theme use={["textSecondaryOnLight"]} wrap>
            <Typography
              use="body2"
              tag="div"
              theme={["secondaryBg, onSecondary"]}
              className={style.channelId}
              title={id}
            >
              {id}
            </Typography>
          </Theme>
        </div>
      </div>
    );
  };
  
  const renderTableHeaders = () => {
    return headers.map((i) => {
      if (i.sortable) {
        return (
          <DataTableHeadCell
            // alignStart
            key={`cr-${i.name}-${i.id}`}
            sort={sortColumn === i.name ? sortDirection : null}
            onSortChange={(sortDir) => handleOnSortChange(i.name, sortDir)}
          >
            {i.label}
          </DataTableHeadCell>
        );
      } else {
        return (
          <DataTableHeadCell key={`cr-${i.name}-${i.id}`}>
            {i.label}
          </DataTableHeadCell>
        );
      }
    });
  };

  const renderTableRows = () => {
    if (!list) {
      return null;
    }
    if (list.length === 0) {
      return (
        <DataTableRow>
          <DataTableCell>
            <div className={style.tableName}>No channels were found.</div>
          </DataTableCell>
          <DataTableCell />
        </DataTableRow>
      );
    }

    const rows = list.map((c) => {
      return (
        <DataTableRow key={c.id}>
          <DataTableCell>
            {renderChannelName(c.id, c.name, c.image)}
          </DataTableCell>
          <DataTableCell>
            <Link to={`/admin/channel/${c.id}`}>
              <Button label="View" />
            </Link>
          </DataTableCell>
        </DataTableRow>
      );
    });
    return rows;
  };

  const renderContent = () => {
    if (channels.fetching || channels.isProcessing) {
      return <PageLoadingIndicator />;
    } else if (channels.error) {
      return <PageError error={channels.error} />;
    } else {
      return (
        <DataTable className={style.tableContainer} stickyRows={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>{renderTableHeaders()}</DataTableRow>
            </DataTableHead>
            <DataTableBody>{renderTableRows()}</DataTableBody>
          </DataTableContent>
        </DataTable>
      );
    }
  };

  return <div className={style.wrapper}>{renderContent()}</div>;
};

export default ChannelsTable;
