import React, { useEffect } from "react";
import { useUserState } from "../../hooks/useUserState";
import { useQuery } from "../../hooks/useQuery";

const SignInRedirect = props => {
  const userMeta = useUserState();
  const query = useQuery();

  useEffect(() => {
    if (userMeta.loginStatus === "isLoggedIn") {
      const state = query.get("state");
      let goTo = "/"; //Default to redirect home
      if (state) {
        const item = localStorage.getItem(state);
        if (item) {
          const redirect = JSON.parse(item);
          localStorage.removeItem(state);

          const deltaTime = Date.now() - redirect.timestamp;

          if (deltaTime < 10000) {
            //10 second timeout
            goTo = redirect.path;
          }
        }
      }

      props.history.push(goTo);
    }
  }, [userMeta, props.history, query]);

  return <div>Redirecting...</div>;
};

export default SignInRedirect;
