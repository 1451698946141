import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

/**
 * TODO: This hook has issues. Needs more debugging. Sometimes it returns the right value and then it will run again later and return null.
 *
 * This will return a null if the user is not logged in or is in the process of logging in.
 * Otherwise it will return a bool indicating if this user is an LTI User or not
 */
export const useIsLTIUser = () => {
  const user = useSelector((state) => state.User);
  const [isLTIUser, setIsLTIUser] = useState(user.isLtiUser);

  useEffect(() => {
    const ltiUser = user.isLtiUser !== null ? user.isLtiUser : false;
    setIsLTIUser(ltiUser);
  }, [user.isLtiUser]);

  return isLTIUser;
};
