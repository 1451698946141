import { useDispatch } from 'react-redux';
import Actions from './Redux/actions';

const Resize = () => {    
    const dispatch = useDispatch();
    
    const windowResized = () => {    
        dispatch(Actions.Player.setDeviceInfo());
    }

    window.addEventListener('resize', windowResized);  

    return (null);
}

export default Resize;