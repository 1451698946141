import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const Terms = () => {
  return (
    <PageWrapper protectedContent={false}>
      <Container fluid className="Terms">
        <Row>
          <Col xs={12} className="Terms__title">
            <span>Terms and Privacy Policy</span>
          </Col>
          <Col xs={12}>
            <span>
              VIVED End User License Agreement And Privacy Policy Cyber Anatomy
              Corporation dba VIVED (“VIVED) is pleased to provide you its
              mobile downloadable applications (each, a VIVED Product, and
              collectively, the VIVED Products). PLEASE READ THIS END-USER
              LICENSE AGREEMENT CAREFULLY BEFORE ACCESSING OR USING ANY VIVED
              PRODUCTS, for this End-User License Agreement governs your use of
              the VIVED Products and how we provide our VIVED Products. We have
              recently updated our policies and we are in the process of
              translating it into several languages. If you do not have an
              appropriate language option and you have any questions please
              contact us via the methods described below. 1. Agreement This
              End-User License Agreement (EULA) is a legal agreement between you
              and Cyber Anatomy Corporation dba VIVED (“VIVED, we, us or our)
              for the VIVED Products. By installing or otherwise using the VIVED
              Products, you: (a) agree to be bounded by the terms of this EULA,
              (b) you are the owner or an authorized user of the computer or
              mobile device in which the VIVED Product will be installed, and
              (c) you represent and warrant that you have the right, authority
              and capacity to enter into this agreement and to abide by all its
              terms and conditions, just as if you had signed it. The terms of
              this EULA also apply to any VIVED Product updates, supplements,
              and services. If you do not agree to the terms of this EULA, do
              not install or use the VIVED Products. VIVED may amend these terms
              from time to time. If you do not agree with any amendment, you
              must discontinue using the VIVED Products. If you have any
              questions or concerns regarding the terms herein, please email
              VIVED at 805 2nd Street, Suite 1 Coralville, IA 52241 or
              support@vivedlearning.com. Do not use the VIVED Products until
              your questions and concerns have been answered to your
              satisfaction and you agree to abide by the EULA. Notice to
              consumers: Depending on the laws of the jurisdiction where you
              live, you may have certain rights that cannot be waived through
              this agreement, are in addition to the terms of this agreement,
              and/or certain provisions of this agreement may be unenforceable
              as to you. To the extent that any term of this agreement is
              unenforceable, the remainder of the agreement shall remain in full
              force and effect. If you have questions regarding your rights
              under your local consumer protection laws, please consult with
              your governments consumer protection agency or a local consumer
              advocacy group. By downloading a VIVED Product, you will be
              installing a software application. 2. Age Requirement You must be
              13 years of age or older to install or to use the VIVED Products.
              If you are at least 13 but not yet 18 years of age, please have
              your parent or legal guardian review this EULA with you, discuss
              any questions you might have, and install the VIVED Products for
              you. NOTICE TO PARENTS AND GUARDIANS: By granting your child
              permission to download and access a VIVED Product, you agree to
              the terms of this EULA on behalf of your child. You are
              responsible for exercising supervision over your childrens online
              activities. If you do not agree to this EULA, do not let your
              child use the VIVED Products or associated features. If you are
              the parent or guardian of a child under 13 and believe that he or
              she is using the VIVED Products, please contact us at
              support@vivedlearning.com. 3. Grant of License Subject to your
              compliance with the terms and conditions of this EULA, Vivid
              grants you a limited, non-exclusive, revocable, non-sublicensable,
              non-transferable license, to access, download and install the most
              current generally available version of the VIVED Products
              (including all updates thereto) solely for your lawful, personal,
              and non-commercial use. 4. Description of Other Rights and
              Limitations A) Restricted Use. You may not rent, sell, lease,
              sublicense, distribute, assign, copy (other than a single copy for
              your own backup purposes), or in any way transfer the VIVED
              Products or use the VIVED Products for the benefit of any third
              party. You may not install the VIVED Products on any computer
              without permission from the owner of that computer. You agree that
              you will not use any robot, spider, other automatic or manual
              device or process to interfere or attempt to interfere with the
              proper working of the VIVED Products, except to remove our VIVED
              Products from a computer of which you are an owner or authorized
              user. You may not violate or attempt to violate the security of
              our services. You may not modify, reverse-engineer, decompile,
              disassemble, or otherwise tamper with any VIVED Products, or
              attempt to do so for any reason. You may not access, create or
              modify the source code of any VIVED Products in any way. You do
              not have the right to and may not create derivative works of any
              VIVED Products. All modifications or enhancements to a VIVED
              Products remain the sole property of VIVED. B) VIVED Products
              Updates. We reserve the right to add or remove features or
              functions to existing VIVED Products. When installed on your
              computer, the VIVED Products periodically communicates with our
              servers. We may require the updating of the VIVED Products on your
              computer when we release a new version of the VIVED Products, or
              when we make new features available. This update may occur
              automatically or upon prior notice to you and may occur all at
              once or over multiple sessions. You understand that we may require
              your review and acceptance of our then-current EULA before you
              will be permitted to use any subsequent versions of the VIVED
              Products. You acknowledge and agree that we have no obligation to
              make available to you any subsequent versions of the VIVED
              Products. C) Mobile Usage. If you access a VIVED Product through a
              mobile network, your network or roaming providers messaging, data
              and other rates and fees may apply. Downloading, installing or
              using certain VIVED Products may be prohibited or restricted by
              your network provider and not all VIVED Products may work with
              your network provider or device. 5. Registration and Passwords A)
              Registration. Most VIVED Products will not require a registration:
              however, some VIVED Products may permit or require you to create
              an account to participate or access additional features or
              functionalities (Registration). If such Registration is required,
              it will be made known to you when you attempt to participate or
              access such additional features or functionalities, associate
              website or specific service. B) Passwords. You are the sole and
              exclusive guardian of any password and ID combination issued or
              chosen by to you. Maintaining the confidentiality and security of
              your Password(s) and ID(s) is solely your responsibility. You are
              fully responsible for all transactions undertaken by means of any
              account opened, held, accessed or used via your password and ID.
              You shall notify us immediately and confirm in writing any
              unauthorized use of accounts or any breach of security, including
              without limitation any loss, theft or unauthorized use of your
              password(s), and/or ID(s) or any related account. If we have
              reasonable grounds to suspect that the security of your Password
              and/or ID has been compromised, we may suspend or terminate your
              account, refuse any and all current or future use of the services,
              and pursue any appropriate legal remedies. We shall not be
              responsible for any losses incurred in connection with any misuse
              of any Password or ID. C) Provided Information. If you provide any
              information in connection with a Registration, you must provide
              and maintain accurate, complete and current information. If we
              have reasonable grounds to suspect that your information is
              inaccurate, not current or not complete, we may suspend or
              terminate your use of the VIVED Product, and pursue any
              appropriate legal remedies. You agree that we shall have the right
              to use the information you provide to us for the purposes
              described in this EULA and in furtherance of your use of our
              services. 6. Privacy By using the VIVED Products and any features,
              services and websites available through the VIVED Products, you
              agree to the terms of our Privacy Policy, which are incorporated
              into this EULA and which set out the practices concerning the
              information processed by VIVED. 7. Intellectual Property The VIVED
              Products, including all code, content, protocols, software, and
              documentation provided to you by VIVED are VIVEDs property, and
              are protected by U.S. and international copyright, trademarks,
              patents and other proprietary rights and laws relating to
              Intellectual Property Rights. Intellectual Property Rights means,
              collectively, rights under patent, trademark, copyright and trade
              secret laws, and any other intellectual property or proprietary
              rights recognized in any country or jurisdiction worldwide,
              including, without limitation, moral or similar rights. You may
              not delete, alter, or remove any copyright, trademark, or other
              proprietary rights notice we have placed on the VIVED Products.
              All rights not expressly granted hereunder are expressly reserved
              to Licensor and its licensors. The VIVED name, logos and
              affiliated properties, are the exclusive property of VIVED. All
              other trademarks appearing on any VIVED Products are trademarks of
              their respective owners. Our partners or service providers may
              also have additional proprietary rights in the content which they
              make available through a Vivid Product. The trade names,
              trademarks and service marks owned by us, whether registered or
              unregistered, may not be used in connection with any product or
              service that is not ours, in any manner that is likely to cause
              confusion. Nothing contained in herein should be construed as
              granting, by implication, estoppel or otherwise, any license or
              right to use any of our trade names, trademarks or service marks
              without our express prior written consent. 8.
              Copyright/Submissions A) Copyright Protection of content you
              display using our services. You are solely responsible for any
              Content you contribute, submit or display on or thorough your use
              of the VIVED Product(s). It is your obligation to ensure that such
              content, including photos, text, video and music files, is not
              violating any copyright. You must either own or have a license to
              use any copyrighted content that you contribute, submit or
              display. B) VIVED respects and expects its users to respect the
              rights of copyright holders. On notice, VIVED will act
              appropriately to remove content that infringes the copyright
              rights of others. 9. Termination You understand that VIVED, in its
              sole discretion, may modify or discontinue or suspend your right
              to access any of our services or use of any VIVED Products at any
              time. Further, VIVED, with or without any reason, may at any time
              suspend or terminate any license hereunder and disable the VIVED
              Products or any of its component features. You agree that VIVED
              shall not be liable to you or any third-party for any termination
              or disabling of the VIVED Products. 10. Disclaimer of Warranty ALL
              VIVED PRODUCTS ARE PROVIDED ON AN AS IS AND AS AVAILABLE BASIS,
              AND YOU USE THEM AT YOUR SOLE RISK. VIVED EXPRESSLY DISCLAIMS ANY
              AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              NON-INFRINGEMENT, AND ANY OTHER WARRANTY ARISING UNDER THE UNIFORM
              COMMERCIAL CODE, USAGE OF TRADE, COURSE OF CONDUCT OR OTHERWISE.
              WITHOUT LIMITATION, VIVED MAKES NO WARRANTY THAT THE VIVED PRODUCT
              WILL MEET YOUR REQUIREMENTS, THAT THEY WILL BE UNINTERRUPTED,
              TIMELY, SECURE, OR ERROR-FREE, THAT THE RESULTS OBTAINED FROM THE
              USE OF THE VIVED PRODUCTS WILL BE ACCURATE OR RELIABLE, OR THAT
              THE QUALITY OF THE VIVED PRODUCT OBTAINED WILL MEET YOUR
              EXPECTATIONS. THE ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF
              A VIVED PRODUCT REMAINS SOLELY WITH YOU. VIVED EXPRESSLY DISCLAIMS
              ALL WARRANTIES RELATING TO PRODUCTS AND/OR SERVICES PROVIDED BY
              THIRD PARTY PARTNERS. SOME JURISDICTIONS DO NOT ALLOW THE
              DISCLAIMER OF IMPLIED WARRANTIES. IN SUCH JURISDICTIONS, THE
              FOREGOING DISCLAIMERS MAY NOT APPLY TO YOU INSOFAR AS THEY RELATE
              TO IMPLIED WARRANTIES. THIS DISCLAIMER OF WARRANTY CONSTITUTES AN
              ESSENTIAL PART OF THIS AGREEMENT. 11. Limitation of Liability YOU
              EXPRESSLY UNDERSTAND AND AGREE THAT VIVED SHALL NOT BE LIABLE FOR
              ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
              EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS
              OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN
              IF VIVED HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
              RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE VIVED
              PRODUCT; (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
              TRANSMISSIONS OR DATA; (III) STATEMENTS OR CONDUCT OF ANY THIRD
              PARTY; OR (IV) ANY OTHER MATTER RELATING TO THE VIVED PRODUCT. IN
              NO EVENT SHALL VIVID’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES,
              LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT
              (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) EXCEED
              THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING A VIVED PRODUCT. IF
              ANY OF THE EXCLUSIONS SET FORTH IN THIS SECTION IS DETERMINED BY A
              COURT OF COMPETENT JURISDICTION TO BE UNENFORCEABLE, THEN ALL SUCH
              EXPRESS, IMPLIED AND STATUTORY WARRANTIES SHALL BE LIMITED IN
              DURATION FOR A PERIOD OF THIRTY (30) DAYS AFTER THE DATE ON WHICH
              YOU FIRST ACCESS THE VIVED PRODUCT, AND NO WARRANTIES SHALL APPLY
              AFTER SUCH PERIOD. 12. Indemnification YOU AGREE TO INDEMNIFY,
              DEFEND AND HOLD HARMLESS VIVED AND ITS OFFICERS, DIRECTORS,
              EMPLOYEES, CONSULTANTS AND AGENTS FROM ANY AND ALL THIRD PARTY
              CLAIMS, LIABILITY, DAMAGES AND/OR COSTS (INCLUDING, BUT NOT
              LIMITED TO, ATTORNEYS FEES) ARISING FROM YOUR USE OF THE VIVED
              PRODUCTS, YOUR VIOLATION OF THE EULA OR YOUR INFRINGEMENT, OR
              INFRINGEMENT BY ANY OTHER USER OF YOUR ACCOUNT, OF ANY
              INTELLECTUAL PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY. YOU
              AGREE TO IMMEDIATELY NOTIFY VIVED OF ANY UNAUTHORIZED USE OF YOUR
              ACCOUNT OR ANY OTHER BREACH OF SECURITY KNOWN TO YOU. 13. Export
              Controls The VIVED Products and the underlying information and
              technology may not be downloaded or otherwise exported or
              re-exported (a) into (or to a national or resident of) any country
              to which the U.S. has currently embargoed goods; or (b) to anyone
              on the U.S. Treasury Departments list of Specially Designated
              Nationals or the U.S. Commerce Departments Table of Deny Orders.
              By downloading or using the VIVED Product, you agree to the
              foregoing and you represent and warrant that you are not located
              in, under the control of, or a national or resident of any such
              country or on any such list, and that you will otherwise comply
              with all applicable export control laws. 14. Notice to Government
              End Users Any VIVED Products installed for or on behalf of the
              United States of America, its agencies and/or instrumentalities
              (U.S. Government), is provided with Restricted Rights as
              commercial Items, as that terms is defined at 48 C.F.R. §2.101,
              consisting of Commercial Computer Software and Commercial Computer
              Software Documentation, as such terms are used in 48 C.F.R.
              §12.212 or 48 C.F.R. §227.7202, as applicable. Pursuant to Federal
              Acquisition Regulation 12.212 (48 C.F.R. §12.212), the U.S.
              Government shall have only those rights specified in the license
              contained herein. The U.S. Government shall not be entitled to (i)
              technical information that is not customarily provided to the
              public or to (ii) use, modify, reproduce, release, perform,
              display, or disclose commercial computer software or commercial
              computer software documentation except as specified herein. Use,
              duplication, or disclosure by the U.S. Government is subject to
              restrictions as set forth in subparagraph (c)(1)(ii) of the Rights
              in Technical Data and Computer Software clause at DFARS
              252.227-7013 or subparagraphs (c)(1) and (2) of the Commercial
              Computer Software - Restricted Rights at 48 C.F.R. 52.227-19, as
              applicable. 15. Governing Law and Other Miscellaneous Terms The
              substantive laws of the State of Iowa in the United States of
              America, without regard to conflict of laws principles, shall
              govern all matters relating to or arising from this EULA, and the
              use (or inability to use) the VIVED Products. You hereby submit to
              the exclusive jurisdiction and venue of the appropriate State and
              Federal courts located in Johnson County, Iowa, with respect to
              all matters arising out of or relating to this EULA. END OF
              DOCUMENT.
            </span>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default Terms;
