import PropTypes from "prop-types";
import React from "react";
import style from "./Headings.module.scss";
import { Typography } from "@rmwc/typography"; // Using Sas in Main. CSS not need here.

import { TypographyUsePropType, ThemeUsePropType } from "../../constants/rmwcPropTypes";

/**
 * A row containing the heading for a page. Can optionally display one action button aligned to the right of the row.
 */
export const SectionHeadlineRow = ({
  className,
  title,
  typographyStyle,
  typographyTheme,
  tag,
  actionButton,
  ...otherProps
}) => {
  const classes = [style.container, className ? className : null].join(" ");
  return (
    <div className={classes} {...otherProps}>
      <Typography
        use={typographyStyle}
        tag={tag}
        className={style.title}
        theme={typographyTheme}
      >
        {title}
      </Typography>
      <div className={style.action}>{actionButton}</div>
    </div>
  );
};
SectionHeadlineRow.defaultProps = {
  typographyStyle: "headline6",
  tag: "h1",
};
SectionHeadlineRow.propTypes = {
  actionButton: PropTypes.element,
  className: PropTypes.string,
  typographyStyle: TypographyUsePropType,
  typographyTheme: ThemeUsePropType,
  tag: PropTypes.string,
  title: PropTypes.string.isRequired,
};
