import React from "react";

import style from "./Footer.module.scss";
import SvgVivedBannerNobkgd from "../../../assets/images/LogoBannerNobg";

export const Footer = () => {
  return (
    <div className={style.container}>
      <div className={style.banner}>
        <SvgVivedBannerNobkgd />
      </div>
    </div>
  );
};
