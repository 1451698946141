import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { ChannelRole, LoginEnum } from "../../Redux/Users/UserTypes";

/**
 * @description returns the role of the current user for the given channel
 * @param {string} channelId The id of the channel
 * @returns {{role: string, isPending: boolean}} The user's role and its pending status
 */
export const useChannelRole = (channelId) => {
  const [role, setRole] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const user = useSelector((state) => state.User);

  useEffect(() => {
    if (channelId) {
      // If no channelId, there's nothing to do
      if (
        user.loginStatus === LoginEnum.init ||
        user.loginStatus === LoginEnum.isLoggingIn
      ) {
        // If not logged in then we're still waiting for the data, so return
        return;
      } else if (user.loginStatus === LoginEnum.isLoggedIn) {
        if (user.channels && user.channels.hasOwnProperty(channelId)) {
          setRole(user.channels[channelId]);
          setIsPending(false);
        } else if (
          user.pendingChannels &&
          user.pendingChannels.hasOwnProperty(channelId)
        ) {
          setRole(user.pendingChannels[channelId]);
          setIsPending(true);
        } else {
          console.warn(
            `[useChannelRole] User Logged In, but no channel role found. Setting role set to "none"`
          );
          setRole(ChannelRole.none);
        }
      } else if (user.loginStatus === "isNotLoggedIn") {
        setRole(ChannelRole.none);
      }
    }
  }, [user.channels, user.pendingChannels, user.loginStatus, channelId]);

  return { role, isPending };
};
