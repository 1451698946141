export default async function basicPutFile (url, body, contentType) {
  
  return await fetch(url, {
    method: "PUT",
    body,
    headers: {
      "Content-Type": contentType
    }
  })
  .then(async (response) => {
    if(response.status === 200) {
      return true
    } else {
      throw new Error("failed to upload large file")
    }
  })
  .catch(err => {
    throw new Error(err)
  })
}