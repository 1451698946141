import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import '@rmwc/card/styles';
import { Typography } from "@rmwc/typography"; // Sass included in main. CSS not needed.
import { Ripple } from "@rmwc/ripple";
import '@rmwc/ripple/styles';
import style from "./ChannelCard.module.scss";

import { useQuery } from "../../../hooks/useQuery";
import globalStyle from "../../../styles/global.module.scss";


const ChannelCard = ({ name, channelId, image, subscriberRole, canAuthor_asSubscriber = false, canEdit_asSubscriber = false, expiration_forSubscription = null, subscriberId = null }) => {
  const query = useQuery();
  const searchParams = query?.toString();

  let channelPath = `/channel/${channelId}`;

  if(searchParams) {
    channelPath += `?${searchParams}`
  }

  return (
    <Ripple>
      <div className={style.container}>
        <Link onClick={()=>{}} to={{ pathname: channelPath, state: { subscriberId, subscriberRole, canAuthor_asSubscriber, canEdit_asSubscriber, expiration_forSubscription }}} className={globalStyle.linkNoStyle}>
          <div className={style.card}>
            <img
              alt=""
              className={style.image}
              style={{ borderRadius: "50%", backgroundImage: `url(${image}` }}
            />
            <Typography
              use="subtitle1"
              tag="h2"
              className={style.title}
              title={name}
            >
              {name}
            </Typography>
          </div>
        </Link>
      </div>
    </Ripple>
  );
};

ChannelCard.defaultProps = {
  title: "Untitled Channel"
  // image: // ToDo: Create a placeholder image and add to project to load if no image is provided.
};

ChannelCard.propTypes = {
  /**
   * The unique id of the channel. Required.
   */
  channelId: PropTypes.string.isRequired,
  /**
   * The url to the channel image. Optional.
   */
  image: PropTypes.string,
  /**
   * The name of the channel. If not provided the name will
   * default to `Untitled Channel`.
   */
  name: PropTypes.string,
  /**
   * The user's role for this channel. If the role is Admin or Author, a row of action
   * buttons will be displayed. Optional. Defaults to null.
   */
  role: PropTypes.string,

  subscriberId: PropTypes.string,
   
  canAuthor_asSubscriber: PropTypes.bool,

  canEdit_asSubscriber: PropTypes.bool,

  expiration_forSubscription: PropTypes.string
};

export default ChannelCard;
