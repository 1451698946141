import * as APPTYPES from "./AppsTypes";

export function getAllApps (callback) {
  return {
    type: APPTYPES.APPS_GET_ALL,
    callback,
  };
}

export function setAppsInState(apps) {
  return {
    type: APPTYPES.APPS_SET_APPS_IN_STATE,
    payload: {
      apps
    }
  }
}

export function addAppToChannel (channelId, appId, callback) {
  return {
    type: APPTYPES.APPS_ADD_TO_CHANNEL,
    payload: {
      appId,
      channelId
    },
    callback,
  };
}

export function removeAppFromChannel (channelId, appId, callback) {
  return {
    type: APPTYPES.APPS_REMOVE_TO_CHANNEL,
    payload: {
      appId,
      channelId
    },
    callback,
  };
}