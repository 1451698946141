import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Typography } from "@rmwc/typography"; // Sass used in Main, css not needed here

import style from "./Landing.module.scss";
import { heroImageDesktop, heroImageMobile, heroImageAlt, crossDeviceImageDesktop, crossDeviceImageMobile, crossDeviceImageAlt } from "./media";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import { ContentContainer } from "../../components/Layout/Layout";
import { Hero } from "../../components/Landing/Hero/Hero";
import { ThreeKeyPoints, KeyPoint } from "../../components/Landing/ThreeKeyPoints/ThreeKeyPoints";
import { FeaturedImage } from "../../components/Landing/FeaturedImage/FeaturedImage";
import { KeyFeatures, KeyFeaturesTitle, KeyFeaturesItem, KeyFeaturesList } from "../../components/Landing/KeyFeatures/KeyFeatures";
import { Hook } from "../../components/Landing/Hook/Hook";
import { Footer } from "../../components/Landing/Footer/Footer";
import { InteractiveDemo } from "../../components/Landing/InteractiveDemo/InteractiveDemo";

import { LoginEnum } from "../../Redux/Users/UserTypes";
import { useIsLTIUser } from "../../hooks/User/useIsLTIUser";
import { useUserRoles } from "../../hooks/User/useUserRoles";
import { SuperCentered } from "../../components/Layout/OneLineLayouts/SuperCentered/SuperCentered";
import VivedIconCustomCreatePen from "../../assets/nucleo/icons/VIVED_icon_custom_create-pen";
import VivedIconCustomUploadCloud from "../../assets/nucleo/icons/VIVED_icon_custom_upload_cloud";
import VivedIconCustomSharePerson from "../../assets/nucleo/icons/VIVED_icon_custom_share_person";
import VivedIconStudentEngagement from "../../assets/nucleo/icons/VIVED_icon_student_engagement";
import VivedIcon21stCenturySkills from "../../assets/nucleo/icons/VIVED_icon_21st_century_skills";
import VivedIconCurriculumAlignment from "../../assets/nucleo/icons/VIVED_icon_curriculum_alignment";



const Landing = (props) => {      
    //Hooks
    const loginStatus = useSelector((state) => state.User.loginStatus);
    const isLTIUser = useIsLTIUser();
    const userRoles = useUserRoles();
    const history = useHistory();

    useEffect(() => {       
        if (loginStatus === LoginEnum.isLoggedIn && userRoles) {
            // user is now logged in                
            const userChannelList = Object.keys(userRoles);
            if(userChannelList.length === 0){
                // no user channels yet, send to join
                history.push("/join");   
            }else {

                history.replace("/dashboard");
            }
        }             
    }, [loginStatus, history, userRoles]);

    // eslint-disable-next-line no-unused-vars
    const signinRedirect = pathName => {
        if(props.redirect){
            return(
                props.history.push({
                    pathname: `/${pathName}`,
                    search: `?redirect=${
                      props.redirect
                    }`,
                  })
            );
        }
        else{
            return(
                props.history.push(`/${pathName}`)
            );
        }
    }

    const learnMoreOnClick = () => {
        window.location.href = "https://vivedlearning.com/vlp";
    } 

    const renderContent = () => {
        return (
            <ContentContainer fullWidth >
                <Hero
                    headline="Engage your students with visual learning resources"
                    subHeadline="With the VIVED Learning Platform students and teachers quickly and easily create, publish and share knowledge in 3D."
                    primaryAction={ <Button label="Learn More" raised onClick={learnMoreOnClick} /> }
                    secondaryAction={ <Button label="Register" raised onClick={()=>props.history.push("/register")} /> }
                    imageDesktop={heroImageDesktop}
                    imageMobile={heroImageMobile}
                    imageAlt={heroImageAlt}

                />
                <ThreeKeyPoints className={style.keyPointsBackground}>
                    <KeyPoint
                        graphic={
                            <SuperCentered className={style.keyPointItemGraphic}>
                                <VivedIconStudentEngagement secondaryFill="rgba(0, 0, 0, 0)"/>
                            </SuperCentered>
                        }
                        heading="Student engagement"
                        description="Many students are visual learners who are used to using their phones, tablets, and computers to learn and game. We are meeting students where they are with our visual solutions, increasing learning opportunities and providing a safe environment to foster learning."
                    />
                    <KeyPoint
                        graphic={
                            <SuperCentered className={style.keyPointItemGraphic}>
                                <VivedIcon21stCenturySkills />
                            </SuperCentered>
                        }
                        heading="21st century skills"
                        description="Using technology to learn online using the VLP 3D solutions is preparing students of today for the skills they will need in the workforce in the future."
                    />
                    <KeyPoint
                        graphic={
                            <SuperCentered className={style.keyPointItemGraphic}>
                                <VivedIconCurriculumAlignment />
                            </SuperCentered>
                        }
                        heading="Curriculum alignment"
                        description="VLP activities align with standards and they enable teachers to display a concept in three dimensions, so that the learner can ‘see’ a concept."
                    />
                </ThreeKeyPoints>
                <FeaturedImage
                    imageAlt={crossDeviceImageAlt}
                    imageMobile={crossDeviceImageMobile}
                    imageDesktop={crossDeviceImageDesktop}
                    className={style.featuredImageContainer}
                />
                <KeyFeatures>
                    <KeyFeaturesTitle title="Key Features" />
                    <KeyFeaturesList>
                        <KeyFeaturesItem
                            headline="Create"
                            description="Teacher or students can create a 3D activity using the VLP editor."
                            graphic={
                                <SuperCentered className={style.featureItemGraphic}>
                                    <VivedIconCustomCreatePen />
                                </SuperCentered>
                            }
                        />
                        <KeyFeaturesItem
                            headline="Publish"
                            description="Once complete, the finished activity can be saved and published on VLP."
                            graphic={
                                <SuperCentered className={style.featureItemGraphic}>
                                    <VivedIconCustomUploadCloud />
                                </SuperCentered>
                            }
                        />
                        <KeyFeaturesItem
                            headline="Share"
                            description="The activity can then be added to an LMS or shared anywhere online."
                            graphic={
                                <SuperCentered className={style.featureItemGraphic}>
                                    <VivedIconCustomSharePerson />
                                </SuperCentered>
                            }
                        />
                    </KeyFeaturesList>
                </KeyFeatures>
                <InteractiveDemo
                    headline="Interactive Demo"
                />
                <Hook
                    message="Start learning today with the new VIVED Learning Platform."
                    actionLabel="Learn More"
                    actionOnClick={learnMoreOnClick}
                />
                <Footer />
            </ContentContainer>
        )
    }

    const renderPage = () => {
        if(isLTIUser){
            // LTI user     
            return(
                <Redirect to="/channels" />
            );            
        }
        else{            
            if(loginStatus === LoginEnum.isNotLoggedIn){
                // not logged in
                return renderContent();
            }    
            else {
                return (
                    <div>
                        <Typography use="headline6" tag="h1" className={style.pageTitle}>
                            VIVED Learning Platform
                        </Typography>
                        <label>Logging in...</label>
                        <PageLoadingIndicator />
                    </div>            
                );
            }   
        } 
    }

    return (
        <PageWrapper protectedContent={false} fixedAppBar={false}>
            {renderPage()}
        </PageWrapper>
    );    
}

const MapStateToProps = (state, ownProps) => {    
    // figure out if there's a redirect
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get('redirect');

    return {               
        redirect   
    };
  }
  
  export default connect(MapStateToProps,{})(Landing);