import React from 'react';
import { Col, Alert } from "react-bootstrap";

const Error = ({ message }) => (
  <Col xs={12}>
    <Alert variant="danger">
      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
      <p>
        We got the following error: {message}
      </p>
    </Alert>
  </Col>);

export default Error