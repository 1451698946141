import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Image } from "react-bootstrap";

import { DialogContent, DialogActions, DialogButton } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import style from "./EditActivityMetadataForm.module.scss";

import { InputErrorMessage } from "../../Auth/AuthInputFields";
import { fetchUpdateMeta } from "../../../actions/activities";
import Actions from "../../../Redux/actions";
import { snackbarQueue } from "../../../components/Snackbar/snackbarQueue";
import { ImageToBlob } from "../../../utils/helpers";

import imagePlaceholder from "../../../assets/images/placeholder-image_16x9_Generic_400x255.svg";

export const EditActivityMetadataForm = ({
  processing,
  setProcessing,
  activityMeta,
  formDefaultValues,
  onClose,
  open,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    errors,
    formState,
  } = useForm({
    mode: "onChange",
    defaultValues: formDefaultValues,
  });
  const { isValid: formIsValid } = formState;
  const [newActivityImage, setNewActivityImage] = useState(undefined);
  const [metadataChanged, setMetadataChanged] = useState(false);
  const [newImagePreview, setNewImagePreview] = useState(null);
  const dispatch = useDispatch();
  const imageInputRef = useRef();
  const watchTitle = watch("title");
  const watchDescription = watch("description");

  // This effect checks for user changes to the activity metadata form and update the setMetadataChanged state property.
  useEffect(() => {
    if (
      watchTitle !== activityMeta.title ||
      watchDescription !== activityMeta.description ||
      newActivityImage
    ) {
      setMetadataChanged(true);
    } else {
      setMetadataChanged(false);
    }
  }, [
    activityMeta.description,
    activityMeta.title,
    activityMeta.image,
    watchTitle,
    watchDescription,
    newActivityImage,
  ]);

  // This hook creates the preview image whenever a new image has been selected
  useEffect(() => {
    if (newActivityImage) {
      ImageToBlob(newActivityImage[0])
        .then((resp) => {
          setNewImagePreview(resp);
        })
        .catch((err) => {
          console.error("[onNewActivityImageChange] ImageToBlob failed! ", err);
        });
    }
  }, [newActivityImage]);

  /// Event handler functions
  const onSubmit = async (data) => {
    const attributes = {};
    let newImage = null;

    // If title has changed, include it in the attributes object sent to API
    if (data.title && data.title.trim() !== activityMeta.title) {
      attributes.title = data.title.trim();
    }

    // If description has changed, include it in the attributes object sent to API
    if (
      data.description &&
      data.description.trim() !== activityMeta.description
    ) {
      attributes.description = data.description.trim();
    }

    // If image has changed, get the path to the new image.
    if (data.image.length > 0) {
      newImage = data.image[0];
    }

    // This HAS to be positioned here or we will lose imageInputRef
    setProcessing(true);

    // Upload the image (if it was changed), and submit the attributes to the API.
    uploadActivityImage(newImage, "Thumbnails", (newImageName) => {
      attributes.image = newImageName;
      onUpdateMeta(attributes, (resp) => {
        if (resp.success) {
          onClose();
          onSaveSuccess();
          setMetadataChanged(false);

          reset({
            title: data.title ? data.title : "",
            description: data.description ? data.description : "",
            image: null,
          });
        } else {
          onSaveFail();
          onClose();
        }

        setProcessing(false);
      });
    });
  };

  /**
   * Resets the activity image input, image preview, and the react state variable. The activity image is not using React-Hook-Form so it must be handled separately from the rest of the form.
   */
  const resetActivityImage = () => {
    reset({ ...getValues(), image: null }); // resets input using react-hook-form
    setNewActivityImage(null); // resets our other image variables
    setNewImagePreview(""); // resets our other image variables
  };

  const uploadActivityImage = (file, folder, cb) => {
    if (file) {
      dispatch(Actions.Universal.upload({ file, folder }, cb));
    } else {
      cb("");
    }
  };
  const onUpdateMeta = (attributes, cb) => {
    dispatch(
      fetchUpdateMeta(activityMeta.id, activityMeta.channelId, attributes, cb)
    );
  };

  /**
   * Function to validate if the selected file is under the maximum file size limit. If no file is found, then the validation will pass. Image is not a required field.
   * @param {FileList} value The value returned by the input.
   */
  const validateImageInputFileSize = (value) => {
    const maxImageSizeBytes = 2100000; // 2 MB = 2,097,152 Bytes (in binary): rounded up

    if (value.length === 1 && value[0]) {
      const imageSizeBytes = value[0].size;

      // If the file size is larger than 2MB, validation should fail.
      // Note: Error message is defined in the input's register function
      if (imageSizeBytes > maxImageSizeBytes) {
        return false; // Validation failed
      } else {
        setNewActivityImage(value);
        return true; // Validation passed
      }
    } else {
      return true; // No file passes validation
    }
  };

  const onSaveFail = () => {
    snackbarQueue.notify({
      title: <b>Activity save failed</b>,
      body: "Activity metadata failed to update. Please try again.",
      dismissesOnAction: true,
      actions: [
        {
          title: "Dismiss",
          icon: "done",
        },
      ],
    });
  };
  const onSaveSuccess = () => {
    snackbarQueue.notify({
      title: <b>Activity saved</b>,
      body: "Activity metadata has been updated successfully.",
      dismissesOnAction: true,
      actions: [
        {
          title: "Dismiss",
          icon: "done",
        },
      ],
    });
  };

  /// Render helper functions
  const renderTitle = () => {
    const inputLabel = "Title";
    const inputName = "title";
    const inputType = "text";
    const inputMinLength = 4;
    const inputMaxLength = 100;
    const validationErrMsg = {
      required: "A title is required.",
      maxLength: `The title must be less than ${inputMaxLength} characters.`,
      minLength: `The title must be at least ${inputMinLength} characters.`,
    };

    return (
      <TextField
        // general style props
        outlined
        fullwidth
        characterCount
        // textarea style props
        textarea
        rows={1}
        // value, configuration, validation props
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.required,
          },
          required: validationErrMsg.required,
          minLength: {
            value: inputMinLength,
            message: validationErrMsg.minLength,
          },
        })}
        maxLength={inputMaxLength}
        helpText={{
          // persistent: true,
          validationMsg: true,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
          // children: {<div>}
        }}
        invalid={errors[inputName]}
        // onBlur={checkForFormChanges}
      />
    );
  };

  const renderDescription = () => {
    const inputLabel = "Description";
    const inputName = "description";
    const inputType = "text";
    const inputMaxLength = 5000;
    const validationErrMsg = {
      maxLength: `The title must be less than ${inputMaxLength} characters.`,
    };

    return (
      <TextField
        // general style props
        outlined
        fullwidth
        characterCount
        // textarea style props
        textarea
        rows={4}
        // value, configuration, validation props
        text={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
        })}
        maxLength={inputMaxLength}
        helpText={{
          // persistent: true,
          validationMsg: true,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
        invalid={errors[inputName]}
        // onBlur={checkForFormChanges}
      />
    );
  };

  const renderResetButton = () => {
    if (imageInputRef?.current?.files.length > 0) {
      return <IconButton icon="close" onClick={resetActivityImage} />;
    }
  };

  const renderActivityImgInput = () => {
    const inputName = "image";
    const inputType = "file";
    const inputTypeAccept = "image/jpeg, image/png, image/gif";
    const validationErrMsg = {
      fileSize: `Image file size must be less than 2MB.`,
    };

    return (
      <div className={style.activityImage}>
        <div className={style.activityImageHeader}>
          <span className={style.activityImageText}>Activity Image</span>
          {renderResetButton()}
          <TextField
            // general style props
            className={style.fieldFullwidth}
            // value, configuration, validation props
            type={inputType}
            accept={inputTypeAccept}
            // label={inputLabel} // We don't want this by design
            name={inputName}
            // inputRef={imageInputRef}
            inputRef={(e) => {
              register(e, {
                validate: {
                  maxFileSize: (value) =>
                    validateImageInputFileSize(value) ||
                    validationErrMsg.fileSize,
                },
              });
              imageInputRef.current = e;
            }}
            invalid={errors[inputName]}
            helpText={{
              // persistent: true,
              validationMsg: true,
              children: (
                <InputErrorMessage errors={errors} inputName={inputName} />
              ),
            }}
          />
        </div>
        <div className={style.activityImagePlaceholder}>
          <Image
            src={
              newImagePreview
                ? newImagePreview
                : activityMeta.image
                ? activityMeta.image
                : imagePlaceholder
            }
            fluid
          />
        </div>
      </div>
    );
  };

  const renderDialogContent = () => {
    if (processing) {
      return (
        <DialogContent style={{ padding: "16px" }}>
          <CircularProgress /> processing...
        </DialogContent>
      );
    } else if (open) {
      return (
        <>
          <DialogContent>
            Use the form below to edit the activity details.
            <form
              id="edit-activity-metadata"
              onSubmit={handleSubmit(onSubmit)}
              style={{ paddingTop: "16px" }}
            >
              {renderTitle()}
              {renderDescription()}
              {renderActivityImgInput()}
            </form>
          </DialogContent>

          <DialogActions>
            <DialogButton action="cancel" type="button">
              Cancel
            </DialogButton>
            <DialogButton
              form="edit-activity-metadata"
              type="submit"
              isDefaultAction
              disabled={!formIsValid || !metadataChanged}
            >
              Save
            </DialogButton>
          </DialogActions>
        </>
      );
    }
  };

  return <>{renderDialogContent()}</>;
};
