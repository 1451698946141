/* eslint-disable max-len */
import React from 'react';

const SvgVivedBrandLogoIconNobkgd = props => (
  <svg viewBox="0 0 192 192" width="24px" {...props}>
    <defs>
      <style>{'.VIVEDBrand-LogoIcon-nobkgd_svg__cls-1{fill:#0095d4}'}</style>
    </defs>
    <g id="VIVEDBrand-LogoIcon-nobkgd_svg___400_" data-name="400%">
      <g id="VIVEDBrand-LogoIcon-nobkgd_svg__Logo">
        <path
          className="VIVEDBrand-LogoIcon-nobkgd_svg__cls-1"
          d="M176 24.14v59.28L122.67 168v-59.11L176 24.14z"
        />
        <path fill="#00a7e0" d="M69.36 108.89h53.32V168H69.36z" />
        <path
          className="VIVEDBrand-LogoIcon-nobkgd_svg__cls-1"
          d="M16 24v59.28l53.33 84.58v-59.11L16 24z"
        />
        <path
          fill="#4ec8f4"
          d="M176 24.14h-53.33L96.04 66.65 69.33 24H16l53.33 84.89h53.34L176 24.14z"
        />
      </g>
    </g>
  </svg>
);

export default SvgVivedBrandLogoIconNobkgd;
