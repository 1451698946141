import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

/**
 * This hook will return a null until all the Channels have been retrieved, then it will
 * return an array of channel objects
 */
export function useAllChannels() {
  const [allChannels, setAllChannels] = useState(null);
  const [isFetching, setIsFetching] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(null); 

  const allHaveBeenRetrieved = useSelector(
    (state) => state.Channels.allHaveBeenRetrieved
  );
  const channelList = useSelector((state) => state.Channels.list);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!isFetching && !error) {
      if (!allHaveBeenRetrieved) {
        setIsProcessing(true);
        setIsFetching(true);
        dispatch(
          Actions.Channels.fetchAllChannels(0, 500, (resp) => {
            if(!resp.success) {
              setError(resp.msg);
            }
            setIsFetching(false);
          })
        );
      } else
      {
          setAllChannels(Object.values(channelList));
          setIsProcessing(false);
      }
    } 
    
  }, [allHaveBeenRetrieved, dispatch, channelList, isFetching, error, isProcessing]);

  return [allChannels, error, isFetching, isProcessing];
}
