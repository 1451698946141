import React from "react";
import { useParams, useHistory } from "react-router-dom";

import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import style from "./AdminChannel.module.scss";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { ContentContainer } from "../../components/Layout/Layout";
import { SectionHeadlineRow } from "../../components/Layout/Headings";
import { ChannelHeader } from "../../components/Admin/ChannelHeader/ChannelHeader";

import { useChannel } from "../../hooks/Channel/useChannel";
import { useState } from "react";
import { useEffect } from "react";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import PageError from "../../components/Error/PageError";
import { useChannelOwnerInfo } from "../../hooks/Channel/useChannelOwnerInfo";
import useChannelContent from "../../hooks/Channel/useChannelContent";
import { ResourcesTable } from "../../components/Admin/ResourcesTable/ResourcesTable";
import { ChannelVisibility } from "../../components/Admin/ChannelVisibility/ChannelVisibility";
import { ChannelUserLimit } from "../../components/Admin/ChannelUserLimit/ChannelUserLimit";
import { CHANNEL_VISIBILITY_TYPES } from "../../constants/channelVisibilityTypes";
import { ChannelSubscriptionsTable } from "../../components/Admin/ChannelSubscriptionsTable/ChannelSubscriptionsTable";
import AddChannelSubscriptionDialog from "../../components/Dialog/AddChannelSubscriptionDialog/AddChannelSubscriptionDialog";

export const AdminChannel = () => {
  let { channelId } = useParams();
  let history = useHistory();
  const { channel: myChannel, error: channelError } = useChannel(channelId);
  const {
    myOrganizedResources,
    fetching: isFetchingChannelContent,
  } = useChannelContent(channelId)

  const [channelName, setChannelName] = useState(
    myChannel ? myChannel.name : ""
  );
  const [channelImage, setChannelImage] = useState(
    myChannel ? myChannel.image : ""
  );

  const [ownerName, setOwnerName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [userLimit, setUserLimit] = useState(14);
  const [totalUsers, setTotalUsers] = useState(0);
  const [visibility, setVisibility] = useState(
    CHANNEL_VISIBILITY_TYPES.PRIVATE
  );
  const channelOwner = useChannelOwnerInfo(channelId);
  const [listMapped, setListMapped] = useState([]);

  const [openAddSubscriptionDialog, setOpenAddSubscriptionDialog] = useState(
    false
  );
  const [processingNewSubscription, setProcessingNewSubscription] = useState(
    false
  );
  
  //Set up channel owner
  useEffect(() => {
    if (channelOwner && channelOwner.owner) {
      const newOwner = {
        name: channelOwner.owner.first_name + channelOwner.owner.last_name,
        email: channelOwner.owner.email,
      };
      if (
        newOwner.email !== channelOwner.email ||
        newOwner.name !== channelOwner.name
      ) {
        setOwnerName(newOwner.name);
        setOwnerEmail(newOwner.email);
      }
    }
  }, [channelOwner]);

  //Seperatly set up all the channel info 
  useEffect(() => {
    if (myChannel) {
      setChannelName(myChannel.name);
      setChannelImage(myChannel.image_url);
      setUserLimit(myChannel.user_limit);
      setTotalUsers(myChannel.total_users);
    }
  }, [myChannel]);

  // Set up access pattern
  useEffect(() => {
    if (myChannel) {
      if (myChannel.access_pattern) {
        if (myChannel.access_pattern === "hidden") {
          setVisibility(CHANNEL_VISIBILITY_TYPES.HIDDEN);
        } else {
          setVisibility(CHANNEL_VISIBILITY_TYPES.PRIVATE);
        }
      }
    }
  }, [myChannel]);

  //Set list mapped
  useEffect(() => {
    if(myOrganizedResources || myOrganizedResources.length !== listMapped.length) {
      setListMapped(myOrganizedResources);
    }
  }, [myOrganizedResources, listMapped.length]);

  /// Event handler functions
  const fireAddSubscriptionDialog = () => {
    setOpenAddSubscriptionDialog(true);
  };
  const onCloseAddSubscriptionDialog = (evt) => {
    setOpenAddSubscriptionDialog(false);
  };
  const onClosedAddSubscriptionDialog = (evt) => {
  };

  /// Render helper functions
  const renderAddChannelSubscriptionDialog = () => {
    return (
      <AddChannelSubscriptionDialog
        open={openAddSubscriptionDialog}
        onClose={onCloseAddSubscriptionDialog}
        onClosed={onClosedAddSubscriptionDialog}
        processing={processingNewSubscription}
        setProcessing={setProcessingNewSubscription}
        channelId={channelId}
        renderToPortal={true}
      />
    );
  };

  // Render content
  const renderContent = () => {
    if (channelError) {
      return <PageError error={channelError} />;
    } else if (!myChannel) {
      return <PageLoadingIndicator />;
    } else {
      return (
        <ContentContainer>
          <SectionHeadlineRow title="Channel Admin" />
          <ChannelHeader
            id={channelId}
            name={channelName}
            image={channelImage}
            owner={{ name: ownerName, email: ownerEmail }}
          />

          <ChannelVisibility visibility={visibility} channel={myChannel} />
          <ChannelUserLimit
            userCount={totalUsers}
            userLimit={userLimit}
            channelVisibility={visibility}
            channel={myChannel}
          />
          <SectionHeadlineRow
            title="Channel Subscriptions"
            tag="h2"
            typographyStyle="subtitle1"
            className={style.sectionHeadline}
            actionButton={
              <Button
                label="Add new"
                raised
                onClick={fireAddSubscriptionDialog}
              />
            }
          />
          <ChannelSubscriptionsTable
            channelId={channelId}
          />
          {renderAddChannelSubscriptionDialog()}
          <SectionHeadlineRow
            title="Assigned Content"
            tag="h2"
            typographyStyle="subtitle1"
            className={style.sectionHeadline}
            actionButton={
              <Button
                label="edit"
                raised
                onClick={() => history.push(`/admin/channel/${channelId}/edit`)}
              />
            }
          />
          <ResourcesTable
            channelResources={listMapped}
            fetching={isFetchingChannelContent}
          />
        </ContentContainer>
      );
    }
  };

  return (
    <PageWrapper appBarTitle={"VLP Administration"}>
      {renderContent()}
    </PageWrapper>
  );
};

export default AdminChannel;
