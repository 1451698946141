import React from "react";

import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Typography } from "@rmwc/typography"; // Sass imported into main so css import not needed.

import style from "./ChannelHeader.module.scss";

export const ChannelHeader = ({ id, name, image, owner }) => {
  const renderOwnerName = () => {
    if (owner?.name) {
      return (
        <Typography use="body2" tag="div">
          {owner.name}
        </Typography>
      );
    }
  };
  const renderOwnerEmail = () => {
    if (owner?.email) {
      return (
        <Typography use="body2" tag="div">
          {owner.email}
        </Typography>
      );
    }
  };
  const renderContent = () => {
    return (
      <div className={style.container}>
        <div className={style.avatarContainer}>
          <Avatar
            src={image}
            size="xlarge"
            name={name}
            className={style.avatar}
          />
        </div>
        <div className={style.content}>
          <Typography use="headline6" tag="div" className={style.contentRow}>
            {name}
          </Typography>
          <Typography
            use="body2"
            tag="div"
            className={style.contentRow}
          >{`Id: ${id}`}</Typography>
          <div className={style.ownerContainer}>
            <Typography use="body2" tag="div" className={style.ownerLabel}>
              Owner:
            </Typography>
            <div className={style.ownerInfo}>
              {renderOwnerName()}
              {renderOwnerEmail()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return renderContent();
};

export default ChannelHeader;
