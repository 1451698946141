import React from "react";
import { Auth } from "aws-amplify";
import Error from "../../components/Error/Error";

class Logout extends React.Component {
  state = {
    isLoggingOut: true,
    error: false,
    errorMessage: ""
  };

  componentDidMount() {
    Auth.signOut()
      .then(() => {
        this.props.history.push("/");
      })
      .catch(err => {
        this.setState({
          error: true,
          errorMessage: err
        });
      });
  }

  render() {
    if (this.state.error) {
      return <Error message={this.state.errorMessage} />;
    } else return <p>Logging out...</p>;
  }
}

export default Logout;
