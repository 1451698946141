import * as TYPES from "../actions/types";
import { CHANNELS_UPDATE } from "../Redux/Channels/ChannelTypes";

const initialState = {
  list: {},
  totalActivitiesCount: 0,
  activitiesRetrieved: 0,
  allHaveBeenRetrieved: false
};

const reducer = (state = initialState, action) => {
  let newActivityList = {};
  let currentActivityKeyArray = [];
  let newState;

  switch (action.type) {
    //Adds an array of activities to the state
    case TYPES.ACTIVITIES_ADD_ACTIVITIES:
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error(
          "ACTIVITIES_ADD_ACTIVITIES action needs action.payload"
        );
      }

      //Check for payload.activities
      if (!action.payload.hasOwnProperty("activities")) {
        throw new Error(
          "ACTIVITIES_ADD_ACTIVITIES action needs action.payload.activities"
        );
      }

      //Make sure payload.activities is an array
      if (!Array.isArray(action.payload.activities)) {
        throw new Error(
          "ACTIVITIES_ADD_ACTIVITIES action needs action.payload.activities to be an array"
        );
      }

      newActivityList = { ...state.list };

      action.payload.activities.forEach(activity => {
        newActivityList[activity.id] = activity;
      });

      return {
        ...state,
        list: newActivityList
      };

    //Removes an activity from the state
    case TYPES.ACTIVITY_REMOVE:
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("ACTIVITY_REMOVE action needs action.payload");
      }

      newActivityList = {};
      currentActivityKeyArray = Object.keys(state.list);
      currentActivityKeyArray.forEach(id => {
        if (id !== action.payload.activityId) {
          newActivityList[id] = { ...state.list[id] };
        }
      });

      return {
        ...state,
        list: newActivityList
      };

    //Updates the data for an activity
    case TYPES.ACTIVITIES_UPDATE_DATA:
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("ACTIVITIES_UPDATE_DATA action needs action.payload");
      }

      newActivityList = { ...state.list };

      const { activityId, dataId, activityData } = action.payload;

      //Make sure we have an activity id
      if (!activityId) {
        throw new Error(
          "ACTIVITIES_UPDATE_DATA action needs action.payload.activityId"
        );
      }

      //Make sure we have data id
      if (!dataId) {
        throw new Error(
          "ACTIVITIES_UPDATE_DATA action needs action.payload.dataId"
        );
      }

      //Make sure we have activity data
      if (!activityData) {
        throw new Error(
          "ACTIVITIES_UPDATE_DATA action needs action.payload.activityData"
        );
      }

      if (newActivityList.hasOwnProperty(activityId)) {
        newActivityList[activityId] = {
          ...newActivityList[activityId],
          data: activityData,
          dataId
        };
      }

      return {
        ...state,
        list: newActivityList
      };

    case TYPES.ACTIVITIES_UPDATE_META:
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("ACTIVITIES_UPDATE_META action needs action.payload");
      }

      if (!action.payload.activityId) {
        throw new Error(
          "ACTIVITIES_UPDATE_META action needs action.payload.activityId"
        );
      }

      newActivityList = { ...state.list };

      if (state.list.hasOwnProperty(action.payload.activityId)) {
        let newActivity = { ...state.list[action.payload.activityId] };

        if (action.payload.title) {
          //A title was passed
          newActivity.title = action.payload.title;
        } else if (action.payload.title === "") {
          //Allow for an empty string to clear the title
          delete newActivity.title;
        }

        if (action.payload.description) {
          //A description was passed
          newActivity.description = action.payload.description;
        } else if (action.payload.description === "") {
          //Allow for an empty string to clear the description
          delete newActivity.description;
        }

        if (action.payload.image) {
          //A image was passed
          newActivity.image = action.payload.image;
        } else if (action.payload.image === "") {
          //Allow for an empty string to clear the image
          delete newActivity.image;
        }

        newActivityList[action.payload.activityId] = newActivity;
      }

      return {
        ...state,
        list: newActivityList
      };

    case CHANNELS_UPDATE:
      newActivityList = {...state.list};

      //Iterate over existing channels and update the channel that was just changed
      const activities = Object.values(newActivityList);
      activities.forEach(activity => {
        if(activity.channel === action.payload.channelId)
        {
          newActivityList[activity.id] = {
            ...activity,
            channel_name: action.payload.name
          }
        }
      });

      return {
        ...state,
        list: newActivityList
      };

    case TYPES.ACTIVITIES_SET_RETRIEVED:
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("ACTIVITIES_SET_RETRIEVED action needs action.payload");
      }

      const { total, offset, allHaveBeenRetrieved } = action.payload;

      if (total === null || total === undefined) {
        throw new Error(
          "ACTIVITIES_SET_RETRIEVED action needs action.payload.total"
        );
      }

      if (offset === null || offset === undefined) {
        throw new Error(
          "ACTIVITIES_SET_RETRIEVED action needs action.payload.offset"
        );
      }

      if (allHaveBeenRetrieved === null || allHaveBeenRetrieved === undefined) {
        throw new Error(
          "ACTIVITIES_SET_RETRIEVED action needs action.payload.allHaveBeenRetrieved"
        );
      }

      return {
        ...state,
        totalActivitiesCount: total,
        activitiesRetrieved: offset,
        allHaveBeenRetrieved: allHaveBeenRetrieved
      };

    case TYPES.ACTIVITIES_SET_EDITING:
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("ACTIVITIES_SET_EDITING action needs action.payload");
      }

      if (!action.payload.activityId) {
        throw new Error(
          "ACTIVITIES_SET_EDITING action needs action.payload.activityId"
        );
      }

      return {
        ...state,
        editingActivity: action.payload.activityId
      };

    case TYPES.ACTIVITIES_CLEAR_EDITING:
      newState = { ...state };

      if (newState.hasOwnProperty("editingActivity")) {
        delete newState.editingActivity;
      }

      return newState;

    case TYPES.ACTIVITES_CLEAR_IMPORT_DATA:
      newState = { ...state };

      if (newState.hasOwnProperty("importData")) {
        delete newState.importData;
      }

      return newState;

    default:
      return state;
  }
};

export default reducer;
