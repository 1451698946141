import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';

export default function RollBarWrapper({children}) {
  

  if(process.env.REACT_APP_DEPLOYMENT_ENV === "production") {
    const rollbarConfig = {
      accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
      environment: process.env.REACT_APP_DEPLOYMENT_ENV,
    }

    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
            {children}
        </ErrorBoundary>
      </Provider>
    );
  } else {

    return (
      <>
      {children}
      </>
    );
  }
}