import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export function useAllCollections() {  
  const [isFetching, setIsFetching] = useState(null);
  const [error, setError] = useState(null);
  
  const allHaveBeenRetrieved = useSelector(
    (state) => state.Collections.allHaveBeenRetrieved
  );
  const collectionsRetrieved = useSelector((state) => state.Collections.collectionsRetrieved);
  const collectionList = useSelector((state) => state.Collections.list);
  const [collections, setCollections] = useState(collectionList); // prevents getting stuck will null collections list after they have all been retrieved
  const dispatch = useDispatch();  

  useEffect(() => {   
    if(!isFetching && !error) {
      if (!allHaveBeenRetrieved) {
        // Need to fetch more collections 
        setIsFetching(true);        
        dispatch(
          Actions.Collections.getAllCollections((resp) => {
            if(!resp.success) {
              // Error
              setError(resp.msg);              
            }
            // Finished call to get collections
            setIsFetching(false);        
            setCollections(collectionList);  
          })
        );      
      } else
      {        
        // Everything should be retrieved, make sure fetching is off now
        setIsFetching(false);
        setCollections(collectionList);  
      }
    } 
  }, [allHaveBeenRetrieved, collectionsRetrieved, dispatch, collectionList, isFetching, error]);  
  
  return {collections, isFetching, allHaveBeenRetrieved, error};
}
