import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const HomeIcon = ({
  fill = colors.gray.dark,
  width = '24px',
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...iconProps}>
    <title>ic_home_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </g>
  </svg>
);

HomeIcon.propTypes = Props;
export default HomeIcon;
