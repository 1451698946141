import PropTypes from "prop-types";
import React from "react";

import { Typography } from "@rmwc/typography"; // Sass used in main. No css needed here.
import { Card } from "@rmwc/card";
import "@material/card/dist/mdc.card.css"; // Only Card css is required at this time for this component. Therefore not importing the full styles for @rmwc/card.
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import { Theme } from "@rmwc/theme";

import style from "./PageError.module.scss";

const PageError = ({ title, subtitle, error, errorCode, errorMessage, outlined }) => {
  if (error) {
    return (
      <div className={style.container}>
        <Card outlined={outlined} className={style.card}>
        <Theme use={["error"]} wrap>
          <Typography use="headline6" tag="h1">
            <Icon icon="error" className={style.icon} /> 
            {title}
          </Typography>
          </Theme>
          {subtitle ? (
            <Typography use="subtitle1" tag="h2">
              {subtitle}
            </Typography>
          ) : null}
          {errorCode ? (
            <Typography use="overline" tag="p">
              Error {errorCode}
            </Typography>
          ) : null}
          {errorMessage ? (
            <Typography use="body" tag="p">
              {errorMessage}
            </Typography>
          ) : null}
        </Card>
      </div>
    );
  } else return null;
};

PageError.defaultProps = {
  title: "Error",
  errorMessage: "We've encountered an error.",
  outlined: true
};
PageError.propTypes = {
  error: PropTypes.bool.isRequired,
  errorCode: PropTypes.string,
  errorMessage: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  outlined: PropTypes.bool
};

export default PageError;
