import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardPrimaryAction } from "@rmwc/card";
import "@rmwc/card/styles";
import { Typography } from "@rmwc/typography"; // Sass used in main. No css needed.

import style from "./Cards.module.scss";
import globalStyle from "../../styles/global.module.scss"

const linkStyle = {width: "100%", height: "100%"};

export const BrowseChannelsCard = ({className, toPath, ...otherProps}) => {
  const classes = [style.card, className ? className : null ].join(" ");
  
  return (
    <Card className={classes} {...otherProps}>
      <Link to={toPath} className={globalStyle.linkNoStyle} style={linkStyle}>
        <CardPrimaryAction className={style.actionContainer} >
          <Typography use="headline6" tag="h2" className={style.callToAction}>
            Browse and Join Channels
          </Typography>
        </CardPrimaryAction>
      </Link>
    </Card>
  )
}

export const ContentCreatorCard = ({className, toPath, ...otherProps}) => {
  const classes = [style.card, className ? className : null ].join(" ");
  return (
    <Card className={classes} {...otherProps}>
      <Link to={toPath} className={globalStyle.linkNoStyle} style={linkStyle}>
        <CardPrimaryAction className={style.actionContainer}>
          <Typography use="headline6" tag="h2" className={style.callToAction}>
            Become a Content Creator
          </Typography>

        </CardPrimaryAction>
      </Link>
    </Card>
  )
}

export const CardContainer = ({children, ...otherProps}) => {
  return (
    <div className={style.container} {...otherProps}>
      {children}
    </div>
  )
}