import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableCell,
  DataTableBody,
} from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { Typography } from "@rmwc/typography"; // Sass imported in main. CSS not needed.
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { SimpleMenu, MenuItem } from "@rmwc/menu";
import "@rmwc/menu/styles";

import { Spinner } from "react-bootstrap";

import style from "./ChannelMembers.module.scss";

import { dialogQueue } from "../Dialog/dialogQueue";
import { useChannelMembers } from "../../hooks/Channel/useChannelMembers";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";

import store from "../../store";
import Actions from "../../Redux/actions";
import { currentUser } from "../../utils/AmplifyAuthController"
import { snackbarQueue } from "../Snackbar/snackbarQueue";
import EditMemberRole from "../../components/Dialog/EditMemberRole/EditMemberRole";

const ChannelMembers = ({channelId, channelName}) => {

  const dispatch = useDispatch();

  //State
  const [sortColumn, setSortColumn] = useState("user");
  const [sortDirection, setSortDirection] = useState(1); // null = unsorted, 1 = ascending, -1 = descending  
  const [processing, setProcessing] = useState(null);
  const [openEditMemberRoleDialog, setOpenEditMemberRoleDialog] = useState(false);  
  const [editMember, setEditMember] = useState(null); // current member being edited

  //Hooks  
  const [members, fetching, error] = useChannelMembers(channelId);    

  //Role Actions
  const roles = {
    owner: "owner",
    admin: "admin",
    author: "author",
    member: "member",
    pending: "pending",
  };

  const transferOwnerShip = (channelId, member) => {   
    setProcessing(true)

    dialogQueue
      .confirm({
        title: "Transfer Ownership",
        body: `Are you sure you want to transfer ownership of channel ${channelName} to member ${member.email}?`,
        acceptLabel: "Transfer",
      })
      .then((resp) => {
        if(resp === true){
          // delete member
          store.dispatch(Actions.Channels.updateChannelMembersRole(channelId, "owner", member.id, (result) => {
            if(result.success) {
              snackbarQueue.notify({
                title: <b>Ownership Transfer Pending</b>,
                body: `Member ${member.email} has been invited to take ownership of ${channelName}.`
              });
            } else {
              snackbarQueue.notify({
                title: <b>Ownership Transfer Failed</b>,
                body: `Something went wrong. Please try again.`,
              });
            }
            setProcessing(false)
          }));
        }
      })
      .catch((err) => console.error("[ChannelMembers] Transfer Ownership Dialog error: ", err));
  };

  const fireConfirmDeleteInvite = (title, body, acceptLabel, channelId, userId, successMessage) => {
    dialogQueue
      .confirm({
        title: title,
        body: body,
        acceptLabel: acceptLabel,
      })
      .then((resp) => {
        if(resp === true){
          // delete member
          store.dispatch(Actions.Channels.deleteMember(channelId, userId, () => {            
            snackbarQueue.notify({
              title: `Success!`,
              body: successMessage,
            })
          }));
        }
        else{
          console.erorr("[ChannelMembers] error trying to delete user: ", resp);
          snackbarQueue.notify({
            title: <b>Delete Invite Failed</b>,
            body: `Something went wrong. Please try again.`,
          });
        }
      })
      .catch((err) => console.error("[ChannelMembers] Delete Dialog error: ", err));
  };

  const fireConfirmRemoveUser = (title, body, acceptLabel, channelId, userId, successMessage) => {
    dialogQueue
      .confirm({
        title: title,
        body: body,
        acceptLabel: acceptLabel,
      })
      .then((resp) => {
        if(resp === true){
          // delete member
          store.dispatch(Actions.Channels.deleteMember(channelId, userId, () => {            
            snackbarQueue.notify({
              title: `Success!`,
              body: successMessage,
            })
          }));
        }
        else{
          console.erorr("[ChannelMembers] error trying to delete user: ", resp);
          snackbarQueue.notify({
            title: <b>Remove Member Failed</b>,
            body: `Something went wrong. Please try again.`,
          });
        }
      })
      .catch((err) => console.error("[ChannelMembers] Delete Dialog error: ", err));
  };

  const fireEditMemberRoleDialog = () => {
    setOpenEditMemberRoleDialog(true);
  };

  const onCloseEditMemberRoleDialog = (evt) => {       
    setOpenEditMemberRoleDialog(false);
  };

  const finishEditMember = () => {   
      if(editMember){
        editMember.processing = false;
      }
      setProcessing(false);
  }

  const onClosedEditMemberRoleDialog = (evt, member) => { 
    const updatedRole = String(evt.detail.action).split(" ")[0].toLowerCase().trim();
    if(updatedRole === "member" || updatedRole === "author" || updatedRole === "admin"){
      
      store.dispatch(Actions.Channels.updateChannelMembersRole(channelId, updatedRole, editMember.id, (resp)=>{
      
        if(resp.success === true){
          snackbarQueue.notify({
            title: <b>Success</b>,
            body: `Member ${member.name}'s role has been updated.`,
          });
        }
        else{
          console.error("[ChannelMembers] error trying to edit user role: ", resp);
          snackbarQueue.notify({
            title: <b>Edit Member Role Failed</b>,
            body: `Something went wrong editing member ${member.name}'s role. Please try again.`,
          });
        }        
        
        finishEditMember();
      }));
    }
    else{
      finishEditMember();
    }
  };

  const renderEditMemberRoleDialog = () => {
    let emailToUse = "";
    if(editMember){  
      if(editMember.id){
        emailToUse = editMember.id;
      }  
      if(editMember.email){
        emailToUse = editMember.email;
      }
    }

    let nameToUse = "";
    if(editMember && editMember.name){
      nameToUse = editMember.name;
    }

    let roleToUse = "member";
    if(editMember && editMember.role){
      roleToUse = editMember.role;
    }

    return (
      <EditMemberRole
        open={openEditMemberRoleDialog}
        onClose={onCloseEditMemberRoleDialog}
        onClosed={onClosedEditMemberRoleDialog}
        renderToPortal
        member={{name: nameToUse, role: roleToUse, email: emailToUse }}
      />
    )
  }

  const fireEditRole = (title, body, acceptLabel, channelId, member, callback) => {            
    setEditMember(member);
    fireEditMemberRoleDialog();        
  };

  const triggerResendInvite = async (channelId, member, handleDone) => {  

    let emailToUse = member.id;
    if(member.email){
      emailToUse = member.email;
    }

    const user = await currentUser();
    if (!user) throw Error("Could not get user");

    let callback = (result) => {
      if(result.success) {
        snackbarQueue.notify({
          title: <b>Success</b>,
          body: `User ${emailToUse} has been re-invited to channel ${channelName}.`, 
        });
      } else {
        snackbarQueue.notify({
          title: <b>Resend Invite Failed</b>,
          body: `Something went wrong inviting ${emailToUse}. Please try again.`,
        });
      }

      if(handleDone){
        handleDone();
      }
    }

    dispatch(Actions.Channels.inviteUserToChannel(channelId, "", emailToUse, "member", user.attributes.sub, true, callback))
  }

  const actions = {
      editRole: {
        label: "Edit role",
        action: function(channelId, member, callback) {
          let emailToUse = member.id;
          if(member.email){
            emailToUse = member.email;
          } 
          fireEditRole(
            "Edit role",
            `Enter in the new role for ${emailToUse} \n (Member, Author, or Admin)`,
            "Confirm",
            channelId,
            member,
            callback
          );  
        },
        requiredPermission: roles.owner || roles.admin,
      },
      transferOwnership: {
        label: "Transfer ownership",
        action: function(channelId, member) {
          transferOwnerShip(channelId, member);
        },
        requiredPermission: roles.owner,
      },
      resendTransferOwnership: {
        label: "Resend invite",
        action: function(channelId, member) {
          transferOwnerShip(channelId, member);
        },
        requiredPermission: roles.owner,
      },
      resendInvite: {
        label: "Resend invite",
        action: function(channelId, member, callback) {
          triggerResendInvite(channelId, member, callback);
        },
        requiredPermission: roles.owner || roles.admin,
      },
      deleteInvite: {
        label: "Delete invite",
        action: function(channelId, member) {
          fireConfirmDeleteInvite(
            "Delete Pending Invite",
            `Are you sure you want to delete user ${member.email}'s pending invite for channel ${channelName}?`,
            "Delete",
            channelId,
            member.id,
            `Pending invite for user ${member.email} has been deleted.`
          );        
        },
        requiredPermission: roles.owner || roles.admin,
      },
      removeMember: {
        label: "Remove member",
        action: function(channelId, member) {
          fireConfirmRemoveUser(
            "Remove Member",
            `Are you sure you want to remove user ${member.email} from channel ${channelName}? This action will remove the user's ability to access the channel.`,
            "Remove",
            channelId,
            member.id,
            `Member ${member.email} has been removed from channel ${channelName}.`
          );  
        },
        requiredPermission: roles.owner || roles.admin,
      },
  };

  const roleActions = {
      owner: [actions.resendTransferOwnership],
      admin: [actions.editRole, actions.transferOwnership, actions.removeMember],
      author: [actions.editRole, actions.removeMember],
      member: [actions.editRole, actions.removeMember],
      pending: [actions.resendInvite, actions.deleteInvite]    
  };

  const getActionsForRole = (member) => {
    let actions = []
    if(String(member.status).toLocaleLowerCase().trim().includes("pending") && !String(member.role).toLocaleLowerCase().trim().includes("owner")){
        // still pending
        actions = roleActions.pending;
      }
      else if(String(member.role).toLocaleLowerCase().trim().includes("owner")){
        // owner
        actions = roleActions.owner;
      }
      else if(String(member.role).toLocaleLowerCase().trim().includes("admin")){
        // admin
        actions = roleActions.admin;
      }
      else if(String(member.role).toLocaleLowerCase().trim().includes("author")){
        // author
        actions = roleActions.author;
      }
      else if(String(member.role).toLocaleLowerCase().trim().includes("member")){
        // member
        actions = roleActions.member;
      } 

      return actions;
  }  

  const getSortedMembers = () => {
    let sortedMembers = [...members];

    if(sortDirection && sortDirection !== null){   

      sortedMembers.forEach((member) => {
        let userToUse = "";
        if(member.email){
          userToUse = member.email; // sort by email second
        }
        if(member.name){
          userToUse = member.name; // sort by name first
        }
        member.user = userToUse;
      });

      
      if(sortColumn === "user"){
        // user
        sortedMembers.sort((a,b) => {
          if(a.user > b.user){
            return sortDirection;
          }
          else{
            return -1 * sortDirection;
          }
        });
      }
      else if(sortColumn === "role"){
        // role
        sortedMembers.sort((a,b) => {
          if(a.role > b.role){
            return sortDirection;
          }
          else{
            return -1 * sortDirection;
          }
        });
      }
      else if(sortColumn === "status"){
        // status
        sortedMembers.sort((a,b) => {
          if(a.date > b.date){
            return sortDirection;
          }
          else{
            return -1 * sortDirection;
          }
        });
      }
    }
    
    
    return sortedMembers;    
  }

  const handleOnSortChange = (column, sortDir) => {
    setSortColumn(column);
    setSortDirection(sortDir);
  };

  const headers = [
    {
      id: 1,
      name: "user",
      label: "User",
      sortable: true,
    },
    {
      id: 2,
      name: "role",
      label: "Role",
      sortable: true,
    },
    {
      id: 3,
      name: "status",
      label: "Status",
      sortable: true,
    },
    {
      id: 4,
      name: "action",
      label: "Action",
      sortable: false,
    },
    {
      id: 5,
      name: "overflow",
      label: "",
      sortable: false,
    },
  ];

  const renderTableHeaders = () => {
    return headers.map((i) => {
      if (i.sortable) {
        return (
          <DataTableHeadCell
            key={i.id}
            sort={sortColumn === i.name ? sortDirection : null}
            onSortChange={(sortDir) => handleOnSortChange(i.name, sortDir)}
          >
            {i.label}
          </DataTableHeadCell>
        );
      } else {
        return <DataTableHeadCell key={i.id}>{i.label}</DataTableHeadCell>;
      }
    });
  };

  const renderTableRows = () => {
    if (!members) {
      return null;
    }

    const generateOverflowActions = (actions, member) => {
      return actions.map((a, i) => {
        if (i > 0) {
          return (
            <MenuItem key={i} onClick={() => a.action(channelId, member)}>
              {a.label}
            </MenuItem>
          );
        } else return null;
      });
    };
   
    const handleClick = (member, action) => {
      if(member.processing){
        // already processing this click
        return;
      }
   
      member.processing = true;
      setProcessing(true);

      const handleDone = (member) => {      
        // primary action has completed  
        member.processing = false;
        setProcessing(false);
      }
      member.actions[action].action(channelId, member, ()=> handleDone(member));
    }

    const sortedMembers = getSortedMembers();

    const rows = sortedMembers.map((d) => {
      
      let hasAction = null;
      let hasOverflow = null;
      // setup actions
      d.actions = getActionsForRole(d);

      if (d.actions.length > 1) {
        hasAction = true;
        hasOverflow = true;
      } else if (d.actions.length === 1) {
        hasAction = true;
        hasOverflow = false;
      }   

      const renderPrimaryButton = (member) => {
        if(processing && member.processing){
          return(
            <span>
              <Spinner animation="grow" />
            </span>
          );
        }
        else{
          let action = member.status === "accepted" && member.role === "owner" ? false : true // only the owner can re send a transfer owner invite
          if(action) {
            return(
              <Button onClick={() => handleClick(member, 0)}>
                {member.actions[0].label}
              </Button>
            );
          }
        }
      }
      let joinDate = ""
      if(d.timestamp) {
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let date = new Date(d.timestamp)
        
        joinDate += " " + monthNames[date.getMonth()];
        joinDate += " " + date.getDate();
        joinDate += ", " + date.getFullYear();
      }

      return (
        <DataTableRow key={d.id}>
          <DataTableCell>
            {d.name ? <div>{d.name}</div> : null}
            <div>{d.email}</div>
          </DataTableCell>
          <DataTableCell>{d.role.charAt(0).toUpperCase() + d.role.slice(1) + (d.status === "pending" ? " (pending)" : "")}</DataTableCell>
          <DataTableCell>{(d.status === "pending" ? "Invited" : "Joined") + joinDate}</DataTableCell>
          <DataTableCell>
            {hasAction ? (              
              renderPrimaryButton(d)          
            ) : null}
          </DataTableCell>
          <DataTableCell>
            {hasOverflow ? (
              <SimpleMenu handle={<Button icon="more_horiz" />} renderToPortal>
                {generateOverflowActions(d.actions, d)}
              </SimpleMenu>
            ) : null}
          </DataTableCell>
        </DataTableRow>
      );
    });
    return rows;
  };

  // eslint-disable-next-line no-unused-vars
  const fireEditRolePrompt = () => {
    dialogQueue.prompt({
      title: <b>Edit user role</b>,
      acceptLabel: "Save",
      cancelLabel: "Cancel",
      inputProps: {
        outlined: true,
        label: "Role",
      },
    });
  };

  const renderContent = () => {
    if (fetching) {
      return <PageLoadingIndicator />;
    }
    if (error) {
      return <label>Something happened</label>;
    } else if (!members || members.length === 0) {
      return (
        <Typography
          use="body1"
          tag="h2"
          style={{ textAlign: "center", flexGrow: "1", paddingTop: "24px" }}
        >
          This channel doesn't have any members yet.
        </Typography>
      );
    } else {
      return (
        <DataTable className={style.tableContainer} stickyRows={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>{renderTableHeaders()}</DataTableRow>
            </DataTableHead>
            <DataTableBody>{renderTableRows()}</DataTableBody>
          </DataTableContent>
        </DataTable>
      );
    }
  };

  return <div>
    {renderContent()}
    {renderEditMemberRoleDialog()}
    </div>;
};

export default ChannelMembers;