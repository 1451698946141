
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useLtiUrlParameters() {
    const lti = useSelector((state) => state.User.lti);

    const [ltiReturnUrl, setLtiReturnUrl] = useState("");
    const [ltiSupportsUrl, setLtiSupportsUrl] = useState(false);
    const [ltiSupportsContentItemIframe, setLtiSupportsContentItemIframe] = useState(false);
    const [ltiSupportsContentItemLaunchUrl, setLtiSupportsContentItemLaunchUrl] = useState(false);
    const [canvasCourseId, setCanvasCourseId] = useState("");
    const [canvasApiDomain, setCanvasApiDomain] = useState("");

    useEffect(() => {
        if (lti) {
            if (lti.ext_content_return_url || lti.launch_presentation_return_url) {
                setLtiReturnUrl(lti.ext_content_return_url || lti.launch_presentation_return_url);

                if (lti.ext_content_return_types) {
                    if (String(lti.ext_content_return_types).includes("url")) {
                        setLtiSupportsUrl(true);
                    }
                    if (String(lti.ext_content_return_types).includes("lti_launch_url")) {
                        setLtiSupportsContentItemLaunchUrl(true);
                    }

                    if (String(lti.ext_content_return_types).includes("iframe") && String(lti.ext_content_return_types.includes("lti_launch_url"))) {
                        setLtiSupportsContentItemIframe(true);
                    }
                }

                if (lti.custom_canvas_course_id) {
                    setCanvasCourseId(lti.custom_canvas_course_id);
                }
                if (lti.custom_canvas_api_domain) {
                    setCanvasApiDomain(lti.custom_canvas_api_domain);
                }
            }
        }
    }, [lti]);

    return {
        ltiReturnUrl,
        ltiSupportsUrl,
        ltiSupportsContentItemIframe,
        ltiSupportsContentItemLaunchUrl,
        canvasCourseId,
        canvasApiDomain
    };
}