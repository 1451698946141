import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Typography } from "@rmwc/typography"; // Using SASS in main so CSS import not needed
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import DialogEmbed from "../Dialog/DialogEmbed";
import DialogShare from "../Dialog/DialogShare";
import globalStyle from "../../styles/global.module.scss";
import { GetActivityLinkUrl, GetActivityLinkUrlNoAuth, GetEmbedActivityUrl } from "../../LTI";
import { useIsLTIUser } from "../../hooks/User/useIsLTIUser";
import { useLtiUrlParameters } from "../../hooks/LTI/useLtiUrlParameters";


import style from "./ActivityDetails.module.scss";

const ActivityDetails = ({
  activityTitle,
  activityDescription,
  activityId,
  activityImage,
  channelId,
  channelName,
  channelAvatar,
  history,
  canEditActivity,
  isViewingFromSubscription,
  subscriberId
}) => {
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openEmbedDialog, setOpenEmbedDialog] = useState(false);

  const isLtiUser = useIsLTIUser();
  const lti = useSelector((state) => state.User.lti);
  const {
    ltiReturnUrl,
    canvasCourseId,
    canvasApiDomain
  } = useLtiUrlParameters(); 

  const fireShareDialog = () => {
    setOpenShareDialog(true);
  };
  const fireEmbedDialog = () => {
    setOpenShareDialog(false);
    setOpenEmbedDialog(true);
  };
  const onCloseShareDialog = (evt) => {
    setOpenShareDialog(false);
  };
  const onCloseEmbedDialog = (evt) => {
    setOpenEmbedDialog(false);
  };

  const renderShareDialog = () => {
    return (
      <DialogShare
        open={openShareDialog}
        onClose={onCloseShareDialog}
        onEmbed={fireEmbedDialog}
        renderToPortal
        onLtiEmbed={handleLtiEmbed}
        onLtiLinkToActivity = {handleLtiLinkToActivity}
        onLtiLinkToActivityNoAuth ={handleLtiLinkToActivityNoAuth}
        ltiUser={isLtiUser ? lti : null}
        isLtiUser={isLtiUser}
        activityMeta={{
          title: activityTitle,
          description: activityDescription,
          image: activityImage,
          channelId: channelId,
          id: activityId
        }}
      />
    );
  };

  const renderEmbedDialog = () => {
    return (
      <DialogEmbed
        activityId={activityId}
        channelId={channelId}
        open={openEmbedDialog}
        onClose={onCloseEmbedDialog}
        renderToPortal
      />
    );
  };

  const renderEditButton = () => {
    if (canEditActivity && !isViewingFromSubscription) {
      return (
        <div className={style.activityEdit}>
          <Button
            label="Edit activity"
            unelevated
            onClick={() => {
              history.push({
                pathname: `/activity/${activityId}/edit`,
                state: {channelId: channelId}
              })
            }}
          />
        </div>
      );
    } else return null;
  };

  const renderShareOrLtiEmbedButton = () => {
    if (!isViewingFromSubscription) { 
      return (
        <Button
          label="Share"
          icon="share"
          className={style.titleShare}
          onClick={fireShareDialog}
        />
      );
    }
    else {   
      return null;
    }
  };

  const handleLtiEmbed = () => {
    const _channelId = subscriberId ?? channelId;

    const lmsUrl = GetEmbedActivityUrl(
      _channelId,
      activityId,
      activityTitle,
      ltiReturnUrl,
      canvasCourseId,
      canvasApiDomain
    );

    window.location.href = lmsUrl.href;
  };

  const handleLtiLinkToActivity = () => { 
    const _channelId = subscriberId ?? channelId;
    const activityLinkUrl = GetActivityLinkUrl(
      _channelId,
      activityId,
      activityTitle,
      ltiReturnUrl
    );  

    window.location.href = activityLinkUrl.href;
  }

  const handleLtiLinkToActivityNoAuth = () => {
    const activityLinkUrl = GetActivityLinkUrlNoAuth(                
      activityId,
      activityTitle,
      ltiReturnUrl
    );

    window.location.href = activityLinkUrl.href;
  }

  return (
    <>
      <div className={style.titleContainer}>
        <Typography use="headline6" tag="h1" className={style.titleTitle}>
          {activityTitle ? activityTitle : "Untitled activity"}
        </Typography>
        {renderShareOrLtiEmbedButton()}
      </div>
      <div className={style.channelContainer}>
        <div className={style.channel}>
          <div className={style.channelAvatar}>
            <Avatar
              size="xlarge"
              src={channelAvatar}
              name={channelName}
              interactive
              onClick={() =>
                history.push(
                  `/channel/${channelId}${history.location.search}`
                )
              }
            />
          </div>

          <Link
            to={`/channel/${channelId}${history.location.search}`}
            className={globalStyle.linkNoStyle}
          >
            <Typography use="subtitle1" tag="p" className={style.channelName}>
              {channelName
                ? channelName
                : "Untitled channel"}
            </Typography>
          </Link>
        </div>

        {renderEditButton()}
      </div>
      <div className={style.descriptionContainer}>
        <Typography use="body1" tag="span">
          {activityDescription ? activityDescription : null}
        </Typography>
      </div>
      {renderShareDialog()}
      {renderEmbedDialog()}
    </>
  );
};

export default ActivityDetails;
