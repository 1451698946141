import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Form, Button, Row, Container, Col, Spinner } from "react-bootstrap";

import { TextField, makeStyles } from "@material-ui/core";

import PageWrapper from "../../components/PageWrapper/PageWrapper";

import Actions from "../../Redux/actions";
import { useUserGroups } from "../../hooks/User/useUserGroups";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import { useHistory } from "react-router";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";

const useStyles = makeStyles({
  textField: {
    width: "100%",
  },
});

const AccountChannelNewItem = ({
  validated,
  validateForm,
  name,
  setName,
  setProfilePhoto,
  setBannerPhoto,
  processing,
}) => {
  const classes = useStyles();

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={validateForm}
      encType="multipart/form-data"
      className="ActivitiesAddNew"
    >
      <Container fluid>
        <Row>
          <Col xs={8} className="ActivitiesAddNew__title">
            <p>Add New Channel</p>
          </Col>
          <Col xs={4}>
            <Row>
              <Col xs={6} />
              <Col xs={6} className="ActivitiesAddNew__submit">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={processing || !name ? true : false}
                >
                  {!processing && <span>Create</span>}
                  {processing && (
                    <span>
                      <Spinner animation="grow" />
                    </span>
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Row>
              <Col xs={12}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="Name"
                  multiline
                  value={name}
                  rows="2"
                  className={classes.textField}
                  onChange={(evt) => setName(evt.target.value)}
                  margin="normal"
                  variant="outlined"
                  placeholder="Enter name of the new channel"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <Row>
              <Col xs={12} className="ActivitiesAddNew__choose">
                <Form.Group controlId="forLastName">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    required
                    type="file"
                    onChange={(e) => setProfilePhoto(e.target.files[0])}
                  />
                </Form.Group>
                <Form.Group controlId="forLastName">
                  <Form.Label>Banner</Form.Label>
                  <Form.Control
                    required
                    type="file"
                    onChange={(e) => setBannerPhoto(e.target.files[0])}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const AccountChannelNew = (props) => {
  //States
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [bannerPhoto, setBannerPhoto] = useState("");
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //Hooks
  const userGroups = useUserGroups();
  const userData = useSelector((state) => state.User);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) return;

    if (userGroups) {
      if (!userGroups.includes("Admin")) {
        history.push("/dashboard");
      }

      setIsLoading(false);
    }
  }, [userGroups, history, isLoading]);

  const validateForm = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      return setValidated(true);
    }
    setValidated(true);

    setIsSubmitting(true);
    uploadFile(profilePhoto, (newProfilePhotoName) => {
      uploadFile(bannerPhoto, (newBannerPhotoName) => {
        dispatch(
          Actions.Channels.fetchAddChannel(
            {
              channel_name: name,
              image: newProfilePhotoName,
              banner: newBannerPhotoName,
              user: userData.id,
            },
            (result) => {
              if (result.success) {
                snackbarQueue.notify({
                  title: <b>Success!</b>,
                  body: "You have created a channel",
                });
                history.push(`/channel/${result.newChannel.id}`);
              } else {
                snackbarQueue.notify({
                  title: <b>Uh oh!</b>,
                  body: "Unable to create channel",
                });
                setIsSubmitting(false);
              }
            }
          )
        );
      });
    });
  };

  function uploadFile(file, cb) {
    if (file) {
      dispatch(Actions.Universal.upload({ file, folder: "Thumbnails" }, cb));
    } else {
      cb("");
    }
  }

  function renderContent() {
    if (isLoading) {
      return <PageLoadingIndicator />;
    } else {
      return (
        <AccountChannelNewItem
          validated={validated}
          validateForm={validateForm}
          name={name}
          setName={setName}
          setProfilePhoto={setProfilePhoto}
          setBannerPhoto={setBannerPhoto}
          processing={isSubmitting}
        />
      );
    }
  }

  return <PageWrapper>{renderContent()}</PageWrapper>;
};

export default AccountChannelNew;
