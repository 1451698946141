import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Card, CardPrimaryAction, CardMedia } from "@rmwc/card";
import "@rmwc/card/styles";
import { Typography } from "@rmwc/typography"; // Sass included in main. CSS not needed.
import { Theme } from "@rmwc/theme"; // Sass included in main. CSS not needed.
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";
import { Menu, MenuItem, MenuSurfaceAnchor } from "@rmwc/menu";
import "@rmwc/menu/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";

import style from "./GenericCard.module.scss";
import { MenuLinkPropType } from "../MenuLinkPropType";

import globalStyle from "../../../styles/global.module.scss";

export const GenericCard = ({
  title,
  image,
  linkTo,
  actions,
  isLocked,
  authorImage,
  authorName,
  authorLink,
  primaryAction,
  selected,
  draftOnly,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  /**
   * Fires the appropriate action based on the menu selection.
   * @param {number} index index value from event.detail.index
   */
  const onSelectMenu = (index) => {
    //This function only needs fired if the action is of type function. Otherwise the action is fired by the Link component.
    if (typeof actions[index].action === "function") {
      actions[index].action();
    }
  };

  /**
   * Builds the list of menu items from the `actions` array prop
   */
  const buildMenuItemsList = () => {
    if (actions && actions.length > 0) {
      const mapped = actions.map((action, index) => {
        if (typeof action.action === "function") {
          return <MenuItem key={index}>{action.label}</MenuItem>;
        } else
          return (
            <Link
              to={action.action}
              className={globalStyle.linkNoStyle}
              key={index}
            >
              <MenuItem>{action.label}</MenuItem>
            </Link>
          );
      });
      return mapped;
    } else return null;
  };

  /**
   * Renders the overflow menu
   * @returns {JSX} JSX
   */
  const renderMenu = () => {
    if (actions && actions.length > 0) {
      return (
        <div className={style.overflowMenu}>
          <MenuSurfaceAnchor>
            <Menu
              open={menuOpen}
              onSelect={(evt) => onSelectMenu(evt.detail.index)}
              onClose={() => setMenuOpen(false)}
              renderToPortal={true}
            >
              {buildMenuItemsList()}
            </Menu>
            <Theme wrap use="textIconOnDark">
              <IconButton
                icon="more_vert"
                onClick={() => setMenuOpen(!menuOpen)}
                className={style.overflowIcon}
              />
            </Theme>
          </MenuSurfaceAnchor>
        </div>
      );
    } else return null;
  };

  /**
   * Renders the isLocked layer of a lock icon in front of a scrim
   */
  const renderisLockedLayer = () => {
    return (
      <>
        <div className={style.lockedContainer}>
          <div className={style.lockedIcon}>
            <Theme wrap use="textIconOnDark">
              <Icon
                icon={{ icon: "lock", size: "xlarge" }}
                className={style.overflowIcon}
              />
            </Theme>
          </div>
        </div>
      </>
    );
  };

  const renderDraftNotice = () => {
    if (draftOnly) {
      return (
        <>
          <div className={style["banner__background"]} />
          <Typography use="caption" tag="div" className={style["banner__text"]}>
            DRAFT
          </Typography>
        </>
      );
    }
  };

  /**
   * Renders the Primary content in the card including the item's image and title as well as the name and image for the item's author/owner.
   */
  const renderPrimaryContent = () => {
    return (
      <>
        <CardMedia sixteenByNine style={{ backgroundImage: `url(${image}` }}>
          {renderDraftNotice()}
        </CardMedia>
        <div className={style.metaContainer}>
          <div className={style.authorAvatarContainer}>
            <Avatar
              size="large"
              name={authorName}
              style={{ border: "1px solid rgba(0, 0, 0, 0.18)" }}
              src={authorImage}
              interactive
            />
          </div>

          <div className={style.metaTextContainer}>
            <Typography
              use="subtitle1"
              tag="h2"
              className={style.title}
              title={title}
            >
              {title}
            </Typography>
            <Typography
              use="subtitle2"
              theme="textSecondaryOnBackground"
              tag="h2"
              className={style.title}
              title={title}
            >
              {authorName}
            </Typography>
          </div>
        </div>
      </>
    );
  };

  /**
   * Renders the full contents of the card including the primary content, the isLocked layer (if isLocked), and the overflow menu.
   */
  const renderCardContent = () => {
    if (isLocked) {
      return (
        <>
          {renderPrimaryContent()}
          {renderisLockedLayer()}
          {renderMenu()}
        </>
      );
    } else if (linkTo) {
      return (
        <>
          <Link to={linkTo} className={globalStyle.linkNoStyle}>
            <CardPrimaryAction>{renderPrimaryContent()}</CardPrimaryAction>
          </Link>
        </>
      );
    } else
      return (
        <>
          <CardPrimaryAction onClick={primaryAction}>
            {renderPrimaryContent()}
          </CardPrimaryAction>
        </>
      );
  };

  return (
    <div className={style.container}>
      <Card outlined className={selected ? style.cardSelected : null}>
        {renderCardContent()}
        {renderMenu()}
      </Card>
    </div>
  );
};
GenericCard.defaultProps = {
  title: "Untitled",
  authorName: "Untitled",
  // image: // ToDo: Default image if one isn't supplied
};

GenericCard.propTypes = {
  /**
   * The title of the activity. If not provided the title will defaults to `Untitled Activity`.
   */
  title: PropTypes.string.isRequired,
  /**
   * The url to the channel image. Optional.
   */
  image: PropTypes.string,
  /**
   * The path this card should link to. Can also be a React-Router link object.
   */
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * The src image of the item's author/owner to be displayed in the Avatar component.
   */
  authorImage: PropTypes.string,
  /**
   * The name of the item's author/owner. (Usually the channel name)
   */
  authorName: PropTypes.string,
  /**
   * The link to view the author/owner page. (usually a channel)
   */
  authorLink: MenuLinkPropType,
  /**
   * Indicates the item displayed in the card is isLocked and cannot be accessible by the user. Defaults to `FALSE`.
   */
  isLocked: PropTypes.bool,
  /**
   * A list of actions that can be taken on this card.
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The text label for this menu item.
       */
      label: PropTypes.string.isRequired,
      /**
       * The action that should occur when selected. Can accept one of the following: a function, a React Router object, or a react router string representation of the link location. A React Router Link component will be used unless a function is provided. For more information about React Router Link: https://reactrouter.com/web/api/Link.
       */
      action: MenuLinkPropType,
    })
  ),
  draftOnly: PropTypes.bool,
};
