import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const NavigateNext = ({
  fill = colors.gray.dark,
  width = '24px',
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height="24" viewBox="0 0 24 24" {...iconProps}>
    <title>ic_navigate_next_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </g>
  </svg>
);

NavigateNext.propTypes = Props;
export default NavigateNext;
