import React from "react";
import { connect } from "react-redux";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PlayerShadowDom from "../../components/PlayerShadowDom/PlayerShadowDom";

class CollectionFreeplay extends React.Component {
  onPlayerCreated = player => {
    player.OpenCollection(this.props.collectionId);
  };

  render() {
    return (
      <PageWrapper>
        <PlayerShadowDom
          onPlayerCreated={player => this.onPlayerCreated(player)}
          size="freeplay"
        />
      </PageWrapper>
    );
  }
}

const MapStateToProps = (state, ownProps) => {
  return {
    collectionId: ownProps.match.params.collectionId,
    history: ownProps.history
  };
};

export default connect(MapStateToProps, {})(CollectionFreeplay);
