import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { usePrevious } from "../usePrevious";
import Actions from "../../Redux/actions";
import { CHANNEL_EMBED_TYPES } from "../../constants/channelEmbedTypes";

/**
 *
 * @param {string} channelId
 */
export function useChannelEmbedPermissions(channelId) {
  const [channelData, setChannelData] = useState(null);
  const [fetchingChannel, setFetchingChannel] = useState(null);
  const [channelError, setChannelError] = useState(null);
  const [embedPermission, setEmbedPermission] = useState(null);
  const [embedWhiteList, setEmbedWhiteList] = useState([]);
  const channelList = useSelector((state) => state.Channels.list);
  const prevEmbedPermission = usePrevious(embedPermission);
  const prevEmbedWhiteList = usePrevious(embedWhiteList);
  const dispatch = useDispatch();

  // Effect to retreive channel data if we don't have the embed_permission property yet.
  useEffect(() => {
    if (!channelData) {
      if (channelId) {
        if (
          channelList.hasOwnProperty(channelId) &&
          channelList[channelId].embed_permission
        ) {
          // If the channel's embed_permission is already in the store, we can assume we have the channel data already.
          setChannelData(channelList[channelId]);

          // Else, we need to retrieve the channel data from the API.
        } else if (!fetchingChannel){
          setFetchingChannel(true);
          dispatch(
            Actions.Channels.fetchAChannel(channelId, (resp) => {
              if (resp.success) {
                setFetchingChannel(false);
                setChannelError(false); //Should I do this?
              } else {
                setFetchingChannel(false);
                setChannelError(true);
              }
            })
          );
        }
      }
    }
  }, [channelData, channelList, channelId, dispatch, fetchingChannel]);

  useEffect(() => {
    // Make sure we have channel data first
    if (channelData && !fetchingChannel && !channelError) {
      // If we have channel data, make sure the embed_permission object exists
      if (
        channelData.hasOwnProperty("embed_permission") &&
        channelData.embed_permission
      ) {
        const channelEmbed = channelData.embed_permission;

        // Embed Permission
        if (channelEmbed.permission) {
          if (prevEmbedPermission !== channelEmbed.permission) {
            // Set value if it has changed
            setEmbedPermission(channelEmbed.permission);
          }
        }

        // Domains Allowed Array
        if (channelEmbed.domains_allowed) {
          const domains = channelEmbed.domains_allowed;

          /**
           * @description a function to compare arrays and returns true if items are the same in both arrays. Doesn't care if they are in a differnt order.
           * @param {array} arr1 - first array to compare
           * @param {array} arr2 - second array to compare
           * @returns {boolean} - Returns true if the arrays match and false if they don't.
           */
          var arraysMatch = function (arr1, arr2) {
            // Check if the arrays are the same length
            if (arr1.length !== arr2.length) return false;
            // Check if all items in arr1 exist in arr2
            for (var i = 0; i < arr1.length; i++) {
              if (!arr2.includes(arr1[i], 0)) {
                return false;
              }
            }
            return true;
          };

          // Check if arrays match
          if (prevEmbedWhiteList) {
            if (arraysMatch(domains, prevEmbedWhiteList)) {
              // If the arrays match, we don't need to change the state value
              return;
            } else {
              // If the arrays are different update the state value.
              setEmbedWhiteList(domains);
            }
          }
        }
      } else {
        // If the channel data has been retreived by the API and is in the store, but there is not an embed_permission object found, then assume the embed permission is `anywhere`
        if (prevEmbedPermission !== CHANNEL_EMBED_TYPES.ANYWHERE) {
          // Set value if it has changed
          setEmbedPermission(CHANNEL_EMBED_TYPES.ANYWHERE);
        }
      }
    } // Don't do anything if we don't have channel data yet.
  }, [
    channelData,
    channelError,
    fetchingChannel,
    prevEmbedPermission,
    prevEmbedWhiteList,
  ]);

  

  return {
    fetching: fetchingChannel,
    error: channelError,
    permission: embedPermission,
    domainsAllowed: embedWhiteList,
  };
}
