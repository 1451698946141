import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Typography } from "@rmwc/typography"; // CSS import not needed
import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { useChannelActivities } from "../../hooks/Channel/useChannelActivities";
import useChannelModels from "../../hooks/Channel/useChannelModels";

export const ExportResources = () => {
  let { channelId } = useParams();
  let baseUrl = window.location.origin;

  const [organizedActivities, setOrganizedActivities] = useState(null);
  const [organizedModels, setOrganizedModels] = useState(null);
  const [organizedData, setOrganizedData] = useState(null);
  const [channelName, setChannelName] = useState(channelId);
  const [channelHasNoData, setChannelHasNoData] = useState(false);

  let { activities } = useChannelActivities(channelId);
  const { channelModels } = useChannelModels(channelId);

  //organize activities
  useEffect(() => {
    const organizeActivities = (activities) => {
      let data = activities.map((activity) => {
        return {
          id: activity.id,
          name: activity.title,
          type: "activity",
          audience: "",
          link: `${baseUrl}/activity/${activity.id}`,
          embed_link: `<iframe width="800" height="450" src="${baseUrl}/embed/${activity.id}" frameborder="0" allowfullscreen ></iframe>`,
          lti_link: `lti`,
        };
      });

      setOrganizedActivities(data);
    };

    if (activities && activities.length > 0 && !organizedActivities) {
      organizeActivities(activities);
      setChannelName(activities[0].channel_name.replace(" ", "_"));
    }
  }, [activities, organizedActivities, baseUrl]);

  //organize models
  useEffect(() => {
    const organizeModels = (models) => {
      let data = models.map((model) => {
        return {
          id: model.id,
          name: model.title,
          type: "model",
          audience: "",
          link: `${baseUrl}/models/${model.id}`,
          embed_link: `not supported for models`,
        };
      });

      setOrganizedModels(data);
    };

    if (channelModels && channelModels.length > 0 && !organizedModels) {
      organizeModels(channelModels);
    }
  }, [channelModels, organizedModels, baseUrl]);

  //organize data
  useEffect(() => {
    if (organizedModels && organizedActivities && !organizedData) {
      setOrganizedData([...organizedActivities, ...organizedModels]);
    }
  }, [organizedActivities, organizedModels, organizedData]);

  //check if no data available
  useEffect(() => {
    if (activities && channelModels && [...activities, ...channelModels].length === 0) {
      setChannelHasNoData(true)
    }
  }, [channelModels, activities, organizedData]);

  let headers = [
    { label: "type", key: "type" },
    { label: "name", key: "name" },
    { label: "id", key: "id" },
    { label: "audience", key: "audience" },
    { label: "link", key: "link" },
    { label: "embed link", key: "embed_link" },
  ];

  if (organizedData) {
    return (
      <CSVLink
        data={organizedData}
        headers={headers}
        filename={`${channelName}.csv`}
        target="_blank"
      >
        <Typography
          tag="span"
          use="overline"
          theme="primary"
          style={{ display: "flex", alignItems: "center", margin: "8px 0" }}
        >
          <Icon
            icon={{ icon: "download", size: "small" }}
            style={{ marginRight: "8px" }}
          />
          Export channel resources
        </Typography>
      </CSVLink>
    );
  } else if(channelHasNoData) {
    return (
      <Typography
        tag="span"
        use="overline"
        theme="textDisabledOnBackground"
        style={{
          display: "flex",
          alignItems: "center",
          margin: "8px 0",
          pointerEvents: "none",
        }}
      >
        <Icon
          icon={{ icon: "download", size: "small" }}
          style={{ marginRight: "8px" }}
        />
        Export channel resources (No data available)
      </Typography>
    );
  } else {
    return (
      <Typography
        tag="span"
        use="overline"
        theme="textDisabledOnBackground"
        style={{
          display: "flex",
          alignItems: "center",
          margin: "8px 0",
          pointerEvents: "none",
        }}
      >
        <Icon
          icon={{ icon: "download", size: "small" }}
          style={{ marginRight: "8px" }}
        />
        Export channel resources (loading...)
      </Typography>
    );
  }
};
