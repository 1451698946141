import React, { useState, useEffect } from "react";
import { useClipboard } from "use-clipboard-copy";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { Typography } from "@rmwc/typography"; // Using Sass in main, css not needed

import style from "./DialogEmbed.module.scss";

import { snackbarQueue } from "../Snackbar/snackbarQueue";
import { CHANNEL_EMBED_TYPES } from "../../constants/channelEmbedTypes";
import { useChannelEmbedPermissions } from "../../hooks/Channel/useChannelEmbedPermissions";

const DialogEmbed = ({
  activityId,
  channelId,
  open,
  onClose,
  renderToPortal,
}) => {
  const [embedWhiteList, setEmbedWhiteList] = useState(false);
  const [autoLoad, setAutoLoad] = useState(false);
  const [startOnSlide, setStartOnSlide] = useState(false);
  const [startingSlideIndex, setStartingSlideIndex] = useState(0);
  const [embedCode, setEmbedCode] = useState(
    `<iframe width="800" height="450" src="${window.location.protocol}//${window.location.hostname}/embed/${activityId}" frameborder="0" allowfullscreen ></iframe>`
  );
  const clipboard = useClipboard({
    copiedTimeout: 1500, //ms
    onSuccess() {
      snackbarQueue.notify({ body: "Embed code copied to clipboard" });
    },
    onError() {
      snackbarQueue.notify({
        title: <b>Error</b>,
        body: "Embed code could not be copied",
      });
    },
  });
  const channelEmbedPermissions = useChannelEmbedPermissions(channelId);

  useEffect(() => {
    if (channelEmbedPermissions) {
      if (
        channelEmbedPermissions.permission ===
        CHANNEL_EMBED_TYPES.WHITE_LIST
      ) {
        setEmbedWhiteList(true);
      }
    }
  }, [channelEmbedPermissions]);

  // This effect watches for changes to the activity and embed options and updates the embed code accordingly
  useEffect(() => {
    // const base = `<iframe width="800" height="450" src="${window.location.protocol}//${window.location.hostname}/embed/${activityId}" frameborder="0" allowfullscreen ></iframe>`

    // Query Parameters
    const params = {};
    if (autoLoad) {
      params.autoload = 1;
    }
    if (startOnSlide && startingSlideIndex){
      params.slideIndex = startingSlideIndex;
    }
    const paramString = new URLSearchParams(params);

     // URL
     let url = "";
     const baseUrl = `${window.location.protocol}//${window.location.hostname}/embed/${activityId}`

     if (paramString.toString()){
       url = `${baseUrl}?${paramString}`
     } else {
       url = baseUrl
     }

    // Final Code block
    const code = ['<iframe', 'width="800"', 'height="450"', `src="${url}"`, 'frameborder="0"', 'allowfullscreen', '></iframe>'].join(" ");

    setEmbedCode(code);
  }, [activityId, autoLoad, startOnSlide, startingSlideIndex])

  const embedMessage =
    "This channel requires domain names be white listed for an activity to be embedded. Go to 'Edit Channel' to view and edit the list of allowed domains.";

  const onCopyEmbed = (evt) => {
    clipboard.copy(embedCode);
  };

  const renderSlideIndexInput = () => {
    if (startOnSlide) {
      return (
        <TextField label="Slide index" value={startingSlideIndex} onChange={evt => setStartingSlideIndex(evt.currentTarget.value)} />
      )
    } else return null;
  }

  const renderEmbedBox = () => {
    return (
      <div className={style.embedBox}>
        <TextField
          textarea
          fullwidth
          rows={6}
          value={embedCode}
          ref={clipboard.target}
          onChange={() => {
            return;
          }} // This event is required, but the input shouldn't be mutated, so do nothing onChange
          // readyonly={embedCode} // Required for a readonly field
          readOnly={true}
        />

          <Checkbox label="Auto load" onChange={evt => {
            setAutoLoad(evt.currentTarget.checked)
          }} className={style.checkboxContainer}/>

        <div>
          <Checkbox onChange={evt => {
            setStartOnSlide(evt.currentTarget.checked);
          }} className={style.checkboxContainer}>Starting slide index </Checkbox>
          <div>{renderSlideIndexInput()}</div>
        </div>
      </div>
    );
  };

  const renderEmbedWhiteListMessage = () => {
    if (embedWhiteList) {
      return (
        <Typography use="body2" tag="div">
          NOTE: {embedMessage}
        </Typography>
      );
    } else return null;
  };

  return (
    <Dialog
      open={open}
      onClose={(evt) => onClose(evt)}
      renderToPortal={renderToPortal}
    >
      <DialogTitle>
        Embed Activity
        <IconButton
          icon="close"
          data-mdc-dialog-action="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
        />
      </DialogTitle>
      <DialogContent>
        {renderEmbedBox()}
        {renderEmbedWhiteListMessage()}
      </DialogContent>
      <DialogActions>
        <DialogButton
          action="copy"
          isDefaultAction
          icon="assignment"
          onClick={(evt) => onCopyEmbed(evt)}
        >
          {!clipboard.copied ? "Copy" : "Copied"}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEmbed;
