import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";
import store from "../../store"

export function useChannelSubscriptions(channelId) {
    //States
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const [subscriptions, setSubcriptions] = useState(null);

    //Hooks
    const channelList = useSelector((state) => state.Channels.list);
    const dispatch = useDispatch();

    useEffect(() => {

        let unsubscrib = ()=>{}

        if (!fetching && !error && channelId) {
            if (!channelList.hasOwnProperty(channelId) || !channelList[channelId].subscriptions) {
                setFetching(true);
                dispatch(
                    Actions.Channels.fetchAChannel(channelId, (resp) => {
                        if (!resp.success) {
                            setError(resp.msg);
                        }
                        setFetching(false);
                    })
                );
            } else {
                unsubscrib = store.subscribe(() => {
                    let _channel = store.getState().Channels.list[channelId]
                    if(subscriptions && subscriptions.length !== _channel.subscriptions.length) {
                        setSubcriptions(_channel.subscriptions);
                    }
                })
                setSubcriptions(channelList[channelId].subscriptions);
            }
        }

        return () => {
            unsubscrib()
          }
    }, [channelId, dispatch, channelList, fetching, error, subscriptions]);

    return { subscriptions, error, isFetchingSubscription: fetching };
}