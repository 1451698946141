import React from "react";
import PropTypes from "prop-types";

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
  TopAppBarNavigationIcon,
  TopAppBarFixedAdjust,
} from "@rmwc/top-app-bar";
import "@rmwc/top-app-bar/styles";
import {
  ListDivider,
  ListItemGraphic,
  ListItemText,
  List,
  ListItem,
} from "@rmwc/list";
import "@rmwc/list/styles";
import { Elevation } from "@rmwc/elevation";
import "@rmwc/elevation/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Theme } from "@rmwc/theme"; // Using SASS in main instead of css here
import { Typography } from "@rmwc/typography"; // Using SASS in main, no css import required

import style from "./AccountMenu.module.scss";

import { useUserIsVivedEmployee } from "../../hooks/User/useUserIsVivedEmployee";
import { useUserCanCreateChannel } from "../../hooks/User/useUserCanCreateChannel";
import {
  TopAppBarActionItem,
} from "@rmwc/top-app-bar";
import "@rmwc/top-app-bar/styles";

const AccountMenuMobile = ({
  isSignedIn,
  isAccountMenuOpen,
  openAccountMenu,
  closeAccountMenu,
  userMeta,
  history,
}) => {
  const userIsEmployee = useUserIsVivedEmployee();
  const canCreateChannel = useUserCanCreateChannel();

  const renderAvatar = () => {
    if(isSignedIn) {
      return (
        <Avatar
          src={userMeta.avatar}
          size={"large"}
          name={userMeta.fullName}
          interactive
          onClick={() => openAccountMenu()}
        />
      );
     } else {
      return (
        <Theme use={["textSecondaryOnBackground"]} wrap>
          <TopAppBarActionItem 
            icon="account_circle" 
            onClick={() => openAccountMenu()} 
          />
        </Theme>
      );
     }
  };

  const renderUserMeta = () => {
    if (isSignedIn) {
      return (
        <div className={style.user}>
          <Avatar
            src={userMeta.avatar}
            size={"xlarge"}
            name={userMeta.fullName}
            interactive
            onClick={() => openAccountMenu()}
          />
          <div className={style.userMeta}>
            <Typography use="subtitle1" tag="p">
              {userMeta.fullName}
            </Typography>
            <Typography use="body1" tag="p">
              {userMeta.email}
            </Typography>
          </div>
        </div>
      );
    }
  };

  const renderMenu_ifSignedOut = () => {
    if (!isSignedIn) {
      return (
        <>
          <ListItem onClick={() => history.push("/signin")}>
            <ListItemGraphic icon="account_circle" />
            <ListItemText>Sign in</ListItemText>
          </ListItem>
          <ListItem onClick={() => history.push("/register")}>
            <ListItemGraphic icon="person_add" />
            <ListItemText>Register</ListItemText>
          </ListItem>
          <ListDivider />
          <ListItem onClick={() => history.push("/about")}>
            <ListItemGraphic icon="new_releases" />
            <ListItemText>About</ListItemText>
          </ListItem>
          <ListItem onClick={() => history.push("/terms")}>
            <ListItemGraphic icon="lock" />
            <ListItemText>{"Privacy & terms"}</ListItemText>
          </ListItem>
        </>
      );
    }
  };

  const renderMenu_ifSignedIn = () => {
    if (isSignedIn) {
      return (
        <>
          <ListItem onClick={() => history.push("/account/edit")}>
            <ListItemGraphic icon="account_circle" />
            <ListItemText>Manage your VIVED account</ListItemText>
          </ListItem>
          <ListDivider />
          <ListItem onClick={() => history.push("/dashboard")}>
            <ListItemGraphic icon="subscriptions" />
            <ListItemText>Your dashboard</ListItemText>
          </ListItem>

          {canCreateChannel ? (
            <ListItem onClick={() => history.push("/account/channels/new")}>
              <ListItemGraphic icon="add_box" />
              <ListItemText>Create channel</ListItemText>
            </ListItem>
          ) : (
            <></>
          )}

          <ListItem onClick={() => history.push("/account/logout")}>
            <ListItemGraphic icon="exit_to_app" />
            <ListItemText>Sign out</ListItemText>
          </ListItem>
          <ListDivider />
          <ListItem onClick={() => history.push("/about")}>
            <ListItemGraphic icon="new_releases" />
            <ListItemText>About</ListItemText>
          </ListItem>
          <ListItem onClick={() => history.push("/terms")}>
            <ListItemGraphic icon="lock" />
            <ListItemText>{"Privacy & terms"}</ListItemText>
          </ListItem>

          {userIsEmployee ? (
              <>
                <ListDivider />
                <ListItem onClick={() => history.push("/admin")}>
                  <ListItemGraphic icon="dashboard" />
                  <ListItemText>Admin</ListItemText>
                </ListItem>
                <ListItem onClick={() => history.push("/debug")}>
                  <ListItemGraphic icon="bug_report" />
                  <ListItemText>Debug</ListItemText>
                </ListItem>
              </>
            ) : null}
          
        </>
      );
    }
  };

  const renderAccountMenuMobile = () => {
    return (
      <div className={style.mobileContainer}>
        <Elevation z={4} wrap>
          <TopAppBar fixed className={style.myTopAppBar}>
            <Theme use={["onSurface"]} wrap>
              <TopAppBarRow>
                <TopAppBarSection alignStart>
                  <Theme use={["onSurface"]} wrap>
                    <TopAppBarNavigationIcon
                      icon="close"
                      onClick={(evt) => closeAccountMenu(evt)}
                    />
                  </Theme>
                  <TopAppBarTitle>Account</TopAppBarTitle>
                </TopAppBarSection>
              </TopAppBarRow>
            </Theme>
          </TopAppBar>
        </Elevation>
        <TopAppBarFixedAdjust />
        <List className={style.menuList}>
          {renderUserMeta()}
          {renderMenu_ifSignedOut()}
          {renderMenu_ifSignedIn()}
        </List>
      </div>
    );
  };

  if (isAccountMenuOpen) {
    return renderAccountMenuMobile();
  } else {
    return renderAvatar()
  }
};

AccountMenuMobile.defaultProps = {
  isSignedIn: false,
};

AccountMenuMobile.propTypes = {
  
  openAccountMenu: PropTypes.func.isRequired,

  closeAccountMenu: PropTypes.func.isRequired,
 
  isAccountMenuOpen: PropTypes.bool.isRequired,

  history: PropTypes.object.isRequired,

  isSignedIn: PropTypes.bool,

  userMeta: PropTypes.object.isRequired,
};

export default AccountMenuMobile;
