import React from 'react';

import { Typography } from "@rmwc/typography"; // No css import needed
import { SimpleDataTable } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

export const FeatureDetection = () => {
    let modernizr = {};

    function tof(test) {
      if (test === undefined || null) {
        return "undefined";
      } else if (test) {
        return "TRUE";
      } else return "FALSE";
    }

    if (window.Modernizr) {
      modernizr = window.Modernizr;

      return (
        <div id="modernizr-feature-detection" style={{ paddingTop: "24px" }}>
          <Typography use="headline5" tag="h2">
            Feature Detection
          </Typography>

          <SimpleDataTable
            getCellProps={(cell, index, isHead) => {
              const props = { style: undefined };

              return !isHead && index === 2 && cell.includes("TRUE")
                ? { ...props, style: { color: "darkgreen" } }
                : !isHead && index === 2 && cell.includes("FALSE")
                ? { ...props, style: { color: "maroon" } }
                : props;
            }}
            headers={[["Category", "Feature", "Supported"]]}
            data={[
              ["CSS", "Custom properties", tof(modernizr.customproperties)],
              ["CSS", "Flexbox", tof(modernizr.flexbox)],
              ["DOM", "Shadow root", tof(modernizr.shadowroot)],
              ["Element", "Picture", tof(modernizr.picture)],
              ["Image", "APNG", tof(modernizr.apng)],
              ["Image", "Cross origin", tof(modernizr.imgcrossorigin)],
              ["Image", "JPEG 2000", tof(modernizr.jpeg2000)],
              ["Image", "JPEG XR", tof(modernizr.jpegxr)],
              ["Image", "WebP", tof(modernizr.webp)],
              ["Image", "WebP alpha", tof(modernizr.webp.alpha)],
              ["Image", "WebP animation", tof(modernizr.webp.animation)],
              ["Image", "WebP lossless", tof(modernizr.webp.lossless)],
              ["Input types", "Color", tof(modernizr.inputtypes.color)],
              ["Input types", "Date", tof(modernizr.inputtypes.date)],
              ["Input types", "Datetime", tof(modernizr.inputtypes.datetime)],
              [
                "Input types",
                "Datetime-local",
                tof(modernizr.inputtypes["datetime-local"]),
              ],
              ["Input types", "Email", tof(modernizr.inputtypes.email)],
              ["Input types", "Month", tof(modernizr.inputtypes.month)],
              ["Input types", "Number", tof(modernizr.inputtypes.number)],
              ["Input types", "Range", tof(modernizr.inputtypes.range)],
              ["Input types", "Search", tof(modernizr.inputtypes.search)],
              ["Input types", "Tel", tof(modernizr.inputtypes.tel)],
              ["Input types", "Time", tof(modernizr.inputtypes.time)],
              ["Input types", "Url", tof(modernizr.inputtypes.url)],
              ["Input types", "Week", tof(modernizr.inputtypes.week)],
              ["General", "Fullscreen", tof(modernizr.fullscreen)],
              ["General", "WebGL", tof(modernizr.webgl)],
            ]}
          />
        </div>
      );
    } else {
      return (
        <div id="modernizr-feature-detection" style={{ paddingTop: "24px" }}>
          <Typography use="headline5" tag="h2">
            Feature Detection
          </Typography>
          <Typography use="body1" tag="p">
            Error: Modernizr was not found.
          </Typography>
        </div>
      );
    }
  };
