import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

import { TextField } from "@rmwc/textfield";
import '@rmwc/textfield/styles';
import { Button } from "@rmwc/button";
import '@rmwc/button/styles';
import { Typography } from "@rmwc/typography"; // Using SASS in main, so no css needed

import style from "./VerifyEmail.module.scss";

import {
  AuthPageContainer,
  AuthPageHeading,
  AuthLogoBanner,
  AuthErrorMessage,
  AuthActionButtons
} from "../../components/Auth/Auth";
import { InputErrorMessage } from "../../components/Auth/AuthInputFields";

const VerifyEmail = () => {
  const [userEmail, setUserEmail] = useState("");
  const [confirmError, setConfirmError] = useState(false);
  const [confirmErrorMessage, setConfirmErrorMessage] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [codeResent, setCodeResent] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
    defaultValues: { email: "youremail@domain.com" }
  });

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.state) {
      if (location.state.signUpEmail) {
        const email = location.state.signUpEmail;
        setUserEmail(email);
        setValue("email", email);
      }
    }
  }, [location, setValue]);

  const onSubmit = data => {
    const email = data.email;
    const code = data.code;

    setConfirmError(false);
    setConfirmErrorMessage("");
    setIsConfirming(true);
    setCodeResent(false);

    Auth.confirmSignUp(email, code)
      .then(response => {
        setConfirmError(false);
        setIsConfirming(false);
        setConfirmSuccess(true);
      })
      .catch(err => {
        setConfirmErrorMessage(err.message);
        setConfirmError(true);
        setIsConfirming(false);
      });
  };

  const resendEmailVerification = data => {
    const username = data.email;
    if (username !== "youremail@domain.com") {
      setIsConfirming(true);
      setConfirmError(false);
      setConfirmErrorMessage("");
      setCodeResent(false);

      Auth.resendSignUp(username)
      .then((resp) => {
        setConfirmError(false);
        setConfirmErrorMessage("");
        setCodeResent(true);
        setIsConfirming(false);

      })
      .catch(err => {
        console.error("resendSignUp err: ", err);
        setConfirmErrorMessage(err.message);
        setConfirmError(true);
        setIsConfirming(false);
      })
    } else {
      setConfirmErrorMessage("Please enter your email address.");
      setConfirmError(true);
    }
    Auth.verifyCurrentUserAttribute(username)
      .catch(e => {
        console.error("Verify Current User attribute ERROR: ", e);
      });
  };

  const renderEmail = () => {
    const inputLabel = "Email";
    const inputName = "email";
    const inputType = "email";
    // const inputMaxLength = 255;
    // const inputPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validationErrMsg = {
      required: "An email address is required."
      // maxLength: "The email address must be 254 characters or less.",
      // pattern: "Email requires a valid email address."
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required
        })}
        invalid={errors[inputName]}
        disabled={userEmail ? true : false}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />
        }}
      />
    );
  };

  const renderCode = () => {
    const inputLabel = "Verification code";
    const inputName = "code";
    const inputType = "number";
    const inputPattern = /[\S]+/;
    const validationErrMsg = {
      required: "An email address is required.",
      pattern: "The confirmation code can't contain whitespace."
    };

    return (
      <TextField
        // general style props
        outlined
        className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          pattern: {
            value: inputPattern,
            message: validationErrMsg.pattern
          }
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />
        }}
      />
    );
  };

  const renderVerificationMessage = () => {
    const renderMessage = () => {
      if (userEmail) {
        return (
          <Typography use="body1" tag="p">
            A confirmation email has been sent to <i>{userEmail}</i> containing
            a verification code. Enter the code below to activate your account
            and continue.
          </Typography>
        );
      } else
        return (
          <Typography use="body1" tag="p">
            Enter your email address and the verification code sent to your
            email to activate your account and continue.
          </Typography>
        );
    };
    const renderCodeResentMessage = () => {
      if (codeResent) {
        return (
          <Typography use="body1" tag="p">
            A new confirmation code has been sent to your email address. Note: Only the newest code sent will be valid.
          </Typography>
        )
      } else return null;
    }
    return (
      <div>
        {renderMessage()}
        <Typography use="body1" tag="p">
          If you don't see the email, check your spam folder for an email from{" "}
          <i>autoresponder@vivedlearning.com</i>.
        </Typography>
        {renderCodeResentMessage()}
        <div>
          <div>{renderEmail()}</div>
          <div>{renderCode()}</div>
          <AuthActionButtons
            primaryAction={
              <Button
                raised
                label="Submit"
                onClick={handleSubmit(onSubmit)}
                type="button"
              />
            }
            secondaryAction={
              <Button
                label="Resend verification code"
                type="button"
                style={{ textTransform: "none" }}
                onClick={handleSubmit(resendEmailVerification)}
              />

            }
          />
        </div>
      </div>
    );
  };

  const renderVerificationSuccess = () => {
    return (
      <div>
        <Typography use="body1" tag="p">
        Success!
        </Typography>
        <Typography use="body1" tag="p">
          Your email address has been verified and your VIVED account
          has been activated.
        </Typography>
        <AuthActionButtons
          primaryAction={
            <Button
              raised
              label="Continue"
              onClick={() => history.push("/signin")}
            />
          }
        />
      </div>
    );
  };

  const renderContent = () => {
    if (confirmSuccess) {
      return renderVerificationSuccess();
    } else return renderVerificationMessage();
  };

  return (
    <AuthPageContainer
      elevation={0}
      linearProgress={{ closed: isConfirming ? false : true }}
    >
      <form>
        <AuthLogoBanner align="left" />
        <AuthPageHeading align="left" title="Verify your email address" />

        <AuthErrorMessage
          isShown={confirmError}
          message={confirmErrorMessage}
        />
        {renderContent()}
        <div></div>
      </form>
    </AuthPageContainer>
  );
};

export default VerifyEmail;
