import React from "react";

function VivedIconCustomCreatePen(props) {
  const fill = props.fill || "currentColor";
  const width = props.width || "100%";
  const height = props.height || "100%";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <path d="M13.18 18.68a.36.36 0 0 0 .26-.11.36.36 0 0 0 .08-.42l-1.21-2.68-2 2L13 18.64a.42.42 0 0 0 .18.04zm-1.32-3.82L5.5 8.51l-2.11 2.11L9.74 17zM4.44 7.45a1.13 1.13 0 0 0-1.58 0L2.33 8a1.11 1.11 0 0 0 0 1.58l.53.53L5 8zm10.69.79a.35.35 0 0 1-.26-.11.36.36 0 0 1-.11-.26V3H6.15a.38.38 0 0 0-.38.37v4.35L6 8l6.36 6.35.41.41.07.17 1.33 2.93a1.12 1.12 0 0 1-1 1.58 1.17 1.17 0 0 1-.45-.09L9.79 18l-.17-.08-3.85-3.85v6.53a.38.38 0 0 0 .38.37h13.48a.38.38 0 0 0 .37-.37V8.24zm.37-5v4.25h4.28z" />
      </g>
    </svg>
  );
}

export default VivedIconCustomCreatePen;
