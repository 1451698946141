import * as TYPES from "./UserTypes";

export const setUserData = (user) => {
  if (!user.groups) {
    user.groups = [];
  }

  if (!user.avatar) {
    user.avatar = "";
  }

  return {
    type: TYPES.SET_USER_DATA,
    payload: user,
  };
};

export const logOut = () => ({
  type: TYPES.LOGOUT_USER,
});

export const setUserLoginStatus = (status) => ({
  type: TYPES.SET_USER_LOGIN_STATUS,
  payload: {
    status,
  },
});

export const setLtiInfo = (data) => {
  return {
    type: TYPES.USER_SET_LTI_INFO,
    payload: {
      lti: data,
    },
  };
};

// sets the JWT that comes in through the session query
export const setLtiSession = (data) => {
  return {
    type: TYPES.USER_SET_LTI_SESSION,
    payload: {
      session: data,
    },
  };
};

export const fetchUserChannels = (userId, callback) => {
  return {
    type: TYPES.FETCH_USER_CHANNELS,
    payload: {
      userId,
    },
    callback,
  };
};

export const setUserChannelList = (userChannels) => {
  return {
    type: TYPES.SET_USER_CHANNEL_LIST,
    payload: {
      userChannels,
    },
  };
};

export const setPendingChannelList = (pendingChannels) => {
  return {
    type: TYPES.SET_PENDING_CHANNEL_LIST,
    payload: {
      pendingChannels,
    },
  };
};

export const addUserChannel = (channelId, role) => {
  return {
    type: TYPES.ADD_USER_CHANNEL,
    payload: {
      channelId,
      role,
    },
  };
};

export const removeUserChannel = (channelId) => {
  return {
    type: TYPES.REMOVE_USER_CHANNEL,
    payload: {
      channelId,
    },
  };
};

export const removePendingChannel = (channelId) => {
  return {
    type: TYPES.REMOVE_PENDING_CHANNEL,
    payload: {
      channelId,
    },
  };
};

export const confirmChannelInvite = (channelId, callback) => {
  return {
    type: TYPES.CONFIRM_CHANNEL_INVITE,
    payload: {
      channelId,
    },
    callback,
  };
};

export const declineChannelInvite = (channelId, role, callback) => {
  return {
    type: TYPES.DECLINE_CHANNEL_INVITE,
    payload: {
      channelId,
      role
    },
    callback,
  };
};

export const checkIfMainUser = (email, requestState, callback) => ({
  type: TYPES.CHECK_IF_MAIN_USER,
  payload: {
    email,
    requestState //cognitoRequest || googleRequest
  },
  callback
});

export default {
  setUserData,
  logOut,
  setUserLoginStatus,
  setLtiInfo,
  setLtiSession,
  fetchUserChannels,
  setUserChannelList,
  addUserChannel,
  removeUserChannel,
  removePendingChannel,
  declineChannelInvite,
  checkIfMainUser
};
