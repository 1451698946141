import {
  call,
  put,
  select
} from 'redux-saga/effects';
import { apiClient, uploadFile } from '../utils/apiClient';
import Actions from './actions';
import { currentUser } from '../utils/AmplifyAuthController';

const { Universal } = Actions;

export function* requestModule(action) {

  try {
    const user = yield call(currentUser);

    let userToken = user ? user.signInUserSession.idToken.jwtToken : null;

    // handle userToken if this is an LTI session
    if(userToken === null){
      const state = yield select();
      if(state.User.isLtiUser){
        userToken = state.User.ltiSession;
      }
    }

    const params = {
      query: {},
      method: 'get',
      data: {},
      path: '',
      ...action,
      userToken
    };
    const response = yield call(apiClient, params);

    if (action.dispatchAction) {
      yield put({
        type: action.dispatchAction,
        action,
        payload: response.data,
        error: false,
        flow: 'end',
      });
    }

    return response;
  } catch (e) {

    yield put(Universal.requestError({
      module: action.module,
      action,
      error: e.toString()
    }));

    throw(e);
  }
}

export function* uploadImage(action) {
  try {
    const { folder, file } = action.data;
    const user = yield call(currentUser);

    let userToken = user ? user.signInUserSession.idToken.jwtToken : null;

    const newfile = yield uploadFile(file, folder, userToken);
    yield put({ type: 'UNIVERSAL_UPLOAD_FILE_SUCCESS', newfile });

    if (action.cb) {
      yield call(action.cb, newfile);
    }

    return newfile;
  } catch (e) {
    yield put(Actions.Universal.requestError({
      payload: { error: e },
    }));
    return null;
  }
}

export default {
  requestModule,
  uploadImage,
};
