import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../Redux/actions";

export function useActivity(activityId, channelId = "-1") {
  //States
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [activity, setActivity] = useState(null);

  //Hooks
  const activitiesList = useSelector((state) => state.Activities.list);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetching && !error) {
      if (
        !activitiesList.hasOwnProperty(activityId) ||
        !activitiesList[activityId].data
      ) {
        setFetching(true);
        dispatch(
          Actions.Activities.fetchActivity(activityId, channelId, (resp) => {
            if (!resp.success) {
              setError(resp.msg);
            }
            setFetching(false);
          })
        );
      } else {
        setActivity(activitiesList[activityId]);
      }
    }
  }, [activityId, channelId, dispatch, activitiesList, fetching, error]);

  return { activity, error };
}
