
const initialState = {
  docs: [],
  currentPlaylist: null,
};


const playist = (state = null, action) => {
  switch (action.type) {
    case 'PLAYLIST_SET_SUBSCRIBED':
      if (state.id === action.id) {
        return {
          ...state,
          subscribed: true,
        };
      }
      return state;
    default:
      return state;
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PLAYLIST_SET_CURRENT_DOCS':
      return { ...state, docs: action.payload.data };
    case 'PLAYLIST_SET_SUBSCRIBED':
      return { ...state, docs: state.docs.map(pl => playist(pl, action)) };

    default:
      return state;
  }
};

export default reducer;
