import { Hub, Auth } from "aws-amplify";
import store from "../store";
import Actions from "../Redux/actions";
import {
  setUserData,
  setUserLoginStatus,
  logOut,
  fetchUserChannels,
} from "../Redux/Users/UserActions";
import { LoginEnum } from "../Redux/Users/UserTypes";
import { checkIfMainUser } from "../Redux/Users/UserActions"
import { dialogQueue } from "../components/Dialog/dialogQueue";

//Call this to get the current user
export const currentUser = async () => {
  try {
    let user = await Auth.currentAuthenticatedUser({
      bypassCache: true, // Enables auto-login next time the user comes back to the site
    });
    return user;
  } catch (e) {
    return null;
  }
};

//Call this to update a users name, family_name, and avatar
export const UpdateUserMeta = (given_name, family_name, avatar) =>
  new Promise(async (resolve, reject) => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Enables auto-login next time the user comes back to the site
    })
      .then((user) => {
        if (user === "not authenticated") reject("User is not logged in");

        //Find new data
        const newAttributes = {};
        const currentAttributes = user.attributes;

        if (given_name && currentAttributes.given_name !== given_name) {
          newAttributes.given_name = given_name;
        }

        if (family_name && currentAttributes.family_name !== family_name) {
          newAttributes.family_name = family_name;
        }

        if(given_name && !family_name) {
          newAttributes.name = given_name + " " + currentAttributes.family_name;
        }

        if(family_name && !given_name) {
          newAttributes.name = currentAttributes.given_name + " " + family_name;
        }

        if(family_name && given_name) {
          newAttributes.name = given_name + " " + family_name;
        }

        if(!family_name && !given_name && currentAttributes.given_name) {
          newAttributes.name = currentAttributes.given_name + " " + currentAttributes.family_name
        }

        if (avatar && !currentAttributes["custom:avatar"]) {
          newAttributes["custom:avatar"] = avatar;
        } else if (avatar && currentAttributes["custom:avatar"] !== avatar) {
          newAttributes["custom:avatar"] = avatar;
        }

        //convert avatar file name to url based on env
        if (newAttributes["custom:avatar"]) {
          if (process.env.REACT_APP_API_ENV === "prod") {
            newAttributes[
              "custom:avatar"
            ] = `https://collections-bucket-prod-5566.s3.us-east-1.amazonaws.com/Thumbnails/${avatar}`;
          }
          if (process.env.REACT_APP_API_ENV === "staging") {
            newAttributes[
              "custom:avatar"
            ] = `https://collections-bucket-staging-5566.s3.us-east-1.amazonaws.com/Thumbnails/${avatar}`;
          }
          if (process.env.REACT_APP_API_ENV === "test") {
            newAttributes[
              "custom:avatar"
            ] = `https://collections-bucket-dev-5566.s3.us-east-1.amazonaws.com/Thumbnails/${avatar}`;
          }
          if (process.env.REACT_APP_API_ENV === "local") {
            newAttributes[
              "custom:avatar"
            ] = `https://collections-bucket-dev-5566.s3.us-east-1.amazonaws.com/Thumbnails/${avatar}`;
          }
        }

        if (Object.entries(newAttributes).length === 0) {
          //Nothing has changed
          resolve();
        }
        return Auth.updateUserAttributes(user, newAttributes);
      })
      .then((resp) => {
        if (resp !== "SUCCESS") {
          reject(resp);
          resolve({ status: "success", changedParameters: this.newAttributes });
        }

        return Auth.currentAuthenticatedUser({
          bypassCache: true, // Enables auto-login next time the user comes back to the site
        });
      })
      .then((user) => {
        const vivedUser = MapAmpliftyUserToLocalUser(user);
        store.dispatch(setUserData(vivedUser));
        resolve();
      })
      .catch((e) => reject(e));
  });

//Maps our Amplify user data to what we need in the store
export const MapAmpliftyUserToLocalUser = (amplifyUser) => {
  let isGoogleUser = amplifyUser?.attributes?.identities ? true : false

  return {
    id: amplifyUser.attributes.sub,
    name: amplifyUser.attributes.name,
    given_name: amplifyUser.attributes.given_name,
    family_name: amplifyUser.attributes.family_name,
    email: amplifyUser.attributes.email,
    phone: amplifyUser.attributes.phone_number
      ? amplifyUser.attributes.phone_number
      : null,
    groups: amplifyUser.signInUserSession.idToken.payload["cognito:groups"]
      ? amplifyUser.signInUserSession.idToken.payload["cognito:groups"]
      : [],
    avatar: amplifyUser.attributes["custom:avatar"]
      ? amplifyUser.attributes["custom:avatar"]
      : "",
    isGoogleUser
  };
};

export default class AmplifyAuthController {
  _verbose = false;

  constructor() {
    Hub.listen("auth", (data) => this.handleAuthEvent(data));
    if (store.getState().User.isLtiUser) {
      // don't attempt auto-login for LTI users
      store.dispatch(setUserLoginStatus(LoginEnum.isNotLoggedIn));
    } else {
      Auth.currentAuthenticatedUser({
        bypassCache: false, // Enables auto-login next time the user comes back to the site
      })
        .then((amplifyUser) => {

          this.LogMessage("Logged in on start");
          //We are logged in
          const vivedUser = MapAmpliftyUserToLocalUser(amplifyUser);
          store.dispatch(setUserData(vivedUser));
          store.dispatch(
            fetchUserChannels(vivedUser.id, (resp) => {
              if (resp.success) {
                store.dispatch(setUserLoginStatus(LoginEnum.isLoggedIn));
              } else {
                console.warn("Something went wrong in getting user channels", resp);
                store.dispatch(setUserLoginStatus(LoginEnum.isNotLoggedIn));
              }
            })
          );
          this.RegisterGoogleAnalyticsEvent("", vivedUser.id);
        })
        .catch(() => {
          //Not logged in
          this.LogMessage("Not logged in on start");
          store.dispatch(setUserLoginStatus(LoginEnum.isNotLoggedIn));
        });
    }
  }

  handleAuthEvent(data) {

    switch (data.payload.event) {
      case "signIn":
        this.handleSignIn();
        break;
      case "signUp":
        break;
      case "signOut":
        this.handleSignOut();
        break;
      case "signIn_failure":
        break;
      case "configured":
        break;
      default:
    }
  }

  handleCheckIfMainUser(user, requestState) {
    const triggerFailedDialog = () => {
      window.onunload = () => {
        Auth.signOut()
        window.onunload = null
      }

      dialogQueue
      .alert({
        title: 'Sign in failed', 
        body: "You tried to sign in with Google, but we've detected that you registered using your email address and password. Please sign in with your email and password to access your VIVED account.",
        preventOutsideDismiss:true
      })
      .then(() => {
        Auth.signOut()
      })
    }
    
    store.dispatch(checkIfMainUser(user.attributes.email, requestState, (result) => {
      if(result.success) {
        if(!result.isMainUser) {

          triggerFailedDialog()
        } else {
          this.setUpUserOnSignIn(user)
        }
      }
    }))
  }

  handleSignIn() {

    this.LogMessage("Handle Sign In");
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Enables auto-login next time the user comes back to the site
    })
      .then((amplifyUser) => {
        let isUserAGoogleUser = amplifyUser.username.includes("Google")
        if(isUserAGoogleUser) {
          this.handleCheckIfMainUser(amplifyUser, "googleRequest")
          return
        } else {
          this.setUpUserOnSignIn(amplifyUser)
        }

      })
      .catch(() => {
        //Not logged in
        store.dispatch(setUserLoginStatus(LoginEnum.isNotLoggedIn));
      });
  }

  setUpUserOnSignIn(user) {
    // //We are logged in
    const vivedUser = MapAmpliftyUserToLocalUser(user);
    store.dispatch(setUserData(vivedUser));
    store.dispatch(
      fetchUserChannels(vivedUser.id, (resp) => {
        if (resp.success) {
          store.dispatch(setUserLoginStatus(LoginEnum.isLoggedIn));
        } else {
          console.warn("Something went wrong in getting user channels", resp);
          store.dispatch(setUserLoginStatus(LoginEnum.isNotLoggedIn));
        }
      })
    );
    this.RegisterGoogleAnalyticsEvent("login", vivedUser.id);
  }

  handleSignOut() {
    this.LogMessage("Handle Sign Out");
    store.dispatch(logOut());
    store.dispatch(Actions.Channels.flush());

    this.RegisterGoogleAnalyticsEvent("logout", null);
  }

  LogMessage(msg) {
    if (this._verbose) {
      console.log("[AMPLIFY AUTH CONTROLLER]", msg);
    }
  }

  RegisterGoogleAnalyticsEvent = (event, userId) =>
  {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      userId,
    });

    this.LogMessage(`Pushed to data layer: { event: ${event}, userId: ${userId} }`);
  }
}
