import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const ChannelsIcon = ({
  fill = colors.gray.dark,
  width = '24px',
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...iconProps}>
    <title>ic_subscriptions_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M20 8H4V6h16v2zm-2-6H6v2h12V2zm4 10v8c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-8c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2zm-6 4l-6-3.27v6.53L16 16z" />
    </g>
  </svg>
);

ChannelsIcon.propTypes = Props;
export default ChannelsIcon;
