import * as CognitoCred from "./utils/CognitoCredentials"

const awsmobile = (params) => {
    return {
        "aws_project_region": "us-east-1",
        "aws_cognito_identity_pool_id": "us-east-1:ccd02b73-fe2b-4680-8fd1-1309f0523ff4",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_ifGPigjqM",
        "aws_user_pools_web_client_id": CognitoCred.ClientId(),
        "oauth": {
            "domain": "vived-users.auth.us-east-1.amazoncognito.com",
            "scope": [
                "phone",
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ],
            "redirectSignIn": CognitoCred.RedirectUrl_SignIn(),
            "redirectSignOut": CognitoCred.RedirectUrl_SignOut(),
            "responseType": "code"
        },
        "federationTarget": "COGNITO_USER_POOLS"
    }
}


export default awsmobile;
