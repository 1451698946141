import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export function useChannelActivities(channelId) {
  //States
  const [activities, setActivities] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  //Hooks
  const channelList = useSelector((state) => state.Channels.list);
  const activitiesList = useSelector((state) => state.Activities.list);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetching && !error) {
      if (
        !channelList.hasOwnProperty(channelId) ||
        !channelList[channelId].hasOwnProperty("allActivitiesFetched") ||
        !channelList[channelId].allActivitiesFetched
      ) {
        setFetching(true);
        dispatch(
          Actions.Activities.fetchActivitiesForChannel(channelId, (resp) => {
            if (!resp.success) {
              setError(resp.msg);
            }
            setFetching(false);
          })
        );
      } else {
        let hasAllActivities = true;
        let listToSet = [];

        channelList[channelId].activities.forEach((activityId) => {
          if (activitiesList[activityId]) {
            listToSet.push(activitiesList[activityId]);
          } else {
            hasAllActivities = false;
          }
        });

        if (hasAllActivities) {
          //Sort
          let sortedList = listToSet.sort(function (a, b) {
            var textA = a.title ? a.title.toUpperCase() : -1;
            var textB = b.title ? b.title.toUpperCase() : -1;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });

          setActivities(sortedList);
        }
      }
    }
  }, [channelId, dispatch, channelList, activitiesList, fetching, error]);

  return { activities, error, fetching };
}
