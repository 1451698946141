import heroImageDesktop_1x from "../../assets/images/landing/hero/compressed/hero-image-desktop@1x.jpg";
import heroImageDesktop_2x from "../../assets/images/landing/hero/compressed/hero-image-desktop@2x.jpg";
import heroImageDesktop_05x from "../../assets/images/landing/hero/compressed/hero-image-desktop@0.5x.jpg";
import heroImageDesktop_3x from "../../assets/images/landing/hero/compressed/hero-image-desktop@3x.jpg";
import heroImageDesktop_4x from "../../assets/images/landing/hero/compressed/hero-image-desktop@4x.jpg";

import heroImageMobile_1x from "../../assets/images/landing/hero/compressed/hero-image-mobile@1x.jpg";
import heroImageMobile_2x from "../../assets/images/landing/hero/compressed/hero-image-mobile@2x.jpg";
import heroImageMobile_05x from "../../assets/images/landing/hero/compressed/hero-image-mobile@0.5x.jpg";
import heroImageMobile_3x from "../../assets/images/landing/hero/compressed/hero-image-mobile@3x.jpg";
import heroImageMobile_4x from "../../assets/images/landing/hero/compressed/hero-image-mobile@4x.jpg";

import crossDeviceImageMobile_1x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-4x3-mobile@1x.png"
import crossDeviceImageMobile_2x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-4x3-mobile@2x.png"
import crossDeviceImageMobile_3x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-4x3-mobile@3x.png"
import crossDeviceImageMobile_4x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-4x3-mobile@4x.png"
import crossDeviceImageMobile_05x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-4x3-mobile@0.5x.png"

import crossDeviceImageDesktop_1x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-16x10-desktop@1x.png"
import crossDeviceImageDesktop_2x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-16x10-desktop@2x.png"
import crossDeviceImageDesktop_3x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-16x10-desktop@3x.png"
import crossDeviceImageDesktop_4x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-16x10-desktop@4x.png"
import crossDeviceImageDesktop_05x from "../../assets/images/landing/cross-device/compressed/lossless/cross-device-mockup-16x10-desktop@0.5x.png"


export const heroImageAlt = "A teacher engaging with excited middle school students using the VIVED Learning Platform on tablets."

export const heroImageDesktop = [
  /// 2:3 ratio - 825x550 @1x
  {
    src: heroImageDesktop_4x,
    size: "4x",
  },
  {
    src: heroImageDesktop_3x,
    size: "3x",
  },
  {
    src: heroImageDesktop_2x,
    size: "2x",
  },
  {
    src: heroImageDesktop_1x,
    size: "1x",
  },
  {
    src: heroImageDesktop_05x,
    size: "0.5x",
  },

];

export const heroImageMobile = [
  /// 1:1 ratio - 450x450 @1x
  {
    src: heroImageMobile_4x,
    size: "4x",
  },
  {
    src: heroImageMobile_3x,
    size: "3x",
  },
  {
    src: heroImageMobile_2x,
    size: "2x",
  },
  {
    src: heroImageMobile_1x,
    size: "1x",
  },
  {
    src: heroImageMobile_05x,
    size: "0.5x",
  }
];

export const crossDeviceImageAlt = `A frog activity from the VIVED Learning Platform displayed on a variety of devices including the Pixel 4 XL, PixelBook Go, iPad Pro 12.9 inch, and an Apple Pro Display XDR.`

export const crossDeviceImageDesktop = [
  {
    src: crossDeviceImageDesktop_4x,
    size: "4x",
  },
  {
    src: crossDeviceImageDesktop_3x,
    size: "3x",
  },
  {
    src: crossDeviceImageDesktop_2x,
    size: "2x",
  },
  {
    src: crossDeviceImageDesktop_1x,
    size: "1x",
  },
  {
    src: crossDeviceImageDesktop_05x,
    size: "0.5x",
  }

]

export const crossDeviceImageMobile = [
  {
    src: crossDeviceImageMobile_4x,
    size: "4x",
  },
  {
    src: crossDeviceImageMobile_3x,
    size: "3x",
  },
  {
    src: crossDeviceImageMobile_2x,
    size: "2x",
  },
  {
    src: crossDeviceImageMobile_1x,
    size: "1x",
  },
  {
    src: crossDeviceImageMobile_05x,
    size: "0.5x",
  }
  
]
