import React, { useState, useEffect, useCallback } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import "@rmwc/icon-button/styles";
import "@rmwc/select/styles";

import { CircularProgress } from "@rmwc/circular-progress";
import { useHistory } from "react-router";

const DuplicatingActivityDialog = ({
  isAlertOpen,
  isRedirectOpen,
  ActivityToRedirectTo,
  resetDuplicatActivityProcess
}) => {
  const [timeleft, setTimeleft] = useState(5);

  const history = useHistory();

  const resetAll = useCallback(() => {
    resetDuplicatActivityProcess()
    setTimeleft(5)
  }, [setTimeleft, resetDuplicatActivityProcess])

  const renderAlert = () => {
    return (
    <Dialog
      open={isAlertOpen}
      preventOutsideDismiss
    >
      <DialogTitle>
        Duplicating activity
      </DialogTitle>
      <DialogContent><CircularProgress /> processing...</DialogContent>
    </Dialog>
    );
  };

  const redirect =  useCallback(() => {
    history.push(`/activity/${ActivityToRedirectTo}/edit`)
    resetAll()
  }, [resetAll, ActivityToRedirectTo, history])

  const renderRedirectDialog = () => {

    return (
      <Dialog
        open={isRedirectOpen}
        preventOutsideDismiss
      >
        <DialogTitle>
            Activity duplicated successfully
        </DialogTitle>
        <DialogContent>Success! You will be redirected to the page to edit the activity in {timeleft}.</DialogContent>
        <DialogActions>
          <DialogButton action="close" onClick={()=>resetAll()}>Cancel</DialogButton>
          <DialogButton onClick={()=>redirect()} isDefaultAction>
            Ok
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  };

  //handle timer count down
  useEffect(() => {
    let timer = () => {}
    if(isRedirectOpen) {
      timer = setTimeout(() => {
        if(timeleft > 0) {
          setTimeleft(timeleft - 1)
        } else {
          redirect()
        }
      }, 1000);
    }

    return () => clearTimeout(timer)
  }, [isRedirectOpen, timeleft, redirect])

  return (
    <>
      {renderAlert()}
      {renderRedirectDialog()}
    </>
  );
};

export default DuplicatingActivityDialog;
