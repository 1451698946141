import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const UploadButton = (
  fill = colors.gray.main,
) => (
  <svg width="28px" viewBox="0 0 24 24">
    <defs>
      <clipPath id="SVG_svg__a">
        <path
          transform="translate(1038 427)"
          fill={fill}
          stroke={fill}
          d="M0 0h20v20H0z"
        />
      </clipPath>
    </defs>
    <g transform="translate(-1038 -427)" clipPath="url(#SVG_svg__a)" fill={fill}>
      <path
        d="M1044.47 442.295h7.059v-7.059h4.706L1048 427l-8.236 8.236h4.706zm-4.706 2.352h16.471V447h-16.471z"
        fill={fill}
      />
    </g>
  </svg>
);

UploadButton.propTypes = Props;
export default UploadButton;
