import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Select } from "@rmwc/select";
import "@rmwc/select/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { Typography } from "@rmwc/typography"; // Using Sass in Main. CSS not needed here.

import style from "./ChannelVisibility.module.scss";

import { CHANNEL_VISIBILITY_TYPES } from "../../../constants/channelVisibilityTypes";
import Actions from "../../../Redux/actions";
import { snackbarQueue } from "../../Snackbar/snackbarQueue";

export const ChannelVisibility = ({ visibility, channel }) => {
  const [editing, setEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [visibilitySelection, setVisibilitySelection] = useState(visibility);
  const dispatch = useDispatch();

  const visibilityOptions = [
    {
      label: "Private",
      value: CHANNEL_VISIBILITY_TYPES.PRIVATE,
    },
    {
      label: "Hidden",
      value: CHANNEL_VISIBILITY_TYPES.HIDDEN,
    },
  ];

  const fetchUpdateChannel = (data, cb) => {
    dispatch(Actions.Channels.fetchUpdateChannel(data, cb));
  }

  const submitChange = () => {
    setIsSaving(true);

    fetchUpdateChannel(
      {
        name: channel.name,
        uid: channel.id,
        access_pattern: visibilitySelection,
      },
      (resp) => {
        if (resp.success) {
          visibility = visibilitySelection;
          setIsSaving(false);
          cancelChange();
        } else {
          setIsSaving(false);
          snackbarQueue.notify({
            title: <b>Error</b>,
            body: "Something went wrong updating the channel's visibility setting. Please try again.",
          });
        }
      }
    );       
  };

  const cancelChange = () => {
    setVisibilitySelection(visibility);
    setEditing(false);
  };

  const editVisibility = () => {
    setEditing(true);
  };

  if (editing) {
    return (
      <div className={style.container}>
        <div className={style.visibility}>
          <Select
            outlined
            disabled={isSaving}
            label="Visibility"
            options={visibilityOptions}
            value={visibilitySelection}
            onChange={(evt) => setVisibilitySelection(evt.currentTarget.value)}
          />
        </div>
        {isSaving ? (
          <CircularProgress />
        ) : (
          <>
            <IconButton icon="cancel" onClick={cancelChange} />
            <IconButton
              icon="check_circle"
              onClick={submitChange}
              disabled={visibility === visibilitySelection}
            />
          </>
        )}
      </div>
    );
  } else {
    // If not editing, only display the current visibility setting
    return (
      <div className={style.container}>
        <div className={style.visibility}>
          <Typography use="subtitle1" tag="div">
            Visibility: <span className={style.capitalize}>{visibility}</span>
          </Typography>
        </div>

        <IconButton icon="edit" onClick={editVisibility} />
      </div>
    );
  }
};

ChannelVisibility.defaultProps = {};
ChannelVisibility.propTypes = {
  visibility: PropTypes.string.isRequired,
};
