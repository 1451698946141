import React from "react";

import { Typography } from "@rmwc/typography"; // No css import needed
import { SimpleDataTable } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { EndPoint as getApiEndpoint } from "../../../utils/apiClient";

export const Environment = () => {
    const deploymentEnv = process.env.REACT_APP_DEPLOYMENT_ENV;
    const apiEnv = process.env.REACT_APP_API_ENV;
    const apiHost = getApiEndpoint();
    const jwtSecret = process.env.REACT_APP_JWT_SECRET ? "defined" : "undefined";

  return (
    <div id="device-player-info" style={{ paddingTop: "24px" }}>
      <Typography use="headline5" tag="h2">
        Environment
      </Typography>
      <SimpleDataTable
        headers={[["Property", "Value"]]}
        data={[
          ["Deployment environment", deploymentEnv],
          ["API environment", apiEnv],
          ["API hostname", apiHost],
          ["JWT token", jwtSecret]
        ]}
      />
    </div>
  );
};
