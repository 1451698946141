import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Portal } from "@rmwc/base";
import { DialogQueue } from "@rmwc/dialog";
import '@rmwc/dialog/styles';
import { SnackbarQueue } from "@rmwc/snackbar";
import '@rmwc/snackbar/styles';

import { dialogQueue } from "./components/Dialog/dialogQueue";
import { snackbarQueue } from "./components/Snackbar/snackbarQueue";

class MainWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  render() {
    const wrapperClass = classNames({
      wrapper: true,
    });

    const { children } = this.props
    return (
      <>
        <div className={wrapperClass}>
          {children}
        </div>
        
        <DialogQueue dialogs={dialogQueue.dialogs}/>
        <Portal />
        <SnackbarQueue messages={snackbarQueue.messages} />
      </>
    );
  }
}

export default connect(state => ({  
  theme: state.theme,
  customizer: state.customizer,
}))(MainWrapper);
