import React from "react";
import style from "./Layout.module.scss";

/**
 * Flex container that fills 100% of the width and has a min-height of the window's inner height. Intended to be used as the root element in the PageWrapper component. Doesn't use any media queries.
 */
export const RootContainer = ({ children, className, ...otherProps }) => {
  const classes = [style.rootContainer, className ? className : null].join(" ");

  return (
    <div className={classes} {...otherProps}>
      {children}
    </div>
  );
};
/**
 * The Main Container that includes the <main> html tag. Only 1 <main> tag is allowed on any page so this component should be used once at most. This container's background color is set to the theme background color. Overscroll behavior is disabled. Overflow is auto. Flex-grow is enabled.
 */
export const MainContainer = ({ children, className, ...otherProps }) => {
  const classes = [style.mainContainer, className ? className : null].join(" ");

  return (
    <main className={classes} {...otherProps}>
      {children}
    </main>
  );
};

/**
 * A div flex container with overflow hidden intended for vertical scrolling.
 */
export const VerticalScrollContainer = ({
  children,
  className,
  ...otherProps
}) => {
  const classes = [
    style.verticalScrollContainer,
    className ? className : null,
  ].join(" ");

  return (
    <div className={classes} {...otherProps}>
      {children}
    </div>
  );
};

/**
 * A div flex container with overflow hidden intended for vertical scrolling. Includes a forward ref.
 */
export const VerticalScrollContainerWithRef = React.forwardRef(
  ({ children, className, ...otherProps }, ref) => {
    const classes = [
      style.verticalScrollContainer,
      className ? className : null,
    ].join(" ");

    return (
      <div className={classes} {...otherProps} ref={ref}>
        {children}
      </div>
    );
  }
);

export const ContentContainer = ({
  children,
  className,
  fullWidth,
  ...otherProps
}) => {
  const classes = [
    fullWidth ? style.contentContainerNoPadding : style.contentContainerNormal,
    className ? className : null,
  ].join(" ");

  return (
    <div className={classes} {...otherProps}>
      {children}
    </div>
  );
};

export const FlexGrid = ({ children, className, ...otherProps }) => {
  const classes = [style.gridContainer, className ? className : null].join(" ");

  return (
    <div className={classes} {...otherProps}>
      {children}
    </div>
  );
};

