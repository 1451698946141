import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import SearchIcon from '../../assets/icons/SearchIcon';
import AccountCircle from '../../assets/icons/AccountCircle';
import HomeIcon from '../../assets/icons/HomeIcon';
import WhatshotIcon from '../../assets/icons/WhatshotIcon';
import FolderIcon from '../../assets/icons/FolderIcon';
import DotsIcon from '../../assets/icons/DotsIcon';
import ChannelsIcon from '../../assets/icons/ChannelIcon';
import VivedIcon from '../../assets/icons/VivedIcon';
import CloseIcon from '../../assets/icons/CloseIcon';
import NavigateNext from '../../assets/icons/NavigateNext';
import NavigatePrevious from '../../assets/icons/NavigatePrevious';
import AccountBox from '../../assets/icons/AccountBox';
import LockIcon from '../../assets/icons/LockIcon';
import DesktopIcon from '../../assets/icons/DesktopIcon';
import ExitIcon from '../../assets/icons/ExitIcon';
import Share from '../../assets/icons/Share';
import Props from './props';
import UploadButton from '../../assets/icons/UploadButton';
import ModelsIcon from '../../assets/icons/ModelsIcon';

const AwesomeIcon = ({
  icon,
  fill,
  width,
  text,
  iconClass,
  ...iconProps
}) => (
  <Fragment>
    {icon === 'search' && <SearchIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'user' && <AccountCircle fill={fill} width={width} {...iconProps} />}
    {icon === 'home' && <HomeIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'trending' && <WhatshotIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'folder' && <FolderIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'dots' && <DotsIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'channels' && <ChannelsIcon fill={fill} width={width} {...iconProps} />}
    {(icon === 'vived' || icon === 'activities') && <VivedIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'forward' && <NavigateNext fill={fill} width={width} {...iconProps} />}
    {icon === 'backward' && <NavigatePrevious fill={fill} width={width} {...iconProps} />}
    {icon === 'close' && <CloseIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'account-box' && <AccountBox fill={fill} width={width} {...iconProps} />}
    {icon === 'lock' && <LockIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'desktop' && <DesktopIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'exit' && <ExitIcon fill={fill} width={width} {...iconProps} />}
    {icon === 'share' && <Share fill={fill} width={width} {...iconProps} />}
    {icon === 'upload' && <UploadButton fill={fill} width={width} {...iconProps} />}
    {icon === 'models' && <ModelsIcon fill={fill} width={width} {...iconProps} />}

    {icon === 'show' && <FontAwesomeIcon icon={faEye} {...iconProps} />}
    {icon === 'hide' && <FontAwesomeIcon icon={faEyeSlash} {...iconProps} />}
    
    {text && <span className="ml-2">{text}</span>}
  </Fragment>
);

AwesomeIcon.propTypes = Props;
export default AwesomeIcon;
