import PropTypes from "prop-types";

export const activityMetadataProps = PropTypes.shape({
  /**
   * The unique ID of the activity being edited.
   */
  id: PropTypes.string.isRequired,
  /**
   * The channelId to which this activity belongs.
   */
  channelId: PropTypes.string.isRequired,
  /**
   * The current title of the activity.
   */
  title: PropTypes.string,
  /**
   * The current description of the activity.
   */
  description: PropTypes.string,
  /**
   * The url of the current activity image.
   */
  image: PropTypes.string,
});