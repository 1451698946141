const colors = {
  yellow: {
    main: '#ECE400',
  },
  blue: {
    main: 'rgba(0, 168, 225, 1)',
  },
  green: {
    main: '#09EB18',
  },
  red: {
    main: '#F5160E',
  },
  gray: {
    light: 'rgba(232, 232, 232, 1)',
    main: 'rgba(112, 112, 112, 1)',
    dark: 'rgba(17, 17, 11, .6)',
  },
  white: {
    main: 'rgba(245, 245, 245, 1)',
  },
};

export default colors;
