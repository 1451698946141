import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader";
import "bootstrap/dist/css/bootstrap.css";
import Router from "./Router";
import store from "./store";
import NetworkWorking from "./containers/NetworkWorking/NetworkWorking";
import Resize from "./Resize";
import LTI from "./LTI";
import AmplifyAuthController from "./utils/AmplifyAuthController";
import Actions from "./Redux/actions";
import RollBarWrapper from './RollBarWrapper';

const App = () => {
  
  const [loading, setLoading] = useState(false);

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh100 = window.innerHeight;
  const vh = vh100 * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vh100", `${vh100}px`);

  // We listen to the resize event
  window.addEventListener("resize", (event) => setCssVariables(event));
  window.addEventListener("orientationchange", (event) => setCssVariables(event))

  const setCssVariables = (event) =>{
    // We execute the same script as before
    const vh100 = window.innerHeight;
    const vh = vh100 * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty("--vh100", `${vh100}px`);

  }

  //Essentially on component did mount
  useEffect(()=>{
    store.dispatch(Actions.Player.setDeviceInfo());
  },[])

  window.addEventListener("load", async () => {
    setLoading(false);
  });
 
  new LTI();
  new AmplifyAuthController();  

  return (
    <RollBarWrapper>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <NetworkWorking />
          <Resize />

          {loading && (
            <div className={`load${loading ? "" : " loaded"}`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path
                    fill="#4ce1b6"
                    d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                  />
                </svg>
              </div>
            </div>
          )}
          {!loading && <Router />}
        </BrowserRouter>
      </Provider>
    </RollBarWrapper>
  );
};

export default hot(module)(App);
