import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Actions from "../../Redux/actions";
import store from "../../store";

export default function useChannelContent(channelId) {
  const [hasFetched, setHasFetched] = useState(false);
  const [fetching, setIsFetching] = useState(false);

  const [numOfBundles, setNumOfBundles] = useState(0)
  const [myBundles, setMyBundles] = useState([])

  const [numOfCollections, setNumOfCollections] = useState(0)
  const [myCollections, setMyCollections] = useState([])

  const [numOfApps, setNumOfApps] = useState(0)
  const [myApps, setMyApps] = useState([])

  const [error, setError] = useState(null);

  const [myOrganizedResources, setMyOrganizedResources] = useState([]);
  const [myOrganizedResources_obj, setMyOrganizedResource_obj] = useState({});
  const [weNeedResources, setWeNeedResources] = useState(true);

  const dispatch = useDispatch();
  
  //Make API call if we need to
  useEffect(() => {
    if ( !fetching && !error && !hasFetched && weNeedResources ) {
      setIsFetching(true);

      dispatch(
        Actions.Channels.fetchChannelContent(channelId, (resp) => {
          if (!resp.success) {
            setError(resp.msg);
          }

          setHasFetched(true)
          setIsFetching(false);
        })
      );
    }
  }, [channelId, dispatch, hasFetched, error, fetching, weNeedResources]);

  useEffect(() => {
    function OrganizeResources() {
      let _resources = [...myBundles, ...myCollections, ...myApps],
      myResourcesInObject = {},
      myOrganizedResources = _resources.filter(resource => {
        if(resource.owner === channelId) {
          resource.assigned = true
        }
  
        if(resource.type === "bundle") {
          resource.name = resource.description
          resource.key = resource.id

          myResourcesInObject[resource.id] = resource
          return resource
        }
  
        if(resource.type === "channel-collection") {
          resource.name = resource.title
          resource.key = resource.id

          myResourcesInObject[resource.id] = resource
          return resource
        }
  
        if(resource.type === "channel-app") {
          resource.name = resource.title
          resource.key = resource.id

          myResourcesInObject[resource.id] = resource
          return resource
        }
  
        return null
      })
      
      setMyOrganizedResources(myOrganizedResources)
      setMyOrganizedResource_obj(myResourcesInObject)
      setWeNeedResources(false)
    }
    
    if(hasFetched && weNeedResources && !error) {
      OrganizeResources()
    }
  }, [hasFetched, weNeedResources, error, channelId, myApps, myCollections, myBundles])

  // Monitor data changes in store
  useEffect(() => {

    let unsubscribe = store.subscribe(() => {
      let bundles = store.getState().Channels.list[channelId]?.bundles ?? [],
      collections = store.getState().Channels.list[channelId]?.collections ?? [],
      channelApps = store.getState().Channels.list[channelId]?.channelApps ?? [],
      totalNum = Number(bundles.length + collections.length + channelApps)
      
      if(totalNum === 0 && !hasFetched && !weNeedResources) {
        setWeNeedResources(true)
      }

      if(bundles.length !== numOfBundles) {
        setNumOfBundles(bundles.length)
        setMyBundles(bundles)
        setWeNeedResources(true)
      }

      if(collections.length !== numOfCollections) {
        setNumOfCollections(collections.length)
        setMyCollections(collections)
        setWeNeedResources(true)
      }

      if(channelApps.length !== numOfApps) {
        setNumOfApps(channelApps.length)
        setMyApps(channelApps)
        setWeNeedResources(true)
      }
    }) 

    return () => {
      unsubscribe()
    }
  }, [myOrganizedResources, myOrganizedResources_obj, error, channelId, numOfBundles, numOfCollections, numOfApps, hasFetched, weNeedResources])

  return { myOrganizedResources, myOrganizedResources_obj, isFetchingChanContent: fetching, updatingChannelContent: weNeedResources, channelContentError: error };
}
