import PropTypes from "prop-types";
import React from 'react';

import { Typography } from "@rmwc/typography"; // Using SASS in main, so no css needed

import style from "./AuthInputFields.module.scss";

export const InputErrorMessage = ({ errors, inputName }) => {
  if (errors[inputName]) {
    if (errors[inputName].message) {
      return (
        <Typography use="body2" tag="span" className={style.errorMessage}>
          {errors[inputName].message}
        </Typography>
      );
    } else return null;
  } else return null;
};
InputErrorMessage.propTypes = {
  errors: PropTypes.object.isRequired,
  inputName: PropTypes.string.isRequired
}
