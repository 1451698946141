import React from "react";
import PropTypes from "prop-types";

import { Chip, ChipSet } from "@rmwc/chip";
import "@rmwc/chip/styles";
import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";

import style from "./ListFilter.module.scss";
import { Icon } from "@rmwc/icon";
import { Theme } from "@rmwc/theme";

export const ListFilter = ({
  keys,
  selected,
  setSelected,
  sortDirection,
  setSortDirection,
}) => {
  /**
   * The array of chip JSX elements mapped from the keys prop which will be displayed in the chipset.
   */
  let mappedChips;

  /**
   * Event handler for when the sort toggle is clicked. Each click will rotate through the three options
   */
  const onSort = () => {
    if (sortDirection === 1) {
      setSortDirection(-1);
    } else if (sortDirection === -1) {
      setSortDirection(null);
    } else {
      setSortDirection(1);
    }
  };

  /**
   * Creates the Sort toggle button.
   */
  const renderSortToggleButton = () => {
    // If we don't have a function to set the sort direction, don't display the sort button
    if (setSortDirection) {
      let trailingIcon;
      if (sortDirection === null) {
        trailingIcon = null;
      } else if (sortDirection === 1) {
        trailingIcon = "south";
      } else if (sortDirection === -1) {
        trailingIcon = "north";
      } else {
        console.log(
          `[ListFilter]: sortToggleButton - sort direction '${sortDirection}' is not being handled.`
        );
      }

      return (
        <Theme wrap use={["textSecondaryOnBackground"]}>
          <Button title="sort" onClick={onSort} className={style.sortToggle}>
            <Icon icon={{ icon: "sort", size: "small" }} />
            <Icon icon={{ icon: trailingIcon, size: "xsmall" }} />
          </Button>
        </Theme>
      );
    } else return null;
  };

  /**
   * Function that builds the list of chip components from the keys array.
   */
  const buildChipItems = () => {
    if (keys && keys.length > 0) {
      mappedChips = keys.map((k, index) => {
        return (
          <React.Fragment key={k.key}>
            <Chip
              selected={k.key === selected}
              label={k.name}
              onInteraction={() => setSelected(k.key)}
              id={k.key}
              icon={
                k.image
                  ? {
                      strategy: "component",
                      icon: <Avatar size="small" name={k.name} src={k.image} />,
                    }
                  : null
              }
            />
          </React.Fragment>
        );
      });
      return mappedChips;
    } else return null;
  };

  const renderFilterChips = () => {
    // Only return this filter component if there are chips to be displayed
    if (keys && keys.length > 0) {
      return (
        <ChipSet choice className={style.chipset}>
          {buildChipItems()}
        </ChipSet>
      );
    }
  };

  return (
    <div className={style.container}>
      <div className={style.chipsContainer}>{renderFilterChips()}</div>
      <div className={style.sortContainer}>{renderSortToggleButton()}</div>
    </div>
  );
};

ListFilter.propTypes = {
  /**
   * The array of keys used to filter the list
   */
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Dispalay name for the filter
       */
      name: PropTypes.string.isRequired,
      /**
       * Key for the filter
       */
      key: PropTypes.string.isRequired,
      /**
       * Image to be displayed in the chip's avatar component. Optional.
       */
      image: PropTypes.string,
    })
  ),
  /**
   * The key of the currently selected filter
   */
  selected: PropTypes.string,
  /**
   * The function to set the selected filter.
   */
  onSelected: PropTypes.func,
  /**
   * Bool defining if the list is sorted (true) or unsorted (false).
   */
  sorted: PropTypes.bool,
  /**
   * The direction this list is sorted. Options: 'none', 'a-z', or 'z-a'.
   * 1 = ascending, -1 = descending, null = unsorted
   */
  sortDirection: PropTypes.oneOf([-1, 1]),
};
