import React from 'react';

function VivedCorpLogoIcon(props) {
	const width = props.width || '100%';
	const height = props.height || '100%';
	const className = props.className || "";

	return (
		<svg height={height} width={width} className={className} viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg">
	<g>
		<defs/>
		<g id="_400_" data-name="400%">
			<g id="Logo">
				<path className="cls-1" style={{}} d="M176 24.14v59.28L122.67 168v-59.11L176 24.14z" fill="#0095d4"/>
				<path className="cls-2" style={{}} d="M69.36 108.89h53.32V168H69.36z" fill="#00a7e0"/>
				<path className="cls-1" style={{}} d="M16 24v59.28l53.33 84.58v-59.11L16 24z" fill="#0095d4"/>
				<path className="cls-3" style={{}} d="M176 24.14h-53.33L96.04 66.65 69.33 24H16l53.33 84.89h53.34L176 24.14z" fill="#4ec8f4"/>
			</g>
		</g>
	</g>
</svg>
	);
};

export default VivedCorpLogoIcon;