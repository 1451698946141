import React, { useState, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import style from "./AccountChannelDelete.module.scss";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useChannel } from "../../hooks/Channel/useChannel";
import { useHistory } from "react-router";
import PageError from "../../components/Error/PageError";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";
import { Typography } from "@material-ui/core";
import { ContentContainer } from "../../components/Layout/Layout";
import { Button } from "@rmwc/button";
import { useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

const AccountChannelDelete = (props) => {
  //Props
  const channelId = props.match.params.id;

  //States
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  //Hooks
  const { role } = useChannelRole(channelId);
  const { channel, error: getChannelError } = useChannel(channelId);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) return; //This effect only takes care of waiting for everything to get setup

    if (channel && role) {
      if (role !== "owner") {
        //Give 'em the boot! Not supposed to be here
        history.push(`/channel/${channelId}`);
      } else {
        setIsLoading(false);
      }
    }
  }, [role, channel, isLoading, history, channelId]);

  //Monitor for errors
  useEffect(() => {
    if (getChannelError) {
      setError("Something went wrong while getting channel " + getChannelError);
    }
  }, [getChannelError]);

  function HandleClickDelete() {
    dispatch(
      Actions.Channels.deleteChannel(channelId, (response) => {
        if (response.success) {
          snackbarQueue.notify({
            title: <b>Success!</b>,
            body: "You have deleted this channel",
          });
        } else {
          snackbarQueue.notify({
            title: <b>Uh oh!</b>,
            body: "Unable to delete channel",
          });
        }
      })
    );
    history.push(`/dashboard`);
  }

  function HandleClickCancel() {
    history.push(`/channel/${channelId}`);
  }

  function renderContent() {
    if (error) {
      return <PageError error={true} errorMessage={error} />;
    } else if (!isLoading) {
      return (
        <>
          <div className={style.yourChannels}>
            <div className={style.yourChannelsHeading}>
              <Typography use="headline5" tag="h1">
                Are you sure you want to delete this channel?
              </Typography>
            </div>
            <div className={style.yourChannelsManage}>
              <Button outlined icon="delete" onClick={HandleClickDelete}>
                Yes, delete
              </Button>
              <Button outlined onClick={HandleClickCancel}>
                No, don't delete
              </Button>
            </div>
          </div>
        </>
      );
    } else {
      return <PageLoadingIndicator />;
    }
  }
  return (
    <PageWrapper>
      <ContentContainer>{renderContent()}</ContentContainer>
    </PageWrapper>
  );
};

export default AccountChannelDelete;

// class AccountChannelDelete extends React.Component {
//   state = {
//     callSubmitted: false,
//     error: false,
//     errorMsg: ""
//   }

//   async componentDidMount() {
//     while (
//       this.props.loginStatus === LoginEnum.init ||
//       this.props.loginStatus === LoginEnum.isLoggingIn
//     ) {
//       await new Promise(resolve => {
//         setTimeout(resolve, 100);
//       }); //Wait 1/10 second for login process
//     }

//     if (this.props.loginStatus !== LoginEnum.isLoggedIn) {
//       this.props.history.push("/signin"); //Bump us out to the login page if we are not logged in
//     } else if (!this.props.channel) {
//       //If we get here then the user probably linked directly to this page. Bump them to their channels
//       this.props.history.push("/account/channels");

//       // Do we want users to be able to link directly to delete channel? If so, thne use the code below instead of the code above
//       //Get the channel if it does not exist
//       //this.props.fetchChannel(this.props.channelId);
//     }
//   }

//   HandleClickDelete = () => {
//     this.setState({ callSubmitted: true });
//     this.props.deleteChannel(this.props.channelId, response => {
//       if (response.success) {
//         this.props.history.push(`/account/channels`);
//       } else {
//         this.setState({
//           callSubmitted: false,
//           error: true,
//           errorMsg: response.msg
//         });
//       }
//     });
//   };

//   HandleClickCancel = () => {
//     this.props.history.push(`/channel/${this.props.channelId}`);
//   };

//   renderContent() {
//     if (this.state.error) {
//       return (
//         <Col xs={12}>
//           <Alert variant="danger">
//             <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
//             <p>
//               We got the following error when trying to delete the channel:{" "}
//               {this.state.errorMsg}
//             </p>
//           </Alert>
//         </Col>
//       );
//     } else if (this.state.callSubmitted) {
//       return (
//         <Col xs={12} className="x-center Activities__no">
//           <span>Deleting channel...</span>
//         </Col>
//       );
//     } else if (this.props.canDeleteChannel) {
//       return (
//         <div>
//           <p>Are you sure you want to delete this Channel?</p>
//           <button onClick={this.HandleClickDelete}>Yes, delete</button>
//           <button onClick={this.HandleClickCancel}>No, don't delete</button>
//         </div>
//       );
//     } else if (
//       this.props.loginStatus === LoginEnum.init ||
//       this.props.loginStatus === LoginEnum.isLoggingIn
//     ) {
//       return (
//         <div>
//           <p>Logging in...</p>
//         </div>
//       );
//     } else {
//       return (
//         <div>
//           <p>You are not authorized to delete this channel</p>
//         </div>
//       );
//     }
//   }

//   render() {
//     return (
//       <PageWrapper>
//         <Container fluid>
//           <Row className="AccountChannels">
//             <Col xs={12} className="AccountChannels__title">
//               <p>Delete Channel</p>
//             </Col>
//             <Col xs={12} className="AccountChannels__channels">
//               <Row>{this.renderContent()}</Row>
//             </Col>
//           </Row>
//         </Container>
//       </PageWrapper>
//     );
//   }
// }

// const MapStateToProps = (state, ownProps) => {
//   const channelId = ownProps.match.params.id;
//   let channel = null;
//   let canDeleteChannel = false;

//   if (state.Channels.list.hasOwnProperty(channelId)) {
//     channel = state.Channels.list[channelId];
//     if (channel.role === "owner") {
//       canDeleteChannel = true;
//     }
//   }

//   return {
//     userId: state.User._id,
//     loginStatus: state.User.loginStatus,
//     history: ownProps.history,
//     channelId,
//     channel,
//     canDeleteChannel
//   };
// };

// export default connect(MapStateToProps, {
//   fetchChannel: channelId => Actions.Channels.fetchAChannel(channelId),
//   deleteChannel: (channelId, userID, callback) =>
//     Actions.Channels.deleteChannel(channelId, userID, callback)
// })(AccountChannelDelete);
