/// https://web.dev/one-line-layouts/
import React from "react";
import PropTypes from "prop-types";

import style from "./SuperCentered.module.scss";

/**
 * @description A container that can have one child element to be super centered, both vertically and horizontally.
 */
export const SuperCentered = ({ className, children, ...otherProps }) => {
  const classNames = [style.parent, className].join(" ");

  return (
    <div className={classNames} {...otherProps}>
      {children}
    </div>
  );
};

SuperCentered.propTypes = {
  /**
   * Optional string of classes to be added to the parent container.
   * @type {string}
   */
  className: PropTypes.string,
  /**
   * The single child element to be super centered (vertically & horizontally) inside the parent container.
   * @type {element}
   */
  children: PropTypes.element.isRequired,
};
