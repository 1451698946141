import colors from '../scss/theme/color';

export function ActiveLinkCheck(url) {
  if (window.location.pathname === url) {
    return colors.blue.main;
  }
  return colors.gray.main;
}

export const trunkText = (str, limit = 10) => {
  if (str.length <= limit) return str;
  return str.substr(0, limit).concat('...');
};

export function ImageToBlob(file) {
  return new Promise((win, fail) => {
    let reader = new FileReader()

    reader.onload = function(e) {
      if(e.target.result) {
        win(e.target.result)
      } else {
        fail("")
      }
    };

    reader.readAsDataURL(file)
  })
}

export function fileSizeChecker(size) {
  
  const sizeInMB = ((size/1024)/1024).toFixed(4); // MB
  
  if(sizeInMB <= 4) {
    return true
  } else {
    return false
  }
}

export const AllBundlesCollectionId = "bb5d39f4-94ab-4658-a37f-83aa80386688";

export const getTodaysDateInyyyMMdd = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const MM = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear();
  const yyyyMMdd = [yyyy,MM,dd].join("-");

  return yyyyMMdd;
}

export const convertDateToyyyMMdd = (datestring) => {
  const date = new Date(datestring);
  const dd = String(date.getDate()).padStart(2, '0');
  const MM = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear();
  const yyyyMMdd = [yyyy,MM,dd].join("-");

  return yyyyMMdd;
}

export const sortAphabetically_forObjects = (list, sortKey) => {

  return list.sort(function(a, b) {

    var nameA = a[sortKey].toUpperCase(); // ignore upper and lowercase
    var nameB = b[sortKey].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0;  // names must be equal
  });
}

/// Define inline functions first
/**
 *
 * @param { string } key Required. The key of the primary sort column
 * @param { -1 | 1 | null } sortDir Required. 1 = ascending, -1 = descending, null = unsorted
 * @param { string } secondaryKey Optional. The key of the secondary sort column
 */
export const sortInAllDirections = (list, key, sortDir, secondaryKey) => {
  return list.sort(function (a, b) {
    let comparison = 0;

    // Handle primary sort
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    } else {
      // Handle secondary sort
      if (
        !a.hasOwnProperty(secondaryKey) ||
        !b.hasOwnProperty(secondaryKey)
      ) {
        return 0;
      }
      const varA2 =
        typeof a[secondaryKey] === "string"
          ? a[secondaryKey].toLowerCase()
          : a[secondaryKey];
      const varB2 =
        typeof b[secondaryKey] === "string"
          ? b[secondaryKey].toLowerCase()
          : b[secondaryKey];

      if (varA2 > varB2) {
        comparison = 1;
      }
      if (varA2 < varB2) {
        comparison = -1;
      }
    }

    // Return the comparison result with the proper sort order
    return sortDir === -1 ? comparison * -1 : comparison;
  })
};