import React from 'react';
import colors from '../../scss/theme/color';
import Props from './props';

const ExitIcon = ({
  fill = colors.gray.dark,
  width = '28px',
  ...iconProps
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...iconProps}>
    <title>ic_exit_to_app_24px</title>
    <g className="nc-icon-wrapper" fill={fill}>
      <path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    </g>
  </svg>
);

ExitIcon.propTypes = Props;
export default ExitIcon;
